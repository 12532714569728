import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const Logout = () => {
  const history = useHistory();

  useEffect(() => {
    // Perform any logout or cleanup operations here
    // Redirect after some delay or immediately
    history.push('/'); // Redirect back to login or home after logout
  }, [history]); // Ensure history is in dependency array

  return (
    <div>
  </div>
  );
};

export default Logout;

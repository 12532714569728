import React, { Component } from "react";
import { Table, Row, Col } from "reactstrap";
import Newsidebar from "../Dashboard/Newsidebar";
import { Modal } from "react-bootstrap";
import "../../Styles/userperformance.css";
import { Button, Input } from "reactstrap";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import { FormControl } from "react-bootstrap";
import moment from "moment";
import RadialSeparators from "./User_Performance/RadialSeparators";
import { BsCurrencyDollar } from "react-icons/bs";
import "../../Styles/loader.scss";
import { ip } from "../Api";
import axios from "axios";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from "recharts";
import {  DatePicker } from "antd";
import { IoIosRefresh } from "react-icons/io";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
let url, url1, url2, url4;
const { RangePicker } = DatePicker;
const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;
  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};
const getPath = (x, y, width, height) => `M${x},${y + height}
  C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${
  x + width / 2
}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
  x + width
}, ${y + height}
  Z`;
const data = [
  {
    name: "Aetna",
    no_of_touches: 2400,
    amt: 2400,
  },
  {
    name: "",
    no_of_touches: 1398,
    amt: 2210,
  },
];
let tooltip;
const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};
export class ManagementUserPerformanceDashboard extends Component {
  constructor(props) {
    super(props);
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    this.state = {
      usermodal: false,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      userDetails: [],
      firstname: sessionStorage.getItem("firstname"),
      lastname: sessionStorage.getItem("lastname"),
      details: [],
      denied: [],
      userid: "",
      uncountable: "",
      paid: [],
      worked: "",
      nis: [],
      inprocess: [],
      drack: [],
      pending: [],
      buttonsize: 0,
      final: [],
      req: [],
      error: [],
      searches: [],
      aging: [],
      manual: [],
      touch_wise: [],
      totalcollectedamount: "",
      claimworked: "",
      paidoutcomes: "",
      deniedamount: "",
      claimalloc: "",
      target: "",
      valueworked: "",
      valueOfInput1: "",
      valueOfInput2: "",
      filterstatus: "custom",
      fetching: false,
      Productivity: "",
      collectedpercent: "",
      clientslist: [],
      searches: [],
      partiallypaid: [],
      client: sessionStorage.getItem("clientid"),
      clientname: sessionStorage.getItem("clientname"),
      opendropdown: false,
      role_type: sessionStorage.getItem("role_type"),
      // user_id:"",
      firstamount: "",
      secondamount: "",
      thirdamount: "",
      fourthamount: "",
      fifthamount: "",
      sixthamount: "",
      seventhamount: "",
      finalamount: "",
      start: start,
      end: end,
      startdatetime: "",
      enddatetime: "",
    };
    this.applyCallback = this.applyCallback.bind(this);
  }
  applyCallback = (startDate, endDate) => {
    let start = moment(startDate._d).format().substring(0, 16); 
    let end = moment(endDate._d).format().substring(0, 16);
    this.setState({
      startdatetime: start,
      enddatetime: end,
    },
    () => {
      this.gettablevalues();
    });
  };
  CustomTooltip = ({ active, payload }) => {
    if (!active || !tooltip) return null;
    for (const bar of payload)
      if (bar.dataKey === tooltip)
        return (
          <div className="custom-tooltip">
            {bar.payload._id}
            <br />
            <p style={{ color: bar.fill, justifyContent: "center" }}>
              {bar.name}:&nbsp;${bar.value}
            </p>
          </div>
        );
    return null;
  };

  toggle = () => {
    this.setState({ opendropdown: !this.state.opendropdown });
  };
  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  gettablevalues = async () => {
    this.setState({ fetching: true });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.startdatetime && this.state.enddatetime) {
      if (this.state.client) {
        url = `${ip}/userdashboard/userdetailsall?clientId=${this.state.client}&start_date=${this.state.startdatetime}&end_date=${this.state.enddatetime}`;
      } else {
        url = `${ip}/userdashboard/userdetailsall`;
      }
    } else {
      if (this.state.client) {
        url = `${ip}/userdashboard/userdetailsall?clientId=${this.state.client}`;
      } else {
        url = `${ip}/userdashboard/userdetailsall`;
      }
    }
    const req = await axios
      .get(url, {
        headers: headers,
      })
      .then((req) => {
        const sorter = (a, b) => {
          if (a.claimsallocated.totalcount > b.claimsallocated.totalcount) {
            return a > b ? 1 : -1;
          }
          return a > b ? -1 : 1;
        };
        this.setState({
          userDetails: req.data.userdetails.sort(sorter),
          fetching: false,
        });

        if (req.data.userdetails.claimsworked.length !== 0) {
          this.setState({
            worked: req.data.userdetails.claimsworked[0].totalamount
              .toFixed(0)
              .toLocaleString(),
          });
        } else {
          this.setState({ worked: 0 });
        }
        if (req.data.userdetails.uncountableclaims.length !== 0) {
          this.setState({
            uncountable: req.data.userdetails.uncountableclaims[0].totalcount
              .toFixed(0)
              .toLocaleString(),
          });
        } else {
          this.setState({ uncountable: 0 });
        }
        this.getclaims();
      })
      .catch((err) => {});
  };

  getoutcomes = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.startdatetime && this.state.enddatetime) {
      if (this.state.client) {
        url = `${ip}/userdashboard/outcome?claimunder=${this.state.userid}&clientId=${this.state.client}&start_date=${this.state.startdatetime}&end_date=${this.state.enddatetime}`;
      } else {
        url = `${ip}/userdashboard/outcome?claimunder=${this.state.userid}`;
      }
    } else {
      if (this.state.client) {
        url = `${ip}/userdashboard/outcome?claimunder=${this.state.userid}&clientId=${this.state.client}`;
      } else {
        url = `${ip}/userdashboard/outcome?claimunder=${this.state.userid}`;
      }
    }
    
    const req = await axios
      .get(url, {
        headers: headers,
      })
      .then((req) => {
        this.setState({ paid: req.data.outcome.paid });
        this.setState({ denied: req.data.outcome.denied });
        this.setState({ nis: req.data.outcome.nis });
        this.setState({ inprocess: req.data.outcome.inprocess });
        this.setState({ drack: req.data.outcome.drack });
        this.setState({ pending: req.data.outcome.pending });
        this.setState({ error: req.data.outcome.error });
        this.setState({ searches: req.data.outcome.searches });
        this.setState({ req: req.data.outcome.request });
        this.setState({ final: req.data.outcome.finalized });
        this.setState({ manual: req.data.outcome.manual });
        this.setState({ partiallypaid: req.data.outcome.partiallypaid });
        if (req.data.outcome.paid.length !== 0) {
          if (
            this.state.claimworked !== 0 &&
            req.data.outcome.paid[0].totalcount !== 0
          ) {
            this.setState({
              paidoutcomes: (
                (req.data.outcome.paid[0].totalcount / this.state.claimworked) *
                100
              ).toFixed(0),
            });
          } else {
            this.setState({ paidoutcomes: 0 });
          }
        } else {
          this.setState({ paidoutcomes: 0 });
        }

        if (req.data.outcome.denied.length !== 0) {
          if (
            this.state.claimworked !== 0 &&
            req.data.outcome.denied[0].totalcount !== 0
          ) {
            this.setState({
              deniedamount: (
                (req.data.outcome.denied[0].totalcount /
                  this.state.claimworked) *
                100
              ).toFixed(0),
            });
          } else {
            this.setState({ deniedamount: 0 });
          }
        } else {
          this.setState({ deniedamount: 0 });
        }

        if (
          this.state.valueworked === 0 ||
          this.state.totalcollectedamount === 0
        ) {
          this.setState({ collectedpercent: 0 });
        } else {
          this.setState({
            collectedpercent: Math.ceil(
              this.state.totalcollectedamount / this.state.valueworked
            ),
          });
        }
        if (this.state.target === 0 || this.state.claimworked === 0) {
          this.setState({ Productivity: 0 });
        } else {
          let percent = this.state.claimworked / this.state.target;
          let result = percent * 100;
          this.setState({ Productivity: result.toFixed(0) });
        }
      })
      .catch((err) => {});
  };

  getaging = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.startdatetime && this.state.enddatetime) {
      if (this.state.client) {
        url = `${ip}/userdashboard/aging?claimunder=${this.state.userid}&clientId=${this.state.client}&start_date=${this.state.startdatetime}&end_date=${this.state.enddatetime}`;
      } else {
        url = `${ip}/userdashboard/aging?claimunder=${this.state.userid}`;
      }
    } else {
      if (this.state.client) {
        url = `${ip}/userdashboard/aging?claimunder=${this.state.userid}&clientId=${this.state.client}`;
      } else {
        url = `${ip}/userdashboard/aging?claimunder=${this.state.userid}`;
      }
    }
    
    const req = await axios
      .get(url, {
        headers: headers,
      })
      .then((req) => {
        this.setState({ aging: req.data.aging });
        if (req.data.aging.length !== 0) {
          let finalamt;
          req.data.aging.map((temp, index) => {
            switch (temp._id) {
              case "0-30days":
                return this.setState({
                  firstamount: temp.totalcount,
                });
              case "31-60days":
                return this.setState({
                  secondamount: temp.totalcount,
                });
              case "61-90days":
                return this.setState({
                  thirdamount: temp.totalcount,
                });
              case "91-120days":
                return this.setState({
                  fourthamount: temp.totalcount,
                });
              case "121-180days":
                return this.setState({
                  fifthamount: temp.totalcount,
                });
              case "181-365days":
                return this.setState({
                  sixthamount: temp.totalcount,
                });
              case "above 1year":
                return this.setState({
                  seventhamount: temp.totalcount,
                });
            }
          });
        }
        this.setState({
          finalamount:
            this.state.fourthamount +
            this.state.fifthamount +
            this.state.sixthamount +
            this.state.seventhamount,
        });
      })
      .catch((err) => {});
  };
  gettouches = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.startdatetime && this.state.enddatetime) {
      if (this.state.client) {
        url = `${ip}/userdashboard/avgtouches?claimunder=${this.state.userid}&clientId=${this.state.client}&start_date=${this.state.startdatetime}&end_date=${this.state.enddatetime}`;
      } else {
        url = `${ip}/userdashboard/avgtouches?claimunder=${this.state.userid}`;
      }
    } else {
      if (this.state.client) {
        url = `${ip}/userdashboard/avgtouches?claimunder=${this.state.userid}&clientId=${this.state.client}`;
      } else {
        url = `${ip}/userdashboard/avgtouches?claimunder=${this.state.userid}`;
      }
    }
    
    const req = await axios
      .get(url, {
        headers: headers,
      })
      .then((req) => {
        this.setState({ touch_wise: req.data.userdetails });
      })
      .catch((err) => {});
  };
  getclients = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/clientonboard/all`, { headers: headers })
      .then((res) => {
        if (res.data.clientonboard.length !== 0) {
          this.setState({
            clientslist: res.data.clientonboard,
          });
        } else {
          this.setState({
            clientslist: res.data.clientonboard,
          });
        }
      })
      .catch((err) => {});
  };
  getdownloadbutton = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.valueOfInput1 && this.state.valueOfInput2) {
      url = `${ip}/userdashboard/userdetailsall?clientId=${this.state.client}&start_date=${this.state.valueOfInput1}&end_date=${this.state.valueOfInput2}&button=${this.state.buttonsize}`;
    } else {
      url = `${ip}/userdashboard/userdetailsall?clientId=${this.state.client}&button=${this.state.buttonsize}`;
    }
    fetch(url, { headers: headers }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", "Userdashboard");
        a.click();
        URL.revokeObjectURL(a);
        // this.setState({
        //   loading: false,
        // });
      });
    });
  };
  valuedate = (option, e) => {
    if (option === "yesterday") {
      this.setState(
        {
          startdatetime : e.target.value,
          enddatetime : moment().subtract(1, "days").format("MM-DD-YYYY HH:mm"),
          filterstatus: option,
        },
        () => {
          this.gettablevalues();
        }
      );
    } else {
      this.setState(
        {
          startdatetime : e.target.value,
         enddatetime: moment().format("MM-DD-YYYY HH:mm"),
          filterstatus: option,
        },
        () => {
          this.gettablevalues();
        }
      );
    }
  };
  componentDidMount() {
    
    this.gettablevalues();
    // this.getclaims();
    this.getclients();

    // this.getoutcomes();
  }

  usertoggle = () => {
    const { usermodal } = this.state;
    this.setState({ usermodal: !usermodal });
  };
  render() {
    let value;
    let local = {
      format: "MM-DD-YYYY HH:mm",
      sundayFirst: false,
    };

    if (this.state.startdatetime && this.state.enddatetime) {
      value = `${moment(this.state.startdatetime.substring(
        0,
        16
      )).format("MM-DD-YYYY HH:mm")} ~ ${moment(this.state.enddatetime.substring(0, 16)).format("MM-DD-YYYY HH:mm")}`;
    } else {
      value = "Start Date ~ End Date";
    }

    return (
      <div>
      <Newsidebar name="User Dashboard" />
      <div className="align-row">
        {this.state.role_type === "SuperAdmin" && (
          <ButtonDropdown
            isOpen={this.state.opendropdown}
            toggle={this.toggle}
            style={{ width: "12%", marginLeft: "4%", position: "inherit" }}
          >
            <DropdownToggle caret>
              {this.state.clientname ? this.state.clientname : "All"}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() =>
                  this.setState({ clientname: "", client: "" }, () =>
                    this.gettablevalues()
                  )
                }
              >
                All
              </DropdownItem>
              {this.state.clientslist.map((e) => {
                return (
                  <DropdownItem
                    id={e.clientId}
                    key={e.clientId}
                    onClick={(e) =>
                      this.setState(
                        {
                          client: e.target.id,
                          clientname: e.target.innerText,
                        },
                        () => this.gettablevalues()
                      )
                    }
                  >
                    {e.client}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </ButtonDropdown>
        )}
        <div style={{ display: "flex", flexDirection: "row", fontSize:"12px" }}>
        <Input
          type="select"
          style={{ width: "20%", margin:"2%" }}
          className="form-control form-select"
          onChange={(e) => {
            const index = e.target.selectedIndex;
            const el = e.target.childNodes[index];
            const option = el.getAttribute("title");
            this.valuedate(option,e)
            this.setState(() => {
              sessionStorage.setItem(
                "querystartdate",
                this.state.startdatetime
              );
              sessionStorage.setItem(
                "queryenddate",
                this.state.enddatetime
              );
            });
          }}
        >
          <option value="" title="custom">
            Custom
          </option>
          <option value={moment().format("MM-DD-YYYY HH:mm")} title="">
            Today
          </option>
          <option
            value={moment().subtract(1, "days").format("MM-DD-YYYY HH:mm")}
            title="yesterday"
          >
            Yesterday
          </option>
          <option
            value={moment().subtract(3, "days").format("MM-DD-YYYY HH:mm")}
            title=""
          >
            Last 3 days
          </option>
          <option
            value={moment().subtract(7, "days").format("MM-DD-YYYY HH:mm")}
            title=""
          >
            Last 7 days
          </option>
          <option
            value={moment().subtract(30, "days").format("MM-DD-YYYY HH:mm")}
            title=""
          >
            Last 30 days
          </option>
          <option
            value={moment().subtract(365, "days").format("MM-DD-YYYY HH:mm")}
            title=""
          >
            Last 365 days
          </option>
        </Input>
        {this.state.filterstatus === "custom" && (
          <div
          style={{width:"30%", margin:"2%"}}>
          <DateTimeRangeContainer
          start={this.state.start}
          end={this.state.end}
          applyCallback={this.applyCallback}
          local={local}
        >
          <FormControl
            id="formControlsTextB"
            type="text"
            label="Text"
            placeholder="Select Date Range"
            value={value}
       
          />
        </DateTimeRangeContainer>
        </div>
        )}
        <IoIosRefresh
        style={{
          color: "blue",
          width: "48px",
          marginTop:"1.3%",
          height: "48px",
          padding: "10px 12px",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
        }}
        onClick={(e) => {
          this.setState({ startdatetime: "", enddatetime: "" });
        }}
      ></IoIosRefresh>
      </div>
      
        {this.state.userDetails.length === 0 ? (

          <div class="bot-ui">
            <div class="bot-head">
              <div class="eyes-container">
                <div class="to-left eye"></div>
                <div class="to-right eye"></div>
              </div>
            </div>
            <div class="bot-body">
              <div class="analyzer"></div>
            </div>
            <p style={{ fontSize: "20px", fontStyle: "italic" }}>
              No User Found
            </p>
          </div>
        ) : (
          <div className="table__data gridtable">
            <Table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th style={{ backgroundColor: "#CEDDFF" }}>User Name</th>
                  <th style={{ backgroundColor: "#CEDDFF" }}>Role</th>
                  <th style={{ backgroundColor: "#CEDDFF" }}>Client</th>
                  <th style={{ backgroundColor: "#CEDDFF" }}>Claims Allocated</th>
                  <th style={{ backgroundColor: "#CEDDFF" }}>Claims Worked</th>
                  <th style={{ backgroundColor: "#CEDDFF" }}>Claims Not Worked</th>
                  <th style={{ backgroundColor: "#CEDDFF" }}>Value Worked</th>
                  <th style={{ backgroundColor: "#CEDDFF", textAlign:"center" }}>
                    <HiOutlineViewGridAdd />
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.userDetails.map((data, index) => {
                  return (
                    <tr>
                      <td style={{ whiteSpace: "break-spaces" }}>
                        {data._id}
                      </td>
                      <td>{data.Role}</td>
                      <td>{data.Client}</td>
                      <td>{data.claimsallocated.totalcount}</td>
                      <td>{data.claimsworked.totalcount}</td>
                      <td>{data.claimsnotworked.totalcount}</td>

                      <td>${data.claimsworked.totalamount}</td>
                      <td>
                        <button
                          className="button__data"
                          onClick={() =>
                            this.setState(
                              {
                                userid: data._id,
                                totalcollectedamount:
                                  data.totalcollected.totalamount,
                                claimworked: data.claimsworked.totalcount,
                                claimalloc: data.claimsallocated.totalcount,
                                valueworked: data.claimsworked.totalamount,
                                target: data.Target,
                                uncountable:
                                  data.uncountableclaims.totalcount,
                              },
                              () => {
                                {
                                  this.getoutcomes();
                                }
                                {
                                  this.getaging();
                                }
                                {
                                  this.gettouches();
                                }
                                {
                                  this.usertoggle();
                                }
                              }
                            )
                          }
                        >
                          View More
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
        <Modal
          // style={{ width: "100%", height: "70%" }}
          show={this.state.usermodal}
          onHide={this.usertoggle}
          // onExit={this.reset}
          // centered
          animation="false"
          size="xl"
        >
          <Modal.Header closeLabel="" closeButton>
            <Modal.Title>View More Details</Modal.Title>
          </Modal.Header>
          <Modal.Body
            className="modal__data"
            style={{
              "max-height": "calc(100vh - 210px)",
              "overflow-y": "auto",
            }}
          >
            <div className="user_view_norm">
              <div className="allocated">
                <Row>
                  <h5>Allocated</h5>
                </Row>
                <br/>
                <br/>
                <Row>
                  <p style={{ marginTop: "-1px", fontSize: "19px" }}>
                    {this.state.claimalloc}
                  </p>
                </Row>
              </div>
              <div className="worked">
                <Row>
                  <h5>Worked</h5>
                </Row>
                <br/>
                <br/>
                <Row>
                  <p style={{ marginTop: "-1px", fontSize: "19px" }}>
                    {this.state.claimworked}
                  </p>
                </Row>
              </div>
              <div className="target">
                <Row>
                  <h5>Target</h5>
                </Row>
                <br/>
                <br/>
                <Row>
                  <p style={{ marginTop: "-1px", fontSize: "19px" }}>
                    {this.state.target ? this.state.target : 0}
                  </p>
                </Row>
              </div>
              <div className="productivity">
                <Row>
                  <h5>Productivity</h5>
                </Row>
                <br/>
                <br/>
                <Row>
                  <p style={{ marginTop: "-1px", fontSize: "19px" }}>
                    {this.state.Productivity}%
                  </p>
                </Row>
              </div>
              <div className="total_collected">
                <Row>
                  <h5>Total Collected</h5>
                </Row>
                <br/>
                <br/>
                <Row>
                  <p style={{ marginTop: "-25px", fontSize: "19px" }}>
                    <BsCurrencyDollar />
                    {this.state.totalcollectedamount}
                  </p>
                </Row>
              </div>
              <div className="absent">
                <Row>
                  <h5>Absent</h5>
                </Row>
                <br/>
                <br/>
                <Row>
                  <p style={{ marginTop: "-1px", fontSize: "19px" }}>1</p>
                </Row>
              </div>
              <div className="uncountable">
                <Row>
                  <h5>Uncountable</h5>
                </Row>
                <br/>
                <br/>
                <Row>
                  <p style={{ marginTop: "-1px", fontSize: "19px" }}>
                    {this.state.uncountable}
                  </p>
                </Row>
              </div>
              {/* <div className="newcard">
                <Row>
                  <h5>New</h5>
                </Row>
                <Row>
                  <p style={{ marginTop: "-1px", fontSize: "19px" }}>1</p>
                </Row>
              </div> */}
            </div>
            <div className="user__wrapper">
              <div className="user__grid__one">
                <Row>
                  {" "}
                  <Col>
                    <h3
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        paddingTop: "1%",
                        fontSize: "14px",
                      }}
                    >
                      Outcome
                    </h3>
                  </Col>
                  <hr style={{ width: "100%", marginLeft: "-1px" }} />
                </Row>
                <div style={{ width: "96%" }}>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          Paid
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          Denied
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          Not In System
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          Ack
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          DR Ack
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          Finalized
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          Req for Additional Information
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          General error
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          Pending
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          Manual
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          Searches
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          Partially Paid
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {this.state.paid.length !== 0 ? (
                          this.state.paid.map((j, index) => (
                            <td
                              style={{
                                backgroundColor: "whitesmoke",
                                textAlign: "center",
                              }}
                            >
                              {j.totalcount}
                            </td>
                          ))
                        ) : (
                          <td
                            style={{
                              backgroundColor: "whitesmoke",
                              textAlign: "center",
                            }}
                          >
                            0
                          </td>
                        )}
                        {this.state.denied.length !== 0 ? (
                          this.state.denied.map((k, index) => (
                            <td
                              style={{
                                backgroundColor: "whitesmoke",
                                textAlign: "center",
                              }}
                            >
                              {k.totalcount}
                            </td>
                          ))
                        ) : (
                          <td
                            style={{
                              backgroundColor: "whitesmoke",
                              textAlign: "center",
                            }}
                          >
                            0
                          </td>
                        )}
                        {this.state.nis.length !== 0 ? (
                          this.state.nis.map((n, index) => (
                            <td
                              style={{
                                backgroundColor: "whitesmoke",
                                textAlign: "center",
                              }}
                            >
                              {n.totalcount}
                            </td>
                          ))
                        ) : (
                          <td
                            style={{
                              backgroundColor: "whitesmoke",
                              textAlign: "center",
                            }}
                          >
                            0
                          </td>
                        )}
                        {this.state.inprocess.length !== 0 ? (
                          this.state.inprocess.map((d, index) => (
                            <td
                              style={{
                                backgroundColor: "whitesmoke",
                                textAlign: "center",
                              }}
                            >
                              {d.totalcount}
                            </td>
                          ))
                        ) : (
                          <td
                            style={{
                              backgroundColor: "whitesmoke",
                              textAlign: "center",
                            }}
                          >
                            0
                          </td>
                        )}
                        {this.state.drack.length !== 0 ? (
                          this.state.drack.map((dr, index) => (
                            <td
                              style={{
                                backgroundColor: "whitesmoke",
                                textAlign: "center",
                              }}
                            >
                              {dr.totalcount}
                            </td>
                          ))
                        ) : (
                          <td
                            style={{
                              backgroundColor: "whitesmoke",
                              textAlign: "center",
                            }}
                          >
                            0
                          </td>
                        )}
                        {this.state.final.length !== 0 ? (
                          this.state.final.map((f, index) => (
                            <td
                              style={{
                                backgroundColor: "whitesmoke",
                                textAlign: "center",
                              }}
                            >
                              {f.totalcount}
                            </td>
                          ))
                        ) : (
                          <td
                            style={{
                              backgroundColor: "whitesmoke",
                              textAlign: "center",
                            }}
                          >
                            0
                          </td>
                        )}
                        {this.state.req.length !== 0 ? (
                          this.state.req.map((r, index) => (
                            <td
                              style={{
                                backgroundColor: "whitesmoke",
                                textAlign: "center",
                              }}
                            >
                              {r.totalcount}
                            </td>
                          ))
                        ) : (
                          <td
                            style={{
                              backgroundColor: "whitesmoke",
                              textAlign: "center",
                            }}
                          >
                            0
                          </td>
                        )}
                        {this.state.error.length !== 0 ? (
                          this.state.error.map((err, index) => (
                            <td
                              style={{
                                backgroundColor: "whitesmoke",
                                textAlign: "center",
                              }}
                            >
                              {err.totalcount}
                            </td>
                          ))
                        ) : (
                          <td
                            style={{
                              backgroundColor: "whitesmoke",
                              textAlign: "center",
                            }}
                          >
                            0
                          </td>
                        )}
                        {this.state.pending.length !== 0 ? (
                          this.state.pending.map((pend, index) => (
                            <td
                              style={{
                                backgroundColor: "whitesmoke",
                                textAlign: "center",
                              }}
                            >
                              {pend.totalcount}
                            </td>
                          ))
                        ) : (
                          <td
                            style={{
                              backgroundColor: "whitesmoke",
                              textAlign: "center",
                            }}
                          >
                            0
                          </td>
                        )}
                        {this.state.manual.length !== 0 ? (
                          this.state.manual.map((m, index) => (
                            <td
                              style={{
                                backgroundColor: "whitesmoke",
                                textAlign: "center",
                              }}
                            >
                              {m.totalcount}
                            </td>
                          ))
                        ) : (
                          <td
                            style={{
                              backgroundColor: "whitesmoke",
                              textAlign: "center",
                            }}
                          >
                            0
                          </td>
                        )}
                        {this.state.searches.length !== 0 ? (
                          this.state.searches.map((s, index) => (
                            <td
                              style={{
                                backgroundColor: "whitesmoke",
                                textAlign: "center",
                              }}
                            >
                              {s.totalcount}
                            </td>
                          ))
                        ) : (
                          <td
                            style={{
                              backgroundColor: "whitesmoke",
                              textAlign: "center",
                            }}
                          >
                            0
                          </td>
                        )}
                        {this.state.partiallypaid.length !== 0 ? (
                          this.state.partiallypaid.map((ppaid, index) => (
                            <td
                              style={{
                                backgroundColor: "whitesmoke",
                                textAlign: "center",
                              }}
                            >
                              {ppaid.totalcount}
                            </td>
                          ))
                        ) : (
                          <td
                            style={{
                              backgroundColor: "whitesmoke",
                              textAlign: "center",
                            }}
                          >
                            0
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="user__grid__two">
                <Row>
                  <Col>
                    <h3
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        paddingTop: "1%",
                        fontSize: "14px",
                      }}
                    >
                      Worked Claims-Aging Contribution{" "}
                    </h3>
                  </Col>
                  <hr style={{ width: "100%", marginLeft: "-1px" }} />
                </Row>
                <div style={{ width: "96%" }}>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          0-30
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          31-60
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          61-90
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          91-120
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          121-180
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          181-365
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          Above 1year
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ backgroundColor: "whitesmoke" }}>
                          {this.state.firstamount}
                        </td>
                        <td style={{ backgroundColor: "whitesmoke" }}>
                          {this.state.secondamount}
                        </td>
                        <td style={{ backgroundColor: "whitesmoke" }}>
                          {this.state.thirdamount}
                        </td>
                        <td style={{ backgroundColor: "whitesmoke" }}>
                          {this.state.fourthamount}
                        </td>
                        <td style={{ backgroundColor: "whitesmoke" }}>
                          {this.state.fifthamount}
                        </td>
                        <td style={{ backgroundColor: "whitesmoke" }}>
                          {this.state.sixthamount}
                        </td>
                        <td style={{ backgroundColor: "whitesmoke" }}>
                          {this.state.seventhamount}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="user_wrapper__one">
              <div>
                <Row>
                  <Col>
                    <h3
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        paddingTop: "1%",
                        fontSize: "14px",
                      }}
                    >
                      Paid%
                    </h3>
                  </Col>
                  <hr style={{ width: "100%", marginLeft: "-1px" }} />
                  <Col>
                    {/* <Paid paidamt={this.state.paidoutcomes} /> */}
                    <CircularProgressbarWithChildren
                      value={this.state.paidoutcomes}
                      text={`${this.state.paidoutcomes}%`}
                      strokeWidth={6}
                      styles={buildStyles({
                        rotation: 0.25,
                        strokeLinecap: "butt",
                        textSize: "18px",
                        pathTransitionDuration: 0.5,
                        pathColor: `rgba(162, 29, 206, 0.8 ${
                          this.state.paidoutcomes / 100
                        })`,
                        textColor: "#041c3c",
                        trailColor: "#d6d6d6",
                        backgroundColor: "#3e98c7",
                      })}
                    >
                      <RadialSeparators
                        count={12}
                        style={{
                          background: "#fff",
                          width: "2px",
                          // This needs to be equal to props.strokeWidth
                          height: `${10}%`,
                        }}
                      />
                    </CircularProgressbarWithChildren>
                  </Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col>
                    <h3
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        paddingTop: "1%",
                        fontSize: "14px",
                      }}
                    >
                      Denied %
                    </h3>
                  </Col>
                  <hr style={{ width: "100%", marginLeft: "-1px" }} />
                  <Col>
                    {/* <Denied deniedamt={this.state.deniedamount} /> */}
                    <CircularProgressbarWithChildren
                      value={this.state.deniedamount}
                      text={`${this.state.deniedamount}%`}
                      strokeWidth={6}
                      styles={buildStyles({
                        rotation: 0.25,
                        strokeLinecap: "butt",
                        textSize: "18px",
                        pathTransitionDuration: 0.5,
                        pathColor: `rgba(255, 13, 0, 0.8)`,
                        textColor: "#041c3c",
                        trailColor: "#d6d6d6",
                        backgroundColor: "#3e98c7",
                      })}
                    >
                      <RadialSeparators
                        count={12}
                        style={{
                          background: "#fff",
                          width: "2px",
                          height: `${10}%`,
                        }}
                      />
                    </CircularProgressbarWithChildren>
                  </Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col>
                    <h3
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        paddingTop: "1%",
                        fontSize: "14px",
                      }}
                    >
                      Collected %
                    </h3>
                  </Col>
                  <hr style={{ width: "100%", marginLeft: "-1px" }} />
                  <Col>
                    {/* <Collected percent={this.state.collectedpercent} /> */}
                    <CircularProgressbarWithChildren
                      value={this.state.collectedpercent}
                      text={`${this.state.collectedpercent}%`}
                      strokeWidth={6}
                      styles={buildStyles({
                        rotation: 0.25,
                        strokeLinecap: "butt",
                        textSize: "18px",
                        pathTransitionDuration: 0.5,
                        pathColor: `rgba(0, 255, 0, 0.8)`,
                        textColor: "#041c3c",
                        trailColor: "#d6d6d6",
                        backgroundColor: "#3e98c7",
                      })}
                    >
                      <RadialSeparators
                        count={12}
                        style={{
                          background: "#fff",
                          width: "2px",
                          height: `${10}%`,
                        }}
                      />
                    </CircularProgressbarWithChildren>
                  </Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col>
                    <h3
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        paddingTop: "1%",
                        fontSize: "14px",
                      }}
                    >
                      Productivity %
                    </h3>
                  </Col>
                  <hr style={{ width: "100%", marginLeft: "-1px" }} />
                  <Col>
                    {/* <Productivity propercent={this.state.Productivity} /> */}
                    <CircularProgressbarWithChildren
                      value={this.state.Productivity}
                      text={`${this.state.Productivity}%`}
                      strokeWidth={6}
                      styles={buildStyles({
                        rotation: 0.25,
                        strokeLinecap: "butt",
                        textSize: "18px",
                        pathTransitionDuration: 0.5,
                        pathColor: `rgba(0, 255, 113, 1)`,
                        textColor: "#041c3c",
                        trailColor: "#d6d6d6",
                        backgroundColor: "#3e98c7",
                      })}
                    >
                      <RadialSeparators
                        count={12}
                        style={{
                          background: "#fff",
                          width: "2px",
                          height: `${10}%`,
                        }}
                      />
                    </CircularProgressbarWithChildren>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="user_wrapper_two">
              {/* <div>
                <div className="icon__data">
                  <SiAcclaim />{" "}
                </div>
                <p style={{fontSize:"30px"}}>
                  {this.state.totalcollectedamount.toLocaleString()}
                </p>
                <p>Total Collected</p>
              </div> */}

              <div>
                <Col>
                  <h3
                    style={{
                      fontWeight: "bold",
                      textAlign: "left",
                      paddingTop: "1%",
                      fontSize: "14px",
                    }}
                  >
                    Avg Touches for Resolution
                  </h3>
                </Col>
                <hr style={{ width: "100%", marginLeft: "-1px" }} />
                <Col onMouseOver={() => (tooltip = "totalamount")}>
                  <BarChart
                    width={1000}
                    height={300}
                    data={this.state.touch_wise}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <XAxis dataKey={"_id"} interval={0} fontSize="12" />
                    <YAxis
                      dataKey={"totalamount"}
                      interval={0}
                      fontSize="12"
                    />
                    <Tooltip
                      cursor={{ fill: "transparent" }}
                      content={<CustomTooltip />}
                    />
                    <Legend />
                    <Bar
                      dataKey={"totalamount"}
                      name="Total Amount"
                      shape={<TriangleBar />}
                      radius={[16, 16, 16, 16]}
                      fill="#00A0FC"
                      onMouseOver={() => (tooltip = "totalamount")}
                    />
                  </BarChart>
                </Col>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.usertoggle();
                // this.reset();
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
    </div>
      </div>

    );
  }
}

export default ManagementUserPerformanceDashboard;

import React, { Component } from "react";
import axios from "axios";
import "../../../Styles/admin.css";
import Newsidebar from "../../Dashboard/Newsidebar";
import { Row, Col } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button } from "reactstrap";
import Select from "react-dropdown-select";
import { Link } from "react-router-dom";
import "../../../Styles/allocation.css";
import "../../../Styles/loader.scss";
import Checkbox from "../../Allocation/Checkbox";
import { Label } from "reactstrap";
import "../../../Styles/loading.scss";
import moment from "moment-timezone";
import ReactPaginate from "react-paginate";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ip } from "../../Api";
import { BiDownArrowAlt,BiUpArrowAlt } from "react-icons/bi";
export default class KeyUnallocated extends Component {
  constructor(props) {
    super(props);
    this.multiselectRef = React.createRef();
    this.state = {
      fetching1: true,
      selecteduser: [],
      selectedage: "",
      ids: [],
      tabindex: 1,
      users: [],
      checkedAll: false,
      alldataresponse: [],
      selectuserErr: "",
      claim_id: "",
      clientslist: [],
      opendropdown: false,
      Location: "",
      doiagegrp: "",
      perPage: 10,
      currentpage: 0,
      offset: 0,
      agegrp: "",
      sortstate: "ASC",
      openicon: false,
      currentpage: 0,
      tableaction: [],
      currentpage1: 0,
      claimids: [],
      perPage1: 10,
      offset1: 0,
      keycategory: sessionStorage.getItem("keycategory"),
      keytype: sessionStorage.getItem("keytype"),
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      role_type: sessionStorage.getItem("role_type"),
      client: sessionStorage.getItem("clientid"),
      clientname: sessionStorage.getItem("clientname"),
    };
  }
  toggle = () => {
    if (this.state.ids.length !== 0) {
      const { modal } = this.state;
      this.setState({ modal: !modal });
    } else {
      alert("Select Claims to allocate");
    }
  };
  toggledropdown = () => {
    this.setState({ opendropdown: !this.state.opendropdown });
  };
  getclaims = async () => {
    //api call to get all users

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.client) {
      if (this.state.Location) {
        url = `${ip}/allocation/all?clientId=${this.state.client}&pageno=${
          this.state.currentpage + 1
        }&Location=${this.state.Location}&count=${this.state.perPage}`;
      } else {
        url = `${ip}/allocation/all?clientId=${this.state.client}&pageno=${
          this.state.currentpage + 1
        }&count=${this.state.perPage}`;
      }
    } else {
      url = `${ip}/allocation/all?pageno=${this.state.currentpage + 1}&count=${
        this.state.perPage
      }`;
    }
     axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          pageCount: Math.ceil(res.data.count / this.state.perPage),
          claims: res.data.claims,

          fetching2: false,
          fetching3: false,
        });
      })
      .catch((err) => {});
  };
  getusers = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
     axios
      .get(`${ip}/users/all?clientId=${this.state.client}`, { headers: headers })
      .then((res) => {
        let data = res.data.user;
        if (this.state.client) {
          data = data.filter((item) => {
            return (
              item.role === "Team Member" 
            );
          });
        } else {
          data = data.filter((item) => {
            return item.role === "Admin" || item.role==="SuperAdmin";
          });
        }
        this.setState({ users: data });
      })

      .catch((err) => {});
  };
  allocateclaims = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      ids: this.state.ids,
    };
     axios
      .put(
        `${ip}/allocation/allocate/${this.state.selecteduser[0]._id}`,
        value,
        {
          headers: headers,
        }
      )
      .then((res) => {
        alert(
          `claims allocated to user ${this.state.selecteduser[0].first_name}`
        );
        this.getdatakeyindicator();
        this.resetuser();
        this.getclaims();
        // this.toggle();
        // window.location.reload();
      })

      .catch((err) => {});
  };
  addsinglecheckboxid = (data, check) => {
    if (check) {
      this.setState(
        { ids: [...this.state.ids, data] }
        // this.toggle();
      );
    } else {
      let arr = this.state.ids.slice();
      let val = arr.filter((id) => {
        return id !== data;
      });
      this.setState(
        { ids: val }
        // this.toggle();
      );
    }
  };
  checkBoxAll = () => {
    if (this.state.checkedAll === false) {
      let idvalue = this.state.tableaction.map((data) => data._id);
      this.setState(
        { checkedAll: !this.state.checkedAll, ids: idvalue },
        () => {
          this.toggle();
        }
      );
    } else {
      this.setState({ checkedAll: !this.state.checkedAll, ids: [] }, () => {});
    }
  };
  checkboxsearchall = () => {
    if (this.state.checkedAll === false) {
      let idvalue = this.state.searchdataresponse.map((data) => data._id);
      this.setState(
        { checkedAll: !this.state.checkedAll, ids: idvalue },
        () => {
          this.toggle();
        }
      );
    } else {
      this.setState({ checkedAll: !this.state.checkedAll, ids: [] }, () => {});
    }
  };
  getdatakeyindicator = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.queryclient) {
      if (this.state.querystartdate && this.state.queryenddate) {
        if (this.state.queryLocation) {
          url = `${ip}/allocation/bucket/${this.state.keycategory}/usersummary?unallocatedpageno=${this.state.currentpage1}&type=${this.state.keytype}`;
        } else {
          url = `${ip}/allocation/bucket/${this.state.keycategory}/usersummary?unallocatedpageno=${this.state.currentpage1}&type=${this.state.keytype}`;
        }
      } else {
        if (this.state.queryLocation) {
          url = `${ip}/allocation/bucket/${this.state.keycategory}/usersummary?unallocatedpageno=${this.state.currentpage1}&type=${this.state.keytype}`;
        } else {
          url = `${ip}/allocation/bucket/${this.state.keycategory}/usersummary?unallocatedpageno=${this.state.currentpage1}&type=${this.state.keytype}`;
        }
      }
    } else {
      if (this.state.querystartdate && this.state.queryenddate)
        url = `${ip}/allocation/bucket/${this.state.keycategory}/usersummary?unallocatedpageno=${this.state.currentpage1}&type=${this.state.keytype}`;
      else
        url = `${ip}/allocation/bucket/${this.state.keycategory}/usersummary?unallocatedpageno=${this.state.currentpage1}&type=${this.state.keytype}`;
    }
     axios
      .get(url, { headers: headers })
      .then((res) => {
        this.setState({
          tableaction: res.data.claims,

          fetching1: false,
        });

        this.setState({
          pageCount1: Math.ceil(res.data.claimcount / this.state.perPage1),
        });
      })

      .catch((err) => {});
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        if (this.state.notselect) {
          this.getclaims();
        } else {
          this.selectedpayer();
        }
      }
    );
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  handlePageClickaction = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage1;

    this.setState(
      {
        currentpage1: selectedPage,
        offset1: offset,
      },
      () => {
        this.getdatakeyindicator();
      }
    );
  };
  sortingweb = (col) => {
    if (this.state.sortstate === "ASC") {
      const sorted = [...this.state.tableaction].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      this.setState({ tableaction: sorted });
      this.setState({ sortstate: "DSC" });
    }
    if (this.state.sortstate === "DSC") {
      const sorted = [...this.state.tableaction].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      this.setState({ tableaction: sorted });
      this.setState({ sortstate: "ASC" });
    }
  };
  resetuser = () => {
    this.setState({
      ids: [],
    });
  };
  componentDidMount() {
    

    this.getdatakeyindicator();
    this.getusers();
  }

  render() {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    const checkboxstyle = {
      marginTop: "27px",
    };
    return (
      <div className="align-row">
        <Newsidebar>
        {this.state.keycategory === "tflexceed" ? (
          <p>
            <Link
              to="/TFLexceed"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              TFL Exceed
            </Link>
          </p>
        ) : this.state.keycategory === "three" ? (
          <p>
            <Link
              to="/ThreeTouch-Claims"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Three Touch Claims
            </Link>
          </p>
        ) : this.state.keycategory === "twotouch" ? (
          <p>
            <Link
              to="/TwoTouch-Claims"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Two Touch Claims
            </Link>
          </p>
        ) : this.state.keycategory === "fourtouch" ? (
          <p>
            <Link
              to="/four-touch-claims"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Four Touch Claims
            </Link>
          </p>
        ) : this.state.keycategory === "lowfollowup" ? (
          <p>
            <Link
              to="/lowfollow-up"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Low Follow-up
            </Link>
          </p>
        ) : this.state.keycategory === "agebetween31to60days" ? (
          <p>
            <Link
              to="/age-between-31-60-days"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Age Between 31 to 60 Days
            </Link>
          </p>
        ) : this.state.keycategory === "lessthan30days" ? (
          <p>
            <Link
              to="/less-than-30days-NotFollow"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Less Than 30 Days
            </Link>
          </p>
        ) : this.state.keycategory === "lessthan180days" ? (
          <p>
            <Link
              to="/less-than-180days-claims"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Less Than 180 Days
            </Link>
          </p>
        ) : this.state.keycategory === "lessthan180days" ? (
          <p>
            <Link
              to="/less-than-180days-claims"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Less Than 180 Days
            </Link>
          </p>
        ) : this.state.keycategory === "morethan30" ? (
          <p>
            <Link
              to="/more-than-30days-notfollow"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              More Than 30 Days
            </Link>
          </p>
        ) : this.state.keycategory === "morethan60" ? (
          <p>
            <Link
              to="/more-than-60days-notfollow"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              More Than 60 Days
            </Link>
          </p>
        ) : this.state.keycategory === "morethan180" ? (
          <p>
            <Link
              to="/more-than-180days-notfollow"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              More Than 180 Days
            </Link>
          </p>
        ) : this.state.keycategory === "morethan90" ? (
          <p>
            <Link
              to="/more-than-90days-notfollow"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              More Than 90 Days
            </Link>
          </p>
        ) : this.state.keycategory === "onetouch" ? (
          <p>
            <Link
              to="/one-touch-claims"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              One Touch Claims
            </Link>
          </p>
        ) : this.state.keycategory === "zero" ? (
          <p>
            <Link
              to="/zero-touch-claims"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Not Touch Claims
            </Link>
          </p>
        ) : this.state.keycategory === "zerotouch" ? (
          <p>
            <Link
              to="/zero-touch-claims"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Not Touch Claims
            </Link>
          </p>
        ) : this.state.keycategory === "agebetween31to60days" ? (
          <p>
            <Link
              to="/age-between-31-60-days"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Age Between 31 to 60 Days
            </Link>
          </p>
        ) : this.state.keycategory === "agebetween61to90days" ? (
          <p>
            <Link
              to="/age-between-61-90-days"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Age Between 61 to 90 Days
            </Link>
          </p>
        ) : this.state.keycategory === "agebetween91to180days" ? (
          <p>
            <Link
              to="/age-between-91-180-days"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Age Between 91 to 180 Days
            </Link>
          </p>
        ) : this.state.keycategory === "agebetween181to365days" ? (
          <p>
            <Link
              to="/age-between-181-365-days"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Age Between 181 to 365 Days
            </Link>
          </p>
        ) : this.state.keycategory === "fastapproach" ? (
          <p>
            <Link
              to="/Fast-Approaching"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Fast Approach
            </Link>
          </p>
        ) : this.state.keycategory === "fourormoretouch" ? (
          <p>
            <Link
              to="/four-or-more-touch"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Four or More Touch
            </Link>
          </p>
        ) : this.state.keycategory === "agegreaterthan365days" ? (
          <p>
            <Link
              to="/agegreaterthan365days"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Age greater than 365 days
            </Link>
          </p>
        ) : null}
        </Newsidebar>
        <div className="align-column">
          <br />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                marginLeft: "20px",
              }}
            >
              {this.state.role_type === "Admin" && (
                <Button
                  style={{
                    borderRadius: "8px",
                    color: "white",
                    background: "#041c3c",
                    fontWeight: "bold",
                  }}
                  onClick={this.toggle}
                >
                  Allocate
                </Button>
              )}
            </div>
          </div>
          <br />
          <br />

          <div style={{ height: "100%", width: "100%" }}>
            {this.state.fetching1 ? (
              // <img src={loading} />
              <div>
                <p
                  style={{
                    fontSize: "23px",
                    marginTop: "10px",
                    color: "#000",
                  }}
                >
                  <span>Loading...</span>
                  <span>Please Wait...</span>
                </p>
                <div class="boxes body__loader dribbble">
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            ) : this.state.tableaction.length === 0 ? (
              /* <img src={nodata} alt="" className="nodata" /> */
              <div class="bot-ui">
                <div class="bot-head">
                  <div class="eyes-container">
                    <div class="to-left eye"></div>
                    <div class="to-right eye"></div>
                  </div>
                </div>
                <div class="bot-body">
                  <div class="analyzer"></div>
                </div>
                <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                  No Data Found
                </p>
              </div>
            ) : (
              <div>
                <div style={{ paddingLeft: "10px" }}>
                <div style={{ float: "right", marginTop: "20px" }}>
                    <ReactPaginate
                      previousLabel={<IoIosArrowBack />}
                      nextLabel={<IoIosArrowForward />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount1}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClickaction}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                  <TableContainer>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          {this.state.role_type === "Admin" && (
                            <StyledTableCell>
                              <input
                                type="checkbox"
                                checked={this.state.checkedAll}
                                onChange={this.checkBoxAll}
                              />
                            </StyledTableCell>
                          )}
                          <StyledTableCell
                            onClick={() => this.sortingweb("ClaimID")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Claim ID{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sortingweb("Location")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Location{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() =>
                              this.sortingweb("patientAccountNumber")
                            }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Account No{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sortingweb("DateOfService")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOS{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sortingweb("PatientFirstName")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Patient Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sortingweb("PatientDOB")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Patient DOB{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sortingweb("PlanCode")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Plan Code{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sortingweb("Pripayer")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Insurance Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sortingweb("PrimaryPolicyNo")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Policy No{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sortingweb("Physician")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Physician Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sortingweb("Age")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Age{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sortingweb("AgeGrp")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Age Grp{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sortingweb("DOIAge")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOI Age
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sortingweb("DOIAgeGrp")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOI AgeGrp
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sortingweb("type")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Type{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sortingweb("ClaimStatus")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Claim Status{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.tableaction &&
                          this.state.tableaction.map((data, index) => {
                            return (
                              <TableRow key={data._id}>
                                {this.state.role_type === "Admin" &&
                                  (this.state.checkedAll === true ? (
                                    <div style={checkboxstyle}>
                                      <input
                                        type="checkbox"
                                        checked={this.state.checkedAll}
                                        onChange={this.checkBoxAll}
                                      />
                                    </div>
                                  ) : (
                                    <div style={checkboxstyle}>
                                      <Checkbox
                                        handleidpush={this.addsinglecheckboxid}
                                        number={data._id}
                                        key={index}
                                      />
                                    </div>
                                  ))}

                                <StyledTableCell>
                                  {data.ClaimID}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.Location}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.patientAccountNumber
                                    ? data.patientAccountNumber
                                    : "-"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.DateOfService
                                    ? data.DateOfService
                                    : "-"}
                                </StyledTableCell>

                                <StyledTableCell>
                                  {data.PatientFirstName}&nbsp;
                                  {data.PatientLastName}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.PatientDOB ? moment(data.PatientDOB).format("MM/DD/YYYY") : "-"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.PlanCode ? data.PlanCode : "-"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.InsuranceName
                                    ? data.InsuranceName
                                    : "-"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.PrimaryPolicyNo}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.Physician}
                                </StyledTableCell>

                                <StyledTableCell>{data.Age}</StyledTableCell>
                                <StyledTableCell>{data.AgeGrp}</StyledTableCell>
                                <StyledTableCell>{data.DOIAge}</StyledTableCell>
                                <StyledTableCell>
                                  {data.DOIAgeGrp}
                                </StyledTableCell>
                                <StyledTableCell>{data.type}</StyledTableCell>
                                <StyledTableCell>
                                  {data.ClaimStatus}
                                </StyledTableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div style={{ float: "right", marginTop: "20px" }}>
                    <ReactPaginate
                      previousLabel={<IoIosArrowBack />}
                      nextLabel={<IoIosArrowForward />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount1}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClickaction}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <br />

          <br />

          {this.state.ids.length !== 0 && this.state.modal && (
            <Modal
              style={{ width: "30%" }}
              isOpen={this.state.modal}
              onHide={this.toggle}
              size="md"
              centered
            >
              <ModalHeader toggle={this.toggle}>Create Allocation</ModalHeader>
              <ModalBody>
                <Row style={{ margin: "0px" }}>
                  <Col>
                    <Label>Agent/Team Member Name</Label>
                    <Select
                      options={this.state.users}
                      values={this.state.selecteduser}
                      searchable={true}
                      dropdownHandle={true}
                      searchBy="first_name"
                      labelField="first_name"
                      noDataLabel="No matches found"
                      onChange={(values) =>
                        this.setState({
                          selecteduser: values,
                          selectuserErr: "",
                        })
                      }
                      required
                    />
                  </Col>
                  <div style={{ fontSize: 16, color: "red" }}>
                    {this.state.selectuserErr}
                  </div>
                </Row>
                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggle();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    this.allocateclaims();
                    this.resetuser();
                  }}
                  style={{ backgroundColor: "grey", color: "#fff" }}
                >
                  Allocate
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </div>
      </div>
    );
  }
}

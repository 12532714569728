import React, { Component } from "react";
import "./../Styles/login.css";
import { Button } from "reactstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Redirect, Link } from "react-router-dom";
import axios from "axios";
import obologo from "./../Images/Imglogo.png";
import "../Styles/login.scss";
import { ip } from "./Api";
class Login extends Component {
  state = {
    email: "",
    password: "",
    clientId: "",
    ispassword: false,
    err: 0,
    msg: "",
    access: false,
    refreshtoken: "",
    accesstoken: "",
    role_type: "",
    admin_access: false,
    member_access: false,
    role: "",
    username: "",
    checked: false,
    logincount: "",
    clientlist: [],
    message: "",
    clientidErr: "",
    obj_id: "",
    firstPage: "",
  };
  togglePassword = () => {
    const { ispassword } = this.state;
    this.setState({ ispassword: !ispassword });
  };

  getrole = async () => {
    this.renewaccesstoken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(`${ip}/role/all`, { headers: headers })
      .then((res) => {
        let rolenames = res.data.role.map((data) => {
          return data.role_name;
        });
        sessionStorage.setItem("getRolenamesdata", rolenames);
        this.setState({ access: true });
      })
      .catch((err) => {});
  };
  clientValidate = () => {
    let hasErr = "true";
    let clientidErr = "";
    if (this.state.clientId === "" || this.state.clientId === null) {
      clientidErr = "This field is required";
      this.setState({ clientidErr });
      hasErr = false;
    }
    return hasErr;
  };
  getFirstTrueAccessPage = (roleAccess) => {
    const accessMap = {
      management: {
        inventory: "/management-inventory-analysis",
        outcome: "/management-outcomes",
        actions: "/management-actions",
        industryStandards: "/management-industry-standards",
        adminUserperformanceDashboard: "/management-userperformance-dashboard",
        trendsAndPatterns: "/management-trends-patterns",
        reports: "/generate-reports",
        claimQuickCheck: "/single-claim-check",
      },
      claim: {
        keyIndicators: "/allocation-buckets",
        denialManagement: "/denial-management",
        claimManagement: "/claim-management",
        automationTracker: "/automation-track",
      },
      dataProcessing: {
        databaseSync: "/database-sync",
        apiSync: "/api-sync",
        dataUploadFile: "/data",
        dataDeleteFile: "/dataDeleting",
        dataConversionOcr: "/dataconversionocr",
        dataCleaning: "/datacleaning",
        dataMigration: "/datamigration",
      },
      workqueue: {
        workqueue: "/actions",
      },
      allocation: {
        allocated: "/allocate/allocated",
        unallocated: "/allocate/unallocated",
        rejectionAndDenials: "/allocate/rejection",
      },
      configure: {
        configure: "/configure/configure-list",
      },
      userManagement: {
        useronboard: "/admin/useronboard",
        role: "/roles",
      },
      eligibility: {
        eligibilityDataupload: "/eligibility/Dataupload",
        eligibilityTracker: "/eligibility/Outcomedata",
        eligibilityQuickcheck: "/eligibility/Check",
        eligibilityUploadAuthorization: "/eligibility/Auth",
        eligibilityPriorauthTracker: "/eligibility/PriorAuth",
      },
      userPerformanceDashboard: {
        userPerformanceDashboard: "/performances",
      },
      completed: {
        completed: "/user-completed-page",
      },
      inprogress: {
        inprogress: "/user-inprogress-page",
      },
      denial: {
        denial: "/user-denial-page",
      },
      needToCall: {
        needToCall: "/user-needtocall-page",
      },
      pending: {
        pending: "/user-nottouch-page",
      },
      userWorkqueue: {
        userWorkqueue: "/actions",
      },
      userRejectionAndDenials: {
        userRejectionAndDenials: "/action-rejection-denials",
      },
      claimTracker: {
        claimtrackerOpenclaims: "/open-claims",
        claimtrackerClosedclaims: "/closed-claims",
      },
    };

    const orderedAccessKeys = [
      {
        key: "management",
        subKeys: [
          "inventory",
          "outcome",
          "actions",
          "industryStandards",
          "adminUserperformanceDashboard",
          "trendsAndPatterns",
          "reports",
          "claimQuickCheck",
        ],
      },
      {
        key: "claim",
        subKeys: [
          "keyIndicators",
          "denialManagement",
          "claimManagement",
          "automationTracker",
        ],
      },
      {
        key: "dataProcessing",
        subKeys: [
          "databaseSync",
          "apiSync",
          "dataUploadFile",
          "dataDeleteFile",
          "dataConversionOcr",
          "dataCleaning",
          "dataMigration",
        ],
      },
      { key: "workqueue", subKeys: ["workqueue"] },
      {
        key: "allocation",
        subKeys: ["allocated", "unallocated", "rejectionAndDenials"],
      },
      { key: "configure", subKeys: ["configure"] },
      { key: "userManagement", subKeys: ["useronboard", "role"] },
      {
        key: "eligibility",
        subKeys: [
          "eligibilityDataupload",
          "eligibilityTracker",
          "eligibilityQuickcheck",
          "eligibilityUploadAuthorization",
          "eligibilityPriorauthTracker",
        ],
      },
      {
        key: "userPerformanceDashboard",
        subKeys: ["userPerformanceDashboard"],
      },
      { key: "completed", subKeys: ["completed"] },
      { key: "inprogress", subKeys: ["inprogress"] },
      { key: "denial", subKeys: ["denial"] },
      { key: "needToCall", subKeys: ["needToCall"] },
      { key: "pending", subKeys: ["pending"] },
      { key: "userWorkqueue", subKeys: ["userWorkqueue"] },
      { key: "userRejectionAndDenials", subKeys: ["userRejectionAndDenials"] },
      {
        key: "claimTracker",
        subKeys: ["claimtrackerOpenclaims", "claimtrackerClosedclaims"],
      },
    ];

    const findFirstAccessPage = (accessObj, accessMap, keys) => {
      for (const item of keys) {
        const key = item.key;
        const subKeys = item.subKeys;

        for (const subKey of subKeys) {
          if (
            accessObj[subKey] === true &&
            accessMap[key] &&
            accessMap[key][subKey]
          ) {
            return accessMap[key][subKey];
          }
        }
      }
      return null;
    };

    return findFirstAccessPage(roleAccess, accessMap, orderedAccessKeys);
  };

  handleSubmit = async (data) => {
    const Valid = this.clientValidate();
    if (Valid) {
      let value;
      if (!this.state.checked) {
        value = {
          clientId: parseInt(this.state.clientId),
          username: this.state.username,
          password: this.state.password,
        };
      } else {
        value = {
          clientId: parseInt(this.state.clientId),
          email: this.state.email,
          password: this.state.password,
        };
      }
      const res = await axios
        .post(`${ip}/auth/login`, value)
        .then((res) => {
          this.setState({
            message: res.data.message,
          });
          // Assuming response contains the JSON data you provided

          // Parse the JSON response
          const responseData = res.data;

          // Find the entry for the AdvancedMD client
          const advancedMDData = responseData.toggle.ehrDetails.find(
            (entry) => entry.client === "AdvancedMD"
          );

          // Extract the disable value for AdvancedMD
          const officekeyValue = advancedMDData
            ? advancedMDData.officekey
            : null;

          const access = res.data.accessToken;
          if (res.data.accessToken) {
            sessionStorage.setItem("officekey", officekeyValue);
            sessionStorage.setItem("api-sync", res.data.apisync);
            localStorage.setItem("isAuthenticated", true);
            sessionStorage.setItem("accesstoken", res.data.accessToken);
            sessionStorage.setItem("refreshtoken", res.data.refreshToken);
            sessionStorage.setItem(
              "firstname",
              res.data.user_details.first_name
            );
            sessionStorage.setItem(
              "Default_pagination",
              res.data.user_details.paginationCount
            );
            sessionStorage.setItem(
              "with_notes",
              res.data.toggle.combainedReportNotes
            );
            sessionStorage.setItem("object_id", res.data.toggle._id);
            sessionStorage.setItem("lastname", res.data.user_details.last_name);
            sessionStorage.setItem("role_type", res.data.role);
            sessionStorage.setItem("user_id", res.data.user_details._id);
            sessionStorage.setItem("totalusercount", res.data.totalusercount);
            sessionStorage.setItem(
              "claim_tracker",
              res.data.user_details.claimtracker
            );
            sessionStorage.setItem("pre-toggle", res.data.toggle.closedcpt);
            sessionStorage.setItem("email_id", res.data.user_details.email);
            sessionStorage.setItem("phone", res.data.user_details.phone_number);
            sessionStorage.setItem("clientid", res.data.clientId);
            sessionStorage.setItem("clientname", res.data.client);
            sessionStorage.setItem(
              "apiusername",
              res.data.user_details.username
            );

            // Convert the array into a string
            const clientDetailsString = JSON.stringify(
              res.data.user_details.clientDetails
            );
            //store role access
            sessionStorage.setItem(
              "role_access",
              JSON.stringify(res.data.roleaccess)
            );

            // Store the stringified array in sessionStorage
            sessionStorage.setItem("clientDetails", clientDetailsString);
            this.getrole();
            this.setState({
              accesstoken: res.data.accessToken,
              refreshtoken: res.data.refreshToken,
              role_type: res.data.role,
              logincount: res.data.user_details.logincount,
              access: true,
            });
            this.state.firstPage = this.getFirstTrueAccessPage(
              res.data.roleaccess
            );
            console.log("firstPage", this.state.firstPage);

            sessionStorage.setItem("access", true);
            let clientarray = res.data.uer_details.clientDetails.map(
              (data, index) => {
                return data.clientId;
              }
            );
            this.setState({
              clientlist: clientarray,
            });
          }
        })
        .catch((err) => {
          this.setState({ err: 1 });
          this.setState({ access: false });
        });
    }
  };

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  render() {
    if (this.state.logincount === 0) {
      return <Redirect to="/change_password" />;
    } else if (this.state.access === true) {
      return <Redirect to={this.state.firstPage} />;
    }

    return (
      <div style={{ overflow: "hidden" }}>
        <div className="nav-title">
          <img
            style={{
              width: "160px",
              left: "0px",
              marginTop: "10px",
              position: "fixed",
            }}
            src={obologo}
            alt="obo"
          />
        </div>
        <br />
        <br />
        <br />
        <div className="global-container">
          <div className="cardlogin login-form">
            <div className="card-bodylogin">
              <h4
                className="card-titlelogin text-center"
                style={{
                  fontFamily: "Josefin Sans",
                  fontWeight: 500,
                  color: "#041c3c",
                }}
              >
                RevShield A.I
              </h4>
              {this.state.message === "Access Granted" ? (
                <div>
                  <label
                    align="center"
                    style={{ color: "green", fontSize: 13 }}
                  >
                    {this.state.message}
                  </label>
                </div>
              ) : (
                <div>
                  <label align="center" style={{ color: "red", fontSize: 13 }}>
                    {this.state.message}
                  </label>
                </div>
              )}
              <div
                align="right"
                style={{
                  fontSize: "13px",
                  fontStyle: "italic",
                }}
              >
                <input
                  type="checkbox"
                  checked={this.state.checked}
                  onChange={() =>
                    this.setState({ checked: !this.state.checked })
                  }
                  style={{ fontSize: "13px" }}
                />
                &nbsp;Sign in with email
              </div>
              <div className="card-textlogin">
                <div className="form-group">
                  <div className="form__input-group">
                    <input
                      className="form__input"
                      type="text"
                      required
                      value={this.state.clientId}
                      onChange={(e) =>
                        this.setState({
                          clientId: e.target.value,
                          clientidErr: "",
                        })
                      }
                    />
                    <label
                      className="form__input-label required"
                      style={{ fontFamily: "Josefin Sans", fontWeight: 500 }}
                    >
                      CLIENT ID
                    </label>
                    <div style={{ fontSize: 13, color: "red" }}>
                      {this.state.clientidErr}
                    </div>
                  </div>
                  {!this.state.checked ? (
                    <div className="form__input-group">
                      <input
                        className="form__input"
                        type="text"
                        required
                        value={this.state.username}
                        onChange={(e) =>
                          this.setState({ username: e.target.value })
                        }
                      />
                      <label
                        className="form__input-label"
                        style={{ fontFamily: "Josefin Sans", fontWeight: 500 }}
                      >
                        USERNAME
                      </label>
                    </div>
                  ) : (
                    <div className="form__input-group">
                      <input
                        className="form__input"
                        type="email"
                        required
                        value={this.state.email}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                      />
                      <label
                        className="form__input-label"
                        style={{ fontFamily: "Josefin Sans", fontWeight: 500 }}
                      >
                        EMAIL ID
                      </label>
                    </div>
                  )}
                  <div className="form__input-group">
                    <input
                      className="form__input"
                      type={this.state.ispassword ? "text" : "password"}
                      required
                      value={this.state.password}
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                    />
                    <label
                      className="form__input-label"
                      style={{ fontFamily: "Josefin Sans", fontWeight: "500" }}
                    >
                      PASSWORD
                      {this.state.ispassword ? (
                        <AiFillEyeInvisible
                          // className="password-icon"
                          onClick={this.togglePassword}
                        />
                      ) : (
                        <AiFillEye
                          // className="password-icon"
                          onClick={this.togglePassword}
                        />
                      )}
                    </label>
                  </div>
                </div>

                <Button
                  style={{ width: "100%", backgroundColor: "#041c3c" }}
                  onClick={() => this.handleSubmit()}
                >
                  SIGN IN
                </Button>
                <Link to="/forgotpassword" style={{ float: "right" }}>
                  Forgot password?
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Login;

import React, { Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import Switch from "react-switch";
import { ip } from ".././Api";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import {Link} from "react-router-dom";
import { Table } from "react-bootstrap";

export default class GeneralSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      userid: sessionStorage.getItem("user_id"),
      checked: sessionStorage.getItem("pre-toggle") === "true",
      checkedNotes: sessionStorage.getItem("with_notes") === "true",
      obj_id: sessionStorage.getItem("object_id"),
      perPage:sessionStorage.getItem("Default_pagination"),
      logoffmodal:false,
      client: sessionStorage.getItem("clientid"),
      noteType:"",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.updatePagecount = this.updatePagecount.bind(this);
  }
  handleChange(checked) {
    this.setState({ checked: !this.state.checked }, ()=> {this.updateCPt()});
  }
  handleChange1(checked) {
    this.setState({ checkedNotes: !this.state.checkedNotes }, ()=> {this.updateNotes()});
  }
  openlogoffmodal = () => {
    this.setState({ logoffmodal: !this.state.logoffmodal });
  };
  updateCPt = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      closedcpt: this.state.checked,
    };
    const res = axios
      .put(
        `${ip}/clientonboard/${this.state.obj_id}`,
        value,
        {
          headers: headers,
        }
      )
      .then((res) => {
      })

      .catch((err) => {});
  };
  updatePagecount = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      paginationCount: parseInt(this.state.perPage),
      clientId:parseInt(this.state.client)
    };
    const res = axios
      .put(
        `${ip}/users/${this.state.userid}`,
        value,
        {
          headers: headers,
        }
      )
      .then((res) => {
        alert("Page count updated in all pages")
      })

      .catch((err) => {});
  };
  updateNotes = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      combainedReportNotes: this.state.checkedNotes,
    };
    const res = axios
      .put(
        `${ip}/clientonboard/${this.state.obj_id}`,
        value,
        {
          headers: headers,
        }
      )

      .catch((err) => {});
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  render() {
    return (
      <div>
      <Newsidebar name="General Settings" />
      <div  style={{ paddingTop:"1%",width:"60%", marginLeft:"15%", marginRight:"15%"}}>
          <Table  className="StandardTable" bordered>
          <thead>
          <tr>
          <th>Title</th>
          <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>Bring all CPTs</td>
          <td> <Switch
          onChange={this.handleChange}
          checked={this.state.checked}
        /></td>
          </tr>
          <tr>
          <td>Select page count</td>
          <td> <select
          id="pageCount"
          style={{height: "35px",  width:"60px"}}
          value={this.state.perPage}
          defaultValue={this.state.perPage}
          onChange={(e) =>
            this.setState(
              {
                perPage: e.target.value,
              },
              () => {
                this.updatePagecount(e);
              }
            )
          }
        >
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="150">150</option>
          <option value="200">200</option>
        </select>
</td>
          </tr>
          <tr>
          <td>With Notes</td>
          <td> <Switch
          onChange={this.handleChange1}
          checked={this.state.checkedNotes}
        /></td>
          </tr>
          </tbody>
          </Table>
      <Button style={{backgroundColor:"#041c3c", color:"#fff"}} onClick={()=> {this.openlogoffmodal()}}>OK</Button>
      </div>
      <Modal
      show={this.state.logoffmodal}
      onHide={this.openlogoffmodal}
      centered
    >
      <Modal.Body>The system is about to log off. Please confirm by selecting 'OK'.</Modal.Body>
      <Modal.Footer>
      <Link to="/">
        <Button
          style={{
            border: "1px solid grey",
            color: "black",
            backgroundColor: "#fff",
          }}
        >
          OK
        </Button>
        </Link>

      </Modal.Footer>
    </Modal>
      </div>
    );
  }
}

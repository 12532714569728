import { Component } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import Newsidebar from "./../../Dashboard/Newsidebar.js";
import { formatCurrency } from "./utils.js";
import React from "react";
import {
  ComposedChart,
  LabelList,
  Bar,
  XAxis,
  ResponsiveContainer,
  YAxis,
  Tooltip,
} from "recharts";
import { ip } from "../../Api";
import axios from "axios";
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  const ClaimTooltip = ({ active, payload }) => {
    if (active && payload) {
      return (
        <div className="custom-tooltip">
          {payload[0].payload.name}
          <br />
          <p style={{ color: payload[0].color }}>
            {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
          </p >
        </div>
      );
    }
    return null;
  };


class PatientPrimaryAR extends Component {
  state = {
    refreshtoken: sessionStorage.getItem("refreshtoken"),
    accesstoken: sessionStorage.getItem("accesstoken"),
    dataarray:[],
    client:sessionStorage.getItem("clientid")
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getData= async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.client) {
    url = `${ip}/trends/toppatientbalance?clientId=${this.state.client}`
    }
     else {
        url = `${ip}/trends/toppatientbalance`;
      }
    
    const req = axios
      .get(url, { headers: headers })
      .then((req) => {
        this.setState({
          dataarray:req.data.data
        })
      })

      .catch((err) => {});
  };
  componentDidMount() {
    
    this.getData();
  }


  render() {
    return (
      <div className="align-row">
        <Newsidebar />
        <div className="align-column">
          <div>
            <div
              style={{
                textAlign: "left",
                marginLeft: "3%",
                marginTop: "2%",
                fontSize: "14px",
              }}
            >
              <p>
                <Link
                  to="/management-trends-patterns"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Trends & Patterns
                </Link>
                &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;Top 10
                Patient with Maximum Primary AR
              </p>
            </div>
            <br />
            <ResponsiveContainer width="100%" height={600}>
              <ComposedChart
                width={500}
                height={400}
                data={this.state.dataarray}
                margin={{
                  top: 10,
                  right: 20,
                  left: 30,
                  bottom: 0,
                }}
              >
                <defs>
                  <linearGradient
                    id="colorUv"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="100%"
                    spreadMethod="reflect"
                  >
                    <stop offset="0" stopColor="#1f90f9" />
                    <stop offset="1" stopColor="#83c1fc" />
                  </linearGradient>
                </defs>

                <XAxis
                  dataKey="name"
                  scale="band"
                  height={160}
                  angle="-45"
                  interval={0}
                  tick={{
                    strokeWidth: 0,
                    fontSize: "15",
                    fill: "black",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    top: 400,
                    textAnchor: "end",
                    dy: 6,
                  }}
                />
                <YAxis
                  dataKey="totalamount"
                  tick={{
                    fontSize: "15",
                    fill: "black",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                  }}
                  interval={0}
                />
                <Tooltip content={<ClaimTooltip/>}/>
                <Bar
                dataKey="totalamount"
                  stroke="rgb(5, 101, 191)"
                  strokeWidth={2}
                  barSize={50}
                  radius={[10, 10, 10, 10]}
                  fill="url(#colorUv)"

                >
                  <LabelList
                    type="number"
                    position="top"
                    fill="#055cae"
                    fontWeight="600"
                    fontSize="15"
                    fontFamily="Poppins"
                    formatter={formatCurrency}
                  />
                </Bar>
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    );
  }
}
export default PatientPrimaryAR;

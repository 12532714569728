
import {Route,Redirect} from 'react-router-dom';
export const ProtectedRoute =({ component: Component,...rest})=>{
    return(
    <Route
    {...rest}
      render={
        (props) =>
          JSON.parse(sessionStorage.getItem("access")) === true?(
            <Component {...props} />):(<Redirect to="/"/>)

    }
/>
);
};
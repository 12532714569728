import React, { PureComponent } from "react";
import {
  ComposedChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { ip } from "../../../Api";
import axios from "axios";
const data2 = [
  { _id: "" },
  { _id: "" },
  { _id: "" },
  { _id: "" },
  { _id: "" },
];
let tooltip;
const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};
const ClaimTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;{payload[0].value}
        </p>
      </div>
    );
  }
  return null;
};
export default class PayerChartsNIS extends PureComponent {
  state = {
    payer_wise: [],
    refreshtoken: sessionStorage.getItem("refreshtoken"),
    accesstoken: sessionStorage.getItem("accesstoken"),
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getDetails = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if(this.props.Location){
      if (this.props.from && this.props.to) {
        url = `${ip}/outcome/payerwise?start_date=${this.props.from}&end_date=${this.props.to}&clientId=${this.props.client}&Location=${this.props.Location}`;
      } else {
        url = `${ip}/outcome/payerwise?clientId=${this.props.client}&Location=${this.props.Location}`;
      }}
      else{
        if (this.props.from && this.props.to) {
          url = `${ip}/outcome/payerwise?start_date=${this.props.from}&end_date=${this.props.to}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/outcome/payerwise?clientId=${this.props.client}`;
        } 
      }
    } else {
      if (this.props.from && this.props.to) {
        url = `${ip}/outcome/payerwise?start_date=${this.props.from}&end_date=${this.props.to}`;
      } else {
        url = `${ip}/outcome/payerwise`;
      }
    }

    const req = axios
      .get(url, { headers: headers })
      .then((req) => {
        let i;
        let data = [];
        if (req.data.payer_wise.nis.length !== 0) {
          for (i = 0; i < 5; i++) {
            data.push(req.data.payer_wise.nis[i]);
          }
          this.setState((this.state.payer_wise = data));
        } else {
          this.setState({ payer_wise: data2 });
        }
      })
      .catch((err) => {});
  };
  componentDidMount() {
    
    this.getDetails();
  }
  componentDidUpdate(prevProps) {
    if (
      !(this.props.from === prevProps.from && this.props.to === prevProps.to)
    ) {
      this.getDetails();
    } else if (this.props.client !== prevProps.client) {
      this.getDetails();
    }
    else if(this.props.Location !== prevProps.Location){
      this.getDetails();
    }
  }
  render() {
    return (
      <div>
        {this.props.payerwisepaid === "$" && (
          <div onMouseOver={() => (tooltip = "totalAmount")}>
            <ResponsiveContainer width={"100%"} height={400}>
              <ComposedChart
                width={1200}
                height={400}
                data={this.state.payer_wise}
                margin={{
                  top: 30,
                  right: 0,
                  bottom: 20,
                  left: 50,
                }}
              >
                <XAxis dataKey={"_id"} height={120} interval={0} angle={"-45"} minTickGap={6} tick={{strokeWidth:0,fontSize:"11",top:400,textAnchor:"end",dy:6}}
                />
                <YAxis dataKey={"totalAmount"} />
                <Tooltip content={<CustomTooltip />} />
                <Area
                  type="monotone"
                  dataKey={"totalAmount"}
                  fill="#6ab187"
                  stroke="#6ab187"
                  name="Total Amount"
                />
                <Bar
                  dataKey={"totalAmount"}
                  barSize={20}
                  fill="#4cb5f5"
                  name="Total Amount"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        )}
        {this.props.payerwisepaid === "#" && (
          <div onMouseOver={() => (tooltip = "totalClaims")}>
            <ResponsiveContainer width={"95%"} height={400}>
              <ComposedChart
                width={1200}
                height={400}
                data={this.state.payer_wise}
                margin={{
                  top: 30,
                  right: 0,
                  bottom: 20,
                  left: 50,
                }}
              >
                <XAxis dataKey={"_id"} height={120} interval={0} angle={"-45"} minTickGap={6} tick={{strokeWidth:0,fontSize:"11",top:400,textAnchor:"end",dy:6}}
                />
                <YAxis dataKey={"totalClaims"} />
                <Tooltip content={<ClaimTooltip />} />
                <Area
                  type="monotone"
                  dataKey={"totalClaims"}
                  fill="#6ab187"
                  stroke="#6ab187"
                  name="Total Claims"
                />
                <Bar
                  dataKey={"totalClaims"}
                  barSize={20}
                  fill="#4cb5f5"
                  name="Total Claims"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
    );
  }
}

import { Component } from "react";
import axios from "axios";
import Newsidebar from "../../Dashboard/Newsidebar";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { ip } from "../../Api";
const medicare = [
  {
    check: "null",
    date: "12-03-2021",
    ssn: "1",
    eno: "--",
    mrn: "dummy",
    claim: "enabled",
    id: "111",
    forwarded: "--",
    paid: "dummy",

    coin: "34",
    balance: "none",
  },
  {
    check: "null",
    date: "11-03-2021",
    ssn: "2",
    eno: "--",
    mrn: "none",
    claim: "disabled",
    id: "765",
    forwarded: "dummy",
    paid: "none",

    coin: "94",
    balance: "none",
  },
  {
    check: "null",
    date: "12-03-2021",
    ssn: "1",
    eno: "--",
    mrn: "dummy",
    claim: "enabled",
    id: "111",
    forwarded: "--",
    paid: "dummy",

    coin: "4",
    balance: "none",
  },
  {
    check: "null",
    date: "11-03-2021",
    ssn: "2",
    eno: "--",
    mrn: "none",
    claim: "disabled",
    id: "765",
    forwarded: "dummy",
    paid: "none",

    coin: "3",
    balance: "none",
  },
  {
    check: "null",
    date: "12-03-2021",
    ssn: "1",
    eno: "--",
    mrn: "dummy",
    claim: "enabled",
    id: "111",
    forwarded: "--",
    paid: "dummy",

    coin: "34",
    balance: "none",
  },
];

class MedicareSecondary extends Component {
  state = {
    refreshtoken: sessionStorage.getItem("refreshtoken"),
    accesstoken: sessionStorage.getItem("accesstoken"),
  };
  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  componentDidMount() {
    
  }

  render() {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:last-child td, &:last-child th,": {
        border: 1,
      },
    }));
    return (
      <div className="align-row">
        <Newsidebar />
        <div className="align-column">

          <br></br>
          <p style={{ textAlign: "left", marginLeft: "3%", fontSize: "14px" }}>
            <Link
              to="/management-trends-patterns"
              style={{ textDecoration: "none", color: "black" }}
            >
              Trends & Patterns
            </Link>
            &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp; Medicare
            Fwd to Secondary Pending{" "}
          </p>
          <div className="align-row items">
            <button className="addNew" onClick={this.toggle}>
              Process Document
            </button>
          </div>
          <br />
          {/* <Row style={{marginTop:"15px"}}>
              <Card  className="agedcard-head">
              <Col md="10" style={{textAlign:"left", fontFamily:'Poppins',fontWeight:"600", fontSize:"20px",paddingTop:"10px",}}>
              Medicare Fwd to Secondary Pending
                  </Col>
              <hr style={{ width: "100%", marginLeft: "-12px", }}/>  */}

          <TableContainer>
            <Table sx={{ minWidth: 100 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Check Number</StyledTableCell>
                  <StyledTableCell align="center">Check Date</StyledTableCell>
                  <StyledTableCell align="center">SSN</StyledTableCell>
                  <StyledTableCell align="center">MRN</StyledTableCell>
                  <StyledTableCell align="center">ENO</StyledTableCell>

                  <StyledTableCell align="center">Claim ID</StyledTableCell>
                  <StyledTableCell align="center">
                    Claim Forwarded To
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Claim Paid Amount
                  </StyledTableCell>
                  <StyledTableCell align="center"> Coins</StyledTableCell>
                  <StyledTableCell align="center">Balance</StyledTableCell>
                </TableRow>
              </TableHead>
              {medicare.length > 0 &&
                medicare.map((data, i) => {
                  return (
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell align="center">
                          {data.check}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data.date}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data.ssn}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {data.mrn}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data.eno}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data.claim}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data.forwarded}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data.paid}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data.coin}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data.balance}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  );
                })}
            </Table>
          </TableContainer>
          <br />
          {/* </Card>
        </Row> */}
        </div>
      </div>
    );
  }
}
export default MedicareSecondary;

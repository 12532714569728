import React, { Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import { ip } from "../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import datafile from "../../Files/DeleteClaims.xlsx";
import ReactPaginate from "react-paginate";
import {
  IoIosArrowBack,
  IoIosArrowForward,
} from "react-icons/io";
import {Table} from "react-bootstrap";

export default class DataDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      type: "",
      upload: [],
      id: "",
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("clientid"),
      username:   sessionStorage.getItem("firstname") +
      " " +
      sessionStorage.getItem("lastname"),
      datadeletiondata:[],
      offset: 0,
      currentpage: 0,
      perPage: 10,
    };
    this.fileInput = React.createRef();
  }
  onFileChange = (e) => {
    this.setState({ file: e.target.files[0] });
  };

  onFileUpload = async (e) => {
    if (!this.state.file) {
      // Display an error message or handle the validation as needed
      toast.error("Please select a file before uploading", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      return;
    }
    e.preventDefault();
    const value = new FormData();
    value.append("file", this.fileInput.current.files[0]);
    value.append("clientids", this.state.client);
    value.append("Username",this.state.username)
    const headers = {
      "Content-Type": `multipart/form-data`,
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    await axios({
      method: "post",
      url: `${ip}/helper/deleteclaimsandcpts`,
      data: value,
      headers: headers,
    })
      .then((response) => {
        //handle success
        if (response.status === 200) {
          toast.success("File Deleted successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        //handle error
        if (err.response) {
          if (err.response.status === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 400) {
            toast.error("Please include all the fields in excel data", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getdatadeletiondata = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    await axios
      .get(`${ip}/helper/datadeletionlog?clientId=${this.state.client}&pageno=${this.state.currentpage}&Username=${this.state.username}`, { headers: headers })
      .then((res) => {
        this.setState({
          pageCount: Math.ceil(res.data.count / this.state.perPage),
          datadeletiondata:res.data.arfollowuplog,
        });
      })
      .catch((err) => {});
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getdatadeletiondata();
      }
    );
  };
  componentDidMount() {
    this.getdatadeletiondata();
  }
  render() {
    return (
      <div>
      <Newsidebar name="Data Deletion" />
      <div className="align-row" style={{ overflow: "hidden" }}>
        <ToastContainer></ToastContainer>
        <div className="align-column">
          <div className="align-row items">
            <form method="post" style={{ width: "17%" }}>
              <div class="files">
                <input
                  type="file"
                  id="file"
                  required
                  ref={this.fileInput}
                  onChange={this.onFileChange}
                  style={{ display: "none" }}
                  accept=".xlsx"
                />
                <label for="file" className="filelabel">
                  {this.state.file ? (
                    <p align="center" style={{ color: "green" }}>
                      {this.state.file.name}
                    </p>
                  ) : (
                    <a className="dropfile">Select files here to delete</a>
                  )}
                </label>
              </div>
            </form>
          </div>
          <div className="boxcss">
            <div>
              <Row>
              <Col className="col-4">
              <p
                align="left"
                style={{
                  marginLeft: "14%",
                  marginTop: "2%",
                  fontSize: "20px",
                }}
              >
                List of upload details
              </p>
            </Col>
            <Col className="col-4"></Col>
                <Col className="col-1"></Col>
                <Col className="col-1">
                  <div
                    class="btn-group"
                    role="group"
                    style={{ marginLeft: "65%" }}
                  >
                    <button
                      type="button"
                      class="btn"
                      style={{ backgroundColor: "#041c3c", color: "#fff" }}
                    >
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "#a8adad",
                        }}
                        to={datafile}
                        download="DataDeletion.xlsx"
                        target="_blank"
                      >
                        {" "}
                        Sample
                      </Link>
                    </button>
                    <button
                      type="button"
                      class="btn"
                      style={{ backgroundColor: "#7DD657", color: "#fff" }}
                      onClick={this.onFileUpload}
                    >
                      Upload
                    </button>
                  </div>
                </Col>
              </Row>
              <hr/>
            </div>
          </div>
          <br/>
          {this.state.datadeletiondata.length !== 0 ?
            <div>
            <Table bordered className="StandardTable">
            <thead>
            <tr>
            <th>Claim Id</th>
            <th>User Id</th>
            <th>Date of modification</th>
            </tr>
            </thead>
              <tbody>
              {this.state.datadeletiondata &&
                this.state.datadeletiondata.map((data, index) => {
                  return (
                    <tr>
              <td>{data.ClaimID}</td>
              <td>{data.UserId}</td>
              <td>{data.Eventdate}</td>
              </tr>)}
                 )}
              </tbody> 
     
            </Table> 
            <div style={{ marginLeft: "3%", marginTop: "20px" }}>
            <ReactPaginate
              previousLabel={<IoIosArrowBack />}
              nextLabel={<IoIosArrowForward />}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={this.state.currentpage}
            />
          </div>
          </div>
            :
            <p style={{ fontSize: "20px", fontStyle: "italic", textAlign:"center" }}>
            No Data Found
          </p>
          }
   
    
        </div>
      </div>
      </div>
    );
  }
}

import { Component, React } from "react";
import axios from "axios";
import { ResponsiveContainer } from "recharts";
import { Row, Col } from "reactstrap";
import "../../../Styles/WebChartStatus.css";
import Table from "react-bootstrap/Table";
import WebCharts__Grid1_cell1 from "../Outcomes/GridCharts/WebCharts__Grid1_cell1";
import CPTCharts from "../Outcomes/GridCharts/CPTCharts";
import PayerCharts from "../Outcomes/GridCharts/Payercharts";
import PayerCategoryCharts from "../Outcomes/GridCharts/PayerCategorycharts";
import PayerChartsPaid from "../Outcomes/GridCharts/PayerChartsPaid";
import PayerClassNIS from "../Outcomes/GridCharts/PayerClassNIS";
import WebCharts__Grid2_cell2 from "../Outcomes/GridCharts/WebCharts__Grid2_cell2";
import { ip } from "../../Api";
import DeptCharts from "../Outcomes/GridCharts/DeptCharts";
import PayerChartsNIS from "../Outcomes/GridCharts/PayerChartsNIS";
import PayerClasspaidStatus from "../Outcomes/GridCharts/PayerClassPaidStatus";
import ReactPaginate from "react-paginate";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
let url = null;
class WebChartStatus extends Component {
  state = {
    refreshtoken: sessionStorage.getItem("refreshtoken"),
    accesstoken: sessionStorage.getItem("accesstoken"),
    denialbyagewise: [],
    name__web: "$",
    name__cpt: "$",
    name__dept: "$",
    name__nis: "$",
    name__nis__payer: "$",
    name__payer: "$",
    name__payerCategory: "$",
    name__payerCategory__nis: "$",
    name__payer__paid: "$",
    PayerClass__paid: "$",
    payer: "",
    table: [],
    input1: 0,
    perPage1: 10,
    pagecountoutcome: [],
    currentpage1: 0,
    offset1: 0,
    input2: 0,
    name__denialreason: "Payer1",
    tablevalues: [],

    // Payer1:"",
    // Payer2:"",
    // Payer3:"",
    // Payer4:"",
    // Payer5:"",
    details: [],
    payer_reason: "",
    checked: false,
    len: 0,
  };

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage1;

    this.setState(
      {
        currentpage1: selectedPage,
        offset1: offset,
      },
      () => {
        this.getDetailstable();
      }
    );
  };

  getallDetailstable = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.startdate && this.props.endDate) {
          url = `${ip}/outcome/pripayer?start_date=${this.props.startdate}&end_date=${this.props.endDate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/outcome/pripayer?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.startdate && this.props.endDate) {
          url = `${ip}/outcome/pripayer?start_date=${this.props.startdate}&end_date=${this.props.endDate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/outcome/pripayer?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.startdate && this.props.endDate) {
        url = `${ip}/outcome/pripayer?start_date=${this.props.startdate}&end_date=${this.props.endDate}`;
      } else {
        url = `${ip}/outcome/pripayer`;
      }
    }

    const req = await axios
      .get(url, { headers: headers })
      .then((req) => {
        this.setState({ details: req.data.pripyer });
      })
      .catch((err) => {});
  };
  getDetailstable = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    if (this.state.payer_reason === "") {
      if (this.props.client) {
        if (this.props.Location) {
          if (this.props.startdate && this.props.endDate) {
            url = `${ip}/outcome/topdenialreason?start_date=${this.props.startdate}&end_date=${this.props.endDate}&clientId=${this.props.client}&Location=${this.props.Location}&pageno=${this.state.currentpage1}`;
          } else {
            url = `${ip}/outcome/topdenialreason?clientId=${this.props.client}&Location=${this.props.Location}&pageno=${this.state.currentpage1}`;
          }
        } else {
          if (this.props.startdate && this.props.endDate) {
            url = `${ip}/outcome/topdenialreason?start_date=${this.props.startdate}&end_date=${this.props.endDate}&clientId=${this.props.client}&pageno=${this.state.currentpage1}`;
          } else {
            url = `${ip}/outcome/topdenialreason?clientId=${this.props.client}&pageno=${this.state.currentpage1}`;
          }
        }
      } else {
        if (this.props.startdate && this.props.endDate) {
          url = `${ip}/outcome/topdenialreason?start_date=${this.props.startdate}&end_date=${this.props.endDate}&pageno=${this.state.currentpage1}`;
        } else {
          url = `${ip}/outcome/topdenialreason?pageno=${this.state.currentpage1}`;
        }
      }
    } else {
      if (this.props.client) {
        if (this.props.Location) {
          if (this.props.startdate && this.props.endDate) {
            url = `${ip}/outcome/topdenialreason?pripayer=${this.state.payer_reason}&start_date=${this.props.startdate}&end_date=${this.props.endDate}&clientId=${this.props.client}&Location=${this.props.Location}&pageno=${this.state.currentpage1}`;
          } else {
            url = `${ip}/outcome/topdenialreason?pripayer=${this.state.payer_reason}&clientId=${this.props.client}&Location=${this.props.Location}&pageno=${this.state.currentpage1}`;
          }
        } else {
          if (this.props.startdate && this.props.endDate) {
            url = `${ip}/outcome/topdenialreason?pripayer=${this.state.payer_reason}&start_date=${this.props.startdate}&end_date=${this.props.endDate}&clientId=${this.props.client}&pageno=${this.state.currentpage1}`;
          } else {
            url = `${ip}/outcome/topdenialreason?pripayer=${this.state.payer_reason}&clientId=${this.props.client}&pageno=${this.state.currentpage1}`;
          }
        }
      } else {
        if (this.props.startdate && this.props.endDate) {
          url = `${ip}/outcome/topdenialreason?pripayer=${this.state.payer_reason}&start_date=${this.props.startdate}&end_date=${this.props.endDate}&pageno=${this.state.currentpage1}`;
        } else {
          url = `${ip}/outcome/topdenialreason?pripayer=${this.state.payer_reason}&pageno=${this.state.currentpage1}`;
        }
      }
    }
    const req = await axios
      .get(url, { headers: headers })
      .then((req) => {
        this.setState(
          {
            // len: req.data.topdenialreason.pripayerwise,
            tablevalues: req.data.topdenialreason,
            pageCount2: Math.ceil(req.data.count / this.state.perPage1),
          }
        );
        //   if (this.state.len.length !== 0) {
        //     this.setState({
        //       tablevalues: req.data.topdenialreason,
        //       pageCount2: Math.ceil(req.data.count / this.state.perPage1),
        // });
        //   } else {
        //     this.setState({ tablevalues: [] });
        //   }
      })
      .catch((err) => {});
  };
  handletablevalues = (e) => {
    this.setState(
      {
        payer_reason: e.target.value,
      },
      () => {
        this.getDetailstable();
      }
    );
  };

  componentDidMount() {
    // this.getoutcome();
    this.getDetailstable();
    this.getallDetailstable();
  }
  async componentDidUpdate(prevProps) {
    if (
      !(
        this.props.startdate === prevProps.startdate &&
        this.props.endDate === prevProps.endDate
      )
    ) {
      await this.getallDetailstable();
      await this.getDetailstable();
    } else if (this.props.client !== prevProps.client) {
      await this.getallDetailstable();
      await this.getDetailstable();
    } else if (this.props.Location !== prevProps.Location) {
      await this.getallDetailstable();
      await this.getDetailstable();
    }
  }
  togglecheckoutcome1 = () => {
    this.setState({ checked: this.state.checked });
  };

  render() {
    return (
      <div>
        {this.props.data2 === "Denial" && (
          <div className="web__wrapper__denial">
            <div className="web__grid__one">
              <Row>
                <Col>
                  <h6
                    style={{
                      fontWeight: "bold",

                      float: "left",
                    }}
                  >
                    Payer Class
                  </h6>
                </Col>
                <Col>
                  <div
                    style={{
                      marginLeft: "10%",
                      marginTop: "-1%",
                      alignItems: "left",
                    }}
                  >
                    <select
                      style={{
                        width: "100%",
                        height: "30%",
                        borderBlockColor: "gray",
                        borderRadius: "4px",
                      }}
                      onChange={(e) =>
                        this.setState({ name__dept: e.target.value })
                      }
                      value={this.state.name__dept}
                    >
                      <option value="$">Charges</option>
                      <option value="#">Claims</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <DeptCharts
                  from={this.props.startdate}
                  to={this.props.endDate}
                  deptwise={this.state.name__dept}
                  client={this.props.client}
                  Location={this.props.Location}
                />
              </Row>
            </div>
            <div className="web__grid__two">
              <Row>
                <Col>
                  <h6
                    style={{
                      fontWeight: "bold",
                      float: "left",
                    }}
                  >
                    CPT
                  </h6>
                </Col>
                <Col>
                  <div
                    style={{
                      marginLeft: "10%",
                      marginTop: "-1%",
                      alignItems: "left",
                    }}
                  >
                    <select
                      style={{
                        width: "100%",
                        height: "30%",
                        borderBlockColor: "gray",
                        borderRadius: "4px",
                      }}
                      onChange={(e) =>
                        this.setState({ name__cpt: e.target.value })
                      }
                      value={this.state.name__cpt}
                    >
                      <option value="$">Charges</option>
                      <option value="#">Claims</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <CPTCharts
                  from={this.props.startdate}
                  to={this.props.endDate}
                  client={this.props.client}
                  Location={this.props.Location}
                  cptwise={this.state.name__cpt}
                />
              </Row>
            </div>
            <div className="web__grid__three">
              <Row>
                <Col>
                  <h6
                    style={{
                      fontWeight: "bold",
                      float: "left",
                    }}
                  >
                    Payer
                  </h6>
                </Col>
                <Col>
                  <div
                    style={{
                      marginLeft: "10%",
                      marginTop: "-1%",
                      alignItems: "left",
                    }}
                  >
                    <select
                      style={{
                        width: "100%",
                        height: "30%",
                        borderBlockColor: "gray",
                        borderRadius: "4px",
                      }}
                      onChange={(e) =>
                        this.setState({ name__payer: e.target.value })
                      }
                      value={this.state.name__payer}
                    >
                      <option value="$">Charges</option>
                      <option value="#">Claims</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <PayerCharts
                  from={this.props.startdate}
                  to={this.props.endDate}
                  payerwise={this.state.name__payer}
                  client={this.props.client}
                  Location={this.props.Location}
                />
              </Row>
            </div>
            <div className="web__grid__four">
              <Row>
                <Col>
                  <h6
                    style={{
                      fontWeight: "bold",
                      float: "left",
                    }}
                  >
                    Department
                  </h6>
                </Col>
                <Col>
                  <div
                    style={{
                      marginLeft: "10%",
                      marginTop: "-1%",
                      alignItems: "left",
                    }}
                  >
                    <select
                      style={{
                        width: "100%",
                        height: "30%",
                        borderBlockColor: "gray",
                        borderRadius: "4px",
                      }}
                      onChange={(e) =>
                        this.setState({ name__payerCategory: e.target.value })
                      }
                      value={this.state.name__payerCategory}
                    >
                      <option value="$">Charges</option>
                      <option value="#">Claims</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <PayerCategoryCharts
                  from={this.props.startdate}
                  to={this.props.endDate}
                  categorywise={this.state.name__payerCategory}
                  client={this.props.client}
                  Location={this.props.Location}
                />
              </Row>
            </div>
            <div className="web__grid__five">
              <Col>
                <h6
                  style={{
                    fontWeight: "bold",
                    float: "left",
                  }}
                >
                  Top Denial Reasons
                </h6>
              </Col>
              <div
                style={{
                  marginLeft: "60%",
                  marginTop: "-1%",
                  alignItems: "left",
                }}
              >
                <br />
                <select
                  style={{
                    width: "100%",
                    height: "30%",
                    borderBlockColor: "gray",
                    borderRadius: "4px",
                  }}
                  value={this.state.payer_reason}
                  onChange={this.handletablevalues}
                >
                  <option value="">Select Pripayer....</option>
                  {this.state.details &&
                    this.state.details.map((data, index) => (
                      <option value={data._id.Pripayer}>
                        {data._id.Pripayer}
                      </option>
                    ))}
                </select>
              </div>
              <br />
              {this.state.payer_reason ? (
                this.state.tablevalues.length === 0 ? (
                  <div>No Reasons Found</div>
                ) : (
                  <div style={{ width: "100%", overflow: "scroll" }}>
                    <ul class="list_one">
                      <li>
                        <span class="category">Category</span>
                        {this.state.tablevalues.map((data, index) => {
                          return (
                            <span class="name">
                              {data._id.category ? data._id.category : "-"}
                            </span>
                          );
                        })}
                      </li>
                      <li>
                        <span class="category" style={{ whiteSpace: "nowrap" }}>
                          SubCategory
                        </span>
                        {this.state.tablevalues.map((data, index) => {
                          return (
                            <span class="name">
                              {data._id.claimSubCategory
                                ? data._id.claimSubCategory
                                : "-"}
                            </span>
                          );
                        })}
                      </li>
                      <li>
                        <span class="category" style={{ textAlign: "left" }}>
                          Reason
                        </span>
                        {this.state.tablevalues.map((data, index) => {
                          return (
                            <span class="name" style={{ whiteSpace: "nowrap" }}>
                              {data._id.Reason ? data._id.Reason : "-"}
                            </span>
                          );
                        })}
                      </li>
                    </ul>

                    <div style={{ marginLeft: "46%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount2}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                )
              ) : this.state.details.length === 0 ? (
                <div>No Reasons Found</div>
              ) : (
                <div style={{ width: "100%", overflow: "scroll" }}>
                  <ul class="list">
                    <li>
                      <span class="category">Pripayer</span>
                      {this.state.tablevalues.map((data, index) => {
                        return (
                          <span class="name" style={{ whiteSpace: "nowrap" }}>
                            {data._id.Pripayer ? data._id.Pripayer : "-"}
                          </span>
                        );
                      })}
                    </li>
                    <li>
                      <span class="category">Category</span>
                      {this.state.tablevalues.map((data, index) => {
                        return (
                          <span class="name">
                            {data._id.category ? data._id.category : "-"}
                          </span>
                        );
                      })}
                    </li>
                    <li>
                      <span class="category"> SubCategory</span>
                      {this.state.tablevalues.map((data, index) => {
                        return (
                          <span class="name">
                            {data._id.claimSubCategory
                              ? data._id.claimSubCategory
                              : "-"}
                          </span>
                        );
                      })}
                    </li>
                    <li>
                      <span class="category" style={{ textAlign: "left" }}>
                        Reason
                      </span>
                      {this.state.tablevalues.map((data, index) => {
                        return (
                          <span class="name" style={{ whiteSpace: "nowrap" }}>
                            {data._id.Reason ? data._id.Reason : "-"}
                          </span>
                        );
                      })}
                    </li>
                  </ul>
                  <div style={{ marginLeft: "46%", marginTop: "20px" }}>
                    <ReactPaginate
                      previousLabel={<IoIosArrowBack />}
                      nextLabel={<IoIosArrowForward />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount2}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}

              <br />
            </div>
          </div>
        )}
        {this.props.data2 === "Paid" && (
          <div className="web__wrapper__paid">
            <div className="paid__one">
              <Row>
                <Col>
                  <h6
                    style={{
                      fontWeight: "bold",

                      float: "left",
                    }}
                  >
                    Age wise
                  </h6>
                </Col>

                <Col>
                  <div
                    style={{
                      marginLeft: "40%",
                      marginTop: "-1%",
                      alignItems: "left",
                    }}
                  >
                    <select
                      style={{
                        width: "50%",
                        height: "30%",
                        borderBlockColor: "gray",
                        borderRadius: "4px",
                      }}
                      onChange={(e) =>
                        this.setState({ name__web: e.target.value })
                      }
                      value={this.state.name__web}
                    >
                      <option value="$">Charges</option>
                      <option value="#">Claims</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <WebCharts__Grid1_cell1
                  from={this.props.startdate}
                  to={this.props.endDate}
                  agegrpwise={this.state.name__web}
                  client={this.props.client}
                  Location={this.props.Location}
                />
              </Row>
            </div>
            <div className="paid__two">
              <Row>
                <Col>
                  <h6
                    style={{
                      fontWeight: "bold",

                      float: "left",
                    }}
                  >
                    Payer
                  </h6>
                </Col>
                <Col>
                  <div
                    style={{
                      marginLeft: "40%",
                      marginTop: "-1%",
                      alignItems: "left",
                    }}
                  >
                    <select
                      style={{
                        width: "70%",
                        height: "30%",
                        borderBlockColor: "gray",
                        borderRadius: "4px",
                      }}
                      onChange={(e) =>
                        this.setState({ name__payer__paid: e.target.value })
                      }
                      value={this.state.name__payer__paid}
                    >
                      <option value="$">Charges</option>
                      <option value="#">Claims</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <PayerChartsPaid
                  from={this.props.startdate}
                  to={this.props.endDate}
                  payerwisepaid={this.state.name__payer__paid}
                  client={this.props.client}
                  Location={this.props.Location}
                />
              </Row>
            </div>
            <div className="paid__three">
              <Row>
                <Col>
                  <h6
                    style={{
                      fontWeight: "bold",
                      float: "left",
                    }}
                  >
                    Payer Class
                  </h6>
                </Col>
                <Col>
                  <div
                    style={{
                      marginLeft: "40%",
                      marginTop: "-1%",
                      alignItems: "left",
                    }}
                  >
                    <select
                      style={{
                        width: "70%",
                        height: "30%",
                        borderBlockColor: "gray",
                        borderRadius: "4px",
                      }}
                      onChange={(e) =>
                        this.setState({ PayerClass__paid: e.target.value })
                      }
                      value={this.state.PayerClass__paid}
                    >
                      <option value="$">Charges</option>
                      <option value="#">Claims</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <PayerClasspaidStatus
                  from={this.props.startdate}
                  to={this.props.endDate}
                  classwise={this.state.PayerClass__paid}
                  client={this.props.client}
                  Location={this.props.Location}
                />
              </Row>
            </div>
          </div>
        )}
        {this.props.data2 === "NIS" && (
          <div className="web__wrapper__NIS">
            <div className="nis__one">
              <Row>
                <Col>
                  <h6
                    style={{
                      fontWeight: "bold",

                      float: "left",
                    }}
                  >
                    Age wise
                  </h6>
                </Col>
                <Col>
                  <div
                    style={{
                      marginLeft: "40%",
                      marginTop: "-1%",
                      alignItems: "left",
                    }}
                  >
                    <select
                      style={{
                        width: "50%",
                        height: "30%",
                        borderBlockColor: "gray",
                        borderRadius: "4px",
                      }}
                      onChange={(e) =>
                        this.setState({ name__nis: e.target.value })
                      }
                      value={this.state.name__nis}
                    >
                      <option value="$">Charges</option>
                      <option value="#">Claims</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <WebCharts__Grid2_cell2
                  from={this.props.startdate}
                  to={this.props.endDate}
                  agegrpwise__one={this.state.name__nis}
                  client={this.props.client}
                  Location={this.props.Location}
                />
              </Row>
            </div>
            <div className="nis__two">
              <Row>
                <Col>
                  <h6
                    style={{
                      fontWeight: "bold",
                      float: "left",
                    }}
                  >
                    Payer class
                  </h6>
                </Col>
                <Col>
                  <div
                    style={{
                      marginLeft: "40%",
                      marginTop: "-1%",
                      alignItems: "left",
                    }}
                  >
                    <select
                      style={{
                        width: "70%",
                        height: "30%",
                        borderBlockColor: "gray",
                        borderRadius: "4px",
                      }}
                      onChange={(e) =>
                        this.setState({
                          name__payerCategory__nis: e.target.value,
                        })
                      }
                      value={this.state.name__payerCategory__nis}
                    >
                      <option value="$">Charges</option>
                      <option value="#">Claims</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <PayerClassNIS
                  from={this.props.startdate}
                  to={this.props.endDate}
                  categorywise={this.state.name__payerCategory__nis}
                  client={this.props.client}
                  Location={this.props.Location}
                />
              </Row>
            </div>
            <div className="nis__three">
              <Row>
                <Col>
                  <h6
                    style={{
                      fontWeight: "bold",
                      float: "left",
                    }}
                  >
                    Payer
                  </h6>
                </Col>
                <Col>
                  <div
                    style={{
                      marginLeft: "40%",
                      marginTop: "-1%",
                      alignItems: "left",
                    }}
                  >
                    <select
                      style={{
                        width: "70%",
                        height: "30%",
                        borderBlockColor: "gray",
                        borderRadius: "4px",
                      }}
                      onChange={(e) =>
                        this.setState({ name__nis__payer: e.target.value })
                      }
                      value={this.state.name__nis__payer}
                    >
                      <option value="$">Charges</option>
                      <option value="#">Claims</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <PayerChartsNIS
                  from={this.props.startdate}
                  to={this.props.endDate}
                  payerwisepaid={this.state.name__nis__payer}
                  client={this.props.client}
                  Location={this.props.Location}
                />
              </Row>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default WebChartStatus;

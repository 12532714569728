import React, { Component } from "react";
import axios from "axios";
import "../../Styles/admin.css";
import "../../Styles/configure.css";
import Newsidebar from "../Dashboard/Newsidebar";
import { Row, Col } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { MdOutlineSearch } from "react-icons/md";
import Tabs, { Tab } from "react-best-tabs";
import "react-best-tabs/dist/index.css";
import ReactPaginate from "react-paginate";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import "../../Styles/PayerMaster.css";
import { styled } from "@mui/material/styles";
import {Table} from "react-bootstrap";
import { Card } from "react-bootstrap";
import { ip } from ".././Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loading from "../../Images/loader.gif";
import { roundToNearestMinutes } from "date-fns";
class Cpt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      updatemodal: false,
      deletemodal: false,
      dataforupdate: null,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      role_type: sessionStorage.getItem("role_type"),
      client: sessionStorage.getItem("clientid"),
      clientname: sessionStorage.getItem("clientname"),
      id: "",
      cptdata: [],
      modifierdata: [],
      cptcode: "",
      searchBy: "cpt_code",
      querySearch: "",
      searchtext: "",
      unit: "",
      price: "",
      expectamt: "",
      revenuecode: "",
      desc: "",
      labcode: "No",
      status: "Inactive",
      mod1: "",
      mod2: "",
      mod3: "",
      mod4: "",
      modifier1: [],
      modifier2: [],
      modifier3: [],
      modifier4: [],
      drug: false,
      descinsv: false,
      createmodal: false,
      rlsd: false,
      provider: "No",
      notbill: false,
      from: "",
      to: "",
      gender: "",
      frequency: "",
      effectdate: "",
      expdate: "",
      insurance: [],
      inmae1: "",
      iname2: "",
      iprice1: "",
      iprice2: "",
      updatecptcode: "",
      updateunit: "",
      updateprice: "",
      updateexpectamt: "",
      updaterevenuecode: "",
      updatedesc: "",
      updatelabcode: "",
      updatestatus: "",
      updatemodifier1: [],
      updatemodifier2: [],
      updatemodifier3: [],
      updatemodifier4: [],
      updatedrug: "",
      searchdataresponse: [],
      searchclaimdata: [],
      updatedescinsv: "",
      updaterlsd: "",
      updateprovider: "",
      updatenotbill: "",
      updatefrom: "",
      updateto: "",
      updategender: "",
      updatefrequency: "",
      updateeffectdate: "",
      updateexpdate: "",
      updateinsurance: [],
      updateinmae1: "",
      updateiname2: "",
      updateiprice1: "",
      updateiprice2: "",
      updateid: "",
      updatemod1: "",
      updatemod2: "",
      updatemod3: "",
      updatemod4: "",
      offset: 0,
      currentpage: 0,
      perPage: 10,
      categorydata: [],
      categoryoption: null,
      modifieroption: [],
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  toggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };
  slicecpt = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(`${ip}/cptmaster/api`, { headers: headers })
      .then((res) => {
        this.getcpt();
      })
      .catch((err) => {});
  };
  // toogle modal for delete
  toggledelete = () => {
    this.setState({ deletemodal: !this.state.deletemodal });
  };
  //toggle modal for update
  toggleupdate = () => {
    this.setState({ updatemodal: !this.state.updatemodal });
  };
  //filter by  Payer category
  handleSearch = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    if (this.state.querySearch === "") {
      toast.error("Please fill the search value!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // eslint-disable-next-line
    else {
      const res = await axios
        .get(
          `${ip}/cptmaster/advancedfilter?${this.state.searchBy}=${this.state.querySearch}`,
          { headers: headers }
        )
        .then((res) => {
          if(res){
            toast.success("Searching completed!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
          else{
            toast.warning("Fetching data", {
              position: "top-right",
              hideProgressBar: false,
              pauseOnHover: true,
              progress: true,
            }); 
          }
          this.setState({ searchtext: 1 });
          this.setState({ search_result: res.data.cpt });
          const slice = res.data.cpt.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );
          this.setState({
            searchdataresponse: res.data.cpt,
            pageCount: Math.ceil(res.data.cpt.length / this.state.perPage),
            searchclaimdata: slice,
          });
        })
        .catch((err) => {});
    }
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getcpt();
      }
    );
  };
  handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  handleSubmit = async () => {
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      cpt_code: this.state.cptcode,
      unit: this.state.unit,
      price: this.state.price,
      expected_amt: this.state.expectamt,
      revenue_code: this.state.revenuecode,
      description: this.state.desc,
      lab_code: this.state.labcode,
      status: this.state.status,
      modifier1: this.state.mod1,
      modifier2: this.state.mod2,
      modifier3: this.state.mod3,
      modifier4: this.state.mod4,
      drug: this.state.drug,
      desc_in_sv101: this.state.descinsv,
      rlsd: this.state.rlsd,
      supervising_provider: this.state.provider,
      not_bill_to_insurance: this.state.notbill,
      insurance_category: this.state.categoryoption,
      age_from: this.state.from,
      age_to: this.state.to,
      gender: this.state.gender,
      frequency: this.state.frequency,
      effective_date: this.state.effectdate,
      expiry_date: this.state.expdate,
    };
    // eslint-disable-next-line
    const res = await axios
      .post(`${ip}/cptmaster/create`, value, { headers: headers })
      .then((res) => {
        if (res) {
          this.toggle();
          this.slicecpt();
          this.getcpt();
          this.setState({ createmodal: !this.state.createmodal });
          toast.success("CPT created", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          this.toggle();
          this.slicecpt();
          this.getcpt();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };

  // fuction for prepopulate the particular data
  settingupdatestate = function () {
    this.setState({
      updatecptcode: this.state.dataforupdate.cpt_code,
      updateunit: this.state.dataforupdate.unit,
      updateid: this.state.dataforupdate._id,
      updateprice: this.state.dataforupdate.price,
      updateexpectamt: this.state.dataforupdate.expected_amt,
      updaterevenuecode: this.state.dataforupdate.revenue_code,
      updatedesc: this.state.dataforupdate.description,
      updatelabcode: this.state.dataforupdate.lab_code,
      updatestatus: this.state.dataforupdate.status,
      updatemod1: this.state.dataforupdate.modifier1,
      updatemod2: this.state.dataforupdate.modifier2,
      updatemod3: this.state.dataforupdate.modifier3,
      updatemod4: this.state.dataforupdate.modifier4,
      updatedrug: this.state.dataforupdate.drug,
      updatedescinsv: this.state.dataforupdate.desc_in_sv101,
      updaterlsd: this.state.dataforupdate.rlsd,
      updateprovider: this.state.dataforupdate.supervising_provider,
      updatenotbill: this.state.dataforupdate.not_bill_to_insurance,
      updateinsurance: this.state.dataforupdate.insurance_category,
      updatefrom: this.state.dataforupdate.age_from,
      updateto: this.state.dataforupdate.age_to,
      updategender: this.state.dataforupdate.gender,
      updatefrequency: this.state.dataforupdate.frequency,
      updateeffectdate: this.state.dataforupdate.effective_date,
      updateexpdate: this.state.dataforupdate.expiry_date,
    });
  };

  putrequests = async (dataid) => {
    this.setState({ createmodal: !this.state.createmodal });
    const InsuranceData = {
      commercial: this.state.updateiprice1,
      medicare: this.state.updateiprice2,
    };
    const insurance = [InsuranceData];
    const datas = Object.assign([], insurance);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      cpt_code: this.state.updatecptcode,
      unit: this.state.updateunit,
      price: this.state.updateprice,
      expected_amt: this.state.updateexpectamt,
      revenue_code: this.state.updaterevenuecode,
      description: this.state.updatedesc,
      lab_code: this.state.updatelabcode,
      status: this.state.updatestatus,
      modifier1: this.state.updatemod1,
      modifier2: this.state.updatemod2,
      modifier3: this.state.updatemod3,
      modifier4: this.state.updatemod4,
      drug: this.state.updatedrug,
      desc_in_sv101: this.state.updatedescinsv,
      rlsd: this.state.updaterlsd,
      supervising_provider: this.state.updateprovider,
      not_bill_to_insurance: this.state.updatenotbill,
      insurance_category: insurance,
      age_from: this.state.updatefrom,
      age_to: this.state.updateto,
      gender: this.state.updategender,
      frequency: this.state.updatefrequency,
      effective_date: this.state.updateeffectdate,
      expiry_date: this.state.updateexpdate,
    };

    // eslint-disable-next-line
    const res = await axios
      .put(`${ip}/cptmaster/${dataid}`, value, {
        headers: headers,
      })
      .then((res) => {
        this.toggleupdate();
        this.slicecpt();
        this.getcpt();
        this.setState({ createmodal: !this.state.createmodal });
        toast.info("CPT details updated", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        this.toggleupdate();
        this.slicecpt();
        this.getcpt();
        this.setState({ createmodal: !this.state.createmodal });
        if (err.response.data.statusCode === 500) {
          toast.error("Server Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        } else if (err.response.data.statusCode === 401) {
          toast.error("Please reload and try again", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        } else {
          toast.error("An unknown error occured", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      });
  };

  deleterequests = async (DataID) => {
    //api call to delete requests
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const req = axios
      .delete(`${ip}/cptmaster/${DataID}`, { headers: headers })
      .then((req) => {
        const requests = this.state.cptdata.filter(
          (data) => data._id !== DataID
        );
        this.setState({ requests }, () => {});
        this.toggledelete();
        this.slicecpt();
        this.getcpt();
        this.setState({ createmodal: !this.state.createmodal });
        toast.error("CPT deleted", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.response) {
          this.toggledelete();
          this.slicecpt();
          this.getcpt();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  //getcpt
  getcpt = async () => {
    //api call to get all cpt data
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = await axios
      .get(`${ip}/cptmaster/cpts?pageno=${this.state.currentpage + 1}`, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          pageCount: Math.ceil(res.data.count / this.state.perPage),
          cptdata: res.data.cpts,
        });
      })
      .catch((err) => {});
  };

  //getcpt
  getmodifier = async () => {
    //api call to get all cpt data
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/modifier/all`, { headers: headers })
      .then((res) => {
        this.setState({ modifierdata: res.data.modifier });

        const modifieroption =
          this.state.modifierdata.length > 0 &&
          this.state.modifierdata.map((data, i) => {
            return (
              <option key={i} value={data.code}>
                {data.code}
              </option>
            );
          });
        this.setState({ modifieroption: modifieroption });
      })
      .catch((err) => {});
  };

  // get payer category
  getpayercategory = async () => {
    //api call to get all cpt data
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/payercategory/all`, { headers: headers })
      .then((res) => {
        this.setState({ categorydata: res.data.payer }, () => {
          var obj = {};
          this.state.categorydata.map((data) => {
            return (obj[data.category_name] = null);
          });
          this.setState({ categoryoption: obj }, () => {});
        });
      })
      .catch((err) => {});
  };

  changestate = (e, data) => {
    let jasperCopy = Object.assign({}, this.state.categoryoption);
    jasperCopy[data.category_name] = e.target.value;
    this.setState({ categoryoption: jasperCopy }, () => {});
  };
  //icon
  reset__one = () => {
    this.setState({
      searchBy: "cpt_code",
      querySearch: "",
      searchdataresponse: [],
      searchclaimdata: [],
      searchtext: "",
    });
    this.getcpt();
  };
  reset = () => {toast.info("All values displayed now!", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
  });
    this.setState({
      cptcode: "",
      unit: "",
      price: "",
      expectamt: "",
      revenuecode: "",
      desc: "",
      labcode: "No",
      status: "Inactive",
      modifier1: "",
      modifier2: "",
      modifier3: "",
      modifier4: "",
      mod1: "",
      mod2: "",
      mod3: "",
      mod4: "",
      drug: false,
      descinsv: false,
      rlsd: false,
      provider: "No",
      notbill: false,
      iprice1: "",
      iprice2: "",
      from: "",
      to: "",
      gender: "female",
      frequency: "",
      effectdate: "",
      expdate: "",
      updatecptcode: "",
      updateunit: "",
      updateprice: "",
      updateexpectamt: "",
      updaterevenuecode: "",
      updatedesc: "",
      updatelabcode: "",
      updatestatus: "",
      updatemodifier1: "",
      updatemodifier2: "",
      updatemodifier3: "",
      updatemodifier4: "",
      updatemod1: "",
      updatemod2: "",
      updatemod3: "",
      updatemod4: "",
      updatedrug: "",
      updatedescinsv: "",
      updaterlsd: "",
      updateprovider: "",
      updatenotbill: "",
      updateiprice1: "",
      updateiprice2: "",
      updatefrom: "",
      updateto: "",
      updategender: "",
      updatefrequency: "",
      updateeffectdate: "",
      updateexpdate: "",
    });
  };

  componentDidMount() {
    
    this.slicecpt();
    this.getcpt();
    this.getmodifier();
    this.getpayercategory();
  }
  render() {
    return (
      <div>
      <Newsidebar name="CPT Master" />
      <div className="align-row" style={{overflow:"hidden"}}>
        <div className="align-column">
          <ToastContainer />
          <br/>

          <div style={{display:"flex",justifyContent:"space-between"}}>
          <div>   
          <div className="form__group">
            <select
              className="drop-down__one"
              value={this.state.searchBy}
              onChange={(e) => this.setState({ searchBy: e.target.value })}
            >
              <option value="cpt_code">CPT Code</option>
              <option value="description">CPT Description</option>
            </select>
            <div className="main__search">
              <input
                type="text"
                className="search__input"
                value={this.state.querySearch}
                onChange={(e) => {
                  this.setState({ querySearch: e.target.value });
                }}
              ></input>
            </div>
            <button style={{
              border:"none",
              padding:"3px",
              backgroundColor:"#041c3c"
            }}
            onClick={this.handleSearch}>
            <MdOutlineSearch 
            style={{
              color: "#fff",
              fontSize: "25px",        
            }}/>
            </button>
            <button      onClick={this.reset__one}>
            <IoIosRefresh
              style={{
                color: "#041c3c",
                fontSize: "25px",
              }}
            ></IoIosRefresh>
            </button>
        </div>
          </div>
          <div>
          {this.state.role_type === "Admin" && (
            <div>
              <button
              class="btn"
              style={{
                backgroundColor: "#7DD657",
                color: "#fff",
                fontSize: "12px",
              }}
                onClick={this.toggle}
              >
                Add New CPT
              </button>
            </div>
          )}
          </div>
        </div>
        
          <div style={{ height: "100%", width: "100%" }}>
            {this.state.cptdata.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "-3%",
                  marginTop: "7%",
                }}
              >
                <img
                  src="/one.gif"
                  alt=""
                  width="10%"
                  height="60%"
                  marginTop="4%"
                />
              </div>
            ) : (
              <div>
                {this.state.searchtext === "" ? (
                  <div>
                      <Table
                      className="StandardTable"
                      bordered
                      >
                        <thead>
                          <tr>
                            <th>CPT Code</th>
                            <th>CPT Description</th>
                            <th>Modifier</th>
                            <th>Price</th>
                            <th>Units</th>
                            {this.state.role_type === "Admin" && (
                              <th>Action</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.cptdata &&
                            this.state.cptdata.map((data, index) => (
                              <tr key={data._id}>
                                <td component="th" scope="row">
                                  {data.cpt_code}
                                </td>
                                <td>
                                  {data.description}
                                </td>
                                <td>
                                  {data.modifier1}
                                </td>
                                <td>{data.price}</td>
                                <td>{data.unit}</td>
                                {this.state.role_type === "Admin" && (
                                  <td>
                                    <button
                                      className="Edit"
                                      onClick={() => {
                                        this.setState(
                                          { dataforupdate: data },
                                          () => {
                                            this.settingupdatestate();
                                            this.toggleupdate();
                                          }
                                        );
                                      }}
                                    >
                                      Edit
                                    </button>
                                    &nbsp;
                                    <button
                                      className="Delete"
                                      onClick={() => {
                                        this.setState({ id: data._id }, () => {
                                          this.toggledelete();
                                        });
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </td>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    {this.state.searchdataresponse.length === 0 ? (
                      <div>
                        <p align="center">No data found</p>
                      </div>
                    ) : (
                      <div style={{marginLeft:"3%", marginRight:"3%"}}>
                          <Table bordered className="StandardTable">
                            <thead>
                              <tr>
                                <th>CPT Code</th>
                                <th>
                                  CPT Description
                                </th>
                                <th>Modifier</th>
                                <th>Price</th>
                                <th>Units</th>
                                {this.state.role_type === "Admin" && (
                                  <th>Action</th>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.searchclaimdata &&
                                this.state.searchclaimdata.map(
                                  (data, index) => (
                                    <tr key={data._id}>
                                      <td
                                        component="th"
                                        scope="row"
                                      >
                                        {data.cpt_code}
                                      </td>
                                      <td>
                                        {data.description}
                                      </td>
                                      <td>
                                        {data.modifier1}
                                      </td>
                                      <td>
                                        {data.price}
                                      </td>
                                      <td>
                                        {data.unit}
                                      </td>

                                      {this.state.role_type === "Admin" && (
                                        <td>
                                          <button
                                            className="Edit"
                                            onClick={() => {
                                              this.setState(
                                                { dataforupdate: data },
                                                () => {
                                                  this.settingupdatestate();
                                                  this.toggleupdate();
                                                }
                                              );
                                            }}
                                          >
                                            Edit
                                          </button>
                                          &nbsp;
                                          <button
                                            className="Delete"
                                            onClick={() => {
                                              this.setState(
                                                { id: data._id },
                                                () => {
                                                  this.toggledelete();
                                                }
                                              );
                                            }}
                                          >
                                            Delete
                                          </button>
                                        </td>
                                      )}
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </Table>
                        <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                          <ReactPaginate
                            previousLabel={<IoIosArrowBack />}
                            nextLabel={<IoIosArrowForward />}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick1}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          {this.state.modal && (
            <Modal
              style={{ width: "100%", height: "70%" }}
              isOpen={this.state.modal}
              onHide={this.toggle}
              onExit={this.reset}
              centered
              animation="false"
              size="lg"
            >
              <ModalHeader toggle={this.toggle}>Create CPT Master</ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <br />
                <Tabs
                  activeTab="1"
                  className="tabs"
                  ulClassName=""
                  activityClassName="bg-info"
                >
                  <Tab title="General" className="mr-3">
                    <br />
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Code</Label>
                            <Input
                              type="number"
                              value={this.state.cptcode}
                              onChange={(e) =>
                                this.setState({
                                  cptcode: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Status</Label>
                            <Input
                              type="select"
                              className="form-control form-select"
                              value={this.state.status}
                              onChange={(e) =>
                                this.setState({
                                  status: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            >
                              <option value="Inactive">Inactive</option>
                              <option value="Active">Active</option>
                            </Input>

                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Modifier 1</Label>
                            <Input
                              type="select"
                              className="form-control form-select"
                              placeholder="Select"
                              value={this.state.mod1}
                              onChange={(e) =>
                                this.setState({
                                  mod1: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            >
                              <option>Select...</option>
                              {this.state.modifierdata.length > 0 &&
                                this.state.modifierdata.map((data, i) => {
                                  return (
                                    <option key={i} value={data.code}>
                                      {data.code}
                                    </option>
                                  );
                                })}
                            </Input>
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col md="8">
                          <FormGroup>
                            <Label>Description</Label>
                            <Input
                              type="text"
                              value={this.state.desc}
                              onChange={(e) =>
                                this.setState({
                                  desc: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Modifier 2</Label>
                            <Input
                              type="select"
                              className="form-control form-select"
                              placeholder="Select"
                              value={this.state.mod2}
                              onChange={(e) =>
                                this.setState({
                                  mod2: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            >
                              <option>Select...</option>
                              {this.state.modifierdata.length > 0 &&
                                this.state.modifierdata.map((data, i) => {
                                  return (
                                    <option key={i} value={data.code}>
                                      {data.code}
                                    </option>
                                  );
                                })}
                            </Input>

                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Unit</Label>
                            <Input
                              type="number"
                              value={this.state.unit}
                              onChange={(e) =>
                                this.setState({
                                  unit: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Laboratory Code</Label>
                            <Input
                              type="select"
                              className="form-control form-select"
                              value={this.state.labcode}
                              onChange={(e) =>
                                this.setState({
                                  labcode: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            >
                              <option value="No">No</option>
                              <option value="Yes">Yes</option>
                            </Input>
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Modifier 3</Label>
                            <Input
                              type="select"
                              placeholder="Select"
                              className="form-control form-select"
                              value={this.state.mod3}
                              onChange={(e) =>
                                this.setState({
                                  mod3: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            >
                              <option>Select...</option>
                              {this.state.modifierdata.length > 0 &&
                                this.state.modifierdata.map((data, i) => {
                                  return (
                                    <option key={i} value={data.code}>
                                      {data.code}
                                    </option>
                                  );
                                })}
                            </Input>
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Price</Label>
                            <Input
                              type="number"
                              value={this.state.price}
                              onChange={(e) =>
                                this.setState({
                                  price: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <br />
                            <Label for="descinsv">
                              Send Desc in SV101-7
                            </Label>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Input
                              type="checkbox"
                              name="descinsv"
                              id="descinsv"
                              value={this.state.descinsv}
                              onChange={() => {
                                this.setState({
                                  descinsv: !this.state.descinsv,
                                  roleErr: "",
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Modifier 4</Label>
                            <Input
                              type="select"
                              className="form-control form-select"
                              placeholder="Select"
                              value={this.state.mod4}
                              onChange={(e) =>
                                this.setState({
                                  mod4: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            >
                              <option>Select...</option>
                              {this.state.modifierdata.length > 0 &&
                                this.state.modifierdata.map((data, i) => {
                                  return (
                                    <option key={i} value={data.code}>
                                      {data.code}
                                    </option>
                                  );
                                })}
                            </Input>
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Expected Amount</Label>
                            <Input
                              type="number"
                              value={this.state.expectamt}
                              onChange={(e) =>
                                this.setState({
                                  expectamt: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <br />
                            <Label for="drug">Drug</Label>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Input
                              type="checkbox"
                              name="drug"
                              id="drug"
                              onChange={() => {
                                this.setState({
                                  drug: !this.state.drug,
                                  roleErr: "",
                                });
                              }}
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <br />
                            <Row>
                              <Col>
                                <Label for="rlsd">RLSD</Label>{" "}
                                &nbsp;&nbsp;&nbsp;
                                <Input
                                  type="checkbox"
                                  name="rlsd"
                                  id="rlsd"
                                  onChange={() => {
                                    this.setState({
                                      rlsd: !this.state.rlsd,
                                      roleErr: "",
                                    });
                                  }}
                                />
                                <div style={{ fontSize: 16, color: "red" }}>
                                  {this.state.firstnameErr}
                                </div>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Do Not Bill To Insurance</Label>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Input
                              type="checkbox"
                              name="notbill"
                              id="notbill"
                              onChange={() => {
                                this.setState({
                                  notbill: !this.state.notbill,
                                  roleErr: "",
                                });
                              }}
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Revenue Code</Label>
                            <Input
                              type="number"
                              value={this.state.revenuecode}
                              onChange={(e) =>
                                this.setState({
                                  revenuecode: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Requires Supervising Provider</Label>
                            <Input
                              type="select"
                              className="form-control form-select"
                              value={this.state.provider}
                              onChange={(e) =>
                                this.setState({
                                  provider: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            >
                              <option value="No">No</option>
                              <option value="Yes">Yes</option>
                            </Input>
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Tab>
                  <Tab title="Insurance Category" className="mr-3">
                    <br />
                    <Form>
                      <Table           
                      className="StandardTable"
                      bordered>
                        <thead>
                          <tr>
                            <th>Category</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <br />
                        <tbody>
                          {this.state.categorydata.map((data, index) => (
                            <tr>
                              <td>{data.category_name}</td>

                              <td>
                                <Col md="3">
                                  <input
                                    type="number"
                                    value={
                                      this.state.categoryoption[
                                        "data.category_name"
                                      ]
                                    }
                                    onChange={(e) => {
                                      this.changestate(e, data);
                                    }}
                                  ></input>
                                </Col>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Form>
                  </Tab>
                  <Tab title="CPT Validation" className="mr-3">
                    <br />
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Age From</Label>
                            <Input
                              type="number"
                              value={this.state.from}
                              onChange={(e) =>
                                this.setState({
                                  from: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>To</Label>
                            <Input
                              type="number"
                              value={this.state.to}
                              onChange={(e) =>
                                this.setState({
                                  to: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Gender</Label>
                            <Input
                              type="select"
                              className="form-control form-select"
                              value={this.state.gender}
                              onChange={(e) =>
                                this.setState({
                                  gender: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            >
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </Input>
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Frequency</Label>
                            <Input
                              type="text"
                              value={this.state.frequency}
                              onChange={(e) =>
                                this.setState({
                                  frequency: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Effective Date</Label>
                            <Input
                              type="date"
                              value={this.state.effectdate}
                              onChange={(e) =>
                                this.setState({
                                  effectdate: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Expiry Date</Label>
                            <Input
                              type="date"
                              value={this.state.expdate}
                              onChange={(e) =>
                                this.setState({
                                  expdate: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Tab>
                </Tabs>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggle();
                    this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={this.handleSubmit}
                >
                  Create
                </Button>
              </ModalFooter>
            </Modal>
          )}
          {this.state.updatemodal && (
            <Modal
              classsName="insuranceedit"
              isOpen={this.state.updatemodal}
              onHide={this.toggleupdate}
              onExit={this.reset}
              centered
              animation="false"
              size="lg"
            >
              <ModalHeader toggle={this.toggleupdate}>Edit CPT</ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <br />
                <Tabs
                  activeTab="1"
                  className="tabs"
                  ulClassName=""
                  activityClassName="bg-info"
                >
                  <Tab title="General" className="mr-3">
                    <br />
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Code</Label>
                            <Input
                              type="number"
                              value={this.state.updatecptcode}
                              onChange={(e) =>
                                this.setState({
                                  updatecptcode: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Status</Label>
                            <Input
                              type="select"
                              className="form-control form-select"
                              value={this.state.updatestatus}
                              onChange={(e) =>
                                this.setState({
                                  updatestatus: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            >
                              <option value="Inactive">Inactive</option>
                              <option value="Active">Active</option>
                            </Input>
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Modifier 1</Label>
                            <Input
                              type="select"
                              className="form-control form-select"
                              placeholder={this.state.updatemod1}
                              value={this.state.updatemod1}
                              onChange={(e) =>
                                this.setState({
                                  updatemod1: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            >
                              <option>Select...</option>
                              {this.state.modifierdata.length > 0 &&
                                this.state.modifierdata.map((data, i) => {
                                  return (
                                    <option key={i} value={data.code}>
                                      {data.code}
                                    </option>
                                  );
                                })}
                            </Input>

                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col md="8">
                          <FormGroup>
                            <Label>Description</Label>
                            <Input
                              type="text"
                              value={this.state.updatedesc}
                              onChange={(e) =>
                                this.setState({
                                  updatedesc: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Modifier 2</Label>
                            <Input
                              type="select"
                              className="form-control form-select"
                              placeholder={this.state.updatemod2}
                              value={this.state.updatemod2}
                              onChange={(e) =>
                                this.setState({
                                  updatemod2: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            >
                              <option>Select...</option>
                              {this.state.modifierdata.length > 0 &&
                                this.state.modifierdata.map((data, i) => {
                                  return (
                                    <option key={i} value={data.code}>
                                      {data.code}
                                    </option>
                                  );
                                })}
                            </Input>
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Unit</Label>
                            <Input
                              type="number"
                              value={this.state.updateunit}
                              onChange={(e) =>
                                this.setState({
                                  updateunit: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Laboratory Code</Label>
                            <Input
                              type="select"
                              className="form-control form-select"
                              value={this.state.updatelabcode}
                              onChange={(e) =>
                                this.setState({
                                  updatelabcode: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            >
                              <option value="No">No</option>
                              <option value="Yes">Yes</option>
                            </Input>
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Modifier 3</Label>
                            <Input
                              type="select"
                              className="form-control form-select"
                              placeholder={this.state.updatemod3}
                              value={this.state.updatemod3}
                              onChange={(e) =>
                                this.setState({
                                  updatemod3: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            >
                              <option>Select...</option>
                              {this.state.modifierdata.length > 0 &&
                                this.state.modifierdata.map((data, i) => {
                                  return (
                                    <option key={i} value={data.code}>
                                      {data.code}
                                    </option>
                                  );
                                })}
                            </Input>
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Price</Label>
                            <Input
                              type="number"
                              value={this.state.updateprice}
                              onChange={(e) =>
                                this.setState({
                                  updateprice: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <br />
                            <Label for="descinsv">
                              Send Desc in SV101-7
                            </Label>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Input
                              type="checkbox"
                              name="descinsv"
                              id="descinsv"
                              checked={this.state.updatedescinsv}
                              value={this.state.updatedescinsv}
                              onChange={() => {
                                this.setState({
                                  updatedescinsv: !this.state.updatedescinsv,
                                  roleErr: "",
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Modifier 4</Label>
                            <Input
                              type="select"
                              className="form-control form-select"
                              placeholder={this.state.updatemod4}
                              value={this.state.updatemod4}
                              onChange={(e) =>
                                this.setState({
                                  updatemod4: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            >
                              <option>Select...</option>
                              {this.state.modifierdata.length > 0 &&
                                this.state.modifierdata.map((data, i) => {
                                  return (
                                    <option key={i} value={data.code}>
                                      {data.code}
                                    </option>
                                  );
                                })}
                              {/* {this.state.modifieroption} */}
                            </Input>
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Expected Amount</Label>
                            <Input
                              type="number"
                              value={this.state.updateexpectamt}
                              onChange={(e) =>
                                this.setState({
                                  updateexpectamt: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <br />
                            <Label for="drug">Drug</Label>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Input
                              type="checkbox"
                              name="drug"
                              id="drug"
                              checked={this.state.updatedrug}
                              onChange={() => {
                                this.setState({
                                  updatedrug: !this.state.updatedrug,
                                  roleErr: "",
                                });
                              }}
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <br />
                            <Row>
                              <Col>
                                <Label for="rlsd">RLSD</Label>{" "}
                                &nbsp;&nbsp;&nbsp;
                                <Input
                                  type="checkbox"
                                  name="rlsd"
                                  id="rlsd"
                                  checked={this.state.updaterlsd}
                                  onChange={() => {
                                    this.setState({
                                      updaterlsd: !this.state.updaterlsd,
                                      roleErr: "",
                                    });
                                  }}
                                />
                                <div style={{ fontSize: 16, color: "red" }}>
                                  {this.state.firstnameErr}
                                </div>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Do Not Bill To Insurance</Label>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Input
                              type="checkbox"
                              name="notbill"
                              id="notbill"
                              checked={this.state.updatenotbill}
                              onChange={() => {
                                this.setState({
                                  updatenotbill: !this.state.updatenotbill,
                                  roleErr: "",
                                });
                              }}
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Revenue Code</Label>
                            <Input
                              type="number"
                              value={this.state.updaterevenuecode}
                              onChange={(e) =>
                                this.setState({
                                  updaterevenuecode: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Requires Supervising Provider</Label>
                            <Input
                              type="select"
                              className="form-control form-select"
                              value={this.state.updateprovider}
                              onChange={(e) =>
                                this.setState({
                                  updateprovider: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            >
                              <option value="No">No</option>
                              <option value="Yes">Yes</option>
                            </Input>
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Tab>
                  <Tab title="Insurance Category" className="mr-3">
                    <br />
                    <Form>
                      <Table           
                      className="StandardTable"
                      bordered>
                        <thead>
                          <tr>
                            <th>Category</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <br />
                        <tbody>
                          <tr>
                            <td>Commercial</td>
                            <td>
                              <Col md="3">
                                <input
                                  type="number"
                                  value={this.state.updateiprice1}
                                  onChange={(e) =>
                                    this.setState({
                                      updateiprice1: e.target.value,
                                      updateiname1: "commercial",
                                      firstnameErr: "",
                                    })
                                  }
                                ></input>
                              </Col>
                            </td>
                          </tr>
                          <tr>
                            <td value="medicare" onChange>
                              Medicare
                            </td>
                            <td>
                              <Col md="3">
                                <input
                                  type="number"
                                  value={this.state.updateiprice2}
                                  onChange={(e) =>
                                    this.setState({
                                      updateiprice2: e.target.value,
                                      updateiname2: "medicare",
                                      firstnameErr: "",
                                    })
                                  }
                                ></input>
                              </Col>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Form>
                  </Tab>
                  <Tab title="CPT Validation" className="mr-3">
                    <br />
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Age From</Label>
                            <Input
                              type="number"
                              value={this.state.updatefrom}
                              onChange={(e) =>
                                this.setState({
                                  updatefrom: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>To</Label>
                            <Input
                              type="number"
                              value={this.state.updateto}
                              onChange={(e) =>
                                this.setState({
                                  updateto: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Gender</Label>
                            <Input
                              type="select"
                              className="form-control form-select"
                              value={this.state.updategender}
                              onChange={(e) =>
                                this.setState({
                                  updategender: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            >
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </Input>
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Frequency</Label>
                            <Input
                              type="text"
                              value={this.state.updatefrequency}
                              onChange={(e) =>
                                this.setState({
                                  updatefrequency: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Effective Date</Label>
                            <Input
                              type="date"
                              value={this.state.updateeffectdate}
                              onChange={(e) =>
                                this.setState({
                                  updateeffectdate: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Expiry Date</Label>
                            <Input
                              type="date"
                              value={this.state.updateexpdate}
                              onChange={(e) =>
                                this.setState({
                                  updateexpdate: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Tab>
                </Tabs>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggleupdate();
                    this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={() => {
                    this.putrequests(this.state.updateid);
                  }}
                >
                  Update
                </Button>
              </ModalFooter>
            </Modal>
          )}

          {this.state.deletemodal && (
            <Modal
              isOpen={this.state.deletemodal}
              onHide={this.toggledelete}
              // onExit={this.reloadpage}
              centered
            >
              <ModalHeader toggle={this.toggledelete}>Delete CPT</ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Row style={{ margin: "0px" }}>Do you want to delete this?</Row>

                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid gray",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={this.toggledelete}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "rgb(35,35,35)" }}
                  onClick={() => {
                    this.deleterequests(this.state.id);
                  }}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
          )}
          <Modal isOpen={this.state.createmodal}>
            <ModalBody>
              <img
                src={loading}
                style={{ width: "200px", height: "200px", marginLeft: "30%" }}
              />
            </ModalBody>
          </Modal>
        </div>
      </div>
      </div>
    );
  }
}
export default Cpt;

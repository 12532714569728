import React, { Component } from "react";
import axios from "axios";
import "../../Styles/admin.css";
import "../../Styles/Category.css";
import Newsidebar from "../Dashboard/Newsidebar";
import { Row, Col } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import Tabs, { Tab } from "react-best-tabs";
import Select from "react-dropdown-select";
import ReactPaginate from "react-paginate";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Card } from "react-bootstrap";
 
import { ip } from ".././Api";
class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      modal: false,
      updatemodal: false,
      deletemodal: false,
      dataforupdate: null,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      payername: "",
      payerid: "",
      type: "",
      contactno: "",
      carriercode: "",
      cat1: "",
      categorydata: [],
      category: [],
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      telph1: "",
      telph2: "",
      fax: "",
      email: "",
      billingmethod: "",
      insurcategory: "",
      recvname: "",
      claimfilingindicator: "",
      plantype: "",
      incentivecategory: "",
      timelyfilinglimit1: "",
      timelyfilinglimit2: "",
      noofdays: "",
      partAclaimfiling: "",
      taxonomycode: "No",
      status: "Enabled",
      providerid: "No",
      sendreferringprovider: "No",
      billedbyEHI: "No",
      resendunappliedclaims: "No",
      sendpatientAddress: "No",
      billingonmins: false,
      sendCRNAID: false,
      checktaxonomyCode: false,
      participatingprovider: false,
      appealclaimaddress: false,
      eleclab: "",
      labcorpid: "",
      labcorplantype: "",
      medicallabid: "",
      questid: "",
      enzoid: "",
      propathid: "",
      biorefid: "",
      phelpsid: "",
      hldid: "",
      accurateid: "",
      chid: "",
      lencoid: "",
      applelabid: "",
      empirelabid: "",
      enigmaid: "",
      sheillabid: "",
      fusionid: "",
      everestid: "",
      mercyid: "",
      smalabid: "",
      ezid: "",
      solstasid: "",
      planname: "",
      payer: [],
      user: [],
      updatepayername: "",
      updatepayerid: "",
      updatecontact: "",
      updatecategoryname: "",
      updatecarriercode: "",
      updateaddress1: "",
      updateaddress2: "",
      updatecity: "",
      updatestate: "",
      updatecountry: "",
      updatezipcode: "",
      updatetelph1: "",
      updatetelph2: "",
      updatefax: "",
      updateemail: "",
      updatebillingmethod: "",
      updateinsurcategory: "",
      updaterecvname: "",
      updateclaimfilingindicator: "",
      updateplantype: "",
      updateincentivecategory: "",
      updatetimelyfilinglimit1: "",
      updatetimelyfilinglimit2: "",
      updatenoofdays: "",
      updatepartAclaimfiling: "",
      updatetaxonomycode: "No",
      updatestatus: "Enabled",
      updateproviderid: "No",
      updatesendreferringprovider: "No",
      updatebilledbyEHI: "No",
      updateresendunappliedclaims: "No",
      updatesendpatientAddress: "No",
      updatebillingonmins: false,
      updatesendCRNAID: false,
      updatechecktaxonomyCode: false,
      updateparticipatingprovider: false,
      updateappealclaimaddress: false,
      updateeleclab: "",
      updatelabcorpid: "",
      updatelabcorplantype: "",
      updatemedicallabid: "",
      updatequestid: "",
      updateenzoid: "",
      updatepropathid: "",
      updatebiorefid: "",
      updatephelpsid: "",
      updatehldid: "",
      updateaccurateid: "",
      updatechid: "",
      updatelencoid: "",
      updateapplelabid: "",
      updateempirelabid: "",
      updateenigmaid: "",
      updatesheillabid: "",
      updatefusionid: "",
      updateeverestid: "",
      updatemercyid: "",
      updatesmalabid: "",
      updateezid: "",
      updatesolstasid: "",
      updateplanname: "",
      updateid: null,
      code: "",
      zipcodeErr: "",
      billingmethodErr: "",
      categoryErr: "",
      recvnameErr: "",
      offset: 0,
      currentpage: 0,
      perPage: 10,
      statuslist: [],
      payercategories: [],
      payervalue1: [],
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  getpayercategory = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(`${ip}/payercategory/all`, {
        headers: headers,
      })
      .then((res) => {
        let other = {
          category_name: "Others",
        };
        let data = res.data.payer;
        data.push(other);
        this.setState({ payercategories: data }, () => {});
      })

      .catch((err) => {});
  };
  getClaimStatus = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

    const res = axios
      .get(`${ip}/claimstatus/all`, { headers: headers })
      .then((res) => {
        this.setState({
          statuslist: res.data.claimstatus,
        });
      })
      .catch((err) => {});
  };
  toggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };
  toggle2 = () => {
    const { modal2 } = this.state;
    this.setState({ modal2: !modal2 });
  };
  // toogle modal for delete
  toggledelete = () => {
    this.setState({ deletemodal: !this.state.deletemodal });
  };
  //toggle modal for update
  toggleupdate = () => {
    this.setState({ updatemodal: !this.state.updatemodal });
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getpayer();
      }
    );
  };

  handleSubmit = async () => {
    // const isValid = this.validate();

    // if (isValid) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      payer_name: this.state.payername,
      payer_id: this.state.payerid,
      category_name: this.state.cat1,
      type: this.state.type,
      contact: this.state.contactno,
      carrier_code: this.state.carriercode,
      address1: this.state.address1,
      address2: this.state.address2,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      zip_code: this.state.zipcode,
      telphno1: this.state.telph1,
      telphno2: this.state.telph2,
      fax: this.state.fax,
      email: this.state.email,
      billing_method: this.state.billingmethod,
      insurance_category: this.state.insurcategory,
      receiver_name: this.state.recvname,
      indicator: this.state.claimfilingindicator,
      filling_limit1: this.state.timelyfilinglimit1,
      filling_limit2: this.state.timelyfilinglimit2,
      no_of_days: this.state.noofdays,
      partA_indicator: this.state.partAclaimfiling,
      taxonomy_code: this.state.taxonomycode,
      status: this.state.status,
      provider_id: this.state.providerid,
      default_refferring_provider: this.state.sendreferringprovider,
      billedby_ehr_for_rcm: this.state.billedbyEHI,
      resend_unapplied_claims: this.state.resendunappliedclaims,
      send_patientadress: this.state.sendpatientAddress,
      billing_on_mins: this.state.billingonmins,
      send_CRNA_ID: this.state.sendCRNAID,
      taxonomy_Code: this.state.checktaxonomyCode,
      participating_provider: this.state.participatingprovider,
      appeal_claim_address: this.state.appealclaimaddress,
      default_electronicLab: this.state.eleclab,
      labcorp_id: this.state.labcorpid,
      labCorp_plaintype: this.state.labcorplantype,
      medicalLab_id: this.state.medicallabid,
      quest_id: this.state.questid,
      enzo_id: this.state.enzoid,
      propath_id: this.state.propathid,
      bloref_id: this.state.biorefid,
      phelps_id: this.state.phelpsid,
      hdl_id: this.state.hldid,
      lenco_id: this.state.lencoid,
      accurate_id: this.state.accurateid,
      chGroup_id: this.state.chid,
      appleLab_id: this.state.applelabid,
      empireLab_id: this.state.empirelabid,
      enigma_id: this.state.enigmaid,
      sheilLab_id: this.state.sheillabid,
      fusion_id: this.state.fusionid,
      everest_id: this.state.everestid,
      mercy_id: this.state.mercyid,
      smaLab_id: this.state.smalabid,
      ez_id: this.state.ezid,
      solstas_id: this.state.solstasid,
      plan_name: this.state.planname,
    };
    // eslint-disable-next-line
    const res = await axios
      .post(`${ip}/userdetails/create`, value, { headers: headers })
      .then((res) => {
        alert("New Payer Master Created Successfully!");
        //this.getusers();
        this.getpayer();
        this.toggle();
      })
      .catch((err) => {});
    // }
  };

  // fuction for prepopulate the particular data
  settingupdatestate = function () {
    this.setState(
      {
        updatepayername: this.state.dataforupdate.payer_name,
        updatepayerid: this.state.dataforupdate.payer_id,
        updatecontact: this.state.dataforupdate.contact,
        updatecategoryname: this.state.dataforupdate.category_name,
        updatecarriercode: this.state.dataforupdate.carrier_code,
        updateaddress1: this.state.dataforupdate.address1,
        updateaddress2: this.state.dataforupdate.address2,
        updatecity: this.state.dataforupdate.city,
        updatestate: this.state.dataforupdate.state,
        updatecountry: this.state.dataforupdate.country,
        updatezipcode: this.state.dataforupdate.zip_code,
        updatetelph1: this.state.dataforupdate.telphno1,
        updatetelph2: this.state.dataforupdate.telphno2,
        updatefax: this.state.dataforupdate.fax,
        updateemail: this.state.dataforupdate.email,
        updatebillingmethod: this.state.dataforupdate.billing_method,
        updateinsurcategory: this.state.dataforupdate.insurance_category,
        updaterecvname: this.state.dataforupdate.receiver_name,
        updateclaimfilingindicator: this.state.dataforupdate.indicator,
        updateplantype: this.state.dataforupdate.plan_type,
        updateincentivecategory: this.state.dataforupdate.incentive_category,
        updatetimelyfilinglimit1: this.state.dataforupdate.filling_limit1,
        updatetimelyfilinglimit2: this.state.dataforupdate.filling_limit2,
        updatenoofdays: this.state.dataforupdate.no_of_days,
        updatepartAclaimfiling: this.state.dataforupdate.partA_indicator,
        updatetaxonomycode: this.state.dataforupdate.taxonomy_code,
        updatestatus: this.state.dataforupdate.status,
        updateproviderid: this.state.dataforupdate.provider_id,
        updatesendreferringprovider:
          this.state.dataforupdate.default_refferring_provider,
        updatebilledbyEHI: this.state.dataforupdate.billedby_ehr_for_rcm,
        updateresendunappliedclaims:
          this.state.dataforupdate.resend_unapplied_claims,
        updatesendpatientAddress: this.state.dataforupdate.send_patientadress,
        updatebillingonmins: this.state.dataforupdate.billing_on_mins,
        updatesendCRNAID: this.state.dataforupdate.send_CRNA_ID,
        updatechecktaxonomyCode: this.state.dataforupdate.taxonomy_Code,
        updateparticipatingprovider:
          this.state.dataforupdate.participating_provider,
        updateappealclaimaddress: this.state.dataforupdate.appeal_claim_address,
        updateeleclab: this.state.dataforupdate.default_electronicLab,
        updatelabcorpid: this.state.dataforupdate.labcorp_id,
        updatelabcorplantype: this.state.dataforupdate.labCorp_plaintype,
        updatemedicallabid: this.state.dataforupdate.medicalLab_id,
        updatequestid: this.state.dataforupdate.quest_id,
        updateenzoid: this.state.dataforupdate.enzo_id,
        updatepropathid: this.state.dataforupdate.propath_id,
        updatebiorefid: this.state.dataforupdate.bloref_id,
        updatephelpsid: this.state.dataforupdate.phelps_id,
        updatehldid: this.state.dataforupdate.hdl_id,
        updateaccurateid: this.state.dataforupdate.accurate_id,
        updatechid: this.state.dataforupdate.first_name,
        updatelencoid: this.state.dataforupdate.lenco_id,
        updateapplelabid: this.state.dataforupdate.appleLab_id,
        updateempirelabid: this.state.dataforupdate.empireLab_id,
        updateenigmaid: this.state.dataforupdate.enigma_id,
        updatesheillabid: this.state.dataforupdate.sheilLab_id,
        updatefusionid: this.state.dataforupdate.fusion_id,
        updateeverestid: this.state.dataforupdate.everest_id,
        updatemercyid: this.state.dataforupdate.mercy_id,
        updatesmalabid: this.state.dataforupdate.smaLab_id,
        updateezid: this.state.dataforupdate.ez_id,
        updatesolstasid: this.state.dataforupdate.solstas_id,
        updateplanname: this.state.dataforupdate.plan_name,
        updateid: this.state.dataforupdate._id,
      },
      (res) => {}
    );
  };

  putrequests = async (dataid) => {
    // const isValid = this.updatevalidate();

    // if (isValid) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const data = {
      payer_name: this.state.updatepayername,
      payer_id: this.state.updatepayerid,
      category_name: this.state.updatecategory,
      contact: this.state.updatecontactno,
      carrier_code: this.state.updatecarriercode,
      address1: this.state.updateaddress1,
      address2: this.state.updateaddress2,
      city: this.state.updatecity,
      state: this.state.updatestate,
      country: this.state.updatecountry,
      zip_code: this.state.updatezipcode,
      telphno1: this.state.updatetelph1,
      telphno2: this.state.updatetelph2,
      fax: this.state.updatefax,
      email: this.state.updateemail,
      billing_method: this.state.updatebillingmethod,
      insurance_category: this.state.updateinsurcategory,
      receiver_name: this.state.updaterecvname,
      indicator: this.state.updateclaimfilingindicator,
      filling_limit1: this.state.updatetimelyfilinglimit1,
      filling_limit2: this.state.updatetimelyfilinglimit2,
      no_of_days: this.state.updatenoofdays,
      partA_indicator: this.state.updatepartAclaimfiling,
      taxonomy_code: this.state.updatetaxonomycode,
      status: this.state.updatestatus,
      provider_id: this.state.updateproviderid,
      default_refferring_provider: this.state.updatesendreferringprovider,
      billedby_ehr_for_rcm: this.state.updatebilledbyEHI,
      resend_unapplied_claims: this.state.updateresendunappliedclaims,
      send_patientadress: this.state.updatesendpatientAddress,
      billing_on_mins: this.state.updatebillingonmins,
      send_CRNA_ID: this.state.updatesendCRNAID,
      taxonomy_Code: this.state.updatechecktaxonomyCode,
      participating_provider: this.state.updateparticipatingprovider,
      appeal_claim_address: this.state.updateappealclaimaddress,
      default_electronicLab: this.state.updateeleclab,
      labcorp_id: this.state.updatelabcorpid,
      labCorp_plaintype: this.state.updatelabcorplantype,
      medicalLab_id: this.state.updatemedicallabid,
      quest_id: this.state.updatequestid,
      enzo_id: this.state.updateenzoid,
      propath_id: this.state.updatepropathid,
      bloref_id: this.state.updatebiorefid,
      phelps_id: this.state.updatephelpsid,
      hdl_id: this.state.updatehldid,
      lenco_id: this.state.updatelencoid,
      accurate_id: this.state.updateaccurateid,
      chGroup_id: this.state.updatechid,
      appleLab_id: this.state.updateapplelabid,
      empireLab_id: this.state.updateempirelabid,
      enigma_id: this.state.updateenigmaid,
      sheilLab_id: this.state.updatesheillabid,
      fusion_id: this.state.updatefusionid,
      everest_id: this.state.updateeverestid,
      mercy_id: this.state.updatemercyid,
      smaLab_id: this.state.updatesmalabid,
      ez_id: this.state.updateezid,
      solstas_id: this.state.updatesolstasid,
      plan_name: this.state.updateplanname,
    };
    // eslint-disable-next-line
    const res = await axios
      .put(`${ip}/userdetails/${dataid}`, data, {
        headers: headers,
      })
      .then((res) => {
        alert("Payer Master Updated Successfully!");
        this.toggleupdate();
        this.getpayer();
      })
      .catch((err) => {});
    // }
  };

  deleterequests = async (DataID) => {
    //api call to delete requests
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const req = axios
      .delete(`${ip}/userdetails/${DataID}`, {
        headers: headers,
      })
      .then((req) => {
        alert("Payer Deleted Successfully");

        const requests = this.state.payer.filter((data) => data._id !== DataID);
        this.setState({ requests }, () => {});
        this.toggledelete();
        this.getpayer();
      })
      .catch((err) => {});
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  //getusers
  getpayer = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/payermaster/all`, { headers: headers })
      .then((res) => {
        const slice = res.data.payer.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        this.setState({
          pageCount: Math.ceil(res.data.payer.length / this.state.perPage),
          payer: slice,
        });
      })

      .catch((err) => {});
  };

  validate = () => {
    let hasErr = "true";
    let zipcodeErr = "";
    let billingmethodErr = "";
    let categoryErr = [];
    let recvnameErr = "";

    if (this.state.zipcode === "") {
      zipcodeErr = "This field is required";
      this.setState({ zipcodeErr });
      hasErr = false;
    }
    if (this.state.billingmethod.length === 0) {
      billingmethodErr = "This field is required";
      this.setState({ billingmethodErr });
      hasErr = false;
    }

    if (this.state.category.length === 0) {
      categoryErr = "This field is required";
      this.setState({ categoryErr });
      hasErr = false;
    }
    if (this.state.recvname.length === 0) {
      recvnameErr = "This field is required";
      this.setState({ recvnameErr });
      hasErr = false;
    }
    return hasErr;
  };

  updatevalidate = () => {
    let hasErr = "true";
    let firstnameErr = "";
    let lastnameErr = "";
    let phnoErr = "";
    let emailErr = "";

    if (this.state.updatefirstname === "") {
      firstnameErr = "This field is required";
      this.setState({ firstnameErr });
      hasErr = false;
    }
    if (this.state.updatelastname === "") {
      lastnameErr = "This field is required";
      this.setState({ lastnameErr });
      hasErr = false;
    }
    if (this.state.updatephno === "") {
      phnoErr = "This field is required";
      this.setState({ phnoErr });
      hasErr = false;
    } else if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
        this.state.updatephno
      )
    ) {
      phnoErr = "Invalid phone number";
      this.setState({ phnoErr });
      hasErr = false;
    }
    if (this.state.updateemail === "") {
      emailErr = "This field is required";
      this.setState({ emailErr });
      hasErr = false;
    } else if (
      // eslint-disable-next-line
      !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
        this.state.updateemail
      )
    ) {
      //var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      emailErr = "Invalid email address";
      this.setState({ emailErr });
      hasErr = false;
    }
    return hasErr;
  };

  getcategory = async () => {
    //api call to get all cpt data
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/payercategory/all`, {
        headers: headers,
      })
      .then((res) => {
        this.setState({ categorydata: res.data.payer });
      })
      .catch((err) => {});
  };

  reset = () => {
    this.setState({
      payername: "",
      payerid: "",
      contactno: "",
      carriercode: "",
      category: [],
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      telph1: "",
      telph2: "",
      fax: "",
      email: "",
      billingmethod: "",
      insurcategory: [],
      recvname: "",
      claimfilingindicator: "",
      plantype: "",
      incentivecategory: "",
      timelyfilinglimit1: "",
      timelyfilinglimit2: "",
      noofdays: "",
      partAclaimfiling: "",
      taxonomycode: "No",
      status: "Disabled",
      providerid: "No",
      sendreferringprovider: "No",
      billedbyEHI: "No",
      resendunappliedclaims: "No",
      sendpatientAddress: "No",
      billingonmins: false,
      sendCRNAID: false,
      checktaxonomyCode: false,
      participatingprovider: false,
      appealclaimaddress: false,
      eleclab: "",
      labcorpid: "",
      labcorplantype: "",
      medicallabid: "",
      questid: "",
      enzoid: "",
      propathid: "",
      biorefid: "",
      phelpsid: "",
      hldid: "",
      accurateid: "",
      chid: "",
      lencoid: "",
      applelabid: "",
      empirelabid: "",
      enigmaid: "",
      sheillabid: "",
      fusionid: "",
      everestid: "",
      mercyid: "",
      smalabid: "",
      ezid: "",
      solstasid: "",
      planname: "",
      // payerdata:[],
      user: [],
      categorydata: [],
      zipcodeErr: "",
      billingmethodErr: "",
      categoryErr: "",
      recvnameErr: "",
      // postcategory:[],
    });
  };

  componentDidMount() {
    
    this.getpayer();
    this.getcategory();
    this.getpayercategory();
    this.getClaimStatus();
  }
  render() {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));
    return (
      <div>
      <Newsidebar  name="User Details" />
      <div style={{overflow:"hidden",paddingTop:"1px"}}>
        <div className="align-column">

          <br></br>

          <br></br>
          <div className="align-row items" style={{ marginTop: "2%" }}>
            <button
              className="addNew"
              style={{ width: "none" }}
              onClick={this.toggle}
            >
              Add User Details
            </button>
          </div>
          <div style={{ height: "100%", width: "100%", marginTop: "2%" }}>
            <TableContainer>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      User ID
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      First Name
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      Last Name
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      Phone
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      Department
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      Status
                    </StyledTableCell>

                    <StyledTableCell style={{ textAlign: "center" }}>
                      Action
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {rows.map((row) => ( */}
                  {/* {this.state.payer &&
                    this.state.payer.map((data, index) => (
                      <StyledTableRow key={data._id}>
                        <StyledTableCell component="th" scope="row">
                          {data.category_name}
                        </StyledTableCell>
                        <StyledTableCell>{data.payer_name}</StyledTableCell>
                        <StyledTableCell>{data.type}</StyledTableCell>
                        <StyledTableCell>{data.payer_id}</StyledTableCell>

                        <StyledTableCell>{data.contact}</StyledTableCell>
                        <StyledTableCell>{data.telphno1}</StyledTableCell>
                        <StyledTableCell>{data.fax}</StyledTableCell>
                        <StyledTableCell>{data.billing_method}</StyledTableCell>
                        <StyledTableCell>{data.receiver_name}</StyledTableCell>
                        <StyledTableCell>
                          <button
                            className="Edit"
                            onClick={() => {
                              this.setState({ dataforupdate: data }, () => {
                                this.settingupdatestate();
                                this.toggleupdate();
                              });
                            }}
                          >
                            Edit
                          </button>
                          &nbsp;
                          <button
                            className="Delete"
                            onClick={() => {
                              this.setState({ id: data._id }, () => {
                                this.toggledelete();
                              });
                            }}
                          >
                            Delete
                          </button>
                        </StyledTableCell>
                      </StyledTableRow>
                      // ))}
                    ))} */}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ marginLeft: "3%", marginTop: "20px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
          {this.state.modal && (
            <Modal
              style={{ width: "100%", height: "90%" }}
              isOpen={this.state.modal}
              onHide={this.toggle}
              onExit={this.reset}
              centered
              animation="false"
              size="lg"
            >
              <ModalHeader toggle={this.toggle}>
                Create User Details
              </ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Tabs
                  activeTab="1"
                  className="tabs"
                  ulClassName=""
                  activityClassName="bg-info"
                >
                  <Tab title="User Details" className="mr-3">
                    <br />
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>User Id</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>DEA Number</Label>
                            <Input type="number" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>First Name</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Last Name</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Email</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>SSN</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>ID Number</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>NPI</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Speciality</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Fed Tax id</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Sub-Speciality</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Link Physician</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Attn Physician</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Dashboard</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Password</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Privileged Slot</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Privilege</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Telephone 1</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Telephone 2</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Departmant</Label>
                            <Input type="select" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Cell Phone</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row style={{ paddingTop: "3%" }}>
                        <Col>
                          <Input type="checkbox" />
                          <Label
                            for="management_access"
                            style={{ marginLeft: "2%" }}
                          >
                            Primary Physician
                          </Label>
                        </Col>
                        <Col>
                          <Input type="checkbox" />
                          <Label
                            for="transaction_access"
                            style={{ marginLeft: "2%" }}
                          >
                            is this role schedulable?
                          </Label>
                        </Col>
                      </Row>
                      <br />
                      <Row style={{ paddingTop: "3%" }}>
                        <Col>
                          <Input type="checkbox" />
                          <Label
                            for="management_access"
                            style={{ marginLeft: "2%" }}
                          >
                            Sign Encounters Labs & Orders
                          </Label>
                        </Col>
                        <Col>
                          <Input type="checkbox" />
                          <Label
                            for="transaction_access"
                            style={{ marginLeft: "2%" }}
                          >
                            Care Coordinator
                          </Label>
                        </Col>
                      </Row>
                      <br />
                      <Row style={{ paddingTop: "3%" }}>
                        <Col>
                          <Input type="checkbox" />
                          <Label
                            for="management_access"
                            style={{ marginLeft: "2%" }}
                          >
                            Show Claims and Financiasis
                          </Label>
                        </Col>
                        <Col>
                          <Input type="checkbox" />
                          <Label
                            for="transaction_access"
                            style={{ marginLeft: "2%" }}
                          >
                            Report Gallery
                          </Label>
                        </Col>
                      </Row>
                      <br />
                      <Row style={{ paddingTop: "3%" }}>
                        <Col>
                          <Input type="checkbox" />
                          <Label
                            for="management_access"
                            style={{ marginLeft: "2%" }}
                          >
                            Allow BTG
                          </Label>
                        </Col>
                        <Col>
                          <Input type="checkbox" />
                          <Label
                            for="transaction_access"
                            style={{ marginLeft: "2%" }}
                          >
                            Allow booking from PHR
                          </Label>
                        </Col>
                      </Row>
                      <br />
                      <Row style={{ paddingTop: "3%" }}>
                        <Col>
                          <Input type="checkbox" />
                          <Label
                            for="management_access"
                            style={{ marginLeft: "2%" }}
                          >
                            Allow multi-location booking
                          </Label>
                        </Col>
                        <Col>
                          <Input type="checkbox" />
                          <Label
                            for="transaction_access"
                            style={{ marginLeft: "2%" }}
                          >
                            Bypass IP Security Rules
                          </Label>
                        </Col>
                      </Row>
                      <br />
                      <Row style={{ paddingTop: "3%" }}>
                        <Col>
                          <Input type="checkbox" />
                          <Label
                            for="management_access"
                            style={{ marginLeft: "2%" }}
                          >
                            Manage Physician Schedule(s)
                          </Label>
                        </Col>
                        <Col>
                          <Input type="checkbox" />
                          <Label
                            for="transaction_access"
                            style={{ marginLeft: "2%" }}
                          >
                            One time password
                          </Label>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Status</Label>
                            <Input type="select">
                              <option>Enable</option>
                              <option>Disable</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Tab>
                  <Tab title="More Details" className="mr-3">
                    <br />
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Medicare Pin</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>PRO Pin</Label>
                            <Input type="number" />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Taxonomy Code</Label>
                            <Input type="number" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Medicaid Pin</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>HMO Pin</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>CP0 No</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Tricare Pin</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>U Pin</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Mammography Certi</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>ID Number</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>NPI</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Blue Cross/Shield Pin</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>CLIA Number</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>WOB Authorization No.</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Commercial Pin</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>TAT No</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>WCB Rating Code</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Attn Physician</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>
                              Default Self Pay Amount for Web Booking
                            </Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Accreditation</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Security Question</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Security Question</Label>
                            <Input type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                    </Form>
                  </Tab>
                  <Tab title="Schedule Details" className="mr-3" />
                </Tabs>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggle();
                    this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={this.handleSubmit}
                >
                  Create
                </Button>
              </ModalFooter>
            </Modal>
          )}

          {this.state.modal2 && (
            <Modal
              style={{ width: "100%", height: "90%" }}
              isOpen={this.state.modal2}
              onHide={this.toggle2}
              //onExit={this.reset}
              centered
              animation="false"
              size="lg"
            >
              <ModalHeader toggle={this.toggle2}>
                Advance Billing Provider Setup
              </ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Form>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Physician</Label>
                        <Input type="select"></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Type</Label>
                        <Input type="select"></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Service Location</Label>
                        <Input type="text" />
                      </FormGroup>
                    </Col>
                  </Row>

                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Payer Category</Label>
                        <Input type="select"></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Billing Provider</Label>
                        <Input type="select"></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                </Form>
                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggle2();
                    //this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={() => {
                    this.toggle2();
                    //this.reset();
                  }}
                >
                  Create
                </Button>
              </ModalFooter>
            </Modal>
          )}

          {/* {this.state.updatemodal && this.state.dataforupdate && ( */}
          {this.state.updatemodal && this.state.dataforupdate && (
            <Modal
              classsName="insuranceedit"
              isOpen={this.state.updatemodal}
              onHide={this.toggleupdate}
              onExit={this.reset}
              centered
              animation="false"
              size="lg"
            >
              <ModalHeader toggle={this.toggleupdate}>
                Edit Payer Master
              </ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
                //  style={{
                //   "max-height": "calc(100vh - 210px)",
                //   "overflow-y": "auto",
                // }}
              >
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Insurance/Payer Name</Label>
                        <Input
                          type="text"
                          value={this.state.updatepayername}
                          onChange={(e) =>
                            this.setState({
                              updatepayername: e.target.value,
                              firstnameErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.firstnameErr}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>EDI Payer Id</Label>
                        <Input
                          type="number"
                          value={this.state.updatepayerid}
                          onChange={(e) =>
                            this.setState({
                              updatepayerid: e.target.value,
                              firstnameErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.firstnameErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Contact</Label>
                        <Input
                          type="number"
                          value={this.state.updatecontact}
                          onChange={(e) =>
                            this.setState({
                              updatecontact: e.target.value,
                              firstnameErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.firstnameErr}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Category</Label>
                        <Input
                          type="select"
                          placeholder={this.state.updatecategoryname}
                          value={this.state.updatecategoryname}
                          onChange={(e) =>
                            this.setState({
                              updatecategoryname: e.target.value,
                              categoryErr: "",
                            })
                          }
                        >
                          <option>Select...</option>
                          {this.state.categorydata.length > 0 &&
                            this.state.categorydata.map((data, i) => {
                              return (
                                <option key={i} value={data.category_name}>
                                  {data.category_name}
                                </option>
                              );
                            })}
                          {/* {this.state.modifieroption} */}
                        </Input>
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.categoryErr}
                        </div>
                      </FormGroup>
                      {/* <FormGroup>
                      <Label className="heading required">Category</Label>
                      <Select
                      
                        placeholder={this.state.updatecategoryname}
                        options={this.state.categorydata}
                        values={this.state.updatecategoryname}
                        searchable={true}
                        dropdownHandle={true}
                        searchBy="category_name"
                        labelField="category_name"
                        noDataLabel="No matches found"
                        onChange={(values) =>
                          this.setState({ updatecategoryname: values, categoryErr: "" })
                        }
                      />
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.categoryErr}
                      </div>
                    </FormGroup> */}
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Workers comp Carrier Code #</Label>
                        <Input
                          type="number"
                          value={this.state.updatecarriercode}
                          onChange={(e) =>
                            this.setState({
                              updatecarriercode: e.target.value,
                              firstnameErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.firstnameErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <br />
                <Tabs
                  activeTab="1"
                  className="tabs"
                  ulClassName=""
                  activityClassName="bg-info"
                >
                  <Tab title="Address" className="mr-3">
                    <br />
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Address 1</Label>
                            <Input
                              type="text"
                              value={this.state.updateaddress1}
                              onChange={(e) =>
                                this.setState({
                                  updateaddress1: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Address 2</Label>
                            <Input
                              type="text"
                              value={this.state.updateaddress2}
                              onChange={(e) =>
                                this.setState({
                                  updateaddress2: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>City</Label>
                            <Input
                              type="text"
                              value={this.state.updatecity}
                              onChange={(e) =>
                                this.setState({
                                  updatecity: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>State</Label>
                            <Select
                              // className=" depselect"
                              placeholder="Select State"
                              options={this.state.categorydata}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  medicationErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Country</Label>
                            <Input
                              type="text"
                              value={this.state.updatecountry}
                              onChange={(e) =>
                                this.setState({
                                  updatecountry: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label className="heading required">Zip Code</Label>
                            <Input
                              type="number"
                              value={this.state.updatezipcode}
                              onChange={(e) =>
                                this.setState({
                                  updatezipcode: e.target.value,
                                  zipcodeErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.zipcodeErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label></Label>
                            <Input
                              type="number"

                              //value={this.state.zipcode}
                              // onChange={(e) =>
                              //   this.setState({ zipcode: e.target.value, zipcodeErr: "" })
                              // }
                            />
                            {/* <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.zipcodeErr}
                      </div> */}
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Telephone 1</Label>
                            <Input
                              type="text"
                              value={this.state.updatetelph1}
                              onChange={(e) =>
                                this.setState({
                                  updatetelph1: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Telephone 2</Label>
                            <Input
                              type="number"
                              value={this.state.updatetelph2}
                              onChange={(e) =>
                                this.setState({
                                  updatetelph2: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Fax</Label>
                            <Input
                              type="number"
                              value={this.state.updatefax}
                              onChange={(e) =>
                                this.setState({
                                  updatefax: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Email</Label>
                            <Input
                              type="text"
                              value={this.state.updateemail}
                              onChange={(e) =>
                                this.setState({
                                  updateemail: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Tab>
                  <Tab title="EDI/Claim Settings" className="mr-3">
                    <br />
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label className="heading required">
                              Default Billing Method
                            </Label>
                            <Select
                              // className=" depselect"
                              placeholder="Select Default Billing Method"
                              options={this.state.categorydata}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  medicationErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col></Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label className="heading required">
                              Receiver Name
                            </Label>
                            <Select
                              // className=" depselect"
                              placeholder="Select Receiver Name"
                              options={this.state.categorydata}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  medicationErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Claim Filing Indicator</Label>
                            <Select
                              // className=" depselect"
                              placeholder="Select Claim Filing Indicator"
                              options={this.state.categorydata}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  medicationErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Program Plan Type</Label>
                            <Select
                              // className=" depselect"
                              placeholder="Select Program Plan Type"
                              options={this.state.categorydata}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  medicationErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Incentive Category</Label>
                            <Select
                              // className=" depselect"
                              placeholder="Select Incentive Category"
                              options={this.state.categorydata}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  medicationErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Timely Filing Limit (Original Claims)</Label>
                            <Input
                              type="number"
                              value={this.state.updatetimelyfilinglimit1}
                              onChange={(e) =>
                                this.setState({
                                  updatetimelyfilinglimit1: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>
                              Timely Filing Limit (Resubmitted Claims)
                            </Label>
                            <Input
                              type="number"
                              value={this.state.updatetimelyfilinglimit2}
                              onChange={(e) =>
                                this.setState({
                                  updatetimelyfilinglimit2: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>No.of Days</Label>
                            <Input
                              type="text"
                              value={this.state.updatenoofdays}
                              onChange={(e) =>
                                this.setState({
                                  updatenoofdays: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Part A Claims filing Indicator</Label>
                            <Select
                              // className=" depselect"
                              placeholder="Select Part A Claims filing Indicator"
                              options={this.state.categorydata}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  medicationErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />

                      <Row>
                        <Col>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label>
                                  Second Billing Provider <br /> Taxonomy Code
                                </Label>
                              </Col>
                              <Col md="6">
                                <Input
                                  type="select"
                                  value={this.state.updatetaxonomycode}
                                  onChange={(e) =>
                                    this.setState({
                                      updatetaxonomycode: e.target.value,
                                    })
                                  }
                                >
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <br />
                        <Col>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label>Status </Label>
                              </Col>
                              <Col md="6">
                                <Input
                                  type="select"
                                  value={this.state.updatestatus}
                                  onChange={(e) =>
                                    this.setState({
                                      updatestatus: e.target.value,
                                    })
                                  }
                                >
                                  <option value="Disabled">Disabled</option>
                                  <option value="Enabled">Enabled</option>
                                </Input>
                              </Col>
                            </Row>

                            {/* <div onChange={(e) =>
                          this.setState({ status: e.target.value, firstnameErr: "" })
                        }> */}
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label>
                                  Provider Service
                                  <br />
                                  Location Provider ID{" "}
                                </Label>
                              </Col>
                              <Col md="6">
                                <Input
                                  type="select"
                                  value={this.state.updateproviderid}
                                  onChange={(e) =>
                                    this.setState({
                                      updateproviderid: e.target.value,
                                    })
                                  }
                                >
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label>
                                  Send Default <br /> Referring Provider
                                </Label>
                              </Col>
                              <Col md="6">
                                <Input
                                  type="select"
                                  value={this.state.updatesendreferringprovider}
                                  onChange={(e) =>
                                    this.setState({
                                      updatesendreferringprovider:
                                        e.target.value,
                                    })
                                  }
                                >
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </Input>
                              </Col>
                            </Row>
                            {/* <br/>
                    <div onChange={(e) =>
                         <Label>Send Default <br/> Referring Provider</Label>  
                          this.setState({ sendreferringprovider: e.target.value, firstnameErr: "" })
                        }> */}
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label>
                                  Billed By EHI For
                                  <br />
                                  RCM
                                </Label>
                              </Col>
                              <Col md="6">
                                <Input
                                  type="select"
                                  value={this.state.updatebilledbyEHI}
                                  onChange={(e) =>
                                    this.setState({
                                      updatebilledbyEHI: e.target.value,
                                    })
                                  }
                                >
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label>
                                  Resend Unapplied
                                  <br />
                                  Claims Automatically
                                </Label>
                              </Col>
                              <Col md="6">
                                <Input
                                  type="select"
                                  value={this.state.updateresendunappliedclaims}
                                  onChange={(e) =>
                                    this.setState({
                                      updateresendunappliedclaims:
                                        e.target.value,
                                    })
                                  }
                                >
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label>
                                  Send Patient Address for Home Service Location
                                </Label>
                              </Col>
                              <Col md="6">
                                <Input
                                  type="select"
                                  value={this.state.updatesendpatientAddress}
                                  onChange={(e) =>
                                    this.setState({
                                      updatesendpatientAddress: e.target.value,
                                    })
                                  }
                                >
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col></Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <br />
                            <Label>Billing on minutes</Label>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Input
                              type="checkbox"
                              name="billingonmins"
                              id="billingonmins"
                              checked={this.state.updatebillingonmins}
                              value={this.state.updatebillingonmins}
                              onChange={() => {
                                this.setState({
                                  updatebillingonmins:
                                    !this.state.updatebillingonmins,
                                  roleErr: "",
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <br />
                            <Label>Send CRNA Id</Label> &nbsp;&nbsp;&nbsp;&nbsp;
                            <Input
                              type="checkbox"
                              name="sendCRNAId"
                              id="sendCRNAId"
                              checked={this.state.updatesendCRNAID}
                              value={this.state.updatesendCRNAID}
                              onChange={() => {
                                this.setState({
                                  updatesendCRNAID:
                                    !this.state.updatesendCRNAID,
                                  roleErr: "",
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <br />
                            <Label>Taxonomy Code</Label>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Input
                              type="checkbox"
                              name="checktaxonomyCode"
                              id="checktaxonomyCode"
                              checked={this.state.updatechecktaxonomyCode}
                              value={this.state.updatechecktaxonomyCode}
                              onChange={() => {
                                this.setState({
                                  updatechecktaxonomyCode:
                                    !this.state.updatechecktaxonomyCode,
                                  roleErr: "",
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <br />
                            <Label>Participating Provider</Label>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Input
                              type="checkbox"
                              name="participatingprovider"
                              id="participatingprovider"
                              checked={this.state.updateparticipatingprovider}
                              value={this.state.updateparticipatingprovider}
                              onChange={() => {
                                this.setState({
                                  updateparticipatingprovider:
                                    !this.state.updateparticipatingprovider,
                                  roleErr: "",
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <br />
                            <Label>Appeal Claim Address</Label>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Input
                              type="checkbox"
                              name="appealclaimaddress"
                              id="appealclaimaddress"
                              checked={this.state.updateappealclaimaddress}
                              value={this.state.updateappealclaimaddress}
                              onChange={() => {
                                this.setState({
                                  updateappealclaimaddress:
                                    !this.state.updateappealclaimaddress,
                                  roleErr: "",
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col></Col>
                      </Row>
                    </Form>
                  </Tab>
                  <Tab title="Lab Setup" className="mr-3">
                    <br />
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Default Electronic Lab</Label>
                            <Select
                              // className=" depselect"
                              placeholder="Select Default Electronic Lab"
                              options={this.state.categorydata}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  medicationErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col></Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>LabCorp ID</Label>
                            <Input
                              type="number"
                              value={this.state.updatelabcorpid}
                              onChange={(e) =>
                                this.setState({
                                  updatelabcorpid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>LabCorp Plan Type</Label>
                            <Input
                              type="text"
                              value={this.state.updatelabcorplantype}
                              onChange={(e) =>
                                this.setState({
                                  updatelabcorplantype: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Medical Lab ID</Label>
                            <Input
                              type="number"
                              value={this.state.updatemedicallabid}
                              onChange={(e) =>
                                this.setState({
                                  updatemedicallabid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Quest ID</Label>
                            <Input
                              type="number"
                              value={this.state.updatequestid}
                              onChange={(e) =>
                                this.setState({
                                  updatequestid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Enzoid</Label>
                            <Input
                              type="number"
                              value={this.state.updateenzoid}
                              onChange={(e) =>
                                this.setState({
                                  updateenzoid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Pro Path</Label>
                            <Input
                              type="number"
                              value={this.state.updatepropathid}
                              onChange={(e) =>
                                this.setState({
                                  updatepropathid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Bio Ref ID</Label>
                            <Input
                              type="number"
                              value={this.state.updatebiorefid}
                              onChange={(e) =>
                                this.setState({
                                  updatebiorefid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Phelps ID</Label>
                            <Input
                              type="number"
                              value={this.state.updatephelpsid}
                              onChange={(e) =>
                                this.setState({
                                  updatephelpsid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>HTL ID</Label>
                            <Input
                              type="number"
                              value={this.state.updatehldid}
                              onChange={(e) =>
                                this.setState({
                                  updatehldid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Accurate ID</Label>
                            <Input
                              type="number"
                              value={this.state.updateaccurateid}
                              onChange={(e) =>
                                this.setState({
                                  updateaccurateid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>CH Group ID</Label>
                            <Input
                              type="number"
                              value={this.state.updatechid}
                              onChange={(e) =>
                                this.setState({
                                  updatechid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Lencoid</Label>
                            <Input
                              type="number"
                              value={this.state.updatelencoid}
                              onChange={(e) =>
                                this.setState({
                                  updatelencoid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Apple Lab ID</Label>
                            <Input
                              type="number"
                              value={this.state.updateapplelabid}
                              onChange={(e) =>
                                this.setState({
                                  updateapplelabid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Empire Lab ID</Label>
                            <Input
                              type="number"
                              value={this.state.updateempirelabid}
                              onChange={(e) =>
                                this.setState({
                                  updateempirelabid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Enigma ID</Label>
                            <Input
                              type="number"
                              value={this.state.updateenigmaid}
                              onChange={(e) =>
                                this.setState({
                                  updateenigmaid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Shiel Lab ID</Label>
                            <Input
                              type="number"
                              value={this.state.updatesheillabid}
                              onChange={(e) =>
                                this.setState({
                                  updatesheillabid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Fusion ID</Label>
                            <Input
                              type="number"
                              value={this.state.updatefusionid}
                              onChange={(e) =>
                                this.setState({
                                  updatefusionid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Mercy ID</Label>
                            <Input
                              type="number"
                              value={this.state.updatefusionid}
                              onChange={(e) =>
                                this.setState({
                                  updatefusionid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>SMA Lab ID</Label>
                            <Input
                              type="number"
                              value={this.state.updatesmalabid}
                              onChange={(e) =>
                                this.setState({
                                  updatesmalabid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>EZ ID</Label>
                            <Input
                              type="number"
                              value={this.state.updateezid}
                              onChange={(e) =>
                                this.setState({
                                  updateezid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Solstas ID</Label>
                            <Input
                              type="number"
                              value={this.state.updatesolstasid}
                              onChange={(e) =>
                                this.setState({
                                  updatesolstasid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Tab>
                  <Tab title="Associate Plans" className="mr-3">
                    <br />
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            {/* <Label>Insurance/Payer Name</Label> */}
                            <Input
                              type="text"
                              placeholder="Plan Name"
                              value={this.state.updateplanname}
                              onChange={(e) =>
                                this.setState({
                                  updateplanname: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Tab>
                </Tabs>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggleupdate();
                    this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={() => {
                    this.putrequests(this.state.updateid);
                  }}
                >
                  Update
                </Button>
              </ModalFooter>
            </Modal>
          )}
          {this.state.deletemodal && (
            <Modal
              isOpen={this.state.deletemodal}
              onHide={this.toggledelete}
              // onExit={this.reloadpage}
              centered
            >
              <ModalHeader toggle={this.toggledelete}>
                Delete Master
              </ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Row style={{ margin: "0px" }}>Do you want to delete this?</Row>

                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid gray",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={this.toggledelete}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "rgb(25,25,25)" }}
                  onClick={() => {
                    this.deleterequests(this.state.id);
                  }}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </div>
      </div>
      </div>
    );
  }
}
export default UserDetails;

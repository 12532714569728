// src/context/FileUploadContext.js
import React, { createContext, Component } from 'react';

export const FileUploadContext = createContext();

export class FileUploadProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
      isMinimized: false,
      isHidden: false,
      startFileUpload: this.startFileUpload.bind(this),
      setIsMinimized: this.setIsMinimized.bind(this),
      setIsHidden: this.setIsHidden.bind(this),
    };
  }

  startFileUpload(file) {
    if (file) {
      const interval = setInterval(() => {
        this.setState((prevState) => {
          const newProgress = prevState.progress + 10;
          if (newProgress >= 100) {
            clearInterval(interval);
            return { progress: 100 };
          }
          return { progress: newProgress };
        });
      }, 300);
    }
  }

  setIsMinimized(isMinimized) {
    this.setState({ isMinimized });
  }

  setIsHidden(isHidden) {
    this.setState({ isHidden });
  }

  render() {
    return (
      <FileUploadContext.Provider value={this.state}>
        {this.props.children}
      </FileUploadContext.Provider>
    );
  }
}

import React, { Component } from "react";
import axios from "axios";
import "../../Styles/admin.css";
import Newsidebar from "../Dashboard/Newsidebar";
import { Row, Col } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Input } from "reactstrap";
import Select from "react-dropdown-select";

import { Link } from "react-router-dom";

import "../../Styles/allocation.css";
import "../../Styles/loader.scss";

import Checkbox from "../Allocation/Checkbox";
import { Form, FormGroup, Label } from "reactstrap";
import "../../Styles/loading.scss";

import ReactPaginate from "react-paginate";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ip } from "../Api";
import { BiUpArrowAlt } from "react-icons/bi";
import { BiDownArrowAlt } from "react-icons/bi";
export default class Unallocatedclaim extends Component {
  constructor(props) {
    super(props);
    this.multiselectRef = React.createRef();
    this.state = {
      fetching1: true,
      selecteduser: [],
      selectedage: "",
      ids: [],
      tabindex: 1,
      users: [],
      checkedAll: false,
      alldataresponse: [],
      selectuserErr: "",
      claim_id: "",
      clientslist: [],
      opendropdown: false,
      Location: "",
      doiagegrp: "",
      perPage: 10,
      currentpage: 0,
      offset: 0,
      agegrp: "",
      sortstate: "ASC",
      openicon: false,
      claimdetailskey: sessionStorage.getItem("claimdetailskey"),
      currentpage: 0,
      tableaction: [],
      currentpage1: 0,
      claimids: [],
      perPage1: 10,
      offset1: 0,
      claimcategory: sessionStorage.getItem("claimcategory"),
      keytype: sessionStorage.getItem("keytype"),
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      role_type: sessionStorage.getItem("role_type"),
      client: sessionStorage.getItem("clientid"),
      clientname: sessionStorage.getItem("clientname"),
      sort: "",
      fieldsort: "",
      sorttable: "false",
      sortdata: [],
      perPagesort: 10,
      offsetsort: 0,
      currentpagesort: 0,
      pageCountsort: "",
      queryLocation: sessionStorage.getItem("queryLocation"),
      querystartdate: sessionStorage.getItem("querystartdate"),
      queryenddate: sessionStorage.getItem("queryenddate"),
    };
  }
  getsorting = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.client) {
      if (this.state.querystartdate && this.state.queryenddate) {
        if (this.state.queryLocation) {
          url = `${ip}/outcome/allocate/sorting?clientId=${this.state.client}&field=${this.state.fieldsort}&sorting=${this.state.sort}&type=${this.state.keytype}&unallocatedpageno=${this.state.currentpagesort}&Location=${this.state.queryLocation}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/outcome/allocate/sorting?clientId=${this.state.client}&field=${this.state.fieldsort}&sorting=${this.state.sort}&type=${this.state.keytype}&unallocatedpageno=${this.state.currentpagesort}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        }
      } else {
        if (this.state.queryLocation) {
          url = `${ip}/outcome/allocate/sorting?clientId=${this.state.client}&field=${this.state.fieldsort}&sorting=${this.state.sort}&type=${this.state.keytype}&unallocatedpageno=${this.state.currentpagesort}&Location=${this.state.queryLocation}`;
        } else {
          url = `${ip}/outcome/allocate/sorting?clientId=${this.state.client}&field=${this.state.fieldsort}&sorting=${this.state.sort}&type=${this.state.keytype}&unallocatedpageno=${this.state.currentpagesort}`;
        }
      }
    } else {
      if (this.state.querystartdate && this.state.queryenddate)
        url = `${ip}/outcome/allocate/sorting?clientId=${this.state.client}&field=${this.state.fieldsort}&sorting=${this.state.sort}&type=${this.state.keytype}&unallocatedpageno=${this.state.currentpagesort}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
      else
        url = `${ip}/outcome/allocate/sorting?clientId=${this.state.client}&field=${this.state.fieldsort}&sorting=${this.state.sort}&type=${this.state.keytype}&unallocatedpageno=${this.state.currentpagesort}`;
    }
    const res = await axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          sortdata: res.data.claims,
          pageCountsort: Math.ceil(
            res.data.claimcount / this.state.perPagesort
          ),
        });
      })

      .catch((err) => {});
  };
  handlePagesort = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPagesort;

    this.setState(
      {
        currentpagesort: selectedPage,
        offsetsort: offset,
      },
      () => {
        this.getsorting();
      }
    );
  };
  toggle = () => {
    if (this.state.ids.length !== 0) {
      const { modal } = this.state;
      this.setState({ modal: !modal });
    } else {
      alert("Select Claims to allocate");
    }
  };
  toggledropdown = () => {
    this.setState({ opendropdown: !this.state.opendropdown });
  };
  getclaims = async () => {
    //api call to get all users

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.client) {
      if (this.state.Location) {
        url = `${ip}/allocation/all?clientId=${this.state.client}&pageno=${
          this.state.currentpage + 1
        }&Location=${this.state.Location}&count=${this.state.perPage}`;
      } else {
        url = `${ip}/allocation/all?clientId=${this.state.client}&pageno=${
          this.state.currentpage + 1
        }&count=${this.state.perPage}`;
      }
    } else {
      url = `${ip}/allocation/all?pageno=${this.state.currentpage + 1}&count=${
        this.state.perPage
      }`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          pageCount: Math.ceil(res.data.count / this.state.perPage),
          claims: res.data.claims,

          fetching2: false,
          fetching3: false,
        });
      })
      .catch((err) => {});
  };
  getusers = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(`${ip}/users/all?clientId=${this.state.client}`, { headers: headers })
      .then((res) => {
        let data = res.data.user;
        if (this.state.client) {
          data = data.filter((item) => {
            return (
              item.role === "Team Member" 
            );
          });
        } else {
          data = data.filter((item) => {
            return item.role === "Admin" || item.role==="SuperAdmin" ;
          });
        }
        this.setState({ users: data });
      })

      .catch((err) => {});
  };
  allocateclaims = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      ids: this.state.ids,
    };
    const res = axios
      .put(
        `${ip}/allocation/allocate/${this.state.selecteduser[0]._id}`,
        value,
        {
          headers: headers,
        }
      )
      .then((res) => {
        alert(
          `claims allocated to user ${this.state.selecteduser[0].first_name}`
        );
        this.getdatakeyindicator();
        this.resetuser();
        this.getclaims();
        // this.toggle();
        // window.location.reload();
      })

      .catch((err) => {});
  };
  addsinglecheckboxid = (data, check) => {
    if (check) {
      this.setState(
        { ids: [...this.state.ids, data] }
        // this.toggle();
      );
    } else {
      let arr = this.state.ids.slice();
      let val = arr.filter((id) => {
        return id !== data;
      });
      this.setState(
        { ids: val }
        // this.toggle();
      );
    }
  };
  checkBoxAll = () => {
    if (this.state.checkedAll === false) {
      let idvalue = this.state.tableaction.map((data) => data._id);
      this.setState(
        { checkedAll: !this.state.checkedAll, ids: idvalue },
        () => {
          this.toggle();
        }
      );
    } else {
      this.setState({ checkedAll: !this.state.checkedAll, ids: [] }, () => {});
    }
  };
  checkboxsearchall = () => {
    if (this.state.checkedAll === false) {
      let idvalue = this.state.searchdataresponse.map((data) => data._id);
      this.setState(
        { checkedAll: !this.state.checkedAll, ids: idvalue },
        () => {
          this.toggle();
        }
      );
    } else {
      this.setState({ checkedAll: !this.state.checkedAll, ids: [] }, () => {});
    }
  };
  getdatakeyindicator = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.client) {
      if (this.state.querystartdate && this.state.queryenddate) {
        if (this.state.queryLocation) {
          url = `${ip}/outcome/allocate/sorting?unallocatedpageno=${this.state.currentpage1}&clientId=${this.state.client}&type=${this.state.keytype}&Location=${this.state.queryLocation}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/outcome/allocate/sorting?unallocatedpageno=${this.state.currentpage1}&clientId=${this.state.client}&type=${this.state.keytype}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        }
      } else {
        if (this.state.queryLocation) {
          url = `${ip}/outcome/allocate/sorting?unallocatedpageno=${this.state.currentpage1}&clientId=${this.state.client}&type=${this.state.keytype}&Location=${this.state.queryLocation}`;
        } else {
          url = `${ip}/outcome/allocate/sorting?unallocatedpageno=${this.state.currentpage1}&clientId=${this.state.client}&type=${this.state.keytype}`;
        }
      }
    } else {
      if (this.state.querystartdate && this.state.queryenddate)
        url = `${ip}/outcome/allocate/sorting?unallocatedpageno=${this.state.currentpage1}&type=${this.state.keytype}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
      else
        url = `${ip}/outcome/allocate/sorting?unallocatedpageno=${this.state.currentpage1}&type=${this.state.keytype}`;
    }
    const res = axios
      .get(url, { headers: headers })
      .then((res) => {
        this.setState({
          tableaction: res.data.claims,
          fetching1: false,
        });

        this.setState({
          pageCount1: Math.ceil(res.data.claimcount / this.state.perPage1),
        });
      })

      .catch((err) => {});
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        if (this.state.notselect) {
          this.getclaims();
        } else {
          this.selectedpayer();
        }
      }
    );
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  handlePageClickaction = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage1;

    this.setState(
      {
        currentpage1: selectedPage,
        offset1: offset,
      },
      () => {
        this.getdatakeyindicator();
      }
    );
  };
  sortingweb = (col) => {
    if (this.state.sortstate === "ASC") {
      const sorted = [...this.state.tableaction].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      this.setState({ tableaction: sorted });
      this.setState({ sortstate: "DSC" });
    }
    if (this.state.sortstate === "DSC") {
      const sorted = [...this.state.tableaction].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      this.setState({ tableaction: sorted });
      this.setState({ sortstate: "ASC" });
    }
  };
  resetuser = () => {
    this.setState({
      ids: [],
    });
  };
  componentDidMount() {
    

    this.getdatakeyindicator();
    this.getusers();
  }

  render() {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    const checkboxstyle = {
      marginTop: "27px",
    };
    return (
      <div>
      <Newsidebar name="Unallocated claims" />
      <div className="align-row">
        <div className="align-column">

          <div
            style={{ textAlign: "left", marginLeft: "1%", fontSize: "16px" }}
          >
            {this.state.claimcategory === "webclaim" ? (
              <p>
                <Link
                  to="/claims/Web"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    marginLeft: "2%",
                  }}
                >
                  Web Claims
                </Link>
                {/* &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
               
                &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
                {this.state.subcategoryname} */}
              </p>
            ) : null}
            {this.state.claimcategory === "ivrclaim" ? (
              <p>
                <Link
                  to="/claims/IVR"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    marginLeft: "2%",
                  }}
                >
                  IVR Claims
                </Link>
                {/* &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
               
                &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
                {this.state.subcategoryname} */}
              </p>
            ) : null}
            {this.state.claimcategory === "manualclaim" ? (
              <p>
                <Link
                  to="/claims/Manual"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    marginLeft: "2%",
                  }}
                >
                  Manual Claims
                </Link>
                {/* &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
               
                &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
                {this.state.subcategoryname} */}
              </p>
            ) : null}
          </div>
          <br />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                marginLeft: "20px",
              }}
            >
              {this.state.role_type === "Admin" && (
                <Button
                  style={{
                    borderRadius: "8px",
                    color: "white",
                    background: "#041c3c",
                    fontWeight: "bold",
                  }}
                  onClick={this.toggle}
                >
                  Allocate
                </Button>
              )}
            </div>
          </div>
          <br />
          <br />

          <div style={{ height: "100%", width: "100%" }}>
            {this.state.fetching1 ? (
              // <img src={loading} />
              <div>
                <p
                  style={{
                    fontSize: "23px",
                    marginTop: "10px",
                    color: "#000",
                  }}
                >
                  <span>Loading...</span>
                  <span>Please Wait...</span>
                </p>
                <div class="boxes body__loader dribbble">
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            ) : this.state.tableaction.length === 0 ? (
              /* <img src={nodata} alt="" className="nodata" /> */
              <div class="bot-ui">
                <div class="bot-head">
                  <div class="eyes-container">
                    <div class="to-left eye"></div>
                    <div class="to-right eye"></div>
                  </div>
                </div>
                <div class="bot-body">
                  <div class="analyzer"></div>
                </div>
                <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                  No Data Found
                </p>
              </div>
            ) : (
              <div>
                <div style={{ paddingLeft: "10px" }}>
                  <TableContainer>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          {this.state.role_type === "Admin" && (
                            <StyledTableCell>
                              <input
                                type="checkbox"
                                checked={this.state.checkedAll}
                                onChange={this.checkBoxAll}
                              />
                            </StyledTableCell>
                          )}
                          <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                            Claim ID{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sort: "ascending",
                                      fieldsort: "ClaimID",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sort: "descending",
                                      fieldsort: "ClaimID",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                            Location{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sort: "ascending",
                                      fieldsort: "Location",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sort: "descending",
                                      fieldsort: "ClaimID",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                            Account No{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sort: "ascending",
                                      fieldsort: "patientAccountNumber",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sort: "descending",
                                      fieldsort: "patientAccountNumber",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                            DOS{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sort: "ascending",
                                      fieldsort: "DOS",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sort: "descending",
                                      fieldsort: "DOS",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                            Patient Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sort: "ascending",
                                      fieldsort: "PatientFirstName",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sort: "descending",
                                      fieldsort: "PatientFirstName",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                            Patient DOB{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sort: "ascending",
                                      fieldsort: "PatientDOB",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sort: "descending",
                                      fieldsort: "PatientDOB",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                            Plan Code{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sort: "ascending",
                                      fieldsort: "PlanCode",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sort: "descending",
                                      fieldsort: "PlanCode",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                            Insurance Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sort: "ascending",
                                      fieldsort: "InsuranceName",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sort: "descending",
                                      fieldsort: "InsuranceName",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                            Policy No{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sort: "ascending",
                                      fieldsort: "PrimaryPolicyNo",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sort: "descending",
                                      fieldsort: "PrimaryPolicyNo",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                            Physician Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sort: "ascending",
                                      fieldsort: "Physician",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sort: "descending",
                                      fieldsort: "Physician",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                            Age{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sort: "ascending",
                                      fieldsort: "Age",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sort: "descending",
                                      fieldsort: "Age",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                            Age Grp{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sort: "ascending",
                                      fieldsort: "AgeGrp",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sort: "descending",
                                      fieldsort: "AgeGrp",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                            DOI Age
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sort: "ascending",
                                      fieldsort: "DOIAge",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sort: "descending",
                                      fieldsort: "DOIAge",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                            DOI AgeGrp
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sort: "ascending",
                                      fieldsort: "DOIAgeGrp",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sort: "descending",
                                      fieldsort: "DOIAgeGrp",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                            Type{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sort: "ascending",
                                      fieldsort: "type",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sort: "descending",
                                      fieldsort: "type",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                            Claim Status{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sort: "ascending",
                                      fieldsort: "ClaimStatus",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sort: "descending",
                                      fieldsort: "ClaimStatus",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {this.state.sorttable === true ? (
                        <TableBody>
                          {this.state.sortdata &&
                            this.state.sortdata.map((data, index) => {
                              return (
                                <TableRow key={data._id}>
                                  {this.state.role_type === "Admin" &&
                                    (this.state.checkedAll === true ? (
                                      <div style={checkboxstyle}>
                                        <input
                                          type="checkbox"
                                          checked={this.state.checkedAll}
                                          onChange={this.checkBoxAll}
                                        />
                                      </div>
                                    ) : (
                                      <div style={checkboxstyle}>
                                        <Checkbox
                                          handleidpush={
                                            this.addsinglecheckboxid
                                          }
                                          number={data._id}
                                          key={index}
                                        />
                                      </div>
                                    ))}

                                  <StyledTableCell>
                                    {data.ClaimID}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.Location}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.patientAccountNumber
                                      ? data.patientAccountNumber
                                      : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.DateOfService
                                      ? data.DateOfService
                                      : "-"}
                                  </StyledTableCell>

                                  <StyledTableCell>
                                    {data.PatientFirstName}&nbsp;
                                    {data.PatientLastName}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.PatientDOB ? data.PatientDOB : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.PlanCode ? data.PlanCode : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.InsuranceName
                                      ? data.InsuranceName
                                      : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.PrimaryPolicyNo}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.Physician}
                                  </StyledTableCell>

                                  <StyledTableCell>{data.Age}</StyledTableCell>
                                  <StyledTableCell>
                                    {data.AgeGrp}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.DOIAge}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.DOIAgeGrp}
                                  </StyledTableCell>
                                  <StyledTableCell>{data.type}</StyledTableCell>
                                  <StyledTableCell>
                                    {data.ClaimStatus}
                                  </StyledTableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      ) : (
                        <TableBody>
                          {this.state.tableaction &&
                            this.state.tableaction.map((data, index) => {
                              return (
                                <TableRow key={data._id}>
                                  {this.state.role_type === "Admin" &&
                                    (this.state.checkedAll === true ? (
                                      <div style={checkboxstyle}>
                                        <input
                                          type="checkbox"
                                          checked={this.state.checkedAll}
                                          onChange={this.checkBoxAll}
                                        />
                                      </div>
                                    ) : (
                                      <div style={checkboxstyle}>
                                        <Checkbox
                                          handleidpush={
                                            this.addsinglecheckboxid
                                          }
                                          number={data._id}
                                          key={index}
                                        />
                                      </div>
                                    ))}

                                  <StyledTableCell>
                                    {data.ClaimID}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.Location}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.patientAccountNumber
                                      ? data.patientAccountNumber
                                      : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.DateOfService
                                      ? data.DateOfService
                                      : "-"}
                                  </StyledTableCell>

                                  <StyledTableCell>
                                    {data.PatientFirstName}&nbsp;
                                    {data.PatientLastName}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.PatientDOB ? data.PatientDOB : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.PlanCode ? data.PlanCode : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.InsuranceName
                                      ? data.InsuranceName
                                      : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.PrimaryPolicyNo}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.Physician}
                                  </StyledTableCell>

                                  <StyledTableCell>{data.Age}</StyledTableCell>
                                  <StyledTableCell>
                                    {data.AgeGrp}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.DOIAge}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.DOIAgeGrp}
                                  </StyledTableCell>
                                  <StyledTableCell>{data.type}</StyledTableCell>
                                  <StyledTableCell>
                                    {data.ClaimStatus}
                                  </StyledTableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                  {this.state.sorttable === true ? (
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCountsort}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePagesort}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  ) : (
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount1}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClickaction}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <br />

          <br />

          {this.state.ids.length !== 0 && this.state.modal && (
            <Modal
              style={{ width: "30%" }}
              isOpen={this.state.modal}
              onHide={this.toggle}
              size="md"
              centered
            >
              <ModalHeader toggle={this.toggle}>Create Allocation</ModalHeader>
              <ModalBody>
                <Row style={{ margin: "0px" }}>
                  <Col>
                    <Label>Agent/Team Member Name</Label>
                    <Select
                      options={this.state.users}
                      values={this.state.selecteduser}
                      searchable={true}
                      dropdownHandle={true}
                      searchBy="first_name"
                      labelField="first_name"
                      noDataLabel="No matches found"
                      onChange={(values) =>
                        this.setState({
                          selecteduser: values,
                          selectuserErr: "",
                        })
                      }
                      required
                    />
                  </Col>
                  <div style={{ fontSize: 16, color: "red" }}>
                    {this.state.selectuserErr}
                  </div>
                </Row>
                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggle();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    this.allocateclaims();
                    this.resetuser();
                  }}
                  style={{ backgroundColor: "grey", color: "#fff" }}
                >
                  Allocate
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </div>
      </div>
      </div>

    );
  }
}

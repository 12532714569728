import { React, Component } from "react";
import Newsidebar from "../../Dashboard/Newsidebar";
 
import { Table, Row, Col } from "reactstrap";
import { FiTrash } from "react-icons/fi";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Container, Card } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Label, Input } from "reactstrap";
import Select from "react-dropdown-select";
import { ip } from "../../Api";
class ARfollowUpUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      ClaimID: localStorage.getItem("ClaimID"),
      ChartID: localStorage.getItem("ChartID"),
      MRN: localStorage.getItem("MRN"),
      Patient: localStorage.getItem("Patient"),
      Pripayer: localStorage.getItem("Pripayer"),
      DateOfService: localStorage.getItem("DateOfService"),
      ClaimDate: localStorage.getItem("ClaimDate"),
      PatientDOB: localStorage.getItem("PatientDOB"),
    };
  }
  toggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };
  render() {
    return (
      <div className="align-row">
        <Newsidebar name="Allocation" />
        <div className="align-column">
          <br></br>
          <div
            style={{ textAlign: "left", marginLeft: "1%", fontSize: "14px" }}
          >
            <p>
              <Link
                to="/allocation-buckets"
                style={{ textDecoration: "none", color: "black" }}
              >
                Allocation
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
              <Link
                to="/TFLexceed"
                style={{ textDecoration: "none", color: "black" }}
              >
                Bucket 1
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
              <Link
                to="/manage-payment"
                style={{ textDecoration: "none", color: "black" }}
              >
                Payment Management
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;AR Update
            </p>
          </div>
          <br></br>
          <div>
            {" "}
            <h5
              style={{ textAlign: "left", marginLeft: "1%", fontWeight: "600" }}
            >
              AR Follow up Update
            </h5>
          </div>
          <div
            className="box"
            style={{ marginLeft: "2%", paddingBottom: "2%" }}
          >
            <div>
              {" "}
              <h5
                style={{
                  float: "left",
                  paddingLeft: "2%",
                  fontWeight: "600",
                  fontSize: "15px",
                  marginTop: "1%",
                }}
              >
                Last Assigned Details
              </h5>{" "}
            </div>
            <div style={{ marginTop: "3%" }}>
              {" "}
              <hr />
            </div>
            <Container style={{ marginLeft: "1%", width: "110%" }}>
              <Row style={{ width: "130%" }}>
                <Col style={{ fontSize: "100%", marginRight: "62%" }}>
                  <div style={{ marginLeft: "2%" }}>
                    <Card style={{ width: "190%", border: "aliceblue" }}>
                      <Container>
                        <Row>
                          <Col>
                            <Row style={{ marginLeft: "-6%" }}>
                              <Col>
                                <p className="ar-follow-up-text">
                                  PAYER TYPE&nbsp;
                                </p>
                              </Col>
                              <Col>
                                <p className="ar-follow-up-text">
                                  STATUS&nbsp;
                                </p>
                              </Col>
                              <Col style={{ marginLeft: "-3%" }}>
                                <p className="ar-follow-up-text">
                                  F/U DATE&nbsp;
                                </p>
                              </Col>
                              <Col style={{ marginLeft: "-3%" }}>
                                <p className="ar-follow-up-text">USER&nbsp;</p>
                              </Col>
                              <Col>
                                <p className="ar-follow-up-text">
                                  CASE TICKET&nbsp;
                                </p>
                              </Col>
                              <Col>
                                <p className="ar-follow-up-text">
                                  PAYER REF. NO&nbsp;
                                </p>
                              </Col>
                              <Col>
                                <p className="ar-follow-up-text">
                                  CHECK NO&nbsp;
                                </p>
                              </Col>
                            </Row>
                            <Row style={{ marginLeft: "-6%" }}>
                              <Col>
                                <p>Medicare&nbsp;</p>
                              </Col>
                              <Col>
                                <p>Paid&nbsp;</p>
                              </Col>
                              <Col>
                                <p>May 30,2021&nbsp;</p>
                              </Col>
                              <Col>
                                <p>Wade Warren&nbsp;</p>
                              </Col>
                              <Col>
                                <p>09090909&nbsp;</p>
                              </Col>
                              <Col>
                                <p>0012367&nbsp;</p>
                              </Col>
                              <Col>
                                <p>236464655&nbsp;</p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <hr />
                        <Row style={{ marginLeft: "-12%" }}>
                          <Col>
                            <p className="ar-follow-up-text">COMMENTS</p>
                            <div style={{ marginLeft: "29%" }}>
                              <p>sdgfgudgfgdgfudb</p>
                            </div>
                          </Col>
                          &nbsp;
                          <Col>
                            <p className="ar-follow-up-text">
                              CLAIM ASSIGNED TO
                            </p>
                            <div style={{ marginLeft: "13%" }}>
                              <p>Cameron Williamson</p>
                            </div>
                          </Col>
                          &nbsp;
                          <Col>
                            <p className="ar-follow-up-text">
                              CLAIM ASSIGNED BY
                            </p>
                            <div style={{ marginLeft: "-9%" }}>
                              <p>John Mathew</p>
                            </div>
                          </Col>
                          &nbsp;
                          <Col>
                            <p className="ar-follow-up-text">
                              CLAIM COMPLETED BY{" "}
                            </p>
                            <div style={{ marginLeft: "9%" }}>
                              <p>Cameron Williamson</p>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </Card>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <br />
          <br />
          <div>
            {" "}
            <h5 style={{ float: "left", paddingLeft: "2%", fontWeight: "600" }}>
              Claim Details
            </h5>{" "}
            &nbsp;{" "}
            <button className="update-status" onClick={this.toggle}>
              {" "}
              Update Status{" "}
            </button>
          </div>
          <div>
            <Table
              className="table"
              style={{ marginLeft: "2%", width: "99%", marginTop: "1%" }}
            >
              <thead>
                <tr align="center">
                  <th>ClaimID</th>
                  <th>Patient Name</th>
                  <th>Rendering Provider</th>
                  <th>Billing Provider</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.state.ClaimID}</td>
                  <td>{this.state.Patient}</td>
                  <td>{this.state.ClaimID}</td>
                  <td>{this.state.ClaimID}</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <br />

          <div>
            {" "}
            <h5 style={{ float: "left", paddingLeft: "2%", fontWeight: "600" }}>
              List Of Payment
            </h5>
          </div>
          <div>
            <Table
              className="table"
              style={{ marginLeft: "1%", width: "99%", marginTop: "1%" }}
            >
              <thead>
                <tr align="center">
                  <th>DateOfService</th>
                  <th>ProcedureCode</th>
                  <th>PrimaryDiagnosis</th>
                  <th>Unit</th>
                  <th>AmountCharges</th>
                  <th>BilledAmount</th>
                  <th>ServiceProvider</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.state.DateOfService}</td>
                  <td>{this.state.ClaimID}</td>
                  <td>{this.state.ClaimID}</td>
                  <td>{this.state.ClaimID}</td>
                  <td>{this.state.ClaimID}</td>
                  <td>{this.state.ClaimID}</td>
                  <td>{this.state.ClaimID}</td>
                  <td>
                    <button className="Resend">Resend</button>&nbsp;
                    <button
                      style={{ backgroundColor: "white", border: "none" }}
                    >
                      <FiTrash></FiTrash>
                    </button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          {this.state.modal && (
            <Modal
              style={{ width: "100%", height: "70%" }}
              isOpen={this.state.modal}
              onHide={this.toggle}
              onExit={this.reset}
              centered
              animation="false"
              size="lg"
            >
              <ModalHeader toggle={this.toggle}>Update Status</ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Row style={{ margin: "0px" }}>
                  <Col>
                    <Label>Payer Type</Label>
                    <Select
                      placeholder="Select Payer Type"
                      searchable={true}
                      dropdownHandle={true}
                      searchBy="code"
                      labelField="code"
                      noDataLabel="No matches found"
                      onChange={(values) => this.setState({})}
                    />
                  </Col>
                  &nbsp;
                  <Col>
                    <Label>Status</Label>
                    <Select
                      placeholder="Select Status"
                      searchable={true}
                      dropdownHandle={true}
                      searchBy="code"
                      labelField="code"
                      noDataLabel="No matches found"
                      onChange={(values) => this.setState({})}
                    />
                  </Col>
                  <Col>
                    <Label>Assign Claim To</Label>
                    <Input
                      type="text"
                      className="textbox"
                      required
                      onChange={(e) => this.setState({})}
                    />
                  </Col>
                  <Col>
                    <Label>Case Ticket No</Label>
                    <Input
                      type="text"
                      className="textbox"
                      required
                      onChange={(e) => this.setState({})}
                    />
                  </Col>
                </Row>
                <br />
                <Row style={{ margin: "0px" }}>
                  <Col>
                    <Label>Comments</Label>
                    <Input
                      type="text"
                      className="textbox"
                      required
                      onChange={(e) => this.setState({})}
                    />
                  </Col>

                  <Col>
                    <Label>Payer Reference No.,</Label>
                    <Input
                      type="text"
                      className="textbox"
                      required
                      onChange={(e) => this.setState({})}
                    />
                  </Col>

                  <Col>
                    <Label>Check No.,</Label>
                    <Input
                      type="text"
                      className="textbox"
                      required
                      onChange={(e) => this.setState({})}
                    />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggle();
                  }}
                >
                  Cancel
                </Button>
                <Button style={{ backgroundColor: "grey", color: "#fff" }}>
                  Update
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </div>
      </div>
    );
  }
}
export default ARfollowUpUpdate;

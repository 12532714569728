import React, { useState, useEffect, useRef } from "react";
import "antd/dist/antd.css";
import axios from "axios";
import { Row, Col } from "reactstrap";
import { Table } from "react-bootstrap";
import fileformat from "../../Files/Automation-Sample.xlsx";
import "../../Styles/data.css";
import "../../Styles/Data.scss";
import { FcCheckmark } from "react-icons/fc";
import { AiOutlineLogout } from "react-icons/ai";
import { IoIosRefresh } from "react-icons/io";
import { Button, Input } from "reactstrap";
import Newsidebar from "../Dashboard/Newsidebar";
import { MdOutlineFileUpload } from "react-icons/md";
import { AiOutlineDownload } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { GrDocumentText } from "react-icons/gr";
import Database from "../../Images/Database.svg";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { RiDeleteBin7Line } from "react-icons/ri";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Spinner } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";
import { ip } from ".././Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUpload } from '../Dashboard/UploadContext';
const Data = () => {
  const [deletemodal, setDeleteModal] = useState(false);
  const [file, setFile] = useState(null);
  const [type, setType] = useState("");
  const [upload, setUpload] = useState([]);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileupload, setFileUpload] = useState(false);
  const [id, setId] = useState("");
  const [refreshtoken, setRefreshToken] = useState(
    sessionStorage.getItem("refreshtoken")
  );
  const [accesstoken, setAccessToken] = useState(
    sessionStorage.getItem("accesstoken")
  );
  const [client, setClient] = useState(sessionStorage.getItem("clientid"));
  const [offset, setOffset] = useState(0);
  const [currentpage, setCurrentPage] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [perPage, setPerPage] = useState(5);
  const [filterdate, setFilterDate] = useState([]);
  const [search, setSearch] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [range, setRange] = useState([
    moment().format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD"),
  ]);

  const fileInput = useRef(null);
  const inputRef = useRef();
  const [selectedFile, setSelectedFile] = useState(null);
  const { setFileName, progress, setProgress , setNotInserted, setInserted, setTotalCount, setDuration, setStatus, status } = useUpload();
  const [uploadStatus, setUploadStatus] = useState("select");
  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    getFiles(); // Initial call
    const interval = setInterval(() => {
      getFiles();
    }, 120000); // 120000 ms = 2 minutes

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const newOffset = selectedPage * perPage;
    setCurrentPage(selectedPage);
    setOffset(newOffset);
    getFiles();
  };

  const toggleModal = () => {
    setFileUploaded(!fileUploaded);
  };

  //funtion to trigger input dialog
  const onChooseFile = () => {
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const clearFileInput = () => {
    inputRef.current.value = "";
    setSelectedFile(null);
    setUploadStatus("select");
    setProgress(0);
  };
  const handleUpload = async () => {
    if (uploadStatus === "done") {
      clearFileInput();
      return;
    }
    try {
      setUploadStatus("uploading");

      const fileSize = selectedFile.size;
      console.log(`File size: ${fileSize} bytes`);

      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("clientids", parseInt(client));
      const headers = {
        "Content-Type": `multipart/form-data`,
        Authorization: `Bearer ${accesstoken}`,
      };

      const response = await axios.post(`${ip}/upload/claim/bulk`, formData, {
        headers,
        // This is a upload progress bar when a real time communication is established then implement this logic it will work onUploadProgress: (progressEvent) => {
        //   const percentCompleted = Math.round(
        //     (progressEvent.loaded * 100) / progressEvent.total
        //   );
        //   setProgress(percentCompleted);
        //   console.log(`Upload progress: ${percentCompleted}%`);
        //   console.log(
        //     `Uploaded: ${progressEvent.loaded} of ${progressEvent.total} bytes`
        //   );
        // },
      });

      if (response.status === 200) {
        setUploadStatus("done");
        toast.success("Claims inserted successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      }
    } catch (err) {
      setUploadStatus("select");
      handleUploadError(err);
    }
  };
  const handleUploadError = (err) => {
    const toastOptions = {
      position: "top-right",
      autoClose: false,
      closeOnClick: true,
      pauseOnHover: true,
      dSampleraggable: true,
      closeButton: true,
    };

    if (err.response) {
      if (err.response.status === 500) {
        toast.error("Server Error", toastOptions);
      } else if (err.response.status === 401) {
        toast.error("Please reload and try again", toastOptions);
      } else if (err.response.status === 400) {
        toast.error(
          "Please include all fields in the Excel data",
          toastOptions
        );
      } else {
        toast.error("An unknown error occurred", toastOptions);
      }
    }
  };
  // Adjust and format dates
  const adjustAndFormatDate = (date) => {
    return moment(date)
      .subtract(5, "hours")
      .subtract(30, "minutes")
      .format("MM/DD/YYYY HH:mm:ss A");
  };
  const getFiles = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };
    try {
      const response = await axios.get(
        `${ip}/upload/getuploaddetails?clientId=${client}`,
        { headers }
      );
      const sortedData = response.data.data.sort(
        (a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt)
      );
      setUpload(sortedData);
    } catch (err) {
      console.error("Error fetching files:", err);
    }
  };
  const fetchUploadStatus = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };

    try {
      const response = await axios.get(
        `${ip}/upload/uploadCountApi?clientId=${client}`,
        { headers }
      );

      const data = response.data.data;
      setNotInserted(data.notinserted);
      setInserted(data.inserted);
      setTotalCount(data.totalcount);
      setDuration(data.duration);
      setStatus(data.status);  
      setFileName(data.filename);

      if (data.status === 'completed') {
        setProgress(100);
      } else {
        setProgress(Math.round((data.inserted / data.totalcount) * 100));
      }      
    } catch (err) {
      console.error("Error fetching files:", err);
    }
  };
  useEffect(() => {
    fetchUploadStatus();
    const interval = setInterval(fetchUploadStatus, 3000); // Refresh every 2 minutes

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);
  
  const toggleDeleteModal = () => {
    setDeleteModal(!deletemodal);
  };
  const deleteRequests = async (dataId) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };

    try {
      await axios.delete(`${ip}/upload/${dataId}`, { headers });
      toast.success("File deleted successfully", {
        position: "top-right",
        autoClose: 5000,
      });
      getFiles();
    } catch (err) {
      console.error("Error deleting file:", err);
    }
  };
  const downloadData = (objectId) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };

    let filename = `Not_Inserted_Claims`;
    let url = `${ip}/upload/getuploaddetails?clientId=${client}&button=0&objectid=${objectId}`;

    fetch(url, { headers })
      .then((response) => response.blob())
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((err) => {
        console.error("Error downloading data:", err);
      });
  };
  const downloadCombinedData = (objectId) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accesstoken}`,
    };

    let filename = `Combined_Report`;
    let url = `${ip}/report/combainedreportforuploads?clientId=${client}&button=0&id=${objectId}`;

    fetch(url, { headers })
      .then((response) => response.blob())
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((err) => {
        console.error("Error downloading data:", err);
      });
  };
  return (
    <div>
      <Newsidebar name="Data Upload" progress={progress} />
      <div className="align-row">
        <ToastContainer />
        <div className="align-row items">
          <div className="upload_container">
            <input
              ref={inputRef}
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            {!selectedFile && (
              <button className="file-btn" onClick={onChooseFile}>
                <span className="file-circle">
                  <MdOutlineFileUpload />
                </span>
                Click file to this area to upload <br />
                <span style={{ color: "GrayText", fontSize: "11px" }}>
                  The given input should be in .xlsx format
                </span>
              </button>
            )}
            {selectedFile && (
              <div className="file-card-upload">
                <div className="file-card">
                  <span>
                    <GrDocumentText
                      style={{ color: "#5d4dcc", fontSize: "30px" }}
                    />
                  </span>
                  <div className="file-info">
                    <div style={{ flex: 1 }}>
                      <h6>{selectedFile.name}</h6>
                    </div>
                    {uploadStatus === "select" ? (
                      <button onClick={clearFileInput}>
                        <span className="close-icon">
                          <IoMdClose />
                        </span>
                      </button>
                    ) : (
                      <div className="check-circle">
                        {uploadStatus === "uploading" ? (
                          `${progress}%`
                        ) : uploadStatus === "done" ? (
                          <span>
                            <FcCheckmark style={{ fontSize: "20px" }} />
                          </span>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
                <button className="upload_button" onClick={handleUpload}>
                  {uploadStatus === "select" || uploadStatus === "uploading"
                    ? "Upload"
                    : "Done"}
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="upload_container">
          <div className="upload_head">
            <div className="vertical" />
            <span style={{ fontWeight: "800", fontSize: "18px" }}>
              Bulk Upload History
            </span>
          </div>
          <div
            className="btn-group"
            role="group"
            style={{ boxShadow: "0px 0px 6px rgb(124 119 119 / 50%)" }}
          >
            <a
              style={{ textDecoration: "none", color: "white" }}
              href={fileformat}
              download="claimdata.xlsx"
              target="_blank"
            >
              <button type="button" className="btn btn-danger">
                <AiOutlineDownload style={{ fontSize: "15px" }} />
                &nbsp;&nbsp;Sample
              </button>
            </a>
          </div>
        </div>
        <hr style={{ margin: "1%" }} />
        <div className="table-wrapper">
          <table bordered="true">
            <thead>
              <tr className="sticky_upload">
                <th className="sticky_upload">File Name</th>
                <th className="sticky_upload">Created AT</th>
                <th className="sticky_upload">Processed Time</th>
                <th className="sticky_upload">Machine</th>
                <th className="sticky_upload">Request Type</th>
                <th className="sticky_upload">Success Record</th>
                <th className="sticky_upload">Failed Record</th>
                <th className="sticky_upload">Duplicate Record</th>
                <th className="sticky_upload">Queuing</th>
                <th className="sticky_upload">Progress</th>
                <th className="sticky_upload">Action</th>
              </tr>
            </thead>
            <tbody>
              {upload.map((data, index) => (
                <tr key={index}>
                  <td
                    style={{
                      color: "#28a745",
                      fontWeight: "500",
                    }}
                  >
                    {data.filename}
                  </td>
                  <td>{data.CreatedAt}</td>
                  <td>{data.processedTime}</td>
                  <td>
                    {data.channel ? (
                      <button
                        style={{
                          color: "#39739d",
                          backgroundColor: "#e1ecf4",
                          border: "1px solid #7aa7c7",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        {data.channel}
                      </button>
                    ) : (
                      <button
                        style={{
                          color: "#39739d",
                          backgroundColor: "#e1ecf4",
                          border: "1px solid #7aa7c7",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        {"-"}
                      </button>
                    )}
                  </td>
                  <td>
                    {data.requestType ? (
                      <button
                        className="request_btn"
                        style={{
                          color: "#7469B6",
                          backgroundColor: "#FFE6E6",
                          border: "1px solid #7469B6",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        {data.requestType}
                      </button>
                    ) : (
                      <button
                        className="request_btn"
                        style={{
                          color: "#7469B6",
                          backgroundColor: "#FFE6E6",
                          border: "1px solid #7469B6",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        {"-"}
                      </button>
                    )}
                  </td>
                  <td>
                    {data.successRecord ? (
                      <button
                        style={{
                          color: "#588247",
                          backgroundColor: "#e1fdd5",
                          border: "1px solid #72a45d",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        {data.successRecord}
                      </button>
                    ) : (
                      <button
                        style={{
                          color: "#588247",
                          backgroundColor: "#e1fdd5",
                          border: "1px solid #72a45d",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        0
                      </button>
                    )}
                  </td>
                  <td>
                    {data.failedRecord ? (
                      <button
                        style={{
                          color: "#00ABB3",
                          backgroundColor: "#EFFFFD",
                          border: "1px solid #7aa7c7",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        {data.failedRecord}
                      </button>
                    ) : (
                      <button
                        style={{
                          color: "#00ABB3",
                          backgroundColor: "#EFFFFD",
                          border: "1px solid #7aa7c7",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        0
                      </button>
                    )}
                  </td>
                  <td>
                    {data.duplicate ? (
                      <button
                        style={{
                          color: "#820000",
                          backgroundColor: "#FEE0C0",
                          border: "1px solid #FF7C7C",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        {data.duplicate}
                      </button>
                    ) : (
                      <button
                        style={{
                          color: "#820000",
                          backgroundColor: "#FEE0C0",
                          border: "1px solid #FF7C7C",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        0
                      </button>
                    )}
                  </td>
                  <td>
                    {data.queuing ? (
                      <button
                        style={{
                          color: "#7743DB",
                          backgroundColor: "#FFF6F6",
                          border: "1px solid #C3ACD0",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        {data.queuing}
                      </button>
                    ) : (
                      <button
                        style={{
                          color: "#7743DB",
                          backgroundColor: "#FFF6F6",
                          border: "1px solid #C3ACD0",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        0
                      </button>
                    )}
                  </td>
                  <td>
                    {data.ProcessedCount_TotalRecords ? (
                      <button
                        style={{
                          color: "#39739d",
                          backgroundColor: "#e1ecf4",
                          border: "1px solid #7aa7c7",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        {data.ProcessedCount_TotalRecords}
                      </button>
                    ) : (
                      <button
                        style={{
                          color: "#39739d",
                          backgroundColor: "#e1ecf4",
                          border: "1px solid #7aa7c7",
                          borderRadius: "3px",
                          padding: "6px 10px",
                        }}
                      >
                        0
                      </button>
                    )}
                  </td>
                  <td class="sticky_upload">
                    <div
                      className="btn-group"
                      role="group"
                      style={{
                        boxShadow: "0px 0px 6px rgb(124 119 119 / 50%)",
                      }}
                    >
                      <button
                        onClick={() => downloadData(data.id)}
                        className="btn btn-danger"
                        style={{ fontSize: "10px" }}
                      >
                        <AiOutlineDownload style={{ fontSize: "14px" }} />
                        &nbsp;&nbsp;Not Inserted
                      </button>
                      <button
                        onClick={() => downloadCombinedData(data.id)}
                        className="btn"
                        style={{
                          background: "#FFF1DB",
                          fontSize: "10px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <AiOutlineDownload style={{ fontSize: "14px" }} />
                        &nbsp;&nbsp;Combined Report
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Modal isOpen={fileUploaded} toggle={toggleModal} size="xl">
          <ModalHeader toggle={toggleModal}>File Upload</ModalHeader>
          <ModalBody>
            <p align="left">
              Please wait for sometime... Your file is being uploaded!
            </p>
            <button className="upload-btn">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              &nbsp;&nbsp;Uploading
            </button>
          </ModalBody>
          <ModalFooter>
            {disabled === true ? (
              <button disabled={disabled}>Close</button>
            ) : (
              <button
                className="button__load"
                disabled={disabled}
                onClick={toggleModal}
              >
                Close
              </button>
            )}
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default Data;

import React, { Component } from "react";
import Newsidebar from "./../Dashboard/Newsidebar";
import "./../../Styles/Trends.css";
import { Link } from "react-router-dom";
import { FiType, FiCodesandbox } from "react-icons/fi";
import { BiCodeCurly } from "react-icons/bi";
import { SiAcclaim } from "react-icons/si";
import { CgNotes } from "react-icons/cg";
import {
  MdOutlinePayments,
  MdSettingsSuggest,
  MdOutlineIntegrationInstructions,
  MdSettingsEthernet,
  MdOutlineCloudUpload,
  MdOutlinePaid,
  MdPersonOutline,
  MdOutlinePersonPin,
  MdOutlineAutoGraph,
  MdOutlineAddLocationAlt,
} from "react-icons/md";
import { GiHospitalCross } from "react-icons/gi";
import { VscReferences } from "react-icons/vsc";

class ConfigureList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: JSON.parse(sessionStorage.getItem("role_access")),
    };
  }

  render() {
    const { role } = this.state;

    // If role is not set or is null, return null to avoid rendering
    if (!role) {
      return null;
    }

    console.log("role config", role);

    const configItems = [
      { permission: 'configurePayerCategory', link: '/configure/payer-category', icon: SiAcclaim, label: 'Payer Category' },
      { permission: 'configurePayerMaster', link: '/configure/payer-master', icon: MdOutlinePayments, label: 'Payer Master' },
      { permission: 'configurePrimaryPayer', link: '/configure/primary-payer', icon: MdOutlineIntegrationInstructions, label: 'Primary Payer' },
      { permission: 'configureCpt', link: '/configure/cpt', icon: MdOutlinePaid, label: 'CPT' },
      { permission: 'configureModifiers', link: '/configure/modifiers', icon: GiHospitalCross, label: 'Modifiers' },
      { permission: 'configureAging', link: '/configure/ageing', icon: MdPersonOutline, label: 'Aging' },
      { permission: 'configureDenialMaster', link: '/configure/denial-master', icon: MdOutlinePersonPin, label: 'Denial Master' },
      { permission: 'configureBillingProvider', link: '/configure/billing-provider', icon: MdOutlinePersonPin, label: 'Billing Provider' },
      { permission: 'configureUserDetails', link: '/configure/user-details', icon: MdPersonOutline, label: 'User Details' },
      { permission: 'configureAdvancedBillingProvider', link: '/configure/advance-billing-provider', icon: MdPersonOutline, label: 'AdvBilling Provider' },
      { permission: 'configureClaimStatus', link: '/configure/claim-status', icon: MdOutlineAutoGraph, label: 'Claim Status' },
      { permission: 'configureClaimCategory', link: '/configure/claim-category', icon: FiType, label: 'Claim Category' },
      { permission: 'configureClaimSubCategory', link: '/configure/claim-subcategory', icon: SiAcclaim, label: 'Claim SubCategory' },
      { permission: 'configureActionCodes', link: '/configure/action-code', icon: MdSettingsEthernet, label: 'Action Codes' },
      { permission: 'configureActionStatusCodes', link: '/configure/status-action-code', icon: BiCodeCurly, label: 'Action StatusCodes' },
      { permission: 'configureClientNotes', link: '/configure/client-notes', icon: CgNotes, label: 'Client Notes' },
      { permission: 'configureLocation', link: '/configure/location', icon: MdOutlineAddLocationAlt, label: 'Location' },
      { permission: 'configureIcd', link: '/configure/icd', icon: FiCodesandbox, label: 'ICD' },
      { permission: 'configureReferralProvider', link: '/configure/refprovider', icon: VscReferences, label: 'Referral Provider' },
      { permission: 'configureGeneralSettings', link: '/configure/general-settings', icon: MdSettingsSuggest, label: 'General Settings' },
      { permission: 'configureProviderDetails', link: '/configure/self-upload', icon: MdOutlineCloudUpload, label: 'Provider Details' },
      { permission: 'configureNotesTypes', link: '/configure/note-types', icon: CgNotes, label: 'Note Types' }
    ];

    return (
      <div>
        <Newsidebar name="Configure" />
        <br />
        <div className="align-row" style={{ overflow: "hidden" }}>
          <div className="ag-format-container">
            <div className="ag-courses_box row">
              {configItems.map((item, index) => (
                role[item.permission] && (
                  <div className="col-md-2 mb-3" key={index}>
                    <div className="ag-courses_item">
                      <Link to={item.link} style={{ textDecoration: "none" }} className="ag-courses-item_link">
                        <div className="ag-icon-circle">
                          <item.icon style={{ color: "white", fontSize: "16px" }} /> {/* Increased font size */}
                        </div>
                        <div className="ag-courses-item_title">
                          {item.label.split(" ").map((line, i) => (
                            <span key={i}>{line}<br /></span>
                          ))}
                        </div>
                      </Link>
                    </div>
                  </div>
                )
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfigureList;

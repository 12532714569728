import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import axios from "axios";
import {ip} from "../Api";

const AccessRoute = ({ component: Component, role, ...rest }) => {
  const [rolenames, setRolenames] = useState([]);
  const [access, setAccess] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const getrole = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("accesstoken")}`, // Assuming accesstoken is stored in sessionStorage
    };
    try {
      const res = await axios.get(`${ip}/role/all`, { headers: headers });
      const fetchedRolenames = res.data.role.map((data) => data.role_name);
      sessionStorage.setItem("getRolenamesdata", JSON.stringify(fetchedRolenames));
      setRolenames(fetchedRolenames);
      setAccess(true);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getrole().then(() => setLoaded(true));
  }, []);

  if (!loaded) {
    return <div>Loading...</div>; // You can replace this with a loading spinner or some other component
  }

  const storedRole = sessionStorage.getItem("role_type");

  return (
    <Route
      {...rest}
      render={(props) =>
        rolenames.includes(storedRole)  && access ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default AccessRoute;

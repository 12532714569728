import React, { createContext, useContext,useEffect } from 'react';
import { PathContext } from './UserActivityProvider';
import {  useLocation } from 'react-router-dom'; // React Router hooks

const PageTracker = () => {
  const location = useLocation();
  const { setPath } = useContext(PathContext);

  useEffect(() => {
    setPath(location.pathname); // Update context with the current path
  }, [location.pathname]);

  return null; // This component does not render anything, only tracks the path
};

export default PageTracker;

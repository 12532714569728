import { React, Component } from "react";
import Newsidebar from "../../Dashboard/Newsidebar";
import "../../../Styles/subdenial.css";
 
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { MdKeyboardArrowRight } from "react-icons/md";
import axios from "axios";
import { ip } from "../../Api";
class SubCategory3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.location.state.current,
      pastvalue: this.props.location.state.past,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      subcategorywisedata: null,
      querystartdate: sessionStorage.getItem("querystartdate"),
      queryenddate: sessionStorage.getItem("queryenddate"),
      denialsub: sessionStorage.getItem("denialsub"),
      subcategorydenial: this.props.location.state.subcategorydenial,
      searchid: sessionStorage.getItem("searchid"),
      grandtotal1: "",
      idof1: "",
      idof2: "",
      idof3: "",
      idof4: "",
      idof5: "",
      idof6: "",
      idof7: "",
      idof8: "",
      idof9: "",
      idof10: "",
      idof11: "",
      idof12: "",
      idof13: "",
      idof14: "",
      idof15: "",
      idof16: "",
      idof17: "",
      grandtotal2: "",
      grandtotal3: "",
      grandtotal4: "",
      grandtotal5: "",
      grandtotal6: "",
      grandtotal7: "",
      grandtotal8: "",
      grandtotal9: "",
      grandtotal10: "",
      grandtotal11: "",
      grandtotal12: "",
      grandtotal13: "",
      grandtotal14: "",
      grandtotal15: "",
      grandtotal16: "",
      grandtotal17: "",
    };
  }
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  getdata = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.queryenddate && this.state.querystartdate) {
      url = `${ip}/denial/subcategory/value?${this.state.value}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
    } else {
      url = `${ip}/denial/subcategory/value?${this.state.value}`;
    }
     await axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        console.log("denial details", res);
        this.setState({
          subcategorywisedata: res.data.data,
          idof1: res.data.data[0]._id,
          idof2: res.data.data[1]._id,
          idof3: res.data.data[2]._id,
          idof4: res.data.data[3]._id,
          idof5: res.data.data[4]._id,
          idof6: res.data.data[5]._id,
          idof7: res.data.data[6]._id,
          idof8: res.data.data[7]._id,
          idof9: res.data.data[8]._id,
          idof10: res.data.data[9]._id,
          idof11: res.data.data[10]._id,
          idof12: res.data.data[11]._id,
          idof13: res.data.data[12]._id,
          idof14: res.data.data[13]._id,
          idof15: res.data.data[14]._id,
          idof16: res.data.data[15]._id,
          idof17: res.data.data[16]._id,

          grandtotal1: res.data.data[0].grandcount,
          grandtotal2: res.data.data[1].grandcount,
          grandtotal3: res.data.data[2].grandcount,
          grandtotal4: res.data.data[3].grandcount,
          grandtotal5: res.data.data[4].grandcount,
          grandtotal6: res.data.data[5].grandcount,
          grandtotal7: res.data.data[6].grandcount,
          grandtotal8: res.data.data[7].grandcount,
          grandtotal9: res.data.data[8].grandcount,
          grandtotal10: res.data.data[9].grandcount,
          grandtotal11: res.data.data[10].grandcount,
          grandtotal12: res.data.data[11].grandcount,
          grandtotal13: res.data.data[12].grandcount,
          grandtotal14: res.data.data[13].grandcount,
          grandtotal15: res.data.data[14].grandcount,
          grandtotal16: res.data.data[15].grandcount,
          grandtotal17: res.data.data[16].grandcount,

        });
        console.log(this.state.idof1, "uiwfuf");
      })
      .catch((err) => {});
  };
  componentDidMount() {
    
    this.getdata();
  }
  render() {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
        padding: "none",
        fontSize: 14,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));
    return (
      <div className="align-row" style={{overflow:"hidden"}}>
        <Newsidebar>
        <p>
        <Link
          to="/denial-management"
          style={{ textDecoration: "none", color: "white",fontSize: "15px",
          fontWeight: 500 }}
        >
          Denial Management
        </Link>
        &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
        <Link
          to="/denial/category"
          style={{ textDecoration: "none", color: "white",fontSize: "15px",
          fontWeight: 500 }}
        >
          Category
        </Link>
        &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;Sub
        Category
      </p>
        </Newsidebar>
        <div className="align-column">
          <br></br>

          <div
            style={{
              height: "100%",
              width: "100%",
              marginTop: "2%",
              marginLeft: "1%",
            }}
          >
            <TableContainer>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Calling/Web Portal</StyledTableCell>
                    <StyledTableCell>a)0-30 Days</StyledTableCell>
                    <StyledTableCell>b)31-60 Days</StyledTableCell>
                    <StyledTableCell>c)61-90 Days</StyledTableCell>
                    <StyledTableCell>d)91-120 Days</StyledTableCell>
                    <StyledTableCell>e)121-180 Days</StyledTableCell>
                    <StyledTableCell>f)181-365 Days</StyledTableCell>
                    <StyledTableCell>g)Above 1yr</StyledTableCell>
                    <StyledTableCell>Grand Total</StyledTableCell>
                  </TableRow>
                </TableHead>
                {(this.state.grandtotal1 ||
                  this.state.grandtotal2 ||
                  this.state.grandtotal3 ||
                  this.state.grandtotal4 ||
                  this.state.grandtotal5 ||
                  this.state.grandtotal6 ||
                  this.state.grandtotal7 ||
                  this.state.grandtotal8 ||
                  this.state.grandtotal9 ||
                  this.state.grandtotal10 ||
                  this.state.grandtotal11 ||
                  this.state.grandtotal12 ||
                  this.state.grandtotal13 ||
                  this.state.grandtotal14 ||
                  this.state.grandtotal15 ||
                  this.state.grandtotal16) === 0 ? (
                  <TableBody>
                    <div class="bot-ui">
                      <div class="bot-head">
                        <div class="eyes-container">
                          <div class="to-left eye"></div>
                          <div class="to-right eye"></div>
                        </div>
                      </div>
                      <div class="bot-body">
                        <div class="analyzer"></div>
                      </div>
                      <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                        No Data Found
                      </p>
                    </div>
                  </TableBody>
                ) : (
                  <TableBody>
                    {this.state.grandtotal1 === 0 ? (
                      " "
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell>
                          {this.state.subcategorywisedata &&
                            this.state.subcategorywisedata[0]._id}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof1 &&
                              this.state.denialsub === "0-30days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            //  style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[0]._id
                                }&agegrp=0-30days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            {" "}
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[0].subcategoryagewise[0].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        {/* <StyledTableCell>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={{
                          pathname: "/denial/denial-details",
                          state: {
                            current: `${this.state.value}&subcategory=${
                              this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[0]._id
                            }&agegrp=11-30days`,
                            past: `${this.state.value}`,
                            past1: `${this.state.pastvalue}`,
                          },
                        }}
                      >
                        {" "}
                        ${" "}
                        {this.state.subcategorywisedata &&
                          this.state.subcategorywisedata[0]
                            .subcategoryagewise[1].totalamount.toLocaleString()}
                      </Link>
                    </StyledTableCell> */}
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof1 &&
                              this.state.denialsub === "31-60days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[0]._id
                                }&agegrp=31-60days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[0].subcategoryagewise[1].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof1 &&
                              this.state.denialsub === "61-90days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[0]._id
                                }&agegrp=61-90days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[0].subcategoryagewise[2].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof1 &&
                              this.state.denialsub === "91-120days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[0]._id
                                }&agegrp=91-120days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[0].subcategoryagewise[3].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof1 &&
                              this.state.denialsub === "121-180days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[0]._id
                                }&agegrp=121-180days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[0].subcategoryagewise[4].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof1 &&
                              this.state.denialsub === "181-365days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[0]._id
                                }&agegrp=181-365days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[0].subcategoryagewise[5].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof1 &&
                              this.state.denialsub === "above 1year"
                                ? "e-card"
                                : "thirdcard"
                            }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[0]._id
                                }&agegrp=above 1year`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[0].subcategoryagewise[6].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[0]._id
                                }`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            <b>
                              ${" "}
                              {this.state.subcategorywisedata &&
                                this.state.subcategorywisedata[0].grandtotal.toLocaleString()}
                            </b>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    {this.state.grandtotal2 === 0 ? (
                      " "
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell>
                          {this.state.subcategorywisedata &&
                            this.state.subcategorywisedata[1]._id}
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof2 &&
                            this.state.denialsub === "0-30days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[1]._id
                                }&agegrp=0-30days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            {" "}
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[1].subcategoryagewise[0].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        {/* <StyledTableCell>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={{
                          pathname: "/denial/denial-details",
                          state: {
                            current: `${this.state.value}&subcategory=${
                              this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[1]._id
                            }&agegrp=11-30days`,
                            past: `${this.state.value}`,
                            past1: `${this.state.pastvalue}`,
                          },
                        }}
                      >
                        {" "}
                        ${" "}
                        {this.state.subcategorywisedata &&
                          this.state.subcategorywisedata[1]
                            .subcategoryagewise[1].totalamount.toLocaleString()}
                      </Link>
                    </StyledTableCell> */}
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof2 &&
                            this.state.denialsub === "31-60days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[1]._id
                                }&agegrp=31-60days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[1].subcategoryagewise[1].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial === this.state.idof2 &&
                            this.state.denialsub === "61-90days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[1]._id
                                }&agegrp=61-90days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[1].subcategoryagewise[2].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof2 &&
                            this.state.denialsub === "91-120days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[1]._id
                                }&agegrp=91-120days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[1].subcategoryagewise[3].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof2 &&
                            this.state.denialsub === "121-180days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[1]._id
                                }&agegrp=121-180days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[1].subcategoryagewise[4].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof2 &&
                            this.state.denialsub === "181-365days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[1]._id
                                }&agegrp=181-365days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[1].subcategoryagewise[5].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial === this.state.idof2 &&
                            this.state.denialsub === "above 1year"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[1]._id
                                }&agegrp=above 1year`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[1].subcategoryagewise[6].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[1]._id
                                }`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            <b>
                              ${" "}
                              {this.state.subcategorywisedata &&
                                this.state.subcategorywisedata[1].grandtotal.toLocaleString()}
                            </b>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    {this.state.grandtotal3 === 0 ? (
                      " "
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell>
                          {this.state.subcategorywisedata &&
                            this.state.subcategorywisedata[2]._id}
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial === this.state.idof3 &&
                            this.state.denialsub === "0-30days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[2]._id
                                }&agegrp=0-30days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            {" "}
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[2].subcategoryagewise[0].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        {/* <StyledTableCell>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={{
                          pathname: "/denial/denial-details",
                          state: {
                            current: `${this.state.value}&subcategory=${
                              this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[2]._id
                            }&agegrp=11-30days`,
                            past: `${this.state.value}`,
                            past1: `${this.state.pastvalue}`,
                          },
                        }}
                      >
                        {" "}
                        ${" "}
                        {this.state.subcategorywisedata &&
                          this.state.subcategorywisedata[2]
                            .subcategoryagewise[1].totalamount.toLocaleString()}
                      </Link>
                    </StyledTableCell> */}
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof3 &&
                            this.state.denialsub === "31-60days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[2]._id
                                }&agegrp=31-60days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[2].subcategoryagewise[1].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial === this.state.idof3 &&
                            this.state.denialsub === "61-90days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[2]._id
                                }&agegrp=61-90days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[2].subcategoryagewise[2].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial === this.state.idof3 &&
                            this.state.denialsub === "91-120days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[2]._id
                                }&agegrp=91-120days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[2].subcategoryagewise[3].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link className={
                            this.state.subcategorydenial === this.state.idof3 &&
                            this.state.denialsub === "121-180days"
                              ? "e-card"
                              : "thirdcard"
                          }

                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[2]._id
                                }&agegrp=121-180days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[2].subcategoryagewise[4].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof3 &&
                            this.state.denialsub === "181-365days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[2]._id
                                }&agegrp=181-365days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[2].subcategoryagewise[5].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link className={
                            this.state.subcategorydenial === this.state.idof3 &&
                            this.state.denialsub === "above 1year"
                              ? "e-card"
                              : "thirdcard"
                          }

                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[2]._id
                                }&agegrp=above 1year`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[2].subcategoryagewise[6].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[2]._id
                                }`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            <b>
                              ${" "}
                              {this.state.subcategorywisedata &&
                                this.state.subcategorywisedata[2].grandtotal.toLocaleString()}
                            </b>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    {this.state.grandtotal4 === 0 ? (
                      " "
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell>
                          {this.state.subcategorywisedata &&
                            this.state.subcategorywisedata[3]._id}
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial === this.state.idof4 &&
                            this.state.denialsub === "0-30days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[3]._id
                                }&agegrp=0-30days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            {" "}
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[3].subcategoryagewise[0].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        {/* <StyledTableCell>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={{
                          pathname: "/denial/denial-details",
                          state: {
                            current: `${this.state.value}&subcategory=${
                              this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[3]._id
                            }&agegrp=11-30days`,
                            past: `${this.state.value}`,
                            past1: `${this.state.pastvalue}`,
                          },
                        }}
                      >
                        {" "}
                        ${" "}
                        {this.state.subcategorywisedata &&
                          this.state.subcategorywisedata[3]
                            .subcategoryagewise[1].totalamount.toLocaleString()}
                      </Link>
                    </StyledTableCell> */}
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof4 &&
                            this.state.denialsub === "31-60days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[3]._id
                                }&agegrp=31-60days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[3].subcategoryagewise[1].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                        
                        >
                          <Link
                            className={
                              this.state.subcategorydenial === this.state.idof4 &&
                              this.state.denialsub === "61-90days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[3]._id
                                }&agegrp=61-90days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[3].subcategoryagewise[2].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof4 &&
                            this.state.denialsub === "91-120days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[3]._id
                                }&agegrp=91-120days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[3].subcategoryagewise[3].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof4 &&
                            this.state.denialsub === "121-180days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[3]._id
                                }&agegrp=121-180days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[3].subcategoryagewise[4].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof4 &&
                            this.state.denialsub === "181-365days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[3]._id
                                }&agegrp=181-365days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[3].subcategoryagewise[5].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof4 &&
                            this.state.denialsub === "above 1year"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[3]._id
                                }&agegrp=above 1year`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[3].subcategoryagewise[6].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[3]._id
                                }`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            <b>
                              ${" "}
                              {this.state.subcategorywisedata &&
                                this.state.subcategorywisedata[3].grandtotal.toLocaleString()}
                            </b>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    {this.state.grandtotal5 === 0 ? (
                      " "
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell>
                          {this.state.subcategorywisedata &&
                            this.state.subcategorywisedata[4]._id}
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof5 &&
                            this.state.denialsub === "0-30days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[4]._id
                                }&agegrp=0-30days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            {" "}
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[4].subcategoryagewise[0].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        {/* <StyledTableCell>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={{
                          pathname: "/denial/denial-details",
                          state: {
                            current: `${this.state.value}&subcategory=${
                              this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[4]._id
                            }&agegrp=11-30days`,
                            past: `${this.state.value}`,
                            past1: `${this.state.pastvalue}`,
                          },
                        }}
                      >
                        {" "}
                        ${" "}
                        {this.state.subcategorywisedata &&
                          this.state.subcategorywisedata[4]
                            .subcategoryagewise[1].totalamount.toLocaleString()}
                      </Link>
                    </StyledTableCell> */}
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial === this.state.idof5 &&
                            this.state.denialsub === "31-60days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[4]._id
                                }&agegrp=31-60days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[4].subcategoryagewise[1].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof5 &&
                            this.state.denialsub === "61-90days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[4]._id
                                }&agegrp=61-90days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[4].subcategoryagewise[2].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial === this.state.idof5 &&
                            this.state.denialsub === "91-120days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[4]._id
                                }&agegrp=91-120days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[4].subcategoryagewise[3].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof5 &&
                            this.state.denialsub === "121-180days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[4]._id
                                }&agegrp=121-180days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[4].subcategoryagewise[4].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                        
                        >
                          <Link
                            className={
                              this.state.subcategorydenial === this.state.idof5 &&
                              this.state.denialsub === "181-365days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[4]._id
                                }&agegrp=181-365days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[4].subcategoryagewise[5].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof5 &&
                            this.state.denialsub === "above 1year"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[4]._id
                                }&agegrp=above 1year`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[4].subcategoryagewise[6].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[4]._id
                                }`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            <b>
                              ${" "}
                              {this.state.subcategorywisedata &&
                                this.state.subcategorywisedata[4].grandtotal.toLocaleString()}
                            </b>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    {this.state.grandtotal6 === 0 ? (
                      " "
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell>
                          {this.state.subcategorywisedata &&
                            this.state.subcategorywisedata[5]._id}
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial === this.state.idof6 &&
                            this.state.denialsub === "0-30days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[5]._id
                                }&agegrp=0-30days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            {" "}
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[5].subcategoryagewise[0].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        {/* <StyledTableCell>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={{
                          pathname: "/denial/denial-details",
                          state: {
                            current: `${this.state.value}&subcategory=${
                              this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[5]._id
                            }&agegrp=11-30days`,
                            past: `${this.state.value}`,
                            past1: `${this.state.pastvalue}`,
                          },
                        }}
                      >
                        {" "}
                        ${" "}
                        {this.state.subcategorywisedata &&
                          this.state.subcategorywisedata[5]
                            .subcategoryagewise[1].totalamount.toLocaleString()}
                      </Link>
                    </StyledTableCell> */}
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof6 &&
                            this.state.denialsub === "31-60days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[5]._id
                                }&agegrp=31-60days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[5].subcategoryagewise[1].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof6 &&
                            this.state.denialsub === "61-90days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[5]._id
                                }&agegrp=61-90days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[5].subcategoryagewise[2].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial === this.state.idof6 &&
                            this.state.denialsub === "91-120days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[5]._id
                                }&agegrp=91-120days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[5].subcategoryagewise[3].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial === this.state.idof6 &&
                            this.state.denialsub === "121-180days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[5]._id
                                }&agegrp=121-180days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[5].subcategoryagewise[4].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof6 &&
                            this.state.denialsub === "181-365days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[5]._id
                                }&agegrp=181-365days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[5].subcategoryagewise[5].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof6 &&
                            this.state.denialsub === "above 1year"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[5]._id
                                }&agegrp=above 1year`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[5].subcategoryagewise[6].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[5]._id
                                }`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            <b>
                              ${" "}
                              {this.state.subcategorywisedata &&
                                this.state.subcategorywisedata[5].grandtotal.toLocaleString()}
                            </b>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    {this.state.grandtotal7 === 0 ? (
                      " "
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell>
                          {this.state.subcategorywisedata &&
                            this.state.subcategorywisedata[6]._id}
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial === this.state.idof7 &&
                            this.state.denialsub === "0-30days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[6]._id
                                }&agegrp=0-30days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            {" "}
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[6].subcategoryagewise[0].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        {/* <StyledTableCell>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={{
                          pathname: "/denial/denial-details",
                          state: {
                            current: `${this.state.value}&subcategory=${
                              this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[6]._id
                            }&agegrp=11-30days`,
                            past: `${this.state.value}`,
                            past1: `${this.state.pastvalue}`,
                          },
                        }}
                      >
                        {" "}
                        ${" "}
                        {this.state.subcategorywisedata &&
                          this.state.subcategorywisedata[6]
                            .subcategoryagewise[1].totalamount.toLocaleString()}
                      </Link>
                    </StyledTableCell> */}
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof7 &&
                            this.state.denialsub === "31-60days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[6]._id
                                }&agegrp=31-60days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[6].subcategoryagewise[1].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof7 &&
                            this.state.denialsub === "61-90days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[6]._id
                                }&agegrp=61-90days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[6].subcategoryagewise[2].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial === this.state.idof7 &&
                            this.state.denialsub === "91-120days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[6]._id
                                }&agegrp=91-120days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[6].subcategoryagewise[3].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof7 &&
                            this.state.denialsub === "121-180days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[6]._id
                                }&agegrp=121-180days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[6].subcategoryagewise[4].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial === this.state.idof7 &&
                            this.state.denialsub === "181-365days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[6]._id
                                }&agegrp=181-365days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[6].subcategoryagewise[5].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof7 &&
                            this.state.denialsub === "above 1year"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[6]._id
                                }&agegrp=above 1year`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[6].subcategoryagewise[6].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[6]._id
                                }`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            <b>
                              ${" "}
                              {this.state.subcategorywisedata &&
                                this.state.subcategorywisedata[6].grandtotal.toLocaleString()}
                            </b>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    {this.state.grandtotal8 === 0 ? (
                      " "
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell>
                          {this.state.subcategorywisedata &&
                            this.state.subcategorywisedata[7]._id}
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial === this.state.idof8 &&
                            this.state.denialsub === "0-30days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[7]._id
                                }&agegrp=0-30days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            {" "}
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[7].subcategoryagewise[0].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        {/* <StyledTableCell>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={{
                          pathname: "/denial/denial-details",
                          state: {
                            current: `${this.state.value}&subcategory=${
                              this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[7]._id
                            }&agegrp=11-30days`,
                            past: `${this.state.value}`,
                            past1: `${this.state.pastvalue}`,
                          },
                        }}
                      >
                        {" "}
                        ${" "}
                        {this.state.subcategorywisedata &&
                          this.state.subcategorywisedata[7]
                            .subcategoryagewise[1].totalamount.toLocaleString()}
                      </Link>
                    </StyledTableCell> */}
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial === this.state.idof8 &&
                            this.state.denialsub === "31-60days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[7]._id
                                }&agegrp=31-60days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[7].subcategoryagewise[1].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial === this.state.idof8 &&
                            this.state.denialsub === "61-90days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[7]._id
                                }&agegrp=61-90days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[7].subcategoryagewise[2].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial === this.state.idof8 &&
                            this.state.denialsub === "91-120days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[7]._id
                                }&agegrp=91-120days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[7].subcategoryagewise[3].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link className={
                            this.state.subcategorydenial === this.state.idof8 &&
                            this.state.denialsub === "121-180days"
                              ? "e-card"
                              : "thirdcard"
                          }

                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[7]._id
                                }&agegrp=121-180days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[7].subcategoryagewise[4].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial === this.state.idof8 &&
                            this.state.denialsub === "181-365days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[7]._id
                                }&agegrp=181-365days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[7].subcategoryagewise[5].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof8 &&
                            this.state.denialsub === "above 1year"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[7]._id
                                }&agegrp=above 1year`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[7].subcategoryagewise[6].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[7]._id
                                }`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            <b>
                              ${" "}
                              {this.state.subcategorywisedata &&
                                this.state.subcategorywisedata[7].grandtotal.toLocaleString()}
                            </b>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    {this.state.grandtotal9 === 0 ? (
                      " "
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell>
                          {this.state.subcategorywisedata &&
                            this.state.subcategorywisedata[8]._id}
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof9 &&
                            this.state.denialsub === "0-30days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[8]._id
                                }&agegrp=0-30days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            {" "}
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[8].subcategoryagewise[0].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        {/* <StyledTableCell>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={{
                          pathname: "/denial/denial-details",
                          state: {
                            current: `${this.state.value}&subcategory=${
                              this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[8]._id
                            }&agegrp=11-30days`,
                            past: `${this.state.value}`,
                            past1: `${this.state.pastvalue}`,
                          },
                        }}
                      >
                        {" "}
                        ${" "}
                        {this.state.subcategorywisedata &&
                          this.state.subcategorywisedata[8]
                            .subcategoryagewise[1].totalamount.toLocaleString()}
                      </Link>
                    </StyledTableCell> */}
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof9 &&
                            this.state.denialsub === "31-60days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[8]._id
                                }&agegrp=31-60days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[8].subcategoryagewise[1].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof9 &&
                            this.state.denialsub === "61-90days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[8]._id
                                }&agegrp=61-90days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[8].subcategoryagewise[2].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial === this.state.idof9 &&
                            this.state.denialsub === "91-120days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[8]._id
                                }&agegrp=91-120days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[8].subcategoryagewise[3].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof9 &&
                            this.state.denialsub === "121-180days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[8]._id
                                }&agegrp=121-180days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[8].subcategoryagewise[4].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial === this.state.idof9 &&
                            this.state.denialsub === "181-365days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[8]._id
                                }&agegrp=181-365days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[8].subcategoryagewise[5].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial === this.state.idof9 &&
                            this.state.denialsub === "above 1year"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[8]._id
                                }&agegrp=above 1year`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[8].subcategoryagewise[6].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[8]._id
                                }`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            <b>
                              ${" "}
                              {this.state.subcategorywisedata &&
                                this.state.subcategorywisedata[8].grandtotal.toLocaleString()}
                            </b>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    {this.state.grandtotal10 === 0 ? (
                      " "
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell>
                          {this.state.subcategorywisedata &&
                            this.state.subcategorywisedata[9]._id}
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial ===
                              this.state.idof10 &&
                            this.state.denialsub === "0-30days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[9]._id
                                }&agegrp=0-30days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            {" "}
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[9].subcategoryagewise[0].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        {/* <StyledTableCell>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={{
                          pathname: "/denial/denial-details",
                          state: {
                            current: `${this.state.value}&subcategory=${
                              this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[9]._id
                            }&agegrp=11-30days`,
                            past: `${this.state.value}`,
                            past1: `${this.state.pastvalue}`,
                          },
                        }}
                      >
                        {" "}
                        ${" "}
                        {this.state.subcategorywisedata &&
                          this.state.subcategorywisedata[9]
                            .subcategoryagewise[1].totalamount.toLocaleString()}
                      </Link>
                    </StyledTableCell> */}
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial ===
                              this.state.idof10 &&
                            this.state.denialsub === "31-60days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[9]._id
                                }&agegrp=31-60days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[9].subcategoryagewise[1].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof10 &&
                            this.state.denialsub === "61-90days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[9]._id
                                }&agegrp=61-90days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[9].subcategoryagewise[2].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof10 &&
                            this.state.denialsub === "91-120days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[9]._id
                                }&agegrp=91-120days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[9].subcategoryagewise[3].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof10 &&
                            this.state.denialsub === "121-180days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[9]._id
                                }&agegrp=121-180days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[9].subcategoryagewise[4].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial ===
                              this.state.idof10 &&
                            this.state.denialsub === "181-365days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[9]._id
                                }&agegrp=181-365days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[9].subcategoryagewise[5].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof10 &&
                            this.state.denialsub === "above 1year"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[9]._id
                                }&agegrp=above 1year`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[9].subcategoryagewise[6].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[9]._id
                                }`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            <b>
                              ${" "}
                              {this.state.subcategorywisedata &&
                                this.state.subcategorywisedata[9].grandtotal.toLocaleString()}
                            </b>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    {this.state.grandtotal11 === 0 ? (
                      " "
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell>
                          {this.state.subcategorywisedata &&
                            this.state.subcategorywisedata[10]._id}
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof11 &&
                            this.state.denialsub === "0-30days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[10]._id
                                }&agegrp=0-30days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            {" "}
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[10].subcategoryagewise[0].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        {/* <StyledTableCell>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={{
                          pathname: "/denial/denial-details",
                          state: {
                            current: `${this.state.value}&subcategory=${
                              this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[10]._id
                            }&agegrp=11-30days`,
                            past: `${this.state.value}`,
                            past1: `${this.state.pastvalue}`,
                          },
                        }}
                      >
                        {" "}
                        ${" "}
                        {this.state.subcategorywisedata &&
                          this.state.subcategorywisedata[10]
                            .subcategoryagewise[1].totalamount.toLocaleString()}
                      </Link>
                    </StyledTableCell> */}
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof11 &&
                            this.state.denialsub === "31-60days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[10]._id
                                }&agegrp=31-60days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[10].subcategoryagewise[1].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof11 &&
                            this.state.denialsub === "61-90days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[10]._id
                                }&agegrp=61-90days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[10].subcategoryagewise[2].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial ===
                              this.state.idof11 &&
                            this.state.denialsub === "91-120days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[10]._id
                                }&agegrp=91-120days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[10].subcategoryagewise[3].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof11 &&
                            this.state.denialsub === "121-180days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[10]._id
                                }&agegrp=121-180days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[10].subcategoryagewise[4].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof11 &&
                            this.state.denialsub === "181-365days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[10]._id
                                }&agegrp=181-365days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[10].subcategoryagewise[5].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial ===
                              this.state.idof11 &&
                            this.state.denialsub === "above 1year"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[10]._id
                                }&agegrp=above 1year`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[10].subcategoryagewise[6].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[10]._id
                                }`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            <b>
                              ${" "}
                              {this.state.subcategorywisedata &&
                                this.state.subcategorywisedata[10].grandtotal.toLocaleString()}
                            </b>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    {this.state.grandtotal12 === 0 ? (
                      " "
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell>
                          {this.state.subcategorywisedata &&
                            this.state.subcategorywisedata[11]._id}
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial ===
                              this.state.idof12 &&
                            this.state.denialsub === "0-30days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[11]._id
                                }&agegrp=0-30days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            {" "}
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[11].subcategoryagewise[0].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        {/* <StyledTableCell>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={{
                          pathname: "/denial/denial-details",
                          state: {
                            current: `${this.state.value}&subcategory=${
                              this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[11]._id
                            }&agegrp=11-30days`,
                            past: `${this.state.value}`,
                            past1: `${this.state.pastvalue}`,
                          },
                        }}
                      >
                        {" "}
                        ${" "}
                        {this.state.subcategorywisedata &&
                          this.state.subcategorywisedata[11]
                            .subcategoryagewise[1].totalamount.toLocaleString()}
                      </Link>
                    </StyledTableCell> */}
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof12 &&
                            this.state.denialsub === "31-60days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[11]._id
                                }&agegrp=31-60days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[11].subcategoryagewise[1].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link className={
                            this.state.subcategorydenial ===
                              this.state.idof12 &&
                            this.state.denialsub === "61-90days"
                              ? "e-card"
                              : "thirdcard"
                          }

                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[11]._id
                                }&agegrp=61-90days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[11].subcategoryagewise[2].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                        
                        >
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof12 &&
                              this.state.denialsub === "91-120days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[11]._id
                                }&agegrp=91-120days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[11].subcategoryagewise[3].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial ===
                              this.state.idof12 &&
                            this.state.denialsub === "121-180days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[11]._id
                                }&agegrp=121-180days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[11].subcategoryagewise[4].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial ===
                              this.state.idof12 &&
                            this.state.denialsub === "181-365days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[11]._id
                                }&agegrp=181-365days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[11].subcategoryagewise[5].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                        
                        >
                          <Link  className={
                            this.state.subcategorydenial ===
                              this.state.idof12 &&
                            this.state.denialsub === "above 1year"
                              ? "e-card"
                              : "thirdcard"
                          }

                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[11]._id
                                }&agegrp=above 1year`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[11].subcategoryagewise[6].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[11]._id
                                }`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            <b>
                              ${" "}
                              {this.state.subcategorywisedata &&
                                this.state.subcategorywisedata[11].grandtotal.toLocaleString()}
                            </b>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    {this.state.grandtotal13 === 0 ? (
                      " "
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell>
                          {this.state.subcategorywisedata &&
                            this.state.subcategorywisedata[12]._id}
                        </StyledTableCell>
                        <StyledTableCell
                        
                        >
                          <Link
                            
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof13 &&
                              this.state.denialsub === "0-30days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[12]._id
                                }&agegrp=0-30days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            {" "}
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[12].subcategoryagewise[0].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        {/* <StyledTableCell>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={{
                          pathname: "/denial/denial-details",
                          state: {
                            current: `${this.state.value}&subcategory=${
                              this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[12]._id
                            }&agegrp=11-30days`,
                            past: `${this.state.value}`,
                            past1: `${this.state.pastvalue}`,
                          },
                        }}
                      >
                        {" "}
                        ${" "}
                        {this.state.subcategorywisedata &&
                          this.state.subcategorywisedata[12]
                            .subcategoryagewise[1].totalamount.toLocaleString()}
                      </Link>
                    </StyledTableCell> */}
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof13 &&
                            this.state.denialsub === "31-60days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[12]._id
                                }&agegrp=31-60days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[12].subcategoryagewise[1].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial ===
                              this.state.idof13 &&
                            this.state.denialsub === "61-90days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[12]._id
                                }&agegrp=61-90days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[12].subcategoryagewise[2].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof13 &&
                            this.state.denialsub === "91-120days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[12]._id
                                }&agegrp=91-120days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[12].subcategoryagewise[3].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof13 &&
                            this.state.denialsub === "121-180days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[12]._id
                                }&agegrp=121-180days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[12].subcategoryagewise[4].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof13 &&
                            this.state.denialsub === "181-365days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[12]._id
                                }&agegrp=181-365days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[12].subcategoryagewise[5].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof13 &&
                            this.state.denialsub === "above 1year"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[12]._id
                                }&agegrp=above 1year`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[12].subcategoryagewise[6].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[12]._id
                                }`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            <b>
                              ${" "}
                              {this.state.subcategorywisedata &&
                                this.state.subcategorywisedata[12].grandtotal.toLocaleString()}
                            </b>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    {this.state.grandtotal14 === 0 ? (
                      " "
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell>
                          {this.state.subcategorywisedata &&
                            this.state.subcategorywisedata[13]._id}
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof14 &&
                            this.state.denialsub === "0-30days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[13]._id
                                }&agegrp=0-30days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            {" "}
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[13].subcategoryagewise[0].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        {/* <StyledTableCell>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={{
                          pathname: "/denial/denial-details",
                          state: {
                            current: `${this.state.value}&subcategory=${
                              this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[13]._id
                            }&agegrp=11-30days`,
                            past: `${this.state.value}`,
                            past1: `${this.state.pastvalue}`,
                          },
                        }}
                      >
                        {" "}
                        ${" "}
                        {this.state.subcategorywisedata &&
                          this.state.subcategorywisedata[13]
                            .subcategoryagewise[1].totalamount.toLocaleString()}
                      </Link>
                    </StyledTableCell> */}
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof14 &&
                            this.state.denialsub === "31-60days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[13]._id
                                }&agegrp=31-60days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[13].subcategoryagewise[1].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof14 &&
                            this.state.denialsub === "61-90days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[13]._id
                                }&agegrp=61-90days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[13].subcategoryagewise[2].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial ===
                              this.state.idof14 &&
                            this.state.denialsub === "91-120days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[13]._id
                                }&agegrp=91-120days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[13].subcategoryagewise[3].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial ===
                              this.state.idof14 &&
                            this.state.denialsub === "121-180days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[13]._id
                                }&agegrp=121-180days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[13].subcategoryagewise[4].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof14 &&
                            this.state.denialsub === "181-365days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[13]._id
                                }&agegrp=181-365days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[13].subcategoryagewise[5].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial ===
                              this.state.idof14 &&
                            this.state.denialsub === "above 1year"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[13]._id
                                }&agegrp=above 1year`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[13].subcategoryagewise[6].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[13]._id
                                }`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            <b>
                              ${" "}
                              {this.state.subcategorywisedata &&
                                this.state.subcategorywisedata[13].grandtotal.toLocaleString()}
                            </b>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    {this.state.grandtotal15 === 0 ? (
                      " "
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell>
                          {this.state.subcategorywisedata &&
                            this.state.subcategorywisedata[14]._id}
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial ===
                              this.state.idof15 &&
                            this.state.denialsub === "0-30days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[14]._id
                                }&agegrp=0-30days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            {" "}
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[14].subcategoryagewise[0].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        {/* <StyledTableCell>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={{
                          pathname: "/denial/denial-details",
                          state: {
                            current: `${this.state.value}&subcategory=${
                              this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[14]._id
                            }&agegrp=11-30days`,
                            past: `${this.state.value}`,
                            past1: `${this.state.pastvalue}`,
                          },
                        }}
                      >
                        {" "}
                        ${" "}
                        {this.state.subcategorywisedata &&
                          this.state.subcategorywisedata[14]
                            .subcategoryagewise[1].totalamount.toLocaleString()}
                      </Link>
                    </StyledTableCell> */}
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof15 &&
                            this.state.denialsub === "31-60days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[14]._id
                                }&agegrp=31-60days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[14].subcategoryagewise[1].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof15 &&
                            this.state.denialsub === "61-90days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[14]._id
                                }&agegrp=61-90days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[14].subcategoryagewise[2].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial ===
                              this.state.idof15 &&
                            this.state.denialsub === "91-120days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[14]._id
                                }&agegrp=91-120days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[14].subcategoryagewise[3].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof15 &&
                            this.state.denialsub === "121-180days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[14]._id
                                }&agegrp=121-180days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[14].subcategoryagewise[4].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial ===
                              this.state.idof15 &&
                            this.state.denialsub === "181-365days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[14]._id
                                }&agegrp=181-365days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[14].subcategoryagewise[5].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof15 &&
                            this.state.denialsub === "above 1year"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[14]._id
                                }&agegrp=above 1year`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[14].subcategoryagewise[6].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[14]._id
                                }`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            <b>
                              ${" "}
                              {this.state.subcategorywisedata &&
                                this.state.subcategorywisedata[14].grandtotal.toLocaleString()}
                            </b>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    {this.state.grandtotal16 === 0 ? (
                      " "
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell>
                          {this.state.subcategorywisedata &&
                            this.state.subcategorywisedata[15]._id}
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof16 &&
                            this.state.denialsub === "0-30days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[15]._id
                                }&agegrp=0-30days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            {" "}
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[15].subcategoryagewise[0].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        {/* <StyledTableCell>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={{
                          pathname: "/denial/denial-details",
                          state: {
                            current: `${this.state.value}&subcategory=${
                              this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[15]._id
                            }&agegrp=11-30days`,
                            past: `${this.state.value}`,
                            past1: `${this.state.pastvalue}`,
                          },
                        }}
                      >
                        {" "}
                        ${" "}
                        {this.state.subcategorywisedata &&
                          this.state.subcategorywisedata[15]
                            .subcategoryagewise[1].totalamount.toLocaleString()}
                      </Link>
                    </StyledTableCell> */}
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof16 &&
                            this.state.denialsub === "31-60days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[15]._id
                                }&agegrp=31-60days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[15].subcategoryagewise[1].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                        
                        >
                          <Link
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof16 &&
                              this.state.denialsub === "61-90days"
                                ? "e-card"
                                : "thirdcard"
                            }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[15]._id
                                }&agegrp=61-90days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[15].subcategoryagewise[2].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial ===
                              this.state.idof16 &&
                            this.state.denialsub === "91-120days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[15]._id
                                }&agegrp=91-120days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[15].subcategoryagewise[3].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof16 &&
                            this.state.denialsub === "121-180days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[15]._id
                                }&agegrp=121-180days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[15].subcategoryagewise[4].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof16 &&
                            this.state.denialsub === "181-365days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[15]._id
                                }&agegrp=181-365days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[15].subcategoryagewise[5].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                            
                            className={
                              this.state.subcategorydenial ===
                                this.state.idof16 &&
                              this.state.denialsub === "above 1year"
                                ? "e-card"
                                : "thirdcard"
                            }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[15]._id
                                }&agegrp=above 1year`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[15].subcategoryagewise[6].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[15]._id
                                }`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            <b>
                              ${" "}
                              {this.state.subcategorywisedata &&
                                this.state.subcategorywisedata[15].grandtotal.toLocaleString()}
                            </b>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    {this.state.grandtotal17 === 0 ? (
                      " "
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell>
                          {this.state.subcategorywisedata &&
                            this.state.subcategorywisedata[16]._id}
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial ===
                              this.state.idof17 &&
                            this.state.denialsub === "0-30days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[16]._id
                                }&agegrp=0-30days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            {" "}
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[16].subcategoryagewise[0].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        {/* <StyledTableCell>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={{
                          pathname: "/denial/denial-details",
                          state: {
                            current: `${this.state.value}&subcategory=${
                              this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[15]._id
                            }&agegrp=11-30days`,
                            past: `${this.state.value}`,
                            past1: `${this.state.pastvalue}`,
                          },
                        }}
                      >
                        {" "}
                        ${" "}
                        {this.state.subcategorywisedata &&
                          this.state.subcategorywisedata[15]
                            .subcategoryagewise[1].totalamount.toLocaleString()}
                      </Link>
                    </StyledTableCell> */}
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof17 &&
                            this.state.denialsub === "31-60days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[16]._id
                                }&agegrp=31-60days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[16].subcategoryagewise[1].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial ===
                              this.state.idof17 &&
                            this.state.denialsub === "61-90days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[16]._id
                                }&agegrp=61-90days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[16].subcategoryagewise[2].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial ===
                              this.state.idof17 &&
                            this.state.denialsub === "91-120days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[16]._id
                                }&agegrp=91-120days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[16].subcategoryagewise[3].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial ===
                              this.state.idof17 &&
                            this.state.denialsub === "121-180days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[16]._id
                                }&agegrp=121-180days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[16].subcategoryagewise[4].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                         
                        >
                          <Link
                           className={
                            this.state.subcategorydenial ===
                              this.state.idof17 &&
                            this.state.denialsub === "181-365days"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[16]._id
                                }&agegrp=181-365days`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[16].subcategoryagewise[5].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          
                        >
                          <Link
                          className={
                            this.state.subcategorydenial ===
                              this.state.idof17 &&
                            this.state.denialsub === "above 1year"
                              ? "e-card"
                              : "thirdcard"
                          }
                            // style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[16]._id
                                }&agegrp=above 1year`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            ${" "}
                            {this.state.subcategorywisedata &&
                              this.state.subcategorywisedata[16].subcategoryagewise[6].totalamount.toLocaleString()}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={{
                              pathname: "/denial/denial-details",
                              state: {
                                current: `${this.state.value}&subcategory=${
                                  this.state.subcategorywisedata &&
                                  this.state.subcategorywisedata[16]._id
                                }`,
                                past: `${this.state.value}`,
                                past1: `${this.state.pastvalue}`,
                              },
                            }}
                          >
                            <b>
                              ${" "}
                              {this.state.subcategorywisedata &&
                                this.state.subcategorywisedata[16].grandtotal.toLocaleString()}
                            </b>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    );
  }
}
export default SubCategory3;

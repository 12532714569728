import React, { Component } from "react";
import Newsidebar from "../../Dashboard/Newsidebar";
 

import { DatePicker } from "antd";
import moment from "moment-timezone";

import { IoIosRefresh } from "react-icons/io";
import Tabs, { Tab } from "react-best-tabs";
import { Input } from "reactstrap";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import { FormControl, Button, Row, Col } from "react-bootstrap";

import Automationatage30 from "./Automationatage30";
import Nextfollow from "./Nextfollow";
import Searchanderror from "./Searchanderror";
import Uploadautomation from "./Uploadautomation";
import UploadedClaims from "./UploadedClaims";
const { RangePicker } = DatePicker;

export class Automationtrack extends Component {
  constructor(props) {
    super(props);
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    let start1 = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end1 = moment(start).add(1, "days").subtract(1, "seconds");
    this.state = {
      start: start,
      end: end,
      start1: start1,
      end1: end1,
      startdate: "",
      enddate: "",
      filterstatus: "custom",
      tabindex: 1,
      startdatetime: "",
      enddatetime: "",
      startdatetime1: "",
      enddatetime1: "",
    };
    this.applyCallback = this.applyCallback.bind(this);
  }
  applyCallback = (startDate, endDate) => {
    let start = moment(startDate._d).format().substring(0, 16);
    let end = moment(endDate._d).format().substring(0, 16);
    this.setState({
      startdatetime: start,
      enddatetime: end,
    });
  };
  applyCallback1 = (startDate, endDate) => {
    let start = moment(startDate._d).format().substring(0, 16);
    let end = moment(endDate._d).format().substring(0, 16);
    this.setState({
      startdatetime1: start,
      enddatetime1: end,
    });
  };
  valuedate = (option, e) => {
    if (option === "yesterday") {
      this.setState({
        startdate: e.target.value,
        enddate: moment().subtract(1, "days").format("MM-DD-YYYY"),
        filterstatus: option,
      });
    } else {
      this.setState({
        startdate: e.target.value,
        enddate: moment().format("MM-DD-YYYY"),
        filterstatus: option,
      });
    }
  };
  render() {
    let value;
    let date1 = moment().subtract(7, "days").format().substring(0, 16);
    let date2 = moment().format().substring(0, 16);
    if (this.state.startdatetime && this.state.enddatetime) {
      value = `${this.state.startdatetime.substring(
        0,
        16
      )} ~ ${this.state.enddatetime.substring(0, 16)}`;
    } else {
      value = `${date1} ~ ${date2}`;
    }
    let value1;
    if (this.state.startdatetime1 && this.state.enddatetime1) {
      value1 = `${this.state.startdatetime1.substring(
        0,
        16
      )} ~ ${this.state.enddatetime1.substring(0, 16)}`;
    } else {
      value1 = "Start Date ~ End Date";
    }
    let disabled = true;
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    let start1 = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end1 = moment(start).add(1, "days").subtract(1, "seconds");
    let local = {
      format: "DD-MM-YYYY HH:mm",
      sundayFirst: false,
    };
    let local1 = {
      format: "DD-MM-YYYY HH:mm",
      sundayFirst: false,
    };

    return (
      <div>
      <Newsidebar name="Automation track" />
      <div className="align-row" style={{overflow:"hidden"}}>
        <div className="align-column">
           <br/>
          <div>
            <Input
              type="select"
              style={{ width: "20%", float: "right" }}
              className="form-control form-select"
              onChange={(e) => {
                const index = e.target.selectedIndex;
                const el = e.target.childNodes[index];
                const option = el.getAttribute("title");
                this.valuedate(option, e);
              }}
            >
              <option value="" title="custom">
                Custom
              </option>
              <option value={moment().format("MM-DD-YYYY")} title="">
                Today
              </option>
              <option
                value={moment().subtract(1, "days").format("MM-DD-YYYY")}
                title="yesterday"
              >
                Yesterday
              </option>
              <option
                value={moment().subtract(3, "days").format("MM-DD-YYYY")}
                title=""
              >
                Last 3 days
              </option>
              <option
                value={moment().subtract(7, "days").format("MM-DD-YYYY")}
                title=""
              >
                Last 7 days
              </option>
              <option
                value={moment().subtract(30, "days").format("MM-DD-YYYY")}
                title=""
              >
                Last 30 days
              </option>
              <option
                value={moment().subtract(365, "days").format("MM-DD-YYYY")}
                title=""
              >
                Last 365 days
              </option>
            </Input>
            {this.state.filterstatus === "custom" && (
              <RangePicker
                format="MM-DD-YYYY"
                onChange={(dates, datestring) => {
                  this.setState({
                    startdate: datestring[0],
                    enddate: datestring[1],
                  });
                }}
                width="200%"
                style={{
                  borderColor: "gray",
                  float: "right",
                  marginRight:"2%"
                }}
              />
            )}
          </div>

          <div style={{paddingTop:"1%"}}>
            <Tabs
              activeTab="1"
              activityClassName="text-secondary"
              style={{ margin: "5%" }}
              onClick={(event, tab) => {
                this.setState({ tabindex: tab });
              }}
            >
              <Tab title="Follow Up Rundate">
                <Automationatage30
                  startdate={this.state.startdate}
                  enddate={this.state.enddate}
                />
              </Tab>
              {/* <Tab title="Next Follow">
                <Nextfollow
                  startdate={this.state.startdate}
                  enddate={this.state.enddate}
                />
              </Tab> */}

              <Tab title="Automate Follow Up-date">
                <Uploadautomation
                  startdate={this.state.startdate}
                  enddate={this.state.enddate}
                />
              </Tab>
              <Tab title="Searches and Error">
                <Searchanderror
                  startdate={this.state.startdate}
                  enddate={this.state.enddate}
                />
              </Tab>
              <Tab title="Uploaded Claims">
                <UploadedClaims
                  startdate={this.state.startdate}
                  enddate={this.state.enddate}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default Automationtrack;

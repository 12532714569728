import React, { Component } from "react";
import axios from "axios";
import { ip } from "../../Api";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import ReactPaginate from "react-paginate";
import moment from "moment-timezone";
import { Table, Row, Col, Input } from "reactstrap";
import { Spinner } from "react-bootstrap";
export class CombinedReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("clientid"),
      combineddata: [],
      currentpage: 0,
      countarlog: 20000,
      countofarlog: "",
      perPage: 10,
      offset: 0,
      buttonsize: 0,
      buttondisable: true,
      currentpagebutton: 0,
      perPagebutton: 10,
      offsetbutton: 0,
      download: false,
      loading: true,
    };
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage4;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getcombinedreport();
      }
    );
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  downloaddata = () => {
    const { download } = this.state;
    this.setState({ download: !download });
  };

  getdownloadbutton = () => {
    this.setState({ loading: true });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    let filename;
    if (this.props.startdate && this.props.enddate) {
      filename = `COMBINED_REPORTS_${this.props.startdate}_${this.props.enddate}`;
      url = `${ip}/report/combainedreport?clientId=${this.state.client}&start_date=${this.props.startdate}&end_date=${this.props.enddate}&button=${this.state.buttonsize}`;
    } else {
      filename = `COMBINED_REPORTS`;
      url = `${ip}/report/combainedreport?clientId=${this.state.client}&button=${this.state.buttonsize}`;
    }
    fetch(url, { headers: headers }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", filename);
        a.click();
        URL.revokeObjectURL(a);
        this.setState({
          loading: false,
        });
      });
    });
  };

  getcount = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/report/getcounts?clientId=${this.state.client}&feature=arlogs&start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
    } else {
      url = `${ip}/report/getcounts?clientId=${this.state.client}&feature=arlogs`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          countofarlog: Math.ceil(res.data.count / this.state.countarlog),
        });
      });
  };
  buttonrandom = () => {
    const buttonsContainer = document.getElementById("buttonsContainer");

    for (var i = 0; i < this.state.countofarlog; i++) {
      var button = document.createElement("button");

      button.innerText = "Button";

      (function (index) {
        button.addEventListener("click", function () {});
      })(i);

      buttonsContainer.appendChild(button);
    }
  };
  increment = () => {
    if (this.state.buttonsize < this.state.countofarlog - 1) {
      this.setState({ buttonsize: this.state.buttonsize + 1 });

      if (this.state.buttonsize < 0) {
        alert("No data here so please click next arrow");
        this.state.buttonsize = 0;
      }
    }
  };

  decrement = () => {
    this.setState({ buttonsize: this.state.buttonsize - 1 });

    if (this.state.buttonsize - 1 < 0) {
      alert("No data here so please click next arrow");
      this.state.buttonsize = 0;
    }
  };
  getcombinedreport = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/report/combainedreport?clientId=${this.state.client}&start_date=${this.props.startdate}&end_date=${this.props.enddate}&pageno=0`;
    } else {
      url = `${ip}/report/combainedreport?clientId=${this.state.client}&pageno=0`;
    }
    const res = axios

      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          pageCount: Math.ceil(res.data.count / this.state.perPage),
          combineddata: res.data.data,
          fetching: false,
        });
      });
  };

  componentDidMount() {
    this.getcombinedreport();
    this.getcount();
    this.buttonrandom();
  }

  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.startdate === prevProps.startdate &&
        this.props.enddate === prevProps.enddate
      )
    ) {
      this.getcombinedreport();
    }
  }
  render() {
    return (
      <div>
        <br />
        <div>
          <button
            style={{
              boxShadow: "inset 0px 1px 0px 0px #E184F3",
              background: "black",
              backgroundColor: "black",
              borderRadius: "6px",
              border: "1px solid black",
              display: "inline-block",
              cursor: "pointer",
              color: "#FFFFFF",
              fontSize: "15px",
              fontWeight: "bold",
              padding: "6px 24px",
              textDecoration: "none",
              textShadow: "0px 1px 0px #9B14B3",
              float: "right",
            }}
            onClick={(e) => {
              this.downloaddata();
              this.getdownloadbutton();
            }}
          >
            Generate Report
          </button>
        </div>
        <br />
        <div>
          {this.state.fetching ? (
            <div>
              <p
                style={{
                  fontSize: "23px",
                  marginTop: "10px",
                  color: "#000",
                }}
              >
                <span>Loading...</span>
                <span>Please Wait...</span>
              </p>
              <div class="boxes body__loader dribbble">
                <div class="box">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="box">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="box">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="box">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          ) : this.state.combineddata.length !== 0 ? (
            <div
              style={{
                marginLeft: "2%",
                marginTop: "2%",
              }}
            >
              <Table className="StandardTable" bordered responsive>
                <thead>
                  <tr align="center">
                    <th>ClaimStatusType</th>
                    <th>ClaimID</th>
                    <th>fromDate</th>
                    <th>toDate</th>
                    <th>Channel</th>
                    <th>PayerId</th>
                    <th>Insurance Name</th>
                    <th>Submitter Lastname</th>
                    <th>Provider TaxId</th>
                    <th>Provider Lastname</th>
                    <th>Provider NPI</th>
                    <th>Subscriber MemberID</th>
                    <th>Patient Firstname</th>
                    <th>Patient Lastname</th>
                    <th>Patient BirthDate</th>
                    <th>Gender</th>
                    <th>Subscriber Lastname</th>
                    <th>Subscriber Firstname</th>
                    <th>Status</th>
                    <th>FinalizeDate</th>
                    <th>Claim Number</th>
                    <th>CPT</th>
                    <th>Claim Amount</th>
                    <th>Paid Amount</th>
                    <th>Check Number</th>
                    <th>Status Category Code</th>
                    <th>Status Code</th>
                    <th>Status Code Description</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.combineddata &&
                    this.state.combineddata.map((data, index) => (
                      <React.Fragment key={index}>
                        {/* Claim Row */}
                        <tr>
                          <td>{data.ClaimStatusType}</td>
                          <td>{data.ClaimID}</td>
                          <td>{data.fromDate}</td>
                          <td>{data.toDate}</td>
                          <td>{data.channel}</td>
                          <td>{data.payerId}</td>
                          <td>{data.InsuranceName}</td>
                          <td>{data.submitterLastName}</td>
                          <td>{data.providersTaxId}</td>
                          <td>{data.providersLastName}</td>
                          <td>{data.providersNpi}</td>
                          <td>{data.subscriberMemberId}</td>
                          <td>{data.patientFirstName}</td>
                          <td>{data.patientLastName}</td>
                          <td>{data.patientBirthDate}</td>
                          <td>{data.Gender}</td>
                          <td>{data.subscriberFirstName}</td>
                          <td>{data.subscriberLastName}</td>
                          <td>{data.ClaimStatus}</td>
                          <td>{data.finalizeDate}</td>
                          <td>{data.claimNumber}</td>
                          <td>{data.CPT}</td>
                          <td>{data.claimAmount}</td>
                          <td>{data.paidAmount}</td>
                          <td>{data.checkNumber}</td>
                          <td>{data.statusCategoryCode}</td>
                          <td>{data.statusCode}</td>
                          <td>{data.statusCategoryCodeDescription}</td>
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </Table>
              <br />
            </div>
          ) : (
            <p
              style={{ fontSize: "20px", fontStyle: "italic", marginTop: "2%" }}
            >
              No Data Found
            </p>
          )}
        </div>
        {this.state.download && (
          <Modal
            style={{ width: "30%" }}
            isOpen={this.state.download}
            onHide={this.downloaddata}
            onExit={this.reset}
            size="md"
            centered
          >
            <ModalHeader toggle={this.downloaddata}>Download</ModalHeader>
            <ModalBody
              style={{
                "max-height": "calc(100vh - 210px)",
                "overflow-y": "auto",
              }}
            >
              <Row style={{ margin: "0px" }}>
                <Col>
                  <div id="container">
                    {this.state.loading ? (
                      <button
                        style={{
                          // boxShadow: "inset 0px 1px 0px 0px #e184f3",
                          background: "green",
                          backgroundColor: "green",
                          borderRadius: "6px",
                          border: "3px solid green",
                          display: "inline-block",
                          cursor: "pointer",
                          color: "#ffffff",
                          fontSize: "15px",
                          fontWeight: "bold",
                          padding: "-4px 24px",
                          textDecoration: "none",
                          width: "45%",
                          whiteSpace: "nowrap",
                          marginLeft: "20%",
                          textShadow: "0px 1px 0px #9b14b3",
                        }}
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        &nbsp;&nbsp;Loading data...{this.state.buttonsize + 1}
                      </button>
                    ) : (
                      <button
                        style={{
                          // boxShadow: "inset 0px 1px 0px 0px #e184f3",
                          background: "Black",
                          backgroundColor: "Black",
                          borderRadius: "6px",
                          border: "3px solid Black",
                          display: "inline-block",
                          cursor: "pointer",
                          color: "#ffffff",
                          fontSize: "15px",
                          fontWeight: "bold",
                          padding: "-4px 24px",
                          textDecoration: "none",
                          width: "42%",
                          marginLeft: "20%",
                          textShadow: "0px 1px 0px #9b14b3",
                        }}
                        onClick={(e) => {
                          this.getdownloadbutton();
                        }}
                      >
                        Report {this.state.buttonsize + 1}
                      </button>
                    )}
                  </div>
                </Col>
                <br />
                <br />
              </Row>

              <br />
            </ModalBody>
          </Modal>
        )}
        <br />
      </div>
    );
  }
}
export default CombinedReport;

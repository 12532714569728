import { React, Component } from "react";
import { styled } from "@mui/material/styles";
import { Row } from "reactstrap";
import loading from "../../../Images/loader.gif";
import { Modal, ModalBody } from "react-bootstrap";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import axios from "axios";
import { ip } from "../../Api";
import { Table } from "react-bootstrap";
import moment from "moment";
import Tabs, { Tab } from "react-best-tabs";
import { Button } from "reactstrap";
import "../../../Styles/checkautoclaim.css";
class AutoCheckAutoClaim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      ClaimID: sessionStorage.getItem("ClaimID"),
      ChartID: sessionStorage.getItem("ChartID"),
      MRN: sessionStorage.getItem("MRN"),
      Patient: sessionStorage.getItem("Patient"),
      Pripayer: sessionStorage.getItem("Pripayer"),
      DateOfService: sessionStorage.getItem("DateOfService"),
      ClaimDate: sessionStorage.getItem("ClaimDate"),
      PatientDOB: sessionStorage.getItem("PatientDOB"),
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      viewclosedcpt:sessionStorage.getItem("pre-toggle") === "true",
      updateBillingOrganizationName:"",
      updateServiceOrganizationName:"",
      updateAge: "",
      cptInactive:[],
      updateIcdCode: "",
      updatePayerCategory: "",
      updatePayerId: "",
      updateArClass: "",
      updatecheckno:"",
      updatePolicyNo: "",
      updateDOS: "",
      updateBeginDOS:"",
      updateEndDOS:"",
      updatePripayer: "",
      updateClaimDate: "",
      updatePhysician: "",
      updatePatientDOB: "",
      updateLastName: "",
      updateFirstName: "",
      updateMRN: "",
      updateChartId: "",
      updateClaimID: "",
      updateAgeGroup: "",
      updateTotalBillAmt: "",
      updateCheckNumber:"",
      updateTotalBalAmt: "",
      updateTotallPaidAmt: "",
      updateClaimStatus: "",
      updateOverAllClaimStatus: "",
      updateTrackingNumber:"",
      updateGender: "",
      updateNpi: "",
      modulename: sessionStorage.getItem("Module"),
      updateServiceProvider: "",
      updateServiceProviderType: "",
      updateTaxID: "",
      updatePripayerContact: "",
      updateControlNo: "",
      updatePayerIdentification: "",
      updateTouch: "",
      updateTfl: "",
      updateid: "",
      updatetype: "",
      updateCheckIssueDate: "",
      updateEndDOS: "",
      updateBeginingDOS: "",
      updateBillingProviderType: "",
      updateFollowUpdate: "",
      updateCreatedAT: "",
      redirecturl: "",
      updateMachinetype:"",
      updateCPTS: "",
      updateClaimCategory: "",
      updateClaimSubCategory: "",
      updateSubmittedAmount: "",
      TrackingNumber: "",
      EndDOS: "",
      BeginingProviderType: "",
      Gender: "",
      BillingProviderType: "",
      BillingProvider: "",
      ServiceProviderType: "",
      Npi: "",
      ServiceProvider: "",
      TaxID: "",
      PayerIdentification: "",
      TradingPartnerDeviceID: "",
      ControlNumber: "",
      PrimaryPolicyNo: "",
      PatientDOB: "",
      PatientLastName: "",
      action: "",
      ActionStatuscode: "",
      Notes: "",
      PatientFirstName: "",
      BeginingDOS: "",
      ChartID: "",
      MRN: "",
      PriPayer: "",
      Phone: "",
      Address: "",
      ClaimDate: "",
      DateOfService: "",
      ProcedureCode: "",
      PrimaryDiagnosis: "",
      Unit: "",
      AmountCharges: "",
      BilledAmount: "",
      ServiceProvider: "",
      updatePartnerId: "",
      loadingModal: false,
      loadModal: false,
      cptcodearray:[],
      cptnotesarray:[],
      updateClientId:"",
    };
  }
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  cpttoggle = () => {
    this.setState({ loadingModal: !this.state.loadingModal });
  };
  UpdateDetails = async () => {
    this.setState({ loadingModal: !this.state.loadingModal });

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

    const value = {
      cpt: this.state.cptdata,
      CPTS: this.state.updateCPTS,
      trackingNumber: this.state.updateTrackingNumber,
      EndDOS: this.state.updateEndDOS,
      BeginingDOS: this.state.updateBeginingDOS,
      Gender: this.state.updateGender,
      BillingProviderType: this.state.updateBillingProviderType,
      ServiceProviderType: this.state.updateServiceProviderType,
      Npi: this.state.updateNpi,
      ServiceProvider: this.state.updateServiceProvider,
      TaxID: this.state.updateTaxID,
      payerIdentification: this.state.updatePayerIdentification,
      tradingPartnerServiceId: this.state.updatePartnerId,
      controlNumber: this.state.updateControlNo,
      PrimaryPolicyNo: this.state.updatePolicyNo,
      PatientDOB: this.state.PatientDOB,
      PatientLastName: this.state.updateLastName,
      PatientFirstName: this.state.updateFirstName,
      ClaimID: this.state.ClaimID,
      ActionCode: this.state.action,
      ActionStatuscode: this.state.ActionStatuscode,
      Notes: this.state.Notes,
      claimSubCategory: this.state.updateClaimSubCategory,
      ClaimCategory: this.state.updateClaimCategory,
      TotalBilledAmount: this.state.updateTotalBilledAmount,
      ClaimStatus: this.state.updateClaimStatus,
      TotalAmountpaid: this.state.updateTotallPaidAmt,
    };

     await axios
      .put(`${ip}/claim/autocheck?claimid=${this.state.ClaimID}`, value, {
        headers: headers,
      })
      .then((res) => {
        this.setState({ loadingModal: !this.state.loadingModal });

        this.getclaims();
        alert("Claims Autocheck Completed");
        this.cpttoggle();
      })
      .catch((err) => {
        this.setState({ loadingModal: !this.state.loadingModal });
      });
  };
  RerunApis = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      Machinetype: this.state.updateMachinetype,
      clientId: this.state.updateClientId,
      trackingNumber: this.state.updateTrackingNumber,
      BillingOrganizationName: this.state.updateBillingOrganizationName,
      ServiceOrganizationName: this.state.updateServiceOrganizationName,
      EndDOS: this.state.updateEndDOS,
      BeginingDOS: this.state.updateBeginingDOS,
      Gender: this.state.updateGender,
      BillingProviderType: this.state.updateBillingProviderType,
      ServiceProviderType: this.state.updateServiceProviderType,
      Npi: this.state.updateNpi,
      ServiceProvider: this.state.updateServiceProvider,
      PrimaryPolicyNo: this.state.updatePolicyNo,
      TaxID: this.state.updateTaxID,
      tradingPartnerServiceId: this.state.updatePartnerId,
      controlNumber: this.state.updateControlNo,
      PayerID: this.state.updatePayerId,
      PatientDOB: this.state.PatientDOB,
      PatientLastName: this.state.updateLastName,
      PatientFirstName: this.state.updateFirstName,
    }
     axios
      .put(
        `${ip}/claim/autocheck?claimid=${this.state.ClaimID}`,
        value,
        {
          headers: headers,
        }
      )
      .then((res) => {
        alert('Auto Claim check successfully completed');
        this.getclaims();
        this.cpttoggle();
      })
      .catch((err) => {});
  };
  getclaims = async () => {
    //api call to get all user
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/claim/viewclaim?claimid=${this.state.ClaimID}`, {
        headers: headers,
      })
      .then((res) => {
        let cptarray = res.data.Claim[0].cpt.map((data,index)=> {
          return [data.CPTCode, data.cptNotes]
        })
        this.setState({
          cptcodearray: cptarray,
          updatePartnerId: res.data.Claim[0].tradingPartnerServiceId,
          updateBillingOrganizationName:res.data.Claim[0].BillingOrganizationName,
          updateServiceOrganizationName: res.data.Claim[0].ServiceOrganizationName,
          cptdata: res.data.Claim[0].active,
          updateClientId:res.data.Claim[0].clientId,
          cptInactive:res.data.Claim[0].inactive,
          Updateclaimunder:res.data.Claim[0].Claimunder,
          touch: res.data.Claim[0].Touch,
          updateCreatedAT: res.data.Claim[0].CreatedAt,
          updateCPTS: res.data.Claim[0].CPTS,
          updateMachinetype: res.data.Claim[0].Machinetype,
          updateBillingProviderType: res.data.Claim[0].BillingProviderType,
          updateEndDOS: res.data.Claim[0].EndDOS,
          updateAge: res.data.Claim[0].Age,
          updateIcdCode: res.data.Claim[0].ICD10Code,
          updatePayerCategory: res.data.Claim[0].Payercategory,
          updatePayerId: res.data.Claim[0].PayerID,
          updateArClass: res.data.Claim[0].ARClass,
          updatePolicyNo: res.data.Claim[0].PrimaryPolicyNo,
          updateDOS: res.data.Claim[0].DateOfService,
          updateBeginDOS: res.data.Claim[0].BeginingDOS,
          updateEndDOS: res.data.Claim[0].EndDOS,
          updatePripayer: res.data.Claim[0].Pripayer,
          updateClaimDate: res.data.Claim[0].ClaimDate,
          updatePhysician: res.data.Claim[0].Physician,
          updatePatientDOB: res.data.Claim[0].PatientDOB,
          updateLastName: res.data.Claim[0].PatientLastName,
          updateFirstName: res.data.Claim[0].PatientFirstName,
          updateMRN: res.data.Claim[0].patientAccountNumber,
          updateChartId: res.data.Claim[0].ChartID,
          updateClaimID: res.data.Claim[0].ClaimID,
          updateAgeGroup: res.data.Claim.AgeGrp,
          updateTotalBillAmt: res.data.Claim[0].TotalBilledAmount,
          updateTotalBalAmt: res.data.Claim[0].TotalBalanceAmount,
          updateTotallPaidAmt: res.data.Claim[0].TotalAmountPaid,
          updateClaimStatus: res.data.Claim[0].ClaimStatus,
          updateOverAllClaimStatus: res.data.Claim[0].OverallClaimStatus,
          updateGender: res.data.Claim[0].Gender,
          updateNpi: res.data.Claim[0].Npi,
          updateServiceProvider: res.data.Claim[0].ServiceProvider,
          updateServiceProviderType: res.data.Claim[0].ServiceProviderType,
          updateTaxID: res.data.Claim[0].TaxID,
          updatePripayerContact: res.data.Claim[0].Pripayerphone,
          updateControlNo: res.data.Claim[0].controlNumber,
          updatePayerIdentification: res.data.Claim[0].payerIdentification,
          updateTouch: res.data.Claim[0].Touch,
          updateTfl: res.data.Claim[0].Tfl,
          updatetype: res.data.Claim[0].type,
          updateStatusCategoryCode: res.data.Claim[0].statusCategoryCode,
          updateStatusCategoryCodeActions: res.data.Claim[0].statusCategoryCode,
          updateStatusCode: res.data.Claim[0].statusCode,
          updateStatusValue: res.data.Claim[0].statusCodeValue,
          updateStatusCategoryValue: res.data.Claim[0].statusCategoryCodeValue,
          updateAmountPaid: res.data.Claim[0].amountPaid,
          updateCheckNumber: res.data.Claim[0].checkNumber,
          updateeffectiveDate: res.data.Claim[0].effectiveDate,
          updateTrackingNumber: res.data.Claim[0].trackingNumber,
          updateid: res.data.Claim[0]._id,
          updateCheckIssueDate: res.data.Claim[0].checkIssueDate,
          updateBeginingDOS: res.data.Claim[0].BeginingDOS,
          updateFollowUpdate: res.data.Claim[0].Followup_date,
          updateClaimSubCategory: res.data.Claim[0].claimSubCategory,
          updateClaimCategory: res.data.Claim[0].ClaimCategory,
          updateSubmittedAmount: res.data.Claim[0].submittedAmount,
          updateTotalBilledAmount: res.data.Claim[0].TotalBilledAmount,
          statuscode: res.data.Claim[0].statusCode,
          action: res.data.Claim[0].ActionCode,
          ActionStatuscode: res.data.Claim[0].ActionStatuscode,
          Notes: res.data.Claim[0].Notes,
          updateClaimStatusActions: res.data.Claim[0].ClaimStatus,
          updateClaimCategoryActions: res.data.Claim[0].ClaimCategory,
          updateClaimSubCategoryActions: res.data.Claim[0].claimSubCategory,
          updateNotesActions: res.data.Claim[0].Notes,
          worked__notes: res.data.Claim[0].cpt[0].cptNotes,
          submitted__notes: res.data.Claim[0].cpt[0].cptNotes,
          closedInsurancePayment: res.data.Claim[0].cpt[0].InsurancePayment,
          closedPatientResponsibility:
            res.data.Claim[0].cpt[0].PatientResponsibility,
          closedpaymentmode: res.data.Claim[0].cpt[0].PaymentMode,
          updateActionCodeStatusActions: res.data.Claim[0].ActionStatuscode
            ? res.data.Claim[0].ActionStatuscode
            : "",
          updateactioncodeActions: res.data.Claim[0].ActionCode
            ? res.data.Claim[0].ActionCode
            : "",
        });
      })

      .catch((err) => {});
  };
  handleaddCpt = async (data) => {    
    const isValid = this.passValidation();
    if(isValid){
    let value;
    value = {
      CPTCode: "87880",
      ClaimID: "ELM185010/28/2022",
      DOS: "",
      ICD: "",
      Module: "addcpt",
      NextFollowupDate: "12-17-2023",
      Notes: "test",
      OverallServiceLevelStatus: "Paid",
      Provider: "",
      clientId: 2004,
      cptNotes: "test",
      submittedAmount: "" ,
      submittedUnits: ""
    };
    // eslint-disable-next-line
     await axios
      .post(`${ip}/claim/addcpt?claimid=${this.state.ClaimID}`, value)
      .then((res) => {
        this.successbox();
      })
        .catch((err) => {});
    }
  };

  componentDidMount() {
    if (this.state.modulename === "Completed")
      this.setState({ redirecturl: "/user-completed-page" });
    else if (this.state.modulename === "Denial")
      this.setState({ redirecturl: "/user-denial-page" });
    else if (this.state.modulename === "InProgress")
      this.setState({ redirecturl: "/user-inprogress-page" });
    else if (this.state.modulename === "Pending")
      this.setState({ redirecturl: "/user-nottouch-page" });
    else if (this.state.modulename === "Work Queue")
      this.setState({ redirecturl: "/actions" });
    else if (this.state.modulename === "Rejection")
      this.setState({ redirecturl: "/action-rejection-denials" });
    else if (this.state.modulename === "OpenClaims")
      this.setState({ redirecturl: "/open-claims" });
    else if (this.state.modulename === "ClosedClaims")
      this.setState({ redirecturl: "/closed-claims" });
      else if (this.state.modulename === "Need to Call")
      this.setState({ redirecturl: "/user-needtocall-page" });  
    }

  toggle = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  };
  render() {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:last-child td, &:last-child th": {
        border: 1,
      },
    }));
    return (
      <div className="align-row">
        <div className="align-column">
          <br></br>
          <div
            style={{ textAlign: "left", marginLeft: "2%", fontSize: "14px" }}
          >
            <p>
              <Link
                to={this.state.redirecturl}
                style={{ textDecoration: "none", color: "black" }}
              >
                {this.state.modulename}
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;Check
              Auto Claim
            </p>
          </div>
          <br></br>
          <div>
            {" "}
            <h5
              style={{ textAlign: "left", marginLeft: "2%", fontWeight: "600" }}
            >
              Check Auto Claim
            </h5>
          </div>
          <br></br>
          <div>
            <Button
              style={{
                marginLeft: "80%",
                marginTop: "-6%",
                marginBottom: "1%",
                textAlign: "left",
                fontSize: "20px",
                fontWeight: "500",
                borderRadius: "6px",
                color: "#ffff",
                backgroundColor: "#041c3c",
              }}
              onClick={this.cpttoggle}
            >
              Check Claim
            </Button>
          </div>
          <div>
            <div className="viewclaim_table2">
              <Table className="table_container2" responsive="md" bordered>
                <thead>
                  <tr>
                    <th
                      colSpan={2}
                      style={{
                        backgroundColor: "#041c3c",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      Patient
                    </th>
                    <th
                      colSpan={2}
                      style={{
                        backgroundColor: "#041c3c",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      Payer
                    </th>
                    <th
                      colSpan={2}
                      style={{
                        backgroundColor: "#041c3c",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      Provider
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr className="datatablevalue">
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      ClaimID
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updateClaimID}
                    </td>
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Pripayer
                    </td>
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                        whiteSpace: "normal",
                        textAlign: "justify",
                      }}
                    >
                      {this.state.updatePripayer}
                    </td>
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Physician
                    </td>

                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updatePhysician}
                    </td>
                  </tr>

                  <tr className="datatablevalue">
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      FirstName
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {" "}
                      {this.state.updateFirstName}
                    </td>
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      PayerID
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updatePayerId}
                    </td>
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Tax ID
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updateTaxID}
                    </td>
                  </tr>

                  <tr className="datatablevalue">
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Lastname
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updateLastName}
                    </td>
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Pripayer Contact
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updatePripayerContact}
                    </td>
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      NPI
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updateNpi}
                    </td>
                  </tr>

                  <tr className="datatablevalue">
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      MRN/Account Number
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updateMRN}
                    </td>
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Payer Category
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updatePayerCategory}
                    </td>
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Service Provider
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updateServiceProvider}
                    </td>
                  </tr>

                  <tr className="datatablevalue">
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      PrimaryPolicyNo
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updatePolicyNo}
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}></td>
                    <td style={{ backgroundColor: "whitesmoke" }}></td>
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Service Provider Type
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updateServiceProviderType}
                    </td>
                  </tr>

                  <tr className="datatablevalue">
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      DOB
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updatePatientDOB}
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}></td>
                    <td style={{ backgroundColor: "whitesmoke" }}></td>
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Billing Provider type
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updateServiceProviderType}
                    </td>
                  </tr>

                  <tr className="datatablevalue">
                    <td
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      Gender
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}>
                      {this.state.updateGender}
                    </td>
                    <td style={{ backgroundColor: "whitesmoke" }}></td>
                    <td style={{ backgroundColor: "whitesmoke" }}></td>
                    <td style={{ backgroundColor: "whitesmoke" }}></td>
                    <td style={{ backgroundColor: "whitesmoke" }}></td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <br></br>
          <div>
            <div style={{ height: "100%", width: "100%" }}>
              <div>
                {" "}
                <h5
                  style={{
                    textAlign: "left",
                    marginLeft: "2%",
                    fontWeight: "600",
                  }}
                >
                  CPT details
                </h5>
              </div>
              <Tabs
              activityClassName="text-secondary"
              style={{ margin: "5%" }}
            >
              <Tab title="CPT Details">
                <Row>
                  <h5
                    style={{
                      textAlign: "left",
                      marginLeft: "2%",
                      fontWeight: "600",
                      marginTop: "2%",
                    }}
                  >
                    CPT Details{" "}
                  </h5>
                </Row>

                <div className="viewclaim_table1">
                  <Table
                    className="table_container"
                    responsive="md"
                    bordered
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          CODE
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          Submitted Amount
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          Paid Amount
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          Status
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          Subcategory
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          Notes
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          Status Code
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          Status Category Code
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          Touches
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.cptdata &&
                        this.state.cptdata.map((data, index) => {
                          return (
                            <tr>
                              <td style={{ backgroundColor: "whitesmoke" }}>
                                
                                {data.CPTCode}
                              </td>
                              <td style={{ backgroundColor: "whitesmoke" }}>
                                ${" "}
                                {data.submittedAmount
                                  ? data.submittedAmount
                                  : 0}
                              </td>
                              <td style={{ backgroundColor: "whitesmoke" }}>
                                $ {data.amountPaid ? data.amountPaid : 0}
                              </td>
                              <td style={{ backgroundColor: "whitesmoke" }}>
                                {data.ClaimStatus}
                              </td>
                              <td style={{ backgroundColor: "whitesmoke" }}>
                                {data.cptSubCategory}
                              </td>
                              <td
                                style={{
                                  backgroundColor: "whitesmoke",
                                  whiteSpace: "normal",
                                  textAlign: "justify",
                                }}
                              >
                                {data.cptNotes}
                              </td>
                              <td
                                style={{
                                  backgroundColor: "whitesmoke",
                                  whiteSpace: "normal",
                                }}
                              >
                                {data.statusCode}
                              </td>
                              <td
                                style={{
                                  backgroundColor: "whitesmoke",
                                  whiteSpace: "normal",
                                }}
                              >
                                {data.statusCategoryCode}
                              </td>
                              <td
                                style={{
                                  backgroundColor: "whitesmoke",
                                  textAlign: "center",
                                }}
                              >
                                {data.Touch ? data.Touch : 0}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </Tab>
              <Tab title="Transaction">
                <Row>
                  <h5
                    style={{
                      textAlign: "left",
                      marginLeft: "2%",
                      fontWeight: "600",
                      marginTop: "2%",
                    }}
                  >
                    Charges{" "}
                  </h5>
                </Row>
                <div className="viewclaim_table1">
                  <Table
                    className="table_container"
                    responsive="md"
                    bordered
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          DOS
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          CPT
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          ICN
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Source
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Units
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Provider
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Total Balance
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          MOP
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Cheque No
                        </th>
                        <th
                          style={{
                            backgroundColor: "#041c3c",
                            color: "white",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Insurance Payment
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.cptdata &&
                        this.state.cptdata.map((data, index) => {
                          return (
                            <tr>
                              <td style={{ backgroundColor: "whitesmoke" }}>
                                {this.state.updateDOS}
                              </td>
                              <td style={{ backgroundColor: "whitesmoke" }}>
                                {data.CPTCode}
                              </td>
                              <td style={{ backgroundColor: "whitesmoke" }}>
                                {this.state.icn_no
                                  ? this.state.icn_no
                                  : "-"}
                              </td>
                              <td style={{ backgroundColor: "whitesmoke" }}>
                                {data.Source ? data.Source : "-"}
                              </td>
                              <td style={{ backgroundColor: "whitesmoke" }}>
                                {data.submittedUnits
                                  ? data.submittedUnits
                                  : "-"}
                              </td>
                              <td style={{ backgroundColor: "whitesmoke" }}>
                                {data.Provider ? data.Provider : "-"}
                              </td>
                              <td style={{ backgroundColor: "whitesmoke" }}>
                                $
                                {data.TotalBalanceAmount
                                  ? data.TotalBalanceAmount
                                  : 0}
                              </td>
                              <td style={{ backgroundColor: "whitesmoke" }}>
                                {data.PaymentMode}
                              </td>
                              <td
                                style={{
                                  backgroundColor: "whitesmoke",
                                  whiteSpace: "normal",
                                  textAlign: "justify",
                                }}
                              >
                                {data.check_no ? data.check_no : 0}
                              </td>
                              <td
                                style={{
                                  backgroundColor: "whitesmoke",
                                  whiteSpace: "normal",
                                }}
                              >
                                {data.InsurancePayment
                                  ? data.InsurancePayment
                                  : 0}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </Tab>
              {this.state.viewclosedcpt === true ?
                <Tab title="Closed CPT">
                <br/>
                <div className="viewclaim_table1">
                <Table
                  className="table_container"
                  responsive="md"
                  bordered
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        CODE
                      </th>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Submitted Amount
                      </th>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Paid Amount
                      </th>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Status
                      </th>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Subcategory
                      </th>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Notes
                      </th>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Status Code
                      </th>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Status Category Code
                      </th>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Touches
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.cptInactive &&
                      this.state.cptInactive.map((data, index) => {
                        return (
                          <tr>
                            <td style={{ backgroundColor: "whitesmoke" }}>
                              {data.CPTCode}
                            </td>
                            <td style={{ backgroundColor: "whitesmoke" }}>
                              ${" "}
                              {data.submittedAmount
                                ? data.submittedAmount
                                : 0}
                            </td>
                            <td style={{ backgroundColor: "whitesmoke" }}>
                              $ {data.amountPaid ? data.amountPaid : 0}
                            </td>
                            <td style={{ backgroundColor: "whitesmoke" }}>
                              {data.ClaimStatus}
                            </td>
                            <td style={{ backgroundColor: "whitesmoke" }}>
                              {data.cptSubCategory}
                            </td>
                            <td
                              style={{
                                backgroundColor: "whitesmoke",
                                whiteSpace: "normal",
                                textAlign: "justify",
                              }}
                            >
                              {data.cptNotes}
                            </td>
                            <td
                              style={{
                                backgroundColor: "whitesmoke",
                                whiteSpace: "normal",
                              }}
                            >
                              {data.statusCode}
                            </td>
                            <td
                              style={{
                                backgroundColor: "whitesmoke",
                                whiteSpace: "normal",
                              }}
                            >
                              {data.statusCategoryCode}
                            </td>
                            <td
                              style={{
                                backgroundColor: "whitesmoke",
                                textAlign: "center",
                              }}
                            >
                              {data.Touch ? data.Touch : 0}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
                </Tab>  :
                 <Tab></Tab>
              }
            </Tabs>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div>
            <div style={{ height: "100%", width: "100%" }}>
              <div>
                {" "}
                <h5
                  style={{
                    textAlign: "left",
                    marginLeft: "2%",
                    fontWeight: "600",
                  }}
                >
                  CLAIM Status
                </h5>
              </div>
              <div className="viewclaim_table2">
                <Table className="table_container2" responsive="md" bordered>
                  <thead>
                    <tr>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Claim Status
                      </th>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Claim SubCategory
                      </th>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Claim Category
                      </th>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Total Billed Amount
                      </th>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        Total Paid Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          backgroundColor: "whitesmoke",
                          textAlign: "center",
                        }}
                      >
                        {this.state.updateClaimStatus}
                      </td>
                      <td
                        style={{
                          backgroundColor: "whitesmoke",
                          textAlign: "center",
                        }}
                      >
                        {this.state.updateClaimSubCategory}
                      </td>
                      <td
                        style={{
                          backgroundColor: "whitesmoke",
                          textAlign: "center",
                        }}
                      >
                        {this.state.updateClaimCategory}
                      </td>
                      <td
                        style={{
                          backgroundColor: "whitesmoke",
                          textAlign: "center",
                        }}
                      >
                        $
                        {this.state.updateTotalBilledAmount
                          ? this.state.updateTotalBilledAmount
                          : 0}
                      </td>
                      <td
                        style={{
                          backgroundColor: "whitesmoke",
                          textAlign: "center",
                        }}
                      >
                        ${" "}
                        {this.state.updateTotallPaidAmt
                          ? this.state.updateTotallPaidAmt
                          : 0}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div>
            <div style={{ height: "100%", width: "100%" }}>
              <div>
                {" "}
                <h5
                  style={{
                    textAlign: "left",
                    marginLeft: "2%",
                    fontWeight: "600",
                  }}
                >
                  Action
                </h5>
              </div>
              <div className="viewclaim_table2">
                <Table className="table_container2" responsive="md" bordered>
                  <thead>
                    <tr>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Action Code
                      </th>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Action StatusCode
                      </th>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Notes
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ backgroundColor: "whitesmoke" }}>
                        {this.state.action}
                      </td>
                      <td style={{ backgroundColor: "whitesmoke" }}>
                        {this.state.ActionStatuscode}
                      </td>
                      <td
                        style={{
                          backgroundColor: "whitesmoke",
                          whiteSpace: "normal",
                          textAlign: "justify",
                        }}
                      >
                        {this.state.Notes}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <Modal
            // style={{ width: "100%", height: "70%" }}
            show={this.state.loadingModal}
            onHide={this.cpttoggle}
            // onExit={this.reset}
            // centered
            animation="false"
            size="lg"
            centered
          >
            <Modal.Header closeLabel="" closeButton>
              <Modal.Title>Check Status</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                "max-height": "calc(100vh - 210px)",
                "overflow-y": "auto",
              }}
            >
            <Row>
            <div>
              <table className="viewtablename1">
                <tr className="datatablevalue">
                  <td className="dataheading">Name</td>
                  <td className="viewdata">{this.state.updateFirstName + " " + this.state.updateLastName }</td>
                  <td className="dataheading">DOB</td>
                  <td className="viewdata">{moment(this.state.updatePatientDOB).format("MM/DD/YYYY")}</td>
                </tr>

                <tr className="datatablevalue">
                  <td className="dataheading">Status</td>
                  <td className="viewdata">{this.state.updateClaimStatus}</td>
                  <td className="dataheading">DOS</td>
                  <td className="viewdata">{moment(this.state.updateBeginingDOS).format("MM/DD/YYYY")}</td>
                </tr>

                <tr className="datatablevalue">
                  <td className="dataheading">InsuranceName</td>
                  <td className="viewdata">{this.state.updatePripayer}</td>
                  <td className="dataheading">Insurance ID </td>
                  <td className="viewdata">{this.state.updatePayerId}</td>
                </tr>
                <tr className="datatablevalue">
                  <th className="dataheading">Claim Status</th>
                  <td colSpan={3} className="viewdata">
                    {this.state.updateCheckNumber}
                  </td>
                </tr>
                <tr className="datatablevalue">
                  <th className="dataheading">CPT </th>
                  <td colSpan={3} className="viewdata">   
                  </td>
                </tr>
                {this.state.cptcodearray.map((data,index)=> {
                  return(
                <tr className="datatablevalue">
                    <th className="dataheading"> {data[0]}</th>
                    <td colSpan={3} className="viewdata">   
                    {data[1]}
                    </td>
                  </tr>
                  )
                })
                }  
             
                <tr className="datatablevalue">
                  <td className="viewdata"></td>
                  <td className="viewdata"></td>
                  <td className="viewdata" style={{background:"#041c3c", color:"white"}} onClick={()=> this.RerunApis()}>Rerun</td>
                  <td className="viewdata" style={{background:"green", color:"white"}}>Add to Notes</td>
                </tr>
              </table>
            </div>
          </Row>
            </Modal.Body>
            <Modal.Footer>
              <button
                class="button-35"
                role="button"
                onClick={() => {
                  this.cpttoggle();
                }}
              >
                cancel
              </button>
            </Modal.Footer>
          </Modal>
          <Modal isOpen={this.state.loadingModal} centered>
            <ModalBody>
              <img
                src={loading}
                style={{ width: "200px", height: "200px", marginLeft: "30%" }}
              />
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}
export default AutoCheckAutoClaim;

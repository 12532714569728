import React, { Component } from "react";
import "antd/dist/antd.css";
import axios from "axios";
import { Row, Col, Table } from "reactstrap";
import Eligibility from "../../Files/Eligibility.xlsx";
import "../../Styles/data.css";
import "../../Styles/Data.scss";
import CO from "../../Images/imag.jpeg";
import arrow from "../../Images/arrow.gif";
import setting from "../../Images/setting.gif";
import { Button} from "reactstrap";
import Newsidebar from "../Dashboard/Newsidebar";
import Database from "../../Images/Database.svg";
import File from "../../Images/folder__file.jpg";
import {  DatePicker } from "antd";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { RiDeleteBin7Line } from "react-icons/ri";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { ProgressBar, Spinner } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";
import { ip } from "../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class DataUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deletemodal: false,
      file: null,
      type: "",
      date: "Oct 13",
      upload: [],
      fileUploaded: false,
      id: "",
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("clientid"),
      offset: 0,
      currentpage: 0,
      disabled: true,
      perPage: 5,
      filterdate: [],
      search: "",
      start: "",
      end: "",
      range: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
    };
    this.handlePageClick = this.handlePageClick.bind(this);
    this.fileInput = React.createRef();
  }

  // toogle modal for delete
  toggledelete = () => {
    this.setState({ deletemodal: !this.state.deletemodal });
  };
  DeleteModal() {
    swal({
      //title: "Are you sure?",
      text: "Are u sure do you want to delete?",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.deleterequests(this.state.id);
      }
    });
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getfiles();
      }
    );
  };
  togglemodal = () => {
    this.setState({
      fileUploaded: !this.state.fileUploaded,
    });
  };
  onFileChange = (e) => {
    this.setState({ file: e.target.files[0] });
  };

  onFileUpload = async (e) => {
    if (!this.state.file) {
      // Display an error message or handle the validation as needed
      toast.error("Please select a file before uploading", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      return;
    }
    this.timerpopup();
    this.setState({ fileUploaded: !this.state.fileUploaded });
    e.preventDefault();
    const value = new FormData();
    value.append("file", this.fileInput.current.files[0]);
    value.append("clientids", JSON.stringify(this.state.client));
    const headers = {
      "Content-Type": `multipart/form-data`,
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    await axios({
      method: "post",
      url: `${ip}/neweligibility/testeligibility`,
      data: value,
      headers: headers,
    })
      .then((response) => {
        //handle success
        if (response.status === 200) {
          this.setState({ fileUploaded: !this.state.fileUploaded });
          toast.success("File uploaded successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        //handle error
        this.setState({ fileUploaded: !this.state.fileUploaded });
        this.getfiles();
        if (err.response) {
          if (err.response.status === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 400) {
            toast.error("Please include all the fields in excel data", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };

  filterdate = async () => {
    this.setState({
      start: this.state.range[0].format("YYYY-MM-DD"),
      end: this.state.range[1].format("YYYY-MM-DD"),
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const req = await axios
      .get(
        `${ip}/upload/filter?from=${this.state.range[0].format(
          "YYYY-MM-DD"
        )}&to=${this.state.range[1].format("YYYY-MM-DD")}`,
        { headers: headers }
      )
      .then((req) => {
        this.setState({ search: 1 });
        const slice = req.data.files.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        this.setState({
          pageCount: Math.ceil(req.data.files.length / this.state.perPage),
          filterdata: slice,
        });
      })
      .catch((err) => {});
  };

  deleterequests = async (DataID) => {
    //api call to delete requests
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const req = axios
      .delete(`${ip}/upload/${DataID}`, {
        headers: headers,
      })
      .then((req) => {
        alert("File Deleted Successfully");

        const requests = this.state.upload.filter(
          (data) => data._id !== DataID
        );
        this.getfiles();
      })
      .catch((err) => {});
  };

  getfiles = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/upload/all`, { headers: headers })
      .then((res) => {
        const slice = res.data.files.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        this.setState({
          pageCount: Math.ceil(res.data.files.length / this.state.perPage),
          upload: slice,
        });
      })
      .catch((err) => {});
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  timerpopup = async () => {
    setTimeout(() => {
      this.setState({ disabled: !this.state.disabled });
    }, 20000);
  };
  componentDidMount() {
    
    this.getfiles();
  }

  render() {
    const selectionRange = {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    };
    return (
      <div>
      <Newsidebar name="Eligibility DataUpload" />
      <div className="align-row" style={{ overflow: "hidden" }}>
        <ToastContainer></ToastContainer>
        <div className="align-column">
          <div className="align-row items">
            <form method="post" style={{ width: "17%" }}>
              <div class="files">
                <input
                  type="file"
                  id="file"
                  required
                  ref={this.fileInput}
                  onChange={this.onFileChange}
                  style={{ display: "none" }}
                  accept=".xlsx"
                />
                <label for="file" className="filelabel">
                  {this.state.file ? (
                    <p align="center" style={{ color: "green" }}>
                      {this.state.file.name}
                    </p>
                  ) : (
                    <a className="dropfile">Select files here or browse</a>
                  )}
                </label>
              </div>
            </form>
          </div>
          <div className="boxcss">
            <Table className="tablewidth">
              <thead>
                <tr>
                  <div>
                    <Row>
                      <Col className="col-4">
                        <p
                          align="left"
                          style={{
                            marginLeft: "14%",
                            marginTop: "2%",
                            fontSize: "20px",
                          }}
                        >
                          List of upload details
                        </p>
                      </Col>
                      <Col className="col-4"></Col>

                      <Col className="col-1">
                        <Button
                          outline
                          color="secondary"
                          size="sm"
                          className="exportcss"
                        >
                          <Link
                            style={{ textDecoration: "none", color: "#a8adad" }}
                            to={Eligibility}
                            download="eligibility.xlsx"
                            target="_blank"
                          >
                            {" "}
                            Sample
                          </Link>
                        </Button>
                      </Col>
                      <Col className="col-1">
                        <Button
                          outline
                          color="secondary"
                          size="sm"
                          onClick={this.onFileUpload}
                          // onClick={this.togglemodal}
                          className="uploadcss"
                        >
                          Upload
                        </Button>
                      </Col>
                    </Row>
                    <hr />
                  </div>
                </tr>
              </thead>
              {this.state.search === "" ? (
                <tbody>
                  {this.state.upload &&
                    this.state.upload.map((data, index) => {
                      return (
                        <tr>
                          <td>
                            <Card>
                              <Row>
                                <Col className="col-1">
                                  <input
                                    type="checkbox"
                                    style={{ height: "79%", float: "left" }}
                                  ></input>
                                </Col>
                                <Col>
                                  <Row
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "90%",
                                    }}
                                  >
                                    {data.filename}
                                  </Row>
                                  <Row
                                    style={{ color: "grey", fontSize: "74%" }}
                                  >
                                    Office/Marketing
                                  </Row>
                                </Col>
                                <Col>
                                  <Row
                                    style={{
                                      color: "black",
                                      fontSize: "74%",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Unique claims: #{data.inserted}
                                  </Row>
                                </Col>
                                <Col>
                                  <Row
                                    style={{
                                      color: "black",
                                      fontSize: "74%",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Duplicate claims: #{data.notinserted}
                                  </Row>
                                </Col>
                                <Col>
                                  <Row
                                    style={{
                                      marginLeft: "88%",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Action
                                  </Row>
                                  <Row>
                                    <Col>
                                      <RiDeleteBin7Line
                                        style={{
                                          float: "right",
                                          marginRight: "5%",
                                        }}
                                        onClick={() => {
                                          this.setState(
                                            { id: data._id },
                                            () => {
                                              this.DeleteModal();
                                            }
                                          );
                                        }}
                                      ></RiDeleteBin7Line>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className="col-10"
                                  style={{
                                    marginTop: "1%",
                                    marginLeft: "50px",
                                  }}
                                >
                                  <ProgressBar
                                    now={100}
                                    style={{ height: "4.5px", width: "90%" }}
                                  />
                                </Col>
                                <Col
                                  className="col-2"
                                  style={{
                                    fontSize: "70%",
                                    marginTop: "4px",
                                    color: "grey",
                                    marginLeft: "-70px",
                                  }}
                                >
                                  <p>100% Complete</p>
                                </Col>
                              </Row>
                            </Card>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              ) : (
                <tbody>
                  {this.state.filterdata &&
                    this.state.filterdata.map((data, index) => {
                      return (
                        <tr>
                          <td>
                            <Card>
                              <Row>
                                <Col className="col-1">
                                  <input
                                    type="checkbox"
                                    style={{ height: "79%", float: "left" }}
                                  ></input>
                                </Col>
                                <Col>
                                  <Row
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "90%",
                                    }}
                                  >
                                    {data.filename}
                                  </Row>
                                  <Row
                                    style={{ color: "grey", fontSize: "74%" }}
                                  >
                                    Office/Marketing
                                  </Row>
                                </Col>
                                <Col>
                                  <Row
                                    style={{
                                      marginLeft: "88%",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Action
                                  </Row>
                                  <Row>
                                    <Col>
                                      <RiDeleteBin7Line
                                        style={{
                                          float: "right",
                                          marginRight: "5%",
                                        }}
                                        onClick={() => {
                                          this.setState(
                                            { id: data._id },
                                            () => {
                                              this.DeleteModal();
                                            }
                                          );
                                        }}
                                      ></RiDeleteBin7Line>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className="col-10"
                                  style={{
                                    marginTop: "1%",
                                    marginLeft: "50px",
                                  }}
                                >
                                  <ProgressBar
                                    now={100}
                                    style={{ height: "4.5px", width: "90%" }}
                                  />
                                </Col>
                                <Col
                                  className="col-2"
                                  style={{
                                    fontSize: "70%",
                                    marginTop: "4px",
                                    color: "grey",
                                    marginLeft: "-70px",
                                  }}
                                >
                                  <p>100% Complete</p>
                                </Col>
                              </Row>
                            </Card>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              )}
            </Table>
            {this.state.upload.length !== 0 ? (
              <div className="data-paginate">
                <ReactPaginate
                  previousLabel={<IoIosArrowBack />}
                  nextLabel={<IoIosArrowForward />}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            ) : (
              <p align="center">
                <br />
                No uploads found!
              </p>
            )}
          </div>

          {this.state.deletemodal && (
            <Modal
              isOpen={this.state.deletemodal}
              onHide={this.toggledelete}
              // onExit={this.reloadpage}
              centered
            >
              <ModalHeader toggle={this.toggledelete}>Delete Data</ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Row style={{ margin: "0px" }}>Do you want to delete this?</Row>

                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid #268da5",
                    color: "#268da5",
                    backgroundColor: "#fff",
                  }}
                  onClick={this.toggledelete}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "#268da5" }}
                  onClick={() => {
                    this.deleterequests(this.state.id);
                  }}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </div>
        <Modal
          isOpen={this.state.fileUploaded}
          title="File Upload"
          size="sm"
          centered
          onChange={this.timerpopup}
          style={{ maxWidth: "550px", maxHeight: "400px" }}
        >
          <ModalHeader>File Upload</ModalHeader>
          <ModalBody>
            <p align="left">
              Please wait for sometime...&nbsp;&nbsp;Your file is being
              uploaded!
            </p>
            <button className="upload-btn">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              &nbsp;&nbsp;Uploading
            </button>
          </ModalBody>
          <ModalFooter>
            {this.state.disabled === true ? (
              <button disabled={this.state.disabled}>Close</button>
            ) : (
              <button
                className="button__load"
                disabled={this.state.disabled}
                onClick={this.togglemodal}
              >
                Close
              </button>
            )}
          </ModalFooter>
        </Modal>
      </div>
      </div>
    );
  }
}
export default DataUpload;

import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../../Styles/configure.css";
import Select from "react-dropdown-select";
import Newsidebar from "../Dashboard/Newsidebar";
import {
  Row,
  Col,
  Form,
  Label,
  Input,
  Button,
  FormGroup,
} from "reactstrap";
import { Modal, Spinner, Table } from "react-bootstrap";
 
import { Card } from "react-bootstrap";
import { ip } from ".././Api";
import { faIcicles } from "@fortawesome/free-solid-svg-icons";
import { AiOutlineFolder } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import ReactPaginate from "react-paginate";
import loading from "../../Images/loader.gif";

export class ClaimSubCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status2: "",
      status1: "",
      query1: [],
      statuslist: [],
      searchtext1: "",
      searchdataresponse1: [],
      searchclaimdata1: [],
      addnewmodal: false,
      editmodal: false,
      deletemodal: false,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      role_type: sessionStorage.getItem("role_type"),
      status: "Active",
      sub: [],
      statuslist: [],
      selectedfile: "",
      uploading: false,
      offset: 0,
      currentpage: 0,
      perPage: 7,
      perPageweb: 7,
      pageCountweb: "",
      subcategory: "",
      id: "",
      updateSubcategory: "",
      updateStatus: "",
      createmodal: false,
    };
    this.fileInput = React.createRef();
  }
  openmodal = () => {
    this.setState({ addnewmodal: !this.state.addnewmodal });
  };

  openeditmodal = () => {
    this.setState({ editmodal: !this.state.editmodal });
  };

  opendeletemodal = () => {
    this.setState({ deletemodal: !this.state.deletemodal });
  };

  imageHandler = (e) => {
    this.setState({ selectedfile: e.target.files[0] });
  };
  addClaimSubCategory = async () => {
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      claimSubCategory: this.state.subcategory,
      status: this.state.status,
    };
    const res = await axios
      .post(`${ip}/claimsubcategory/create`, value, { headers: headers })
      .then((res) => {
        if (res) {
          this.setState({ createmodal: !this.state.createmodal });
          this.setState({ addnewmodal: !this.state.addnewmodal });
          this.getClaimSubcategory();
          toast.success("Claim subcategory created", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          this.openmodal();
          this.getClaimSubcategory();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  reset = (e) => {
    e.preventDefault();
    this.setState({
      updateDescErr: "",
      DescErr: "",
      claimfield1: "",
      claimvalue1: "",
      status1: [],
      status2: [],
      payervalue1: [],
      cptvalue1: [],
      searchdataresponse1: [],
      searchclaimdata1: [],
      searchtext1: "",
    });
  };
  updateClaimCategory = async () => {
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      claimSubCategory: this.state.updateSubcategory,
      status: this.state.updateStatus,
    };
    const res = await axios
      .put(`${ip}/claimsubcategory/${this.state.id} `, value, {
        headers: headers,
      })
      .then((res) => {
        if (res) {
          this.openeditmodal();
          this.getClaimSubcategory();
          this.setState({ createmodal: !this.state.createmodal });
          toast.info("Claim Subcategory updated", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          this.openeditmodal();
          this.getClaimSubcategory();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  deleteClaimCategory = async () => {
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = await axios
      .delete(`${ip}/claimsubcategory/${this.state.id} `, { headers: headers })
      .then((res) => {
        if (res) {
          this.opendeletemodal();
          this.getClaimSubcategory();
          this.setState({ createmodal: !this.state.createmodal });
          toast.error("Claim Subcategory deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          this.opendeletemodal();
          this.getClaimSubcategory();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  getquery = () => {
    var query_o;
    let query = [];
    if (this.state.status1.length !== 0) {
      query_o = `claimSubCategory=${this.state.status1}`;
      query.push(query_o);
    }
    if (this.state.status2.length !== 0) {
      query_o = `status=${this.state.status2}`;
      query.push(query_o);
    }
    if (query.length === 1) {
      this.state.query1.push(`${query[0]}`);
    }
    if (query.length === 2) {
      this.state.query1.push(`${query[0]}&${query[1]}`);
    }
  };
  filtersearch1 = (e) => {
    // e.preventDefault();
    this.setState({ query1: [] });
    this.getquery();
    let filtereddata = [];
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    if (this.state.query1.length !== 0) {
      let url = `${ip}/claimsubcategory/advancedmultiplefilter?${this.state.query1}`;
      const res = axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          filtereddata = res.data.claims;
          if (filtereddata.length !== 0) {
            this.setState({ searchtext1: 1 });
            const slice = filtereddata.slice(
              this.state.offsetweb,
              this.state.offsetweb + this.state.perPageweb
            );
            this.setState({
              searchdataresponse1: filtereddata,
              pageCountweb: Math.ceil(
                filtereddata.length / this.state.perPageweb
              ),
              searchclaimdata1: slice,
            });
          } else if (filtereddata.length === 0) {
            this.setState({
              searchtext1: 1,
              searchdataresponse1: [],
              searchclaimdata1: [],
            });
          }
        })
        .catch((err) => {});
    } else {
      alert("Select data to search");
    }
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getClaimSubcategory();
      }
    );
  };
  handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.filtersearch1();
      }
    );
  };
  getClaimSubcategory = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

    const res = await axios
      .get(`${ip}/claimsubcategory/all`, { headers: headers })
      .then((res) => {
        const slice = res.data.claimsubcategory.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        this.setState({
          pageCount: Math.ceil(
            res.data.claimsubcategory.length / this.state.perPage
          ),
          sub: slice,
        });
      })
      .catch((err) => {});
  };

  componentDidMount() {
    
    this.getClaimSubcategory();
  }
  render() {
    return (
      <div>
      <Newsidebar name="Claim Sub Category" />
      <div className="align-row" style={{overflow:"hidden"}}>
        <div className="align-column">
        {this.state.role_type === "Admin" && (
          <button
            className="addNew"
            onClick={this.openmodal}
          >
            Add Claim SubCategory
          </button>
        )}
          <Row style={{ height: "100%" }}>
            <Card
              style={{
                height: "97%",
                marginTop: "1%",
                marginLeft: "2%",
                width: "97%",
                background: "#FBFBFB",
                border: "none",
              }}
            >
              <Form>
                <Row style={{ marginTop: "2%" }}>
                  <Col style={{ marginLeft: "0%" }}>
                    <FormGroup>
                      <Label>Claim SubCategory</Label>
                      <Input
                        value={this.state.status1}
                        onChange={(e) => {
                          this.setState({ status1: e.target.value });
                        }}
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col style={{ marginLeft: "0%" }}>
                    <FormGroup>
                      <Label>Status</Label>
                      <Input
                        value={this.state.status2}
                        onChange={(e) => {
                          this.setState({ status2: e.target.value });
                        }}
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col>
                  <div class="btn-group" role="group">
                  <button
                  type="button"
                  class="btn"
                  style={{ backgroundColor: "#7DD657", color: "#fff" }}
                  onClick={(e) => {
                    this.setState({ tabname: "web" }, () => {
                      this.filtersearch1(e);
                    });
                  }}
                >
                  Search
                </button>
                <button
                type="button"
                class="btn"
                style={{ backgroundColor: "#211C6A" }}
                onClick={(e) => {
                  this.reset(e);
                }}
              >
                <IoIosRefresh
                  style={{
                    color: "#fff",
                    fontSize: "25px",
                  }}
                ></IoIosRefresh>
              </button>
                </div>
          
                  </Col>
             
                </Row>
              </Form>
            </Card>
          </Row>
          <br />
          <br />
          <ToastContainer></ToastContainer>
          
          <Modal show={this.state.addnewmodal} onHide={this.openmodal} centered>
            <Modal.Header closeButton closeLabel="">
              <Modal.Title>Add Claim SubCategory</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row>
                  <Col>
                    <Label className="required">Claim SubCategory</Label>
                    <Input
                      type="text"
                      value={this.state.subcategory}
                      onChange={(e) =>
                        this.setState({ subcategory: e.target.value })
                      }
                    ></Input>
                  </Col>
                  <Col>
                    <Label for="select" className="required">
                      Status
                    </Label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control form-select"
                      value={this.state.status}
                      onChange={(e) =>
                        this.setState({ status: e.target.value })
                      }
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </Input>
                  </Col>
                </Row>
                <br />
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{
                  border: "1px solid grey",
                  color: "black",
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  this.openmodal();
                  //   this.reset();
                }}
              >
                Cancel
              </Button>
              <Button
                style={{ backgroundColor: "black", color: "white" }}
                onClick={this.addClaimSubCategory}
              >
                Add
              </Button>
            </Modal.Footer>
          </Modal>
          {this.state.sub.length === 0 ? (
            <div class="bot-ui">
              <div class="bot-head">
                <div class="eyes-container">
                  <div class="to-left eye"></div>
                  <div class="to-right eye"></div>
                </div>
              </div>
              <div class="bot-body">
                <div class="analyzer"></div>
              </div>
              <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                No Data Found
              </p>
            </div>
          ) : (
            <div>
              {this.state.searchtext1 === "" ? (
                  <div style={{marginLeft:"2%"}}>
                    <Table bordered className="StandardTable">
                      <thead>
                        <tr>
                          <th>Claim SubCategory</th>
                          <th>Status</th>
                          {this.state.role_type === "Admin" && <th>Action</th>}
                        </tr>
                      </thead>
                      <tbody >
                        {this.state.sub.map((data, index) => (
                          <tr >
                            <td>{data.claimSubCategory}</td>
                            <td>{data.status}</td>
                            {this.state.role_type === "Admin" && (
                              <td>
                                <button
                                  className="Edit"
                                  onClick={() => {
                                    this.setState({
                                      id: data._id,
                                      updateStatus: data.status,
                                      updateSubcategory: data.claimSubCategory,
                                    });
                                    this.openeditmodal();
                                  }}
                                >
                                  Edit
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                  className="Delete"
                                  onClick={() => {
                                    this.setState({ id: data._id });
                                    this.opendeletemodal();
                                  }}
                                >
                                  Delete
                                </button>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <br />
                    <div>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
              ) : (
                <div>
                  {this.state.searchdataresponse1.length === 0 ? (
                    <div class="bot-ui">
                      <div class="bot-head">
                        <div class="eyes-container">
                          <div class="to-left eye"></div>
                          <div class="to-right eye"></div>
                        </div>
                      </div>
                      <div class="bot-body">
                        <div class="analyzer"></div>
                      </div>
                      <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                        No Data Found
                      </p>
                    </div>
                  ) : (
                    <div>
                      <div className="claimstatus-table">
                        <Table  bordered className="StandardTable">
                          <thead>
                            <tr>
                              <th>Claim SubCategory</th>
                              <th>Status</th>
                              {this.state.role_type === "Admin" && (
                                <th>Action</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.searchdataresponse1.map(
                              (data, index) => (
                                <tr>
                                  <td>{data.claimSubCategory}</td>
                                  <td>{data.status}</td>
                                  {this.state.role_type === "Admin" && (
                                    <td>
                                      <button
                                        className="Edit"
                                        onClick={() => {
                                          this.setState({
                                            id: data._id,
                                            updateStatus: data.status,
                                            updateSubcategory:
                                              data.claimSubCategory,
                                          });
                                          this.openeditmodal();
                                        }}
                                      >
                                        Edit
                                      </button>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <button
                                        className="Delete"
                                        onClick={() => {
                                          this.setState({ id: data._id });
                                          this.opendeletemodal();
                                        }}
                                      >
                                        Delete
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                        <br />
                        <div>
                          <ReactPaginate
                            previousLabel={<IoIosArrowBack />}
                            nextLabel={<IoIosArrowForward />}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.pageCountweb}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick1}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <Modal show={this.state.editmodal} onHide={this.openeditmodal} centered>
          <Modal.Header closeButton closeLabel="">
            <Modal.Title>Edit Claim SubCategory</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col>
                  <Label>Claim SubCategory</Label>
                  <Input
                    type="text"
                    value={this.state.updateSubcategory}
                    onChange={(e) =>
                      this.setState({ updateSubcategory: e.target.value })
                    }
                  ></Input>
                </Col>
                <Col>
                  <Label for="select">Status</Label>
                  <Input
                    type="select"
                    name="select"
                    className="form-control form-select"
                    value={this.state.updateStatus}
                    onChange={(e) =>
                      this.setState({ updateStatus: e.target.value })
                    }
                  >
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </Input>
                </Col>
              </Row>
              <br />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.openeditmodal();
                //   this.reset();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              onClick={this.updateClaimCategory}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.deletemodal}
          onHide={this.opendeletemodal}
          centered
        >
          <Modal.Header closeButton closeLabel="">
            <Modal.Title>Delete Claim SubCategory</Modal.Title>
          </Modal.Header>
          <Modal.Body>Do you want to delete this claim subcategory?</Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.opendeletemodal();
                //   this.reset();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              onClick={this.deleteClaimCategory}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.createmodal} centered>
          <Modal.Body>
            <img
              src={loading}
              style={{ width: "200px", height: "200px", marginLeft: "30%" }}
            />
          </Modal.Body>
        </Modal>
      </div>
      </div>
    );
  }
}

export default ClaimSubCategory;

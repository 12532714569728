import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./Components/App";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { FileUploadProvider } from "./Components/Data/FileUploadContext";

ReactDOM.render(
  <FileUploadProvider>
    <App />
  </FileUploadProvider>,
  document.getElementById('root')
);

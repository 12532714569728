import { React, Component } from "react";
import Newsidebar from "../../Dashboard/Newsidebar";
import "../../../Styles/subclaim.css";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import axios from "axios";
import { ip } from "../.././Api";
class Pending extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: sessionStorage.getItem("queryurl"),
      client: sessionStorage.getItem("queryclient"),
      Location: sessionStorage.getItem("queryLocation"),
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      querystartdate: sessionStorage.getItem("querystartdate"),
      queryenddate: sessionStorage.getItem("queryenddate"),
      subcategoryvalue: null,
      subcategoryvalue: null,
      detailstotalamt: "",
      detailstotalcnt: "",
      inprocesstotalamt: "",
      show2: sessionStorage.getItem("queryclaimid"),
      inprocesstotalcnt: "",
      prtotalamt: "",
      prtotalcnt: "",
      pritotalamt: "",
      pritotalcnt: "",
      patienttotalamt: "",
      patienttoalcnt: "",
      admintotalamt: "",
      admintotalcnt: "",
    };
  }

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  getpendingdata = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.queryenddate && this.state.querystartdate) {
      url = `${ip}/claimstatus/subcategory?${this.state.value}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
    } else {
      url = `${ip}/claimstatus/subcategory?${this.state.value}`;
    }
    const res = await axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({ subcategoryvalue: res.data.data });
        if (res.data.data.length !== 0) {
          res.data.data.map((data, index) => {
            switch (data._id) {
              case "P0":
                return this.setState({
                  detailstotalamt: Math.round(
                    data.totalamount
                  ).toLocaleString(),
                  detailstotalcnt: data.totalcount,
                });
              case "P1":
                return this.setState({
                  inprocesstotalamt: Math.round(
                    data.totalamount
                  ).toLocaleString(),
                  inprocesstotalcnt: data.totalcount,
                });
              case "P2":
                return this.setState({
                  prtotalamt: Math.round(data.totalamount).toLocaleString(),
                  prtotalcnt: data.totalcount,
                });
              case "P3":
                return this.setState({
                  pritotalamt: Math.round(data.totalamount).toLocaleString(),
                  pritotalcnt: data.totalcount,
                });
              case "P4":
                return this.setState({
                  patienttoalcnt: data.totalcount,
                  patienttotalamt: Math.round(
                    data.totalamount
                  ).toLocaleString(),
                });
              case "P5":
                return this.setState({
                  admintotalcnt: data.totalcount,
                  admintotalamt: Math.round(data.totalamount).toLocaleString(),
                });
            }
          });
        }
      })
      .catch((err) => {});
  };

  componentDidMount() {
    
    this.getpendingdata();
  }
  render() {
    return (
      <div className="align-row" style={{ overflow: "hidden" }}>
        <Newsidebar>
        <p>
        <Link
          to="/claim-management"
          style={{ textDecoration: "none", color: "white",fontSize: "15px",
          fontWeight: 500 }}
        >
          Claim Status Management
        </Link>
        &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;Pending
      </p>
        </Newsidebar>
        <br/>
        <div className="align-column">
          <div className="row" style={{ marginLeft: "1%" }}>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // Details card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem("querysub", "Details");
                        sessionStorage.setItem("subclaimname", "pendingp0");
                        sessionStorage.setItem("queryCategoryCode", "P0");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P0&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem("querysub", "Details");
                        sessionStorage.setItem("subclaimname", "pendingp0");
                        sessionStorage.setItem("queryCategoryCode", "P0");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P0&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem("querysub", "Details");
                        sessionStorage.setItem("subclaimname", "pendingp0");
                        sessionStorage.setItem("queryCategoryCode", "P0");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P0&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem("querysub", "Details");
                        sessionStorage.setItem("subclaimname", "pendingp0");
                        sessionStorage.setItem("queryCategoryCode", "P0");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P0&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem("querycategory", "Pending");
                      sessionStorage.setItem("querysub", "Details");
                      sessionStorage.setItem("subclaimname", "pendingp0");
                      sessionStorage.setItem("queryCategoryCode", "P0");
                      sessionStorage.setItem("queryCategoryname", "pending");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=pending&statusCategoryCode=P0&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem("querycategory", "Pending");
                      sessionStorage.setItem("querysub", "Details");
                      sessionStorage.setItem("subclaimname", "pendingp0");
                      sessionStorage.setItem("queryCategoryCode", "P0");
                      sessionStorage.setItem("queryCategoryname", "pending");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=pending&statusCategoryCode=P0`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white"
            >
            <div
            style={{
              background: this.state.show2 === "P0" ?"radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" :   "linear-gradient(to right, #002755 65%, #EB1A23)",  padding:"3px",color:"white"
            }}>
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Details
                  </span>
                  </div>
                  <div class="card-text">
                  <Link
                    to={{
                      pathname: "/claim/sub-category-details",
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <Row>
                      <Col className="subclaim-description">
                        This is a generic message about a pended claim.
                      </Col>
                    </Row>
                    <Row>
                      <Col className="sub-totalclaim">
                        Total Claims:
                        <span>
                          <div
                            className="to"
                            style={{
                              color: "black",
                              fontWeight: "600",
                              fontSize: "19px",
                            }}
                          >
                            {" "}
                            {this.state.detailstotalcnt}
                          </div>
                        </span>
                      </Col>
                      <Col className="sub-total">
                        Grand Total:
                        <span>
                          {" "}
                          <div
                            style={{
                              color: "black",
                              fontSize: "19px",
                              fontWeight: "600",
                            }}
                          >
                            $ {this.state.detailstotalamt}
                          </div>
                        </span>
                      </Col>
                    </Row>
                  </Link>
                </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // In process card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem("querysub", "In Process");
                        sessionStorage.setItem("queryCategoryCode", "P1");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem("subclaimname", "pendingp1");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P1&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem("querysub", "In Process");
                        sessionStorage.setItem("queryCategoryCode", "P1");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem("subclaimname", "pendingp1");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P1&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem("querysub", "In Process");
                        sessionStorage.setItem("subclaimname", "pendingp1");
                        sessionStorage.setItem("queryCategoryCode", "P1");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P1&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem("querysub", "In Process");
                        sessionStorage.setItem("subclaimname", "pendingp1");
                        sessionStorage.setItem("queryCategoryCode", "P1");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P1&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem("querycategory", "Pending");
                      sessionStorage.setItem("querysub", "In Process");
                      sessionStorage.setItem("queryCategoryCode", "P1");
                      sessionStorage.setItem("queryCategoryname", "pending");
                      sessionStorage.setItem("subclaimname", "pendingp1");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=pending&statusCategoryCode=P1&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem("querycategory", "Pending");
                      sessionStorage.setItem("querysub", "In Process");
                      sessionStorage.setItem("subclaimname", "pendingp1");
                      sessionStorage.setItem("queryCategoryCode", "P1");
                      sessionStorage.setItem("queryCategoryname", "pending");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=pending&statusCategoryCode=P1`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white"
            >
            <div
            style={{
              background: this.state.show2 === "P1" ?"radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" :   "linear-gradient(to right, #002755 65%, #EB1A23)",  padding:"3px",color:"white"
            }}>
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    In Process
                  </span>
                  </div>
                  <div class="card-text">
                  <Link
                    to={{
                      pathname: "/claim/sub-category-details",
                      state: {
                        query: `ClaimCategory=pending&statusCategoryCode=P1&clientId=${this.props.location.client}`,
                        category_name: "Pending",
                        sub_name: "In Process",
                      },
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <Row>
                      <Col className="subclaim-description">
                        The claim or encounter is in the adjudication system.
                      </Col>
                    </Row>
                    <Row>
                      <Col className="sub-totalclaim">
                        Total Claims:
                        <span>
                          <div
                            className="to"
                            style={{
                              color: "black",
                              fontWeight: "600",
                              fontSize: "19px",
                            }}
                          >
                            {" "}
                            {this.state.inprocesstotalcnt}
                          </div>
                        </span>
                      </Col>
                      <Col className="sub-total">
                        Grand Total:
                        <span>
                          {" "}
                          <div
                            style={{
                              color: "black",
                              fontSize: "19px",
                              fontWeight: "600",
                            }}
                          >
                            $ {this.state.inprocesstotalamt}
                          </div>
                        </span>
                      </Col>
                    </Row>
                  </Link>
                </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // In process card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem("queryCategoryCode", "P2");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem("querysub", "Payer Review");
                        sessionStorage.setItem("subclaimname", "pendingp2");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P2&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem("querysub", "Payer Review");
                        sessionStorage.setItem("queryCategoryCode", "P2");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem("subclaimname", "pendingp2");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P2&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem("querysub", "Payer Review");
                        sessionStorage.setItem("subclaimname", "pendingp2");
                        sessionStorage.setItem("queryCategoryCode", "P2");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P2&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem("querysub", "Payer Review");
                        sessionStorage.setItem("subclaimname", "pendingp2");
                        sessionStorage.setItem("queryCategoryCode", "P2");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P2&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem("querycategory", "Pending");
                      sessionStorage.setItem("querysub", "Payer Review");
                      sessionStorage.setItem("subclaimname", "pendingp2");
                      sessionStorage.setItem("queryCategoryCode", "P2");
                      sessionStorage.setItem("queryCategoryname", "pending");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=pending&statusCategoryCode=P2&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem("querycategory", "Pending");
                      sessionStorage.setItem("querysub", "Payer Review");
                      sessionStorage.setItem("queryCategoryCode", "P2");
                      sessionStorage.setItem("queryCategoryname", "pending");
                      sessionStorage.setItem("subclaimname", "pendingp2");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=pending&statusCategoryCode=P2`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white"
            >
            <div
            style={{
              background: this.state.show2 === "P2" ?"radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" :   "linear-gradient(to right, #002755 65%, #EB1A23)",  padding:"3px",color:"white"
            }}>
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Payer Review
                  </span>
                  </div>
                  <div class="card-body">
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          The claim/encounter is suspended and is pending review
                        </Col>
                      </Row>
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {/* {this.state.subcategoryvalue &&
                            this.state.subcategoryvalue[2].totalcount} */}
                              {this.state.prtotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              ${" "}
                              {/* {this.state.subcategoryvalue &&
                            this.state.subcategoryvalue[2].totalamount} */}
                              {this.state.prtotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // provider requested information card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem(
                          "querysub",
                          "Provider Requested Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "P3");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem("subclaimname", "pendingp3");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P3&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem(
                          "querysub",
                          "Provider Requested Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "P3");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem("subclaimname", "pendingp3");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P3&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem(
                          "querysub",
                          "Provider Requested Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "P3");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem("subclaimname", "pendingp3");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P3&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem(
                          "querysub",
                          "Provider Requested Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "P3");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem("subclaimname", "pendingp3");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P3&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem("querycategory", "Pending");
                      sessionStorage.setItem(
                        "querysub",
                        "Provider Requested Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "P3");
                      sessionStorage.setItem("queryCategoryname", "pending");
                      sessionStorage.setItem("subclaimname", "pendingp3");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=pending&statusCategoryCode=P3&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem("querycategory", "Pending");
                      sessionStorage.setItem(
                        "querysub",
                        "Provider Requested Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "P3");
                      sessionStorage.setItem("queryCategoryname", "pending");
                      sessionStorage.setItem("subclaimname", "pendingp3");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=pending&statusCategoryCode=P3`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white"
            >
            <div
            style={{
              background: this.state.show2 === "P3" ?"radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" :   "linear-gradient(to right, #002755 65%, #EB1A23)",  padding:"3px",color:"white"
            }}>
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Provider Requested Information
                  </span>
                  </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          The claim or encounter is waiting for information that
                          has already been requested from the provider.
                        </Col>
                      </Row>
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {" "}
                              {this.state.pritotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.pritotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 mb-3">
              <div
                class="card"
                // patient requested information card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem(
                          "querysub",
                          "Patient Requested Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "P4");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem("subclaimname", "pendingp4");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P4&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem(
                          "querysub",
                          "Patient Requested Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "P4");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem("subclaimname", "pendingp4");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P4&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem(
                          "querysub",
                          "Patient Requested Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "P4");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem("subclaimname", "pendingp4");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P4&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem(
                          "querysub",
                          "Patient Requested Information"
                        );
                        sessionStorage.setItem("queryCategoryCode", "P4");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem("subclaimname", "pendingp4");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P4&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem("querycategory", "Pending");
                      sessionStorage.setItem(
                        "querysub",
                        "Patient Requested Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "P4");
                      sessionStorage.setItem("queryCategoryname", "pending");
                      sessionStorage.setItem("subclaimname", "pendingp4");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=pending&statusCategoryCode=P4&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem("querycategory", "Pending");
                      sessionStorage.setItem(
                        "querysub",
                        "Patient Requested Information"
                      );
                      sessionStorage.setItem("queryCategoryCode", "P4");
                      sessionStorage.setItem("queryCategoryname", "pending");
                      sessionStorage.setItem("subclaimname", "pendingp4");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=pending&statusCategoryCode=P4`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white"
            >
            <div
            style={{
              background: this.state.show2 === "P4" ?"radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" :   "linear-gradient(to right, #002755 65%, #EB1A23)",  padding:"3px",color:"white"
            }}>
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Patient Requested Information
                  </span>
                  </div>
                  <div class="card-text">
                  <Link
                    to={{
                      pathname: "/claim/sub-category-details",
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <Row>
                      <Col className="subclaim-description">
                        The claim or encounter is waiting for information that
                        has already been requested from the patient.
                      </Col>
                    </Row>
                    <Row>
                      <Col className="sub-totalclaim">
                        Total Claims:
                        <span>
                          <div
                            className="to"
                            style={{
                              color: "black",
                              fontWeight: "600",
                              fontSize: "19px",
                            }}
                          >
                            {" "}
                            {this.state.patienttoalcnt}
                          </div>
                        </span>
                      </Col>
                      <Col className="sub-total">
                        Grand Total:
                        <span>
                          {" "}
                          <div
                            style={{
                              color: "black",
                              fontSize: "19px",
                              fontWeight: "600",
                            }}
                          >
                            $ {this.state.patienttoalcnt}
                          </div>
                        </span>
                      </Col>
                    </Row>
                  </Link>
                </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // payer admin card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem(
                          "querysub",
                          "Payer Administrative"
                        );
                        sessionStorage.setItem("queryCategoryCode", "P5");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem("subclaimname", "pendingp5");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P5&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem(
                          "querysub",
                          "Payer Administrative"
                        );
                        sessionStorage.setItem("queryCategoryCode", "P5");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem("subclaimname", "pendingp5");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P5&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem(
                          "querysub",
                          "Payer Administrative"
                        );
                        sessionStorage.setItem("queryCategoryCode", "P5");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem("subclaimname", "pendingp5");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P5&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem("querycategory", "Pending");
                        sessionStorage.setItem(
                          "querysub",
                          "Payer Administrative"
                        );
                        sessionStorage.setItem("queryCategoryCode", "P5");
                        sessionStorage.setItem("queryCategoryname", "pending");
                        sessionStorage.setItem("subclaimname", "pendingp5");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=pending&statusCategoryCode=P5&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem("querycategory", "Pending");
                      sessionStorage.setItem(
                        "querysub",
                        "Payer Administrative"
                      );
                      sessionStorage.setItem("queryCategoryCode", "P5");
                      sessionStorage.setItem("queryCategoryname", "pending");
                      sessionStorage.setItem("subclaimname", "pendingp5");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=pending&statusCategoryCode=P5&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem("querycategory", "Pending");
                      sessionStorage.setItem(
                        "querysub",
                        "Payer Administrative"
                      );
                      sessionStorage.setItem("queryCategoryCode", "P5");
                      sessionStorage.setItem("queryCategoryname", "pending");
                      sessionStorage.setItem("subclaimname", "pendingp5");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=pending&statusCategoryCode=P5`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white"
            >
            <div
            style={{
              background: this.state.show2 === "P5" ?"radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" :   "linear-gradient(to right, #002755 65%, #EB1A23)",  padding:"3px",color:"white"
            }}>
                  <span class="d-inline" style={{ fontWeight: "bold" }}>
                    Payer Administrative
                  </span>
                  </div>   
                   <div class="card-text">
                  <Link
                    to={{
                      pathname: "/claim/sub-category-details",
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <br />
                    <br />
                    <Row>
                      <Col className="sub-totalclaim">
                        Total Claims:
                        <span>
                          <div
                            className="to"
                            style={{
                              color: "black",
                              fontWeight: "600",
                              fontSize: "19px",
                            }}
                          >
                            {" "}
                            {this.state.admintotalcnt}
                          </div>
                        </span>
                      </Col>
                      <Col className="sub-total">
                        Grand Total:
                        <span>
                          {" "}
                          <div
                            style={{
                              color: "black",
                              fontSize: "19px",
                              fontWeight: "600",
                            }}
                          >
                            $ {this.state.admintotalamt}
                          </div>
                        </span>
                      </Col>
                    </Row>
                  </Link>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Pending;

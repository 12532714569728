import React, { Component } from "react";
import axios from "axios";
import "../../Styles/admin.css";
import "../../Styles/configure.css";
import "../../Styles/PayerMaster.css";
import Newsidebar from "../Dashboard/Newsidebar";
import { Row, Col } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import loading from "../../Images/loader.gif";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import { MdOutlineSearch } from "react-icons/md";
import { Table } from "react-bootstrap";
import { ip } from ".././Api";
import "../../Styles/typetext.css";
class PayerCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      updatemodal: false,
      deletemodal: false,
      dataforupdate: null,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      role_type: sessionStorage.getItem("role_type"),
      payer: [],
      searchclaimdata: [],
      searchdataresponse: [],
      updatedayscount: "",
      categoryId: "",
      name: "",
      description: "",
      searchBy: "category_id",
      querySearch: "",
      searchtext: "",
      provider: "",
      insurance: "",
      authorization: "",
      write: "",
      status: "",
      updatecategoryId: "",
      updatename: "",
      updatedescription: "",
      updateprovider: "",
      updateinsurance: "",
      updateauthorization: "Yes",
      updatewrite: "",
      updatestatus: "Enabled",
      categoryIdErr: "",
      nameErr: "",
      descriptionErr: "",
      providerErr: "",
      insuranceErr: "",
      dayscount: "",
      writeErr: "",
      singleid: "",
      offset: 0,
      currentpage: 0,
      perPage: 10,
      createmodal: false,
      statusErr:"",
      authErr:"",
      idErr:"",
      daysErr:"",
      clientid: sessionStorage.getItem("clientid"),
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  toggle = () => {
    const { modal } = this.state;
    this.setState({
      modal: !modal,
      categoryId: "",
      status: "",
      authorization: "",
      write: "",
      insurance: "",
      provider: "",
      description: "",
      dayscount: "",
      name: "",
      nameErr:"",
      authErr:"",
      idErr:"",
      statusErr:"",
      daysErr:""
    });
  };
  // toogle modal for delete
  toggledelete = () => {
    this.setState({ deletemodal: !this.state.deletemodal });
  };
  //toggle modal for update
  toggleupdate = () => {
    this.setState({ updatemodal: !this.state.updatemodal });
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getpayer();
      }
    );
  };
  handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.handleSearch();
      }
    );
  };
  handleSubmit = async () => {
    const isValid = this.validate();
    if (isValid) {
      this.setState({ createmodal: !this.state.createmodal });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        category_id: this.state.categoryId,
        category_name: this.state.name,
        description: this.state.description,
        billing_provider: this.state.provider,
        insurance_adjustment: this.state.insurance,
        preauthorization: this.state.authorization,
        write_off: this.state.write,
        status: this.state.status,
        claimFollowupDays: parseInt(this.state.dayscount),
        clientId: this.state.clientid,
      };
      // eslint-disable-next-line
      const res = await axios
        .post(`${ip}/payercategory/create`, value, {
          headers: headers,
        })
        .then((res) => {
          if (res) {
            this.toggle();
            this.setState({ createmodal: !this.state.createmodal });
            this.getpayer();
            toast.success("Payer category created", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            this.toggle();
            this.getpayer();
            this.setState({ createmodal: !this.state.createmodal });
            if (err.response.data.statusCode === 500) {
              toast.error("Server Error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else if (err.response.data.statusCode === 401) {
              toast.error("Please reload and try again", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else {
              toast.error("An unknown error occured", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }
          }
        });
    }
  };

  // fuction for prepopulate the particular data
  settingupdatestate = function () {
    this.setState({
      updatecategoryId: this.state.dataforupdate.category_id,
      updatename: this.state.dataforupdate.category_name,
      updatedescription: this.state.dataforupdate.description,
      updateprovider: this.state.dataforupdate.billing_provider,
      updateinsurance: this.state.dataforupdate.insurance_adjustment,
      updatewrite: this.state.dataforupdate.write_off,
      updateauthorization: this.state.dataforupdate.preauthorization,
      updatestatus: this.state.dataforupdate.status,
      singleid: this.state.dataforupdate._id,
      updatedayscount: this.state.dataforupdate.claimFollowupDays,
    });
  };

  putrequests = async (dataid) => {
    // const isValid = this.updatevalidate();

    // if (isValid) {
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      category_id: this.state.updatecategoryId,
      category_name: this.state.updatename,
      description: this.state.updatedescription,
      billing_provider: this.state.updateprovider,
      insurance_adjustment: this.state.updateinsurance,
      preauthorization: this.state.updateauthorization,
      write_off: this.state.updatewrite,
      status: this.state.updatestatus,
      claimFollowupDays: parseInt(this.state.updatedayscount),
      clientId: this.state.clientid,
    };
    // eslint-disable-next-line
    const res = await axios
      .put(`${ip}/payercategory/${dataid}`, value, {
        headers: headers,
      })
      .then((res) => {
        if (res) {
          this.setState({ createmodal: !this.state.createmodal });
          this.toggleupdate();
          this.getpayer();
          toast.info("Payer category updated", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          this.getpayer();
          this.toggleupdate();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };

  deleterequests = async (DataID) => {
    //api call to delete requests
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const req = axios
      .delete(`${ip}/payercategory/${DataID}`, {
        headers: headers,
      })
      .then((req) => {
        if (req) {
          this.setState({ createmodal: !this.state.createmodal });
          this.toggledelete();
          this.getpayer();
          toast.error("Payer category deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          this.getpayer();
          this.toggledelete();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  //filter by  Payer category
  handleSearch = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    if (this.state.querySearch === "") {
      toast.error("Please fill the search value!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const res = await axios
        .get(
          `${ip}/payercategory/advancedFilter?${this.state.searchBy}=${this.state.querySearch}`,
          { headers: headers }
        )
        .then((res) => {
          if (res) {
            toast.success("Searching completed!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.warning("Fetching data", {
              position: "top-right",
              hideProgressBar: false,
              pauseOnHover: true,
              progress: true,
            });
          }
          this.setState({ searchtext: 1 });
          this.setState({ search_result: res.data.payer });
          const slice = res.data.payer.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );
          this.setState({
            searchdataresponse: res.data.payer,
            pageCount: Math.ceil(res.data.payer.length / this.state.perPage),
            searchclaimdata: slice,
          });
        })
        .catch((err) => {});
    }
  };
  //getpayer
  getpayer = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if(this.state.clientid){
      url = `${ip}/payercategory/all?clientId=${this.state.clientid}`
    }else{
      url = `${ip}/payercategory/all`
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        const slice = res.data.payer.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        this.setState({
          pageCount: Math.ceil(res.data.payer.length / this.state.perPage),
          payer: slice,
        });
      })
      .catch((err) => {});
  };

  validate = () => {
    let hasErr = "true";

    let nameErr = "";
    let statusErr = "";
    let authErr = "";
    let idErr="";
    let daysErr = "";
    if (this.state.name === "") {
      nameErr = "This field is required";
      this.setState({ nameErr });
      hasErr = false;
    }
    if (this.state.status === "") {
      statusErr = "This field is required";
      this.setState({ statusErr });
      hasErr = false;
    }
    if (this.state.authorization === "") {
      authErr = "This field is required";
      this.setState({ authErr });
      hasErr = false;
    }
    if (this.state.categoryId === "") {
      idErr = "This field is required";
      this.setState({ idErr });
      hasErr = false;
    }
    if (this.state.dayscount === "") {
      daysErr = "This field is required";
      this.setState({ daysErr });
      hasErr = false;
    }
    return hasErr;
  };

  updatevalidate = () => {
    let hasErr = "true";
    let categoryIdErr = "";
    let nameErr = "";
    let providerErr = "";
    let insuranceErr = "";
    let writeErr = "";
    let descriptionErr = "";

    if (this.state.updatecategoryId === "") {
      categoryIdErr = "This field is required";
      this.setState({ categoryIdErr });
      hasErr = false;
    }
    if (this.state.updatename === "") {
      nameErr = "This field is required";
      this.setState({ nameErr });
      hasErr = false;
    }
    if (this.state.updatedescription === "") {
      descriptionErr = "This field is required";
      this.setState({ descriptionErr });
      hasErr = false;
    }
    if (this.state.updateprovider === "") {
      providerErr = "This field is required";
      this.setState({ providerErr });
      hasErr = false;
    }
    if (this.state.updateinsurance === "") {
      insuranceErr = "This field is required";
      this.setState({ insuranceErr });
      hasErr = false;
    }
    if (this.state.updatewrite === "") {
      writeErr = "This field is required";
      this.setState({ writeErr });
      hasErr = false;
    }

    return hasErr;
  };

  componentDidMount() {
    
    this.getpayer();
  }
  reset = () => {
    toast.info("All values displayed now!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
    });
    this.setState({
      searchBy: "payer_id",
      querySearch: "",
      searchdataresponse: [],
      searchclaimdata: [],
      searchtext: "",
    });
    this.getpayer();
  };
  reset = () => {
    this.setState({
      categoryId: "",
      name: "",
      description: "",
      provider: "",
      insurance: "",
      authorization: "Yes",
      write: "",
      status: "Enabled",
      categoryIdErr: "",
      nameErr: "",
      descriptionErr: "",
      providerErr: "",
      insuranceErr: "",
      writeErr: "",
    });
  };
  reset = () => {
    this.setState({
      searchBy: "category_id",
      querySearch: "",
      searchdataresponse: [],
      searchclaimdata: [],
      searchtext: "",
    });
    this.getpayer();
  };
  render() {
    const numbers = Array.from({ length: 100 }, (_, index) => index + 1);
    return (
      <div>
      <Newsidebar name="Payer Category" />
      <div className="align-row" style={{ overflow: "hidden"}}>
        <div className="align-column">
          <ToastContainer></ToastContainer>
          <br/>
          <div style={{display:"flex",  justifyContent:"space-between"}}>
            <div className="top__search">
              <div className="form__group">
                <select
                  className="drop-down__one"
                  value={this.state.searchBy}
                  onChange={(e) => this.setState({ searchBy: e.target.value })}
                >
                  <option value="category_id">Category ID</option>
                  <option value="category_name">Payer Category</option>
                </select>
                <div className="main__search">
                  <input
                    type="text"
                    className="search__input"
                    value={this.state.querySearch}
                    onChange={(e) => {
                      this.setState({ querySearch: e.target.value });
                    }}
                  ></input>
                </div>
                <button 
                style={{
                  border:"none",
                  padding:"3px",
                  backgroundColor:"#041c3c"
                }}
                onClick={this.handleSearch}>
                <MdOutlineSearch 
                style={{
                  color: "#fff",
                  fontSize: "25px",        
                }}
                />
                </button>
                <button 
                onClick={this.reset}>
                  <IoIosRefresh
                    style={{
                      color: "#041c3c",
                      fontSize: "25px",
                    }}
                  ></IoIosRefresh>
                </button>
              </div>
            </div>
          <div>
            <div>
              <button type="button"
              class="btn"
              style={{
                backgroundColor: "#7DD657",
                color: "#fff",
                fontSize: "12px",
              }} onClick={this.toggle}>
                Add New Category
              </button>
            </div>
          </div>
          </div>
              <br/>
          <div style={{ height: "100%", width: "100%" }}>
            {this.state.payer.length !== 0 ? (
              <div>
              {this.state.searchtext === "" ? (
                <div
                  style={{
                    marginLeft: "1%",
                    marginRight: "4%",
                  }}
                >
                  <Table bordered 
                  className="StandardTable"
                  >
                    <thead>
                      <tr
                        className="table-primary"
                        style={{ textAlign: "center" }}
                      >
                        <th className="text-center">Category ID</th>
                        <th>Claim Followup Days</th>
                        <th>Category Name</th>
                        <th>Description</th>
                        <th>Billing Provider</th>
                        <th>Adjustment%</th>
                        <th>Write Off%</th>
                        <th>Pre Auth</th>
                        <th>Status</th>
                       <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {rows.map((row) => ( */}
                      {this.state.payer &&
                        this.state.payer.map((data, index) => (
                          <tr key={data._id}>
                            <td
                              className="text-center"
                              component="th"
                              scope="row"
                            >
                              {data.category_id}
                            </td>
                            <td
                              className="text-center"
                              component="th"
                              scope="row"
                            >
                              {data.claimFollowupDays}
                            </td>
                            <td className="text-center">
                              {data.category_name}
                            </td>
                            <td className="text-center">
                              {data.description}
                            </td>
                            <td className="text-center">
                              {data.billing_provider}
                            </td>
                            <td className="text-center">
                              {data.insurance_adjustment}
                            </td>
                            <td className="text-center">{data.write_off}</td>
                            <td className="text-center">
                              {data.preauthorization}
                            </td>
                            <td className="text-center">{data.status}</td>
                              <td className="text-center">
                                <button
                                  className="Edit"
                                  onClick={() => {
                                    this.setState(
                                      { dataforupdate: data },
                                      () => {
                                        this.settingupdatestate();
                                        this.toggleupdate();
                                      }
                                    );
                                  }}
                                >
                                  Edit
                                </button>
                                &nbsp;
                              </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                    <ReactPaginate
                      previousLabel={<IoIosArrowBack />}
                      nextLabel={<IoIosArrowForward />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  {this.state.searchdataresponse.length === 0 ? (
                    <div>
                      <p align="center">No data found</p>
                    </div>
                  ) : (
                    <div
                      style={{
                        marginLeft: "4%",
                        marginRight: "4%",
                      }}
                    >
                      <Table bordered striped>
                        <thead>
                          <tr className="table-primary">
                            <td>Category ID</td>
                            <td>Category Name</td>
                            <td>Description</td>
                            <td>Billing Provider</td>
                            <td>Adjustment%</td>
                            <td>Write Off%</td>
                            <td>Pre Auth</td>
                            <td>Status</td>
                              <td>Action</td>
                          </tr>
                        </thead>
                        <tbody>
                          {/* {rows.map((row) => ( */}
                          {this.state.searchclaimdata &&
                            this.state.searchclaimdata.map((data, index) => (
                              <tr key={data._id}>
                                <td component="th" scope="row">
                                  {data.category_id}
                                </td>
                                <td>{data.category_name}</td>
                                <td>{data.description}</td>
                                <td>{data.billing_provider}</td>
                                <td>{data.insurance_adjustment}</td>
                                <td>{data.write_off}</td>
                                <td>{data.preauthorization}</td>
                                <td>{data.status}</td>
                                  <td>
                                    <button
                                      className="Edit"
                                      onClick={() => {
                                        this.setState(
                                          { dataforupdate: data },
                                          () => {
                                            this.settingupdatestate();
                                            this.toggleupdate();
                                          }
                                        );
                                      }}
                                    >
                                      Edit
                                    </button>
                                    &nbsp;
                                    <button
                                      className="Delete"
                                      onClick={() => {
                                        this.setState(
                                          { singleid: data._id },
                                          () => {
                                            this.toggledelete();
                                          }
                                        );
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </td>
                              </tr>
                              // ))}
                            ))}
                        </tbody>
                      </Table>
                      <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                        <ReactPaginate
                          previousLabel={<IoIosArrowBack />}
                          nextLabel={<IoIosArrowForward />}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageClick1}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            ) : (
              <div>
              <p style={{ fontSize: "20px", fontStyle: "italic" }}>
              No Data Found
            </p>
              </div>
            )}
          </div>
          {this.state.modal && (
            <Modal
              style={{ width: "50%" }}
              isOpen={this.state.modal}
              onHide={this.toggle}
              onExit={this.reset}
              size="lg"
              centered
            >
              <ModalHeader toggle={this.toggle}>
                Create New Payer Category
              </ModalHeader>
              <ModalBody>
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="input"  className="required">Category ID</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.categoryId}
                          onChange={(e) =>
                            this.setState({
                              categoryId: e.target.value,
                              idErr : "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.idErr}
                      </div>
                        {/* <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.categoryIdErr}
                        </div> */}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="required">Category Name</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.name}
                          onChange={(e) =>
                            this.setState({ name: e.target.value, nameErr: "" })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.nameErr}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <div>
                        <FormGroup>
                          <Label htmlFor="numberDropdown"  className="required">
                            Claim Followup Days
                          </Label>
                          <Input
                           
                            type="select"
                            id="numberDropdown"
                            value={this.state.dayscount}
                            onChange={(e) =>
                              this.setState({ dayscount: e.target.value, daysErr:"" })
                            }
                          >
                            <option value="" disabled selected>
                              -- Select --
                            </option>
                            {numbers.map((number) => (
                              <option key={number} value={number}>
                                {number}
                              </option>
                            ))}
                          </Input>
                          <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.daysErr}
                        </div>
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Description</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.description}
                          onChange={(e) =>
                            this.setState({
                              description: e.target.value,
                              descriptionErr: "",
                            })
                          }
                        />
                        {/* <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.descriptionErr}
                        </div> */}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Billing Provider</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.provider}
                          onChange={(e) =>
                            this.setState({
                              provider: e.target.value,
                              providerErr: "",
                            })
                          }
                        />
                        {/* <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.providerErr}
                        </div> */}
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Adjustment</Label>
                        <Input
                          className="arrowchange"
                          type="number"
                          required
                          value={this.state.insurance}
                          onChange={(e) =>
                            this.setState({
                              insurance: e.target.value,
                              insuranceErr: "",
                            })
                          }
                        />
                        {/* <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.insuranceErr}
                        </div> */}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Write Off</Label>
                        <Input
                          type="number"
                          required
                          value={this.state.write}
                          onChange={(e) =>
                            this.setState({
                              write: e.target.value,
                              writeErr: "",
                            })
                          }
                        />
                        {/* <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.writeErr}
                        </div> */}
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label  className="required">Preauthorization</Label>

                        <Input
                          type="select"
                          value={this.state.authorization}
                          onChange={(e) =>
                            this.setState({
                              authorization: e.target.value,
                              authErr:""
                            })
                          }
                        >
                          <option value="" disabled selected>
                            --select--
                          </option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Input>
                        <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.authErr}
                      </div>
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <Label  className="required">Status</Label>
                        <Input
                          type="select"
                          className="form-control form-select"
                          value={this.state.status}
                          onChange={(e) =>
                            this.setState({
                              status: e.target.value,
                              statusErr:""
                            })
                          }
                        >
                          <option value="" disabled selected>
                            --select--
                          </option>
                          <option value="Enabled">Enabled</option>
                          <option value="Disabled">Disabled</option>
                        </Input>
                        <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.statusErr}
                      </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggle();
                    this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={this.handleSubmit}
                >
                  Create
                </Button>
              </ModalFooter>
            </Modal>
          )}

          {this.state.updatemodal && (
            <Modal
              style={{ width: "50%" }}
              isOpen={this.state.updatemodal}
              onHide={this.toggleupdate}
              onExit={this.reset}
              size="lg"
              centered
            >
              <ModalHeader toggle={this.toggleupdate}>
                Update Category
              </ModalHeader>
              <ModalBody>
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label  className="required">Category ID</Label>
                        <Input
                          disabled
                          type="text"
                          required
                          value={this.state.updatecategoryId}
                          onChange={(e) =>
                            this.setState({
                              updatecategoryId: e.target.value,
                              idErr:""
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Category Name</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.updatename}
                          onChange={(e) =>
                            this.setState({
                              updatename: e.target.value,
                              nameErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.nameErr}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <div>
                        <FormGroup>
                          <Label htmlFor="numberDropdown"  className="required">
                            Claim Followup Days
                          </Label>
                          <Input
                            type="select"
                            id="numberDropdown"
                            value={this.state.updatedayscount}
                            // defaultValue={this.state.updatedayscount}
                            onChange={(e) =>
                              this.setState({
                                updatedayscount: e.target.value,
                                daysErr:""
                              })
                            }
                          >
                            <option value="" disabled selected>
                              -- Select --
                            </option>
                            {numbers.map((number) => (
                              <option key={number} value={number}>
                                {number}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Description</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.updatedescription}
                          onChange={(e) =>
                            this.setState({
                              updatedescription: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Billing Provider</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.updateprovider}
                          onChange={(e) =>
                            this.setState({
                              updateprovider: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Adjustment</Label>
                        <Input
                          type="number"
                          required
                          value={this.state.updateinsurance}
                          onChange={(e) =>
                            this.setState({
                              updateinsurance: e.target.value,
                              insuranceErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.insuranceErr}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Write Off</Label>
                        <Input
                          type="number"
                          required
                          value={this.state.updatewrite}
                          onChange={(e) =>
                            this.setState({
                              updatewrite: e.target.value,
                              writeErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.writeErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label  className="required">Preauthorization</Label>

                        <Input
                          type="select"
                          value={this.state.updateauthorization}
                          onChange={(e) =>
                            this.setState({
                              updateauthorization: e.target.value,
                              authErr:""
                            })
                          }
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <Label  className="required">Status</Label>
                        <Input
                          type="select"
                          className="form-control form-select"
                          value={this.state.updatestatus}
                          onChange={(e) =>
                            this.setState({
                              updatestatus: e.target.value,
                              statusErr:""
                            })
                          }
                        >
                          <option value="Enabled">Enabled</option>
                          <option value="Disabled">Disabled</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggleupdate();
                    this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={() => {
                    this.putrequests(this.state.singleid);
                  }}
                >
                  Update
                </Button>
              </ModalFooter>
            </Modal>
          )}

          <Modal isOpen={this.state.createmodal} centered>
            <ModalBody>
              <img
                src={loading}
                style={{ width: "200px", height: "200px", marginLeft: "30%" }}
              />
            </ModalBody>
          </Modal>

          {this.state.deletemodal && (
            <Modal
              isOpen={this.state.deletemodal}
              onHide={this.toggledelete}
              // onExit={this.reloadpage}
              centered
            >
              <ModalHeader toggle={this.toggledelete}>
                Delete Payer Category
              </ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Row style={{ margin: "0px" }}>Do you want to delete this?</Row>

                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={this.toggledelete}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={() => {
                    this.deleterequests(this.state.singleid);
                  }}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </div>
      </div>
      </div>
    );
  }
}

export default PayerCategory;

import { Component } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import Newsidebar from "./../../Dashboard/Newsidebar.js";
import { ip } from "../../Api";
import React from "react";
import {
  Line,
  XAxis,
  LineChart,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  const ClaimTooltip = ({ active, payload }) => {
    if (active && payload) {
      return (
        <div className="custom-tooltip">
          {payload[0].payload._id}
          <br />
          <p style={{ color: payload[0].color }}>
            {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
          </p >
          <p style={{ color: payload[1].color }}>
            {payload[1].name}:&nbsp;${payload[1].value.toLocaleString()}
          </p>
        </div>
      );
    }
    return null;
  };

class PayerAnalysis extends Component {
  state = {
    refreshtoken: sessionStorage.getItem("refreshtoken"),
    accesstoken: sessionStorage.getItem("accesstoken"),
    dataarray:[],
    client:sessionStorage.getItem("clientid"),

  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getData = async () => {
    try{
    const headers = {
      "Content-Type" : "application/json",
      Authorization : `Bearer ${this.state.accesstoken}`
    };
    let url;
    if(this.state.client){
      url = `${ip}/trends/payercollanalysis?clientId=${this.state.client}`
    }
    else{
      url = `${ip}/trends/payercollanalysis`
    };
    const res= await axios
    .get(url,{headers:headers})
    this.setState ({
      dataarray : res.data.data
    });
  }
  catch(err) {
  }
  }
  componentDidMount() {
    
    this.getData();
  }
  render() {
    return (
      <div className="align-row">
        <Newsidebar />
        <div className="align-column">          <br />
          <p style={{ textAlign: "left", marginLeft: "3%", fontSize: "14px" }}>
            <Link
              to="/management-trends-patterns"
              style={{ textDecoration: "none", color: "black" }}
            >
              Trends & Patterns
            </Link>
            &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;Provider
            Payer Collection Analysis{" "}
          </p>
          <div>
            <ResponsiveContainer width={"95%"} height={650}>
              <LineChart
                width={400}
                height={400}
                data={this.state.dataarray}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 10,
                  left: 60,
                }}
              >
                <XAxis dataKey="_id" />
                <YAxis dataKey="billedamount"/>
                <Tooltip content={<ClaimTooltip/>}/>

                <Line
                  type="monotone"
                  dataKey="payerpayamount"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                  //stroke="rgb(5, 101, 191)"
                  fill="rgb(5, 101, 191)"
                />
                <Line
                  type="monotone"
                  dataKey="billedamount"
                  stroke="rgb(247, 73, 105)"
                  fill="rgb(247, 73, 105)"
                />
                {/* <Legend /> */}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    );
  }
}
export default PayerAnalysis;

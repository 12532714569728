import React, { Component } from "react";
import axios from "axios";
import Newsidebar from "../Dashboard/Newsidebar";
 
import { ip } from ".././Api";
import { Link } from "react-router-dom";
import Tabs, { Tab } from "react-best-tabs";
import Select from "react-dropdown-select";
import "../../Styles/databasesync.css";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { Row, Col, Card } from "react-bootstrap";
class ApiSync extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
    };
  }

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  componentDidMount() {
    
  }
  render() {
    return (
      <div>
      <Newsidebar name="API Sync" />
      <div className="align-row" style={{overflow:"hidden"}}>
        <div className="align-column apisync">
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="card apisync-card" style={{alignItems:"center", border:"1px solid skyblue",  width:"90%", padding:"10px"}}>
        <div className="row" style={{alignItems:"center", justifyContent:"center",  margin:"10px"}}>
        <div className="col-md-4 mb-3">
        <div className="apisync-container">
        <div className="card-body">
        <Link
        to="/apisync/advancedmd"
        style={{ textDecoration: "none" }}
      >
        <Row>
          <Col className="apisync-text">Advanced MD</Col>
        </Row>
      </Link>
        </div>
        </div>
        </div>
        <div className="col-md-4 mb-3">
        <div className="apisync-container">
        <div className="card-body">
        <Link
        to="/apisync/advancedmd"
        style={{ textDecoration: "none" }}
      >
        <Row>
          <Col className="apisync-text">EPIC</Col>
        </Row>
      </Link>
        </div>
        </div>
        </div>
        <div className="col-md-4 mb-3">
        <div className="apisync-container">
        <div className="card-body">
        <Link
        to="/apisync/advancedmd"
        style={{ textDecoration: "none" }}
      >
        <Row>
          <Col className="apisync-text">ECW</Col>
        </Row>
      </Link>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
      </div>
      </div>
    );
  }
}
export default ApiSync;

import React, { Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import axios from "axios";
import { ip } from "../Api";
import { Row, Col, Form, Label, Input, Button, FormGroup } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import {
    IoIosArrowBack,
    IoIosArrowForward,
  } from "react-icons/io";
export default class noteType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addnewmodal: false,
      client: sessionStorage.getItem("clientid"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      notetypecode: "",
      notetype: "",
      noteTypearray:[],
      offset: 0,
      currentpage: 0,
      perPage: 10,
    };
  }

  addNoteType = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      noteTypeCode: this.state.notetypecode,
      noteType: this.state.notetype,
      clientId: parseInt(this.state.client),
    };
    axios
      .post(`${ip}/notetypecodes/create`, value, { headers: headers })
      .then((res) => {
        if (res) {
          this.setState({ addnewmodal: !this.state.addnewmodal });
          this.getNotetypeDeatils();
          toast.success("Note Type created", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          this.getNotetypeDeatils();
          this.setState({ addnewmodal: !this.state.addnewmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  addmodal = () => {
    this.setState({ addnewmodal: !this.state.addnewmodal });
  };

  getNotetypeDeatils = () => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      let url;
    if (this.state.client) {
      url = `${ip}/notetypecodes/all?pageno=${this.state.currentpage}&clientId=${this.state.client}`;
    } else {
      url = `${ip}/notetypecodes/all?pageno=${this.state.currentpage}`;
    }
      // eslint-disable-next-line
       axios
        .get(url, { headers: headers })
        .then((res) => {
          this.setState({
            noteTypearray: res.data.notetypecodes,
          pageCount: Math.ceil(res.data.count / this.state.perPage),
          }
          )
        })
        .catch((err) => {});
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getNotetypeDeatils();
      }
    );
  };
  reset = () => {
    this.setState({
        notetype:"",
        notetypecode:""
    })
  }
  componentDidMount = () => {
    this.getNotetypeDeatils();
  }
  render() {
    return (
      <div>
      <Newsidebar name="Note Types" />
      <div className="align-row" style={{ overflow: "hidden" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ fontSize: "13px" }}>
            </div>
            <div>
              <button
                style={{
                  backgroundColor: "#E5E1DA",
                  border: "1px solid #E5E1DA",
                  width: "fit-content",
                  padding: "8px",
                  marginRight: "40px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  whiteSpace:"nowrap"
                }}
                onClick={this.addmodal}
              >            
               + New Note Type
              </button>
            </div>
          </div>
        <div className="align-column">
          <ToastContainer />
          <br/>
          <div style={{marginLeft:"30%", marginRight:"30%"}}>
          <Table bordered className="StandardTable">
          <thead>
          <tr>
          <th>Note Type Code</th>
          <th>Note Type</th>
          </tr>
          </thead>
          <tbody>
          {this.state.noteTypearray && 
            this.state.noteTypearray.map((data, index) => (
              <tr key={index}>
                <td>{data.noteTypeCode}</td>
                <td>{data.noteType}</td>
              </tr>
            ))
          }
        </tbody>
        
          </Table>
          <div>
          <ReactPaginate
            previousLabel={<IoIosArrowBack />}
            nextLabel={<IoIosArrowForward />}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
          </div>
        </div>
        <Modal
        show={this.state.addnewmodal}
        onHide={this.addmodal}
        onExit={this.reset}
        centered
      >
        <Modal.Header closeButton closeLabel="">
          <Modal.Title>Add Note Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Label>Note Type Code</Label>
                <Input
                  value={this.state.notetypecode}
                  onChange={(e) =>
                    this.setState({ notetypecode: e.target.value})
                  }
                />
              </Col>
              <Col>
                <Label for="select">
                  Note Type
                </Label>
                <Input
                  value={this.state.notetype}
                  onChange={(e) =>
                    this.setState({ notetype: e.target.value})
                  }
                />
              </Col>
            </Row>
            <br />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              border: "1px solid grey",
              color: "black",
              backgroundColor: "#fff",
            }}
            onClick={() => {
              this.addmodal();
              this.reset();
            }}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "black", color: "white" }}
            onClick={() => {
              this.addNoteType();
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import axios from "axios";
import { ip } from "./Api";
import { Button, FormGroup, Form, Input, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { Row} from "react-bootstrap";

export default class setQues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("clientid"),
      number: sessionStorage.getItem("totalusercount"),
      username: this.props.location.state.username,
      result:"",
      ans1:"",
      ans2:"",
      ans3:"",
      ans4:"",
      ans5:"",
      ans6:"",
      ques1:"",
      ques2:"",
      ques3:"",
      ques4:"",
      ques5:"",
      ques6:"",
      securityquesans:[],
      questions: [],
      answers: [],
      successmodal:false,
    };
  }
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  checkOddEven = () => {
    const { number } = this.state;
    const result = number % 2 === 0 ? "Even" : "Odd";
    this.setState({ result });
  };
  handleSubmitAns = async (data) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let value
    let formattedQuestions;
    if(this.state.result === "Odd"){
      this.state.questions=[
      this.state.ques1,
      this.state.ques2,
      this.state.ques3
      ]
      this.state.answers=[
        this.state.ans1,
        this.state.ans2,
        this.state.ans3
      ]
      formattedQuestions = this.state.questions.map((question, index) => {
        return {
          Q: question,
          A: this.state.answers[index]
        };
      });

    }
    else if(this.state.result === "Even"){
      this.state.questions=[
        this.state.ques4,
        this.state.ques5,
        this.state.ques6
        ]
        this.state.answers=[
          this.state.ans4,
          this.state.ans5,
          this.state.ans6
        ]
    formattedQuestions = this.state.questions.map((question, index) => {
          return {
            Q: question,
            A: this.state.answers[index],
          };
        });
  }
    value = {
     securityquestions: formattedQuestions,
     username: this.state.username
    };
    // eslint-disable-next-line
    const res = await axios
      .post(`${ip}/auth/securityquestions`, value, {headers: headers})
      .then((res) => {
        this.successbox();
      })
      .catch((err) => {});
  };
  successbox = () => {
    this.setState({
   successmodal : !this.state.successmodal
    })
  }
  componentDidMount() {
    
    this.checkOddEven();
  }
  render() {
    return (
      <div className="container_change">
            <div className="login">
              <Form className="login-form-change">
                <br />
                <h5 style={{ fontWeight: "bold" }}>
                  <h4 align="center" style={{ fontWeight: "bold" }}>
                    Set Your Answers!
                  </h4>
                  <br />
                </h5>
                {this.state.result === "Odd" ? (
                  <div>
                    <FormGroup className="input-group-lg">
                      <Label>In what city were you born?</Label>
                      <Input
                        className="input-text"
                        type="text"
                        placeholder=""
                        value={this.state.ans1}
                        onChange={(e) =>
                          this.setState({ 
                            ans1: e.target.value, 
                            ques1:"In what city were you born"
                          })
                        }
                      />
                    </FormGroup>
                    <br/>
                    <FormGroup className="input-group-lg">
                      <Label>What is the name of your favorite pet?</Label>
                      <Input
                        className="input-text"
                        type={"text"}
                        placeholder=""
                        value={this.state.ans2}
                        onChange={(e) =>
                          this.setState({ 
                            ans2: e.target.value,
                            ques2:"What is the name of your favorite pet"
                          })
                        }
                      />
                    </FormGroup>
                    <br/>
                    <FormGroup className="input-group-lg">
                      <Label>What is your mother's maiden name?</Label>
                      <Input
                        className="input-text"
                        type={"text"}
                        placeholder=""
                        value={this.state.ans3}
                        onChange={(e) =>
                          this.setState({ ans3: e.target.value,
                          ques3:"What is your monther's maiden name"
                          })
                        }
                      />
                    </FormGroup>
                  </div>
                ) : (
                  <div>
                    <FormGroup className="input-group-lg">
                      <Label>What was the name of your elementary school?</Label>
                      <Input
                        className="input-text"
                        type="text"
                        placeholder=""
                        value={this.state.ans4}
                        onChange={(e) =>
                          this.setState({ ans4: e.target.value,
                          ques4:"What was the name of your elementary school" })
                        }
                      />
                    </FormGroup>
                    <br/>
                    <FormGroup className="input-group-lg">
                      <Label>What was the make of your first car?</Label>
                      <Input
                        className="input-text"
                        type={"text"}
                        placeholder=""
                        value={this.state.ans5}
                        onChange={(e) =>
                          this.setState({ ans5: e.target.value,
                          ques5:"What was the make of your first car" })
                        }
                      />
                    </FormGroup>
                    <br/>
                    <FormGroup className="input-group-lg">
                      <Label>Where did you meet your spouse?</Label>
                      <Input
                        className="input-text"
                        type={"text"}
                        placeholder=""
                        value={this.state.ans6}
                        onChange={(e) =>
                          this.setState({ ans6: e.target.value,
                          ques6:"Where did you meet your spouse" })
                        }
                      />
                    </FormGroup>
                  </div>
                )}
                <br />
                <Button
                  className="btn-login"
                  color="primary"
                  onClick={() => this.handleSubmitAns()}
                >
                  Confirm
                </Button>
                <br />
                <br />
              </Form>
        </div>
          <Modal
            isOpen={this.state.successmodal}
            onHide={this.successbox}
            centered
          >
            <ModalBody
              style={{
                "max-height": "calc(100vh - 210px)",
                "overflow-y": "auto",
              }}
            >
              <Row style={{ margin: "0px" }}>Done! Your answers are saved!</Row>
              <br />
            </ModalBody>
            <ModalFooter>
              <Button
                style={{
                  border: "1px solid #268da5",
                  color: "#268da5",
                  backgroundColor: "#fff",
                }}
                onClick={this.successbox}
              >
                Cancel
              </Button>
              <Link to="/">
                <Button style={{ backgroundColor: "#268da5" }}>
                  Redirect to Home Page
                </Button>
              </Link>
            </ModalFooter>
          </Modal>
      </div>
    );
  }
}

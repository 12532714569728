import { Component, Reaact } from "react";
import axios from "axios";
import { ResponsiveContainer } from "recharts";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
} from "recharts";
import { ip } from "../../Api";
const data = [
  { _id: "" },
  { _id: "" },
  { _id: "" },
  { _id: "" },
  { _id: "" },
  { _id: "" },
  { _id: "" },
];
let tooltip;
const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: "#f5234a" }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};
class UploadStatus extends Component {
  state = {
    refreshtoken: sessionStorage.getItem("refreshtoken"),
    accesstoken: sessionStorage.getItem("accesstoken"),
    denialbyagewise: [],
    action_wise: [],
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  getDetails = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if(this.props.Location){
      if (this.props.uploadStart !== 0 && this.props.uploadEnd !== 0) {
        url = `${ip}/outcome/actionwise?start_date=${this.props.uploadStart}&end_date=${this.props.uploadEnd}&clientId=${this.props.client}&Location=${this.props.Location}`;
      } else {
        url = `${ip}/outcome/actionwise?clientId=${this.props.client}&Location=${this.props.Location}`;
      }
    }else{
      if (this.props.uploadStart !== 0 && this.props.uploadEnd !== 0) {
        url = `${ip}/outcome/actionwise?start_date=${this.props.uploadStart}&end_date=${this.props.uploadEnd}&clientId=${this.props.client}`;
      } else {
        url = `${ip}/outcome/actionwise?clientId=${this.props.client}`;
      }
    }
    } else {
      if (this.props.uploadStart !== 0 && this.props.uploadEnd !== 0) {
        url = `${ip}/outcome/actionwise?start_date=${this.props.uploadStart}&end_date=${this.props.uploadEnd}`;
      } else {
        url = `${ip}/outcome/actionwise`;
      }
    }
    const req = axios
      .get(url, { headers: headers })
      .then((req) => {
        if (req.data.action_wise.length !== 0) {
          this.setState({ action_wise: req.data.action_wise });
        } else {
          this.setState({
            action_wise: data,
          });
        }
      })
      .catch((err) => {});
  };
  componentDidMount() {
    
    this.getDetails();
  }
  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.uploadStart === prevProps.uploadStart &&
        this.props.uploadEnd === prevProps.uploadEnd
      )
    ) {
      this.getDetails();
    } else if (this.props.client !== prevProps.client) {
      this.getDetails();
    }
    else if(this.props.Location !== prevProps.Location){
      this.getDetails();
    }
  }

  render() {
    return (
      <div>
        {this.props.data === "Web" && (
          <div onMouseOver={() => (tooltip = "totalAmount")}>
            <ResponsiveContainer width={"99%"} height={430}>
              <ComposedChart
                width={450}
                height={350}
                data={this.state.action_wise}
                margin={{
                  top: 13,
                  right: 0,
                  left: 50,
                  bottom: 5,
                }}
              >
                <XAxis dataKey={"_id"} height={113} interval={0} angle={"-45"} minTickGap={6} tick={{strokeWidth:0,fontSize:"14",top:400,textAnchor:"end",dy:6}}/>
                <YAxis dataKey={"totalAmount"} />
                <Tooltip content={<CustomTooltip />} />
                <defs>
                  <linearGradient
                    id="colorUv"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="100%"
                    spreadMethod="reflect"
                  >
                    <stop offset="0" stopColor="#f5234a" />
                    <stop offset="1" stopColor="#fa859a" />
                  </linearGradient>
                </defs>
                <Bar
                  name="Total Amount"
                  dataKey={"totalAmount"}
                  barSize={30}
                  fill="url(#colorUv)"
                >
                  <LabelList
                    dataKey="totalclaims"
                    type="number"
                    position="top"
                    fill="#7a051b"
                    fontWeight="600"
                    fontFamily="Poppins"
                  />
                </Bar>
                <Line
                  name="Total Claims"
                  type="monotoneX"
                  dataKey="totalclaims"
                  stroke="#8884d8"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
    );
  }
}
export default UploadStatus;

import React, { Component } from "react";
import axios from "axios";
import "../../../Styles/configure.css";
import Newsidebar from "../../Dashboard/Newsidebar";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import "../../../Styles/loader.scss";
import { components } from "react-select";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  IoIosArrowBack,
  IoIosArrowForward,
} from "react-icons/io";
import moment from "moment-timezone";
import { BiDownArrowAlt,BiUpArrowAlt  } from "react-icons/bi";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { styled } from "@mui/material/styles";
import Checkbox from "../../Allocation/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Col } from "reactstrap";
import { Button, Label } from "reactstrap";
import {  Row } from "react-bootstrap";
import Select from "react-dropdown-select";
import { ip } from "../../Api";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
const options = [
  { value: "2", label: "PatientFirstName" },
  { value: "3", label: "PatientLastName" },
  { value: "4", label: "Physician" },
  { value: "5", label: "Age" },
  { value: "6", label: "PrimaryPolicyNo" },
  { value: "7", label: "PatientDOB" },
  { value: "8", label: "PlanCode" },
  { value: "9", label: "patientAccountNumber" },
  { value: "10", label: "DateOfService" },
  { value: "11", label: "InsuranceName" },
];

class Keyallocated extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalunallocate: false,
      doioptionSelected: [],
      modalunallocate1: false,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      usernamekey: sessionStorage.getItem("usernamekey"),
      claimdetailskey: sessionStorage.getItem("claimdetailskey"),
      completedclaims: sessionStorage.getItem("completedclaims"),
      nottouch: sessionStorage.getItem("nottouch"),

      offset1: 0,
      keypageno: sessionStorage.getItem("keypageno"),
      claimsdata: [],
      filterpage: [],
      reports: [],
      fetching: true,
      currentpage: 0,
      perPage: 10,
      offset: 0,
      ar: [],
      loading: true,
      any: [],
      statuslist: [],
      PatientFirstName: "",
      PatientLastName: "",
      webclaim: [],
      patientAccountNumber: "",
      searchtext: "",
      searchclaimdata: [],
      payercategories: [],
      cptdata: [],
      totalClaims: [],
      status: [],
      optionSelected: [],
      checkfiltertable: sessionStorage.getItem("checkfiltertable"),
      checkcompletetable: sessionStorage.getItem("checkcompletetable"),
      nottouchtable: sessionStorage.getItem("nottouchtable"),
      username1: "",
      subcategoryname: sessionStorage.getItem("querysub"),
      claimfield: "",
      user: "",
      claimvalue: "",
      keycategory: sessionStorage.getItem("keycategory"),
      keytype: sessionStorage.getItem("keytype"),
      value: sessionStorage.getItem("claimurl"),

      payervalue: [],
      currentpage1: 0,
      perPage1: 10,
      currentpage2: 0,
      perPage2: 10,
      currentpage3: 0,
      perPage3: 10,
      cptvalue: [],
      userdetails: [],
      query: [],
      ids: [],
      offset: 0,
      currentpage: 0,
      currentpagesearch: 0,
      categoryname: sessionStorage.getItem("querycategory"),
      perPage: 10,
      checkedAll: false,
      alldataresponse: [],
      searchdataresponse: [],
      selecteduser: [],
      users: [],
      user_id: sessionStorage.getItem("user_id"),
      role: sessionStorage.getItem("role_type"),
      clientid: sessionStorage.getItem("clientid"),
      clientname: sessionStorage.getItem("clientname"),
      clientslist: [],
      opendropdown: false,
      nottouch: [],
      complete: [],
      Location: "",
      locationslist: [],
      count: 10,

      userstatus: "",
      agegrp: "",
      doiagegrp: "",
      unallocateid: "",
      sortstate: "ASC",
      openicon: false,
      claimids: [],
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  toggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };
  toggle1 = () => {
    const { modalunallocate } = this.state;
    this.setState({ modalunallocate: !modalunallocate });
  };
  toggle2 = () => {
    const { modalunallocate1 } = this.state;
    this.setState({ modalunallocate1: !modalunallocate1 });
  };
  handleChange = (selected) => {
    this.setState({
      optionSelected: selected,
    });
  };
  doihandleChange = (selected) => {
    this.setState({
      doioptionSelected: selected,
    });
  };
  checkboxsearchall = () => {
    if (this.state.checkedAll === false) {
      let idvalue = this.state.searchdataresponse.map((data) => data._id);
      this.setState(
        { checkedAll: !this.state.checkedAll, ids: idvalue },
        () => {
          this.toggle2();
        }
      );
    } else {
      this.setState({ checkedAll: !this.state.checkedAll, ids: [] }, () => {});
    }
  };

  handlePageClick2 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getwebclaimsallocate();
      }
    );
  };

  allocateclaims = () => {
    const isValid = this.validate();
    if (isValid) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        ids: this.state.ids,
      };
       axios
        .put(
          `${ip}/allocation/allocate/${this.state.selecteduser[0]._id}`,
          value,
          {
            headers: headers,
          }
        )
        .then((res) => {
          alert(
            `claims allocated to user ${this.state.selecteduser[0].first_name}`
          );
          this.getwebclaimsallocate();
          this.resetuser();
          this.toggle();
          this.toggle2();
          // window.location.reload();
        })

        .catch((err) => {});
    }
  };
  bulkunallocatedclaims = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      ids: this.state.ids,
    };
     axios
      .put(`${ip}/claim/updatemultipleclaimunder`, value, {
        headers: headers,
      })
      .then((res) => {
        alert(`claim unallocated successfully`);
        this.getwebclaimsallocate();
        this.resetuser();

        this.toggle2();
        // window.location.reload();
      })

      .catch((err) => {});
  };
  unallocateclaims = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      id: this.state.unallocateid,
    };
     axios
      .put(`${ip}/claim/updateclaimunder`, value, {
        headers: headers,
      })
      .then((res) => {
        alert(`claim unallocated successfully`);
        this.getwebclaimsallocate();
        this.resetuser();
        this.toggle1();
        // window.location.reload();
      })

      .catch((err) => {});
  };
  toggledropdown = () => {
    this.setState({ opendropdown: !this.state.opendropdown });
  };

  getclients = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     axios
      .get(`${ip}/clientonboard/all`, { headers: headers })
      .then((res) => {
        if (res.data.clientonboard.length !== 0) {
          this.setState({
            clientslist: res.data.clientonboard,
            // clientname: res.data.clientonboard[0].client,
          });
        } else {
          this.setState({
            clientslist: res.data.clientonboard,
          });
        }
      })
      .catch((err) => {});
  };
  getClaimStatus = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

     await axios
      .get(`${ip}/claimstatus/all`, { headers: headers })
      .then((res) => {
        this.setState({
          statuslist: res.data.claimstatus,
        });
      })
      .catch((err) => {});
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getallocatedclaims();
      }
    );
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  getwebclaimsallocate = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.queryclient) {
      if (this.state.querystartdate && this.state.queryenddate) {
        if (this.state.queryLocation) {
          url = `${ip}/allocation/bucket/allocatedusersummary?clientId=${this.state.clientid}&type=${this.state.keytype}&allocatedpageno=${this.state.keypageno}&bucketname=${this.state.keycategory}`;
        } else {
          url = `${ip}/allocation/bucket/allocatedusersummary?clientId=${this.state.clientid}&type=${this.state.keytype}&allocatedpageno=${this.state.keypageno}&bucketname=${this.state.keycategory}`;
        }
      } else {
        if (this.state.queryLocation) {
          url = `${ip}/allocation/bucket/allocatedusersummary?clientId=${this.state.clientid}&type=${this.state.keytype}&allocatedpageno=${this.state.keypageno}&bucketname=${this.state.keycategory}`;
        } else {
          url = `${ip}/allocation/bucket/allocatedusersummary?clientId=${this.state.clientid}&type=${this.state.keytype}&allocatedpageno=${this.state.keypageno}&bucketname=${this.state.keycategory}`;
        }
      }
    } else {
      if (this.state.querystartdate && this.state.queryenddate)
        url = `${ip}/allocation/bucket/allocatedusersummary?clientId=${this.state.clientid}&type=${this.state.keytype}&allocatedpageno=${this.state.keypageno}&bucketname=${this.state.keycategory}`;
      else
        url = `${ip}/allocation/bucket/allocatedusersummary?clientId=${this.state.clientid}&type=${this.state.keytype}&allocatedpageno=${this.state.keypageno}&bucketname=${this.state.keycategory}`;
    }

     axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        res.data.claimdetails.map((values) => {
          return (
            values.username === this.state.usernamekey &&
            this.setState({
              totalClaims: values.filteredClaims,
              pageCountkey: values.filteredClaimscount,
              nottouch: values.nottouchedClaims,
              complete: values.completedClaims,
              fetching: false,
              alldataresponse: values.filteredClaims,
            })
          );
        });

        const slice = this.state.totalClaims.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        const slice1 = this.state.complete.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        this.setState({
          pageCountkey: Math.ceil(
            this.state.totalClaims.length / this.state.perPage
          ),
          pageCountkey1: Math.ceil(
            this.state.complete.length / this.state.perPage
          ),
          totalClaims: slice,
          complete: slice1,
        });
      })

      .catch((err) => {});
  };

  //getusers
  getallocatedclaims = async () => {
    //this.setState({ fetching: true });
    //api call to get all users

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    if (
      this.state.role === "Admin" ||
      this.state.role === "SuperAdmin" ||
      this.state.role === "Team Manager"
    ) {
      let url;
      if (this.state.clientid) {
        if (this.state.Location) {
          url = `${ip}/allocation/allocated/all?clientId=${
            this.state.clientid
          }&pageno=${this.state.currentpage + 1}&count=${
            this.state.perPage
          }&Location=${this.state.Location}`;
        } else {
          url = `${ip}/allocation/allocated/all?clientId=${
            this.state.clientid
          }&pageno=${this.state.currentpage + 1}&count=${this.state.perPage}`;
        }
      } else {
        url = `${ip}/allocation/allocated/all?pageno=${
          this.state.currentpage + 1
        }&count=${this.state.perPage}`;
      }
       axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          this.setState({
            claimsdata: res.data.claims,

            // any: res.data.claims,
          });
        })
        .catch((err) => {});
    } else if (this.state.role === "Team Member") {
       axios
        .get(`${ip}/claim/user?userid=${this.state.user_id}`, {
          headers: headers,
        })
        .then((res) => {
          const slice = res.data.Claims.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );
          this.setState({
            claimsdata: slice,
          });
        })
        .catch((err) => {});
    }
  };
  sorting = (col) => {
    if (this.state.sortstate === "ASC") {
      const sorted = [...this.state.totalClaims].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      this.setState({ totalClaims: sorted });
      this.setState({ sortstate: "DSC" });
    }
    if (this.state.sortstate === "DSC") {
      const sorted = [...this.state.totalClaims].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      this.setState({ totalClaims: sorted });
      this.setState({ sortstate: "ASC" });
    }
  };

  getcpt = async () => {
    //api call to get all cpt data
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     axios
      .get(`${ip}/cptmaster/all`, { headers: headers })
      .then((res) => {
        this.setState({
          cptdata: res.data.cpt,
        });
      })
      .catch((err) => {});
  };

  getusers = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
     axios
      .get(`${ip}/users/all?clientId=${this.state.clientid}`, { headers: headers })
      .then((res) => {
        let data = res.data.user;
        // this.setState({ userdetails: res.data.user });
        if(this.state.clientid){
          data = data.filter((item) => {
            return (
              item.role == "Team Member" 
            );
          });
        }
        else{
          data = data.filter((item) => {
            return (
              item.role === "Admin" || item.role==="SuperAdmin"
            );
          });
        }
        

        this.setState({ users: data });
      })
      .catch((err) => {});
  };
  resetuser = () => {
    this.setState({
      ids: [],
    });
  };
  reset = () => {
    this.setState(
      {
        claimfield: "",
        claimids: [],
        claimvalue: "",
        username: "",
        userstatus: [],
        user: "",
        ar: [],

        status: [],
        agegrp: "",
        payervalue: [],
        cptvalue: [],
        searchdataresponse: [],
        searchclaimdata: [],
        searchtext: "",
        currentpage: 0,
        currentpagesearch: 0,
        checkedAll: false,
        perPage: 10,
        doiagegrp: "",
        optionSelected: [],
        doioptionSelected: [],
      },
      () => this.getallocatedclaims()
    );
  };

  componentDidMount() {
    
    this.getallocatedclaims();
    this.getcpt();

    this.getwebclaimsallocate();
    this.getClaimStatus();
    this.getclients();
    this.getusers();
  }

  addsinglecheckboxid = (data, check) => {
    if (check) {
      this.setState({ ids: [...this.state.ids, data] }, () => this.toggle2());
    } else {
      let arr = this.state.ids.slice();
      let val = arr.filter((id) => {
        return id !== data;
      });
      this.setState({ ids: val }, () => {
        this.toggle2();
      });
    }
  };

  checkBoxAll = () => {
    if (this.state.checkedAll === false) {
      let idvalue = this.state.totalClaims.map((data) => data._id);
      this.setState(
        { checkedAll: !this.state.checkedAll, ids: idvalue },
        () => {
          this.toggle2();
        }
      );
    } else {
      this.setState({ checkedAll: !this.state.checkedAll, ids: [] }, () => {});
    }
  };

  validate = () => {
    let hasErr = "true";
    let selectuserErr = "";
    if (this.state.selecteduser.length === 0) {
      selectuserErr = "This field is required";
      this.setState({ selectuserErr });
      hasErr = false;
    }
    return hasErr;
  };
  render() {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    const checkboxstyle = {
      marginTop: "15px",
    };
    return (
      <div className="align-row">
        <Newsidebar>
        {this.state.keycategory === "tflexceed" ? (
          <p>
            <Link
              to="/TFLexceed"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              TFL Exceed
            </Link>
          </p>
        ) : this.state.keycategory === "lowbalance" ? (
          <p>
            <Link
              to="/lowfollow-up"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Low Follow-up
            </Link>
          </p>
        ) : this.state.keycategory === "three" ? (
          <p>
            <Link
              to="/ThreeTouch-Claims"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Three Touch Claims
            </Link>
          </p>
        ) : this.state.keycategory === "two" ? (
          <p>
            <Link
              to="/TwoTouch-Claims"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Two Touch Claims
            </Link>
          </p>
        ) : this.state.keycategory === "31to60" ? (
          <p>
            <Link
              to="/age-between-31-60-days"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Age Between 31 to 60 Days
            </Link>
          </p>
        ) : this.state.keycategory === "lessthan30" ? (
          <p>
            <Link
              to="/less-than-30days-NotFollow"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Less Than 30 Days
            </Link>
          </p>
        ) : this.state.keycategory === "lessthan180days" ? (
          <p>
            <Link
              to="/less-than-180days-claims"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Less Than 180 Days
            </Link>
          </p>
        ) : this.state.keycategory === "lessthan180days" ? (
          <p>
            <Link
              to="/less-than-180days-claims"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Less Than 180 Days
            </Link>
          </p>
        ) : this.state.keycategory === "morethan30" ? (
          <p>
            <Link
              to="/more-than-30days-notfollow"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              More Than 30 Days
            </Link>
          </p>
        ) : this.state.keycategory === "morethan60" ? (
          <p>
            <Link
              to="/more-than-60days-notfollow"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              More Than 60 Days
            </Link>
          </p>
        ) : this.state.keycategory === "morethan180" ? (
          <p>
            <Link
              to="/more-than-180days-notfollow"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              More Than 180 Days
            </Link>
          </p>
        ) : this.state.keycategory === "morethan90" ? (
          <p>
            <Link
              to="/more-than-90days-notfollow"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              More Than 90 Days
            </Link>
          </p>
        ) : this.state.keycategory === "one" ? (
          <p>
            <Link
              to="/one-touch-claims"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              One Touch Claims
            </Link>
          </p>
        ) : this.state.keycategory === "zero" ? (
          <p>
            <Link
              to="/zero-touch-claims"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Not Touch Claims
            </Link>
          </p>
        ) : this.state.keycategory === "61to90" ? (
          <p>
            <Link
              to="/age-between-61-90-days"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Age Between 61 to 90 Days
            </Link>
          </p>
        ) : this.state.keycategory === "91to180" ? (
          <p>
            <Link
              to="/age-between-91-180-days"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Age Between 91 to 180 Days
            </Link>
          </p>
        ) : this.state.keycategory === "181to365" ? (
          <p>
            <Link
              to="/age-between-181-365-days"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Age Between 181 to 365 Days
            </Link>
          </p>
        ) : this.state.keycategory === "fastapproach" ? (
          <p>
            <Link
              to="/Fast-Approaching"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Fast Approach
            </Link>
          </p>
        ) : this.state.keycategory === "morethanfour" ? (
          <p>
            <Link
              to="/four-or-more-touch"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Four or More Touch
            </Link>
          </p>
        ) : this.state.keycategory === "four" ? (
          <p>
            <Link
              to="/four-touch-claims"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Four Touch
            </Link>
          </p>
        ) : this.state.keycategory === "greaterthan365" ? (
          <p>
            <Link
              to="/agegreaterthan365days"
              style={{
                textDecoration: "none",
                color: "white",
                marginLeft: "1%",
              }}
            >
              Age greater than 365 days
            </Link>
          </p>
        ) : null}
        </Newsidebar>
        <div className="align-column">
          <br />
          <br />

          <div style={{ display: "flex", flexDirection: "row" }}>
            {this.state.role === "SuperAdmin" ? (
              <ButtonDropdown
                isOpen={this.state.opendropdown}
                toggle={this.toggledropdown}
                style={{ width: "12%", marginLeft: "4%", position: "inherit" }}
              >
                <DropdownToggle caret>
                  {this.state.clientname ? this.state.clientname : "All"}
                </DropdownToggle>
                <DropdownMenu>
                  {this.state.clientslist.map((e) => {
                    return (
                      <DropdownItem
                        id={e.clientId}
                        key={e.clientId}
                        onClick={(e) =>
                          this.setState(
                            {
                              client: e.target.id,
                              clientname: e.target.innerText,
                            },
                            () => this.getallocatedclaims()
                          )
                        }
                      >
                        {e.client}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </ButtonDropdown>
            ) : null}
          </div>

          <div style={{ height: "100%", width: "100%" }}>
            {this.state.fetching ? (
              // <img src={loading} />
              <div>
                <p
                  style={{
                    fontSize: "23px",
                    marginTop: "10px",
                    color: "#000",
                  }}
                >
                  <span>Loading...</span>
                  <span>Please Wait...</span>
                </p>
                <div class="boxes body__loader dribbble">
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            ) : this.state.totalClaims.length === 0 ? (
              <div class="bot-ui">
                <div class="bot-head">
                  <div class="eyes-container">
                    <div class="to-left eye"></div>
                    <div class="to-right eye"></div>
                  </div>
                </div>
                <div class="bot-body">
                  <div class="analyzer"></div>
                </div>
                <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                  No Data Found
                </p>
              </div>
            ) : (
              <div>
                <div>
                <div style={{ float: "right", marginTop: "20px" }}>
                    <ReactPaginate
                      forcePage={0}
                      previousLabel={<IoIosArrowBack />}
                      nextLabel={<IoIosArrowForward />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCountkey}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick2}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 700 }}
                      aria-label="customized table"
                      style={{ padding: "3%" }}
                    >
                      <TableHead>
                        <TableRow>
                          {this.state.role === "Admin" && (
                            <StyledTableCell>
                              <input
                                type="checkbox"
                                checked={this.state.checkedAll}
                                onChange={this.checkBoxAll}
                              />
                            </StyledTableCell>
                          )}
                          <StyledTableCell
                            onClick={() => this.sorting("ClaimID")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Claim ID{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sorting("Location")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Location{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sorting("patientAccountNumber")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Account No{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sorting("DateOfService")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOS{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sorting("PatientFirstName")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Patient Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sorting("PatientDOB")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Patient DOB{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sorting("PlanCode")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Plan Code{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sorting("Pripayer")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Insurance Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sorting("PrimaryPolicyNo")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Policy No{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sorting("Physician")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Physician Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sorting("Age")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Age{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sorting("AgeGrp")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Age Grp{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sorting("DOIAge")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOI Age
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sorting("DOIAgeGrp")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOI AgeGrp
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sorting("type")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Type{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sorting("Claimunder")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Allocated To{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sorting("OverallClaimStatus")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            User Status{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => this.sorting("ClaimStatus")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Claim Status{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </StyledTableCell>
                          {this.state.role === "Admin" && (
                            <StyledTableCell>Unallocate</StyledTableCell>
                          )}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {this.state.totalClaims &&
                          this.state.totalClaims.map((data, index) => (
                            <TableRow key={data._id}>
                              {this.state.role === "Admin" &&
                                (this.state.checkedAll === true ? (
                                  <div style={checkboxstyle}>
                                    <input
                                      type="checkbox"
                                      checked={this.state.checkedAll}
                                    />
                                  </div>
                                ) : (
                                  <div style={checkboxstyle}>
                                    <Checkbox
                                      handleidpush={this.addsinglecheckboxid}
                                      number={data._id}
                                      key={index}
                                    />
                                  </div>
                                ))}
                              <StyledTableCell>{data.ClaimID}</StyledTableCell>
                              <StyledTableCell>{data.Location}</StyledTableCell>
                              <StyledTableCell>
                                {data.patientAccountNumber
                                  ? data.patientAccountNumber
                                  : "-"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.DateOfService ? data.DateOfService : "-"}
                              </StyledTableCell>

                              <StyledTableCell>
                                {data.PatientFirstName}&nbsp;
                                {data.PatientLastName}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.PatientDOB ? moment(data.PatientDOB).format("MM/DD/YYYY") : "-"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.PlanCode ? data.PlanCode : "-"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.InsuranceName ? data.InsuranceName : "-"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.PrimaryPolicyNo}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.Physician}
                              </StyledTableCell>

                              <StyledTableCell>{data.Age}</StyledTableCell>
                              <StyledTableCell>{data.AgeGrp}</StyledTableCell>
                              <StyledTableCell>{data.DOIAge}</StyledTableCell>
                              <StyledTableCell>
                                {data.DOIAgeGrp}
                              </StyledTableCell>
                              <StyledTableCell>{data.type}</StyledTableCell>
                              <StyledTableCell>
                                {data.Claimunder}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.OverallClaimStatus}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.ClaimStatus}
                              </StyledTableCell>
                              {this.state.role === "Admin" && (
                                <StyledTableCell>
                                  <Button
                                    onClick={() =>
                                      this.setState(
                                        { unallocateid: data._id },
                                        () => {
                                          this.toggle1();
                                        }
                                      )
                                    }
                                  >
                                    Unallocate
                                  </Button>
                                </StyledTableCell>
                              )}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div style={{ float: "right", marginTop: "20px" }}>
                    <ReactPaginate
                      forcePage={0}
                      previousLabel={<IoIosArrowBack />}
                      nextLabel={<IoIosArrowForward />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCountkey}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick2}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>            
                </div>
              </div>
            )}
          </div>
        </div>

        {this.state.ids.length !== 0 && this.state.modal && (
          <Modal
            style={{ width: "30%" }}
            isOpen={this.state.modal}
            onHide={this.toggle}
            size="md"
            centered
          >
            <ModalHeader toggle={this.toggle}>Reallocate</ModalHeader>
            <ModalBody>
              <Row style={{ margin: "0px" }}>
                <Col>
                  <Label>Agent/Team Member Name</Label>
                  <Select
                    options={this.state.users}
                    values={this.state.selecteduser}
                    searchable={true}
                    dropdownHandle={true}
                    searchBy="first_name"
                    labelField="first_name"
                    noDataLabel="No matches found"
                    onChange={(values) =>
                      this.setState({
                        selecteduser: values,
                        selectuserErr: "",
                      })
                    }
                    required
                  />
                </Col>
                <div style={{ fontSize: 16, color: "red" }}>
                  {this.state.selectuserErr}
                </div>
              </Row>
              <br />
            </ModalBody>
            <ModalFooter>
              <Button
                style={{
                  border: "1px solid grey",
                  color: "black",
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  this.toggle();
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  this.allocateclaims();
                  this.resetuser();
                }}
                style={{ backgroundColor: "grey", color: "#fff" }}
              >
                Allocate
              </Button>
            </ModalFooter>
          </Modal>
        )}

        <Modal
          style={{ width: "30%" }}
          isOpen={this.state.modalunallocate}
          onHide={this.toggle1}
          size="md"
          centered
        >
          <ModalHeader toggle={this.toggle1}>Unallocate</ModalHeader>
          <ModalBody>Do you want to unallocate this claim..?</ModalBody>
          <ModalFooter>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.toggle1();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.unallocateclaims();
              }}
              style={{ backgroundColor: "grey", color: "#fff" }}
            >
              Unallocate
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          style={{ width: "30%" }}
          isOpen={this.state.modalunallocate1}
          onHide={this.toggle2}
          size="md"
          centered
        >
          <ModalHeader toggle={this.toggle2}>Reallocate/Unallocate</ModalHeader>
          <ModalBody>
            <span
              style={{
                fontSize: "20px",
                color: "#041c3c",
                textAlign: "center",
              }}
            >
              Do you want to..?
            </span>
            <br />
            <div className="multi-button">
              <button
                className="reunalloc"
                onClick={() => {
                  this.toggle();
                }}
              >
                Reallocate
              </button>
              <button
                className="reunalloc"
                onClick={() => {
                  this.bulkunallocatedclaims();
                  this.resetuser();
                }}
              >
                Unallocate
              </button>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.toggle2();
              }}
            >
              Cancel
            </Button>
            {/* <Button
              onClick={() => {
                this.unallocateclaims();
              }}
              style={{ backgroundColor: "grey", color: "#fff" }}
            >
              Unallocate
            </Button> */}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Keyallocated;

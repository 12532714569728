import React, { Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import { Row, Col } from "react-bootstrap";
import { Button } from "reactstrap";
import { DatePicker } from "antd";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import moment from "moment";
import { FiSettings } from "react-icons/fi";
import Switch from "react-switch";
import { ip } from ".././Api";
import axios from "axios";


import { Modal, ModalHeader, ModalBody, Input } from "reactstrap";

const { RangePicker } = DatePicker;

export default class AdvancedMD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settingsmodal: false,
      checked:false,
      appointment:"",
      client:sessionStorage.getItem("clientid"),
      officekey:sessionStorage.getItem("officekey"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      callvisitdate:"",
      startdate:"",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(checked) {
    this.setState({ checked: !this.state.checked });
  }
  handleCallvisit = async (data) => {    
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let value;
    value = {
      officekey: parseInt(this.state.officekey),
      clientId: parseInt(this.state.client),
      date:  this.state.startdate,
  }
    // eslint-disable-next-line
    const res = await axios
      .post(`${ip}/advancedmdapisync/advancedmdClaimstatusApi`, value, {
        headers: headers,
      })
      .then((res) => {
        
      })
        .catch((err) => {});
  };

  //setting icon toggle
  toggleSettings = () => {
    this.setState({
      settingsmodal: !this.state.settingsmodal,
    });
  };

  render() {
    return (
      <div>
      <Newsidebar name="Advanced MD" />
      <div className="align-row" style={{ overflow: "hidden" }}>
        <div className="align-column">
          <br />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ fontSize: "13px" }}>
              <span>
                <Link to="/api-sync" style={{ textDecoration: "none" }}>
                  API Sync
                </Link>
                &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
                <span style={{ color: "#0D6EFD" }}>Advanced MD</span>
              </span>
            </div>
            <div>
              <button
                style={{
                  backgroundColor: "#E5E1DA",
                  border: "1px solid #E5E1DA",
                  width: "50%",
                  padding: "5px",
                  marginRight: "40px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                onClick={this.toggleSettings}
              >
                <FiSettings style={{ fontSize: "18px" }} />
              </button>
            </div>
          </div>

          <div className="nosql-container">
            <div className="nosql1">
              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label">Call Visit</h5>
                </Col>
                <Col md="6">
                <RangePicker
                format="MM-DD-YYYY"
                onChange={(dates, datestring) => {
                  this.setState({
                    startdate : datestring[0],
                    enddate: datestring[1],
                  });
                }}
                width="200%"
                style={{
                  borderColor: "gray",
                  float: "right",
                  width:"98%",
                  marginRight: "2%",
                }}
              />
                </Col>
                <Col>
                  <Button style={{ background: "#07326c" }}
                  onClick={() => {
                    this.handleCallvisit();
                  }}
                  >Run</Button>
                </Col>
              </Row>
              <br />

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label">Updated Visit</h5>
                </Col>
                <Col md="6">
                <RangePicker
                format="MM-DD-YYYY"
                // defaultValue={[moment('28-02-2024', 'DD-MM-YYYY'), moment('28-02-2024', 'DD-MM-YYYY')]}
                onChange={(dates, datestring) => {
                  this.setState({
                    startdate1: datestring[0],
                    enddate: datestring[1],
                  });
                }}
                width="200%"
                style={{
                  borderColor: "gray",
                  float: "right",
                  width:"98%",
                  marginRight: "2%",
                }}
              />
                </Col>
                <Col>
                  <Button style={{ background: "#07326c" }}
             
                  >Run</Button>
                </Col>
              </Row>
              <br />

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label">Appointment</h5>
                </Col>
                <Col md="6">
                <DatePicker
                format="MM/DD/YYYY"
                style={{
                  borderColor: "gray",
                  float: "right",
                  marginRight: "2%",
                  width: "98%",
                }}
              />
                </Col>
                <Col>
                  <Button style={{ background: "#07326c" }}>Run</Button>
                </Col>
              </Row>
              <br />
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.settingsmodal}
          onHide={this.toggleSettings}
          title="Settings"
          size="L"
          centered
        >
          <ModalHeader toggle={this.toggleSettings}>Settings</ModalHeader>
          <ModalBody>
            <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
              <Col md="4">
                <h5 className="label">Chart Note</h5>
              </Col>
              <Col>
              <Switch
              onChange={this.handleChange}
              checked={this.state.checked}
            />
              </Col>
            </Row>

            <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
            <Col md="4">
              <h5 className="label">Collection Note</h5>
            </Col>
            <Col>
            <Switch
            onChange={this.handleChange}
            checked={this.state.checked}
          />
            </Col>
          </Row>

          
          <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
          <Col md="4">
          <h5 className="label">Note Type</h5>
          </Col>
          <Col>
           <Input
           type="text"
           >
           </Input>
          </Col>
        </Row>
          </ModalBody>
        </Modal>
      </div>
      </div>
    );
  }
}

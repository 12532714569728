import { React, Component } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";
import { AiFillSetting } from "react-icons/ai";
import { Card, Row } from "react-bootstrap";
import { Col, Input } from "reactstrap";
import { Modal } from "react-bootstrap";
import { ip } from "../../Api";
import moment from "moment-timezone";
import axios from "axios";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { Typeahead } from "react-bootstrap-typeahead";
import EditForm from "../EditForm";
const normalizeInput = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;
  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
};
export default class SimpleAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isButtonDisabled: false,
      anchorEl: null,
      authmodal: false,
      addmodal: false,
      viewmodal: false,
      editmodal: false,
      classvalue: this.props.classvalue,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("clientid"),
      payernamearray: [],
      payername: "",
      Updatedpayername: this.props.payername,
      contactname: "",
      callref: "",
      phone: "",
      effectivedate: "",
      expirationdate: "",
      authid: "",
      type: "",
      noofvisits: "",
      service: "",
      maxamtallow: "",
      status: "",
      notes: "",
      viewpayername: "",
      viewcontactname: "",
      viewphonenumber: "",
      viewcallref: "",
      vieweffectivedate: "",
      viewexpirationdate: "",
      viewauthid: "",
      viewtype: "",
      viewnoofvisits: "",
      viewservice: "",
      viewmaxamtallow: "",
      viewstatus: "",
      viewnotes: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  AddResponse = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let testservice;
     if(this.props.service){
      testservice= this.props.service
     }
     else{
      testservice= this.state.service
     }
     let testtype;
     if(this.props.type){
       testtype= this.props.type
     }
     else{
      testtype= this.state.type
     }
     const value = {
        PayerName: this.props.payername,
        contactName: this.state.contactname,
        phoneNumber: this.state.phone,
        callReference: this.state.callref,
        effectiveDate: this.state.effectivedate,
        expirationDate: this.state.expirationdate,
        authorizationId: this.state.authid,
        type: testtype,
        noOfVisits: this.state.noofvisits,
        service: testservice,
        maximumAmountAllowed: this.state.maxamtallow,
        Status: this.state.status,
        status: "Response",
        notes: this.state.notes,
        clientId: this.state.client,
        ClaimID: this.props.claimID,
        SubscriberFirstName: this.props.SubsFirstName,
        SubscriberLastName: this.props.SubsLastName,
        SubscriberId: this.state.SubsID,
        SubscriberMRN: this.props.MRN,
        providerDetils: this.props.NPI,
        serviceType: testservice,
        claimType: this.props.claimtype,
      };

    // eslint-disable-next-line
    const res = await axios
      .post(`${ip}/authorization/addresponse`, value, {
        headers: headers,
      })
      .then((res) => {
        alert("Added sucessfully");
        this.setState({
          addmodal: !this.state.addmodal,
          isButtonDisabled: false,
        },
        () => {
          window.location.reload(); // Reload the screen
        });

      })
      .catch((err) => {});
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  handleChange({ target: { value } }) {
    this.setState((prevState) => ({
      phone: normalizeInput(value, prevState.phone),
    }));
  }
  //get payer name
  getpayer = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.client) {
      url = `${ip}/payermaster/all?clientId=${this.state.client}`;
    } else {
      url = `${ip}/payermaster/all`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          payernamearray: res.data.payer,
        });
      })

      .catch((err) => {});
  };
  handleClick = (event) => this.setState({ anchorEl: event.currentTarget });
  handleClose = () => this.setState({ anchorEl: null });
  toggleAuth = () => {
    this.setState({
      authmodal: !this.state.authmodal,
    });
  };
  toggleEdit = () => {
    this.setState({
      editmodal: !this.state.editmodal,
    });
  };
  toggleAdd = () => {
    this.setState({
      addmodal: !this.state.addmodal,
      contactname: "",
      phone: "",
      callref: "",
      effectivedate: "",
      expirationdate: "",
      authid: "",
      type: "",
      noofvisits: "",
      service: "",
      maxamtallow: "",
      status: "",
      notes: "",
    });
  };

  componentDidMount() {
    
    this.getpayer();
    const { payername } = this.props;
    this.setState({ payername });
    const { service } = this.props;
    this.setState({ service });
  }
  render() {
    const { anchorEl } = this.state;
    return (
      <div>
        <div>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <AiFillSetting
              style={{ color: "#57534b", marginTop: "2%" }}
            ></AiFillSetting>
          </Button>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            aria-labelledby="composition-button"
            style={{ marginTop: "1%" }}
          >
            <MenuItem
              onClick={() => {
                this.toggleEdit();
                this.handleClose();
              }}
            >
              Edit Auth
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.toggleAdd();
                this.handleClose();
              }}
            >
              Add Response
            </MenuItem>
          </Menu>
        </div>
        <Modal
          show={this.state.editmodal}
          onHide={this.toggleEdit}
          size="lg"
          centered
          animation={false}
        >
          <Modal.Header toggle={this.toggleEdit} closeButton closeLabel="">
            Edit Auth
          </Modal.Header>
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <p style={{ color: "blue" }}>
                Claim ID :{" "}
                <span style={{ color: "#041c3c" }}>{this.props.claimID}</span>
              </p>
            </div>
            <div style={{ paddingTop: "1%" }}>
              <div>
                <Row style={{ paddingTop: "1%", justifyContent: "center" }}>
                  <Col md="4">
                    <h5
                      className="label required"
                      style={{ textAlign: "left" }}
                    >
                      Payer Name
                    </h5>
                  </Col>
                  <Col md="6">
                    <Typeahead
                      id="typeahed1"
                      clearButton={true}
                      defaultInputValue={this.state.Updatedpayername}
                      options={this.state.payernamearray}
                      labelKey={(option) =>
                        (this.state.Updatedpayername = `${option.payerName}`)
                      }
                      onClick={(e) => {
                        this.setState({
                          Updatedpayername: e.target.value,
                        });
                      }}
                      placeholder="Select Payer Name"
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: "1%", justifyContent: "center" }}>
                  <Col md="4">
                    <h5
                      className="label required"
                      style={{ textAlign: "left" }}
                    >
                      Type of Request
                    </h5>
                  </Col>
                  <Col md="6">
                    <Input type="select" placeholder="Select Request">
                      <option value={""} hidden>
                        Select Type of Request
                      </option>
                      <option value={"Referral"}>Referral</option>
                      <option value={"Admission for Surgery"}>
                        Admission for Surgery
                      </option>
                      <option
                        value={
                          "Request for Behavioral Health Emergency Admission"
                        }
                      >
                        Request for Behavioral Health Emergency Admission
                      </option>
                      <option value={"Request for Home Health Care"}>
                        Request for Home Health Care
                      </option>
                      <option
                        value={
                          "Request for Non-emergency Transportation Service"
                        }
                      >
                        Request for Non-emergency Transportation Service
                      </option>
                      <option value={"Medical Services Reservation"}>
                        Medical Services Reservation
                      </option>
                    </Input>
                  </Col>
                </Row>
                <br />
                <Row
                  style={{
                    paddingTop: "1%",
                    justifyContent: "center",
                    marginLeft: "70%",
                  }}
                >
                  <Col md="10" style={{ display: "flex" }}>
                    <Link
                      to={{
                        pathname: "/eligibility/EditForm",
                        state: {
                          claimvalue: this.props.claimID,
                          classvalue: this.props.classvalue,
                        },
                      }}
                    >
                      <Button
                        style={{ background: "#07326c", color: "#fff" }}
                        onClick={() => (
                          <EditForm
                            claimvalue={this.props.claimID}
                            classvalue={this.props.classvalue}
                          />
                        )}
                      >
                        Next
                      </Button>
                    </Link>{" "}
                    &nbsp;
                    <Button
                      style={{ background: "#07326c", color: "#fff" }}
                      onClick={this.toggleEdit}
                    >
                      Close
                    </Button>
                  </Col>
                </Row>
                <br />
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.addmodal}
          onHide={this.toggleAdd}
          size="lg"
          centered
          animation={false}
        >
          <Modal.Header toggle={this.toggleAdd} closeButton closeLabel="">
            Add Response
          </Modal.Header>
          <Modal.Body
            style={{
              "max-height": "calc(100vh - 210px)",
              "overflow-y": "scroll",
              "overflow-x": "hidden",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <p style={{ color: "blue" }}>
                Claim ID :{" "}
                <span style={{ color: "#041c3c" }}>{this.props.claimID}</span>
              </p>
            </div>
            <div className="auth_form">
              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Payer Name
                  </h5>
                </Col>
                <Col md="6">
                  <Typeahead
                    id="typeahed1"
                    clearButton={true}
                    inputProps={this.props.payername}
                    defaultInputValue={this.props.payername}
                    options={this.state.payernamearray}
                    labelKey={(option) =>
                      (this.state.payername = `${option.payerName}`)
                    }
                    onChange={(e) => {
                      this.setState({
                        payername: e,
                      });
                    }}
                    placeholder="Select Payer Name"
                  />
                </Col>
              </Row>
              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Contact Name
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    placeholder=""
                    value={this.state.contactname}
                    onChange={(e) => {
                      this.setState({ contactname: e.target.value });
                    }}
                  ></Input>
                </Col>
              </Row>
              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Phone Number
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    name="phone"
                    placeholder="(xxx) xxx-xxxx"
                    value={this.state.phone}
                    onChange={this.handleChange}
                  />
                  {this.state.error && (
                    <p className="error">{this.state.error}</p>
                  )}
                </Col>
              </Row>
              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Call reference #
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    placeholder=""
                    value={this.state.callref}
                    onChange={(e) => {
                      this.setState({ callref: e.target.value });
                    }}
                  ></Input>
                </Col>
              </Row>
              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Effective Date
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="date"
                    placeholder=""
                    value={this.state.effectivedate}
                    onChange={(e) => {
                      this.setState({ effectivedate: e.target.value });
                    }}
                  ></Input>
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Expiration Date
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="date"
                    placeholder=""
                    value={this.state.expirationdate}
                    onChange={(e) => {
                      this.setState({ expirationdate: e.target.value });
                    }}
                  />
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Authorization ID
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    placeholder=""
                    value={this.state.authid}
                    onChange={(e) => {
                      this.setState({ authid: e.target.value });
                    }}
                  />
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Type
                  </h5>
                </Col>
                <Col md="6">
                  {this.props.type ?
    ( <Input
     type="text"
     value={this.props.type}
   >
   </Input> ): ( 
        <Input
        type="select"
        value={this.state.type}
        onChange={(e) => {
          this.setState({ type: e.target.value });
        }}
      >
        <option value={""} hidden>
          Select Type of Request
        </option>
        <option value={"Referral"}>Referral</option>
        <option value={"Admission for Surgery"}>
          Admission for Surgery
        </option>
        <option
          value={
            "Request for Behavioral Health Emergency Admission"
          }
        >
          Request for Behavioral Health Emergency Admission
        </option>
        <option value={"Request for Home Health Care"}>
          Request for Home Health Care
        </option>
        <option
          value={"Request for Non-emergency Transportation Service"}
        >
          Request for Non-emergency Transportation Service
        </option>
        <option value={"Medical Services Reservation"}>
          Medical Services Reservation
        </option>
      </Input>
              )    }
             
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Number of Visits
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    placeholder=""
                    value={this.state.noofvisits}
                    onChange={(e) => {
                      this.setState({ noofvisits: e.target.value });
                    }}
                  />
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Service
                  </h5>
                </Col>
                <Col md="6">
                  {this.props.service ? (
                    <Input
                      type="text"
                      placeholder=""
                      value={this.props.service}
                      onChange={(e) => {
                        this.setState({ service: e.target.value });
                      }}
                    />
                  ) : (
                    <Input
                      type="text"
                      placeholder=""
                      value={this.state.service}
                      onChange={(e) => {
                        this.setState({ service: e.target.value });
                      }}
                    />
                  )}
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Maximum Amount Allowed
                  </h5>
                </Col>
                <Col md="6">
                  <div class="input-group prefix">
                    <span class="input-group-addon">$</span>
                    <Input
                      type="text"
                      placeholder=""
                      value={this.state.maxamtallow}
                      onChange={(e) => {
                        this.setState({ maxamtallow: e.target.value });
                      }}
                    ></Input>
                  </div>
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Status
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="select"
                    placeholder=""
                    value={this.state.status}
                    onChange={(e) => {
                      this.setState({ status: e.target.value });
                    }}
                  >
                    <option value="" hidden>
                      Select Status...
                    </option>
                    <option value="Active">Active</option>
                    <option value="Expired">Expired</option>
                  </Input>
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Notes
                  </h5>
                </Col>
                <Col md="6">
                  <textarea
                    type="text"
                    style={{ height: "100px", width: "100%", outline: "none" }}
                    placeholder=""
                    value={this.state.notes}
                    onChange={(e) => {
                      this.setState({ notes: e.target.value });
                    }}
                  />
                </Col>
              </Row>
              <br />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Row
              style={{
                paddingTop: "4%",
                justifyContent: "center",
                marginLeft: "70%",
              }}
            >
              <Col md="10" style={{ display: "flex" }}>
                {this.state.isButtonDisabled === true ? (
                  <Button
                    disabled
                    style={{ height: "40px" }}
                    onClick={() =>
                      this.setState(
                        {
                          isButtonDisabled: true,
                        },
                        () => this.AddResponse()
                      )
                    }
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    style={{ background: "#07326c", color: "white" }}
                    onClick={() =>
                      this.setState(
                        {
                          isButtonDisabled: true,
                        },
                        () => this.AddResponse()
                      )
                    }
                  >
                    Save
                  </Button>
                )}
                &nbsp;
                <Button
                  style={{ background: "#07326c", color: "white" }}
                  onClick={() => this.toggleAdd()}
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

import React, { Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar"; 
import Claim from "./Reports/Claim";
import Cpt from "./Reports/Cpt";
import Arlog from "./Reports/Arlogs";
import { DatePicker } from "antd";
import moment from "moment-timezone";
import DailyReport from "./Reports/DailyReport";
import OpenedClaims from "./Reports/OpenedClaims";
import { IoIosRefresh } from "react-icons/io";
import Tabs, { Tab } from "react-best-tabs";
import { Input } from "reactstrap";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import { FormControl } from "react-bootstrap";
import ClaimwiseProduction from "./Reports/ClaimwiseProduction";
import ClosedCpt from "./Reports/ClosedCpt";
import { CombinedReport } from "./Reports/CombinedReport";
import { Container, Row } from "react-bootstrap";
import warning from "../../Images/warning.png";

const { RangePicker } = DatePicker;

export class Reports extends Component {
  constructor(props) {
    super(props);
    let now = new Date();
    let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    let start1 = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let end1 = moment(start).add(1, "days").subtract(1, "seconds");
    this.state = {
      start: start,
      end: end,
      start1: start1,
      end1: end1,
      startdate: "",
      enddate: "",
      filterstatus: "custom",
      tabindex: 1,
      startdatetime: "",
      enddatetime: "",
      startdatetime1: "",
      enddatetime1: "",
      roleAccess: JSON.parse(sessionStorage.getItem("role_access")) || {},
    };
    this.applyCallback = this.applyCallback.bind(this);
    this.applyCallback1 = this.applyCallback1.bind(this);
    this.valuedate = this.valuedate.bind(this);
  }

  applyCallback(startDate, endDate) {
    let start = moment(startDate._d).format().substring(0, 16);
    let end = moment(endDate._d).format().substring(0, 16);
    this.setState({
      startdatetime: start,
      enddatetime: end,
    });
  }

  applyCallback1(startDate, endDate) {
    let start = moment(startDate._d).format().substring(0, 16);
    let end = moment(endDate._d).format().substring(0, 16);
    this.setState({
      startdatetime1: start,
      enddatetime1: end,
    });
  }

  valuedate(option, e) {
    if (option === "yesterday") {
      this.setState({
        startdate: e.target.value,
        enddate: moment().subtract(1, "days").format("MM-DD-YYYY"),
        filterstatus: option,
      });
    } else {
      this.setState({
        startdate: e.target.value,
        enddate: moment().format("MM-DD-YYYY"),
        filterstatus: option,
      });
    }
  }

  render() {
    const { roleAccess } = this.state;
    let value;
    let date1 = moment().subtract(7, "days").format("MM-DD-YYYY HH:mm").substring(0, 16);
    let date2 = moment().format("MM-DD-YYYY HH:mm").substring(0, 16);
    if (this.state.startdatetime && this.state.enddatetime) {
      value = `${moment(this.state.startdatetime.substring(0, 16)).format("MM-DD-YYYY HH:mm")} ~ ${moment(this.state.enddatetime.substring(0, 16)).format("MM-DD-YYYY HH:mm")}`;
    } else {
      value = `${date1} ~ ${date2}`;
    }
    let value1;
    if (this.state.startdatetime1 && this.state.enddatetime1) {
      value1 = `${moment(this.state.startdatetime1.substring(0, 16)).format("MM-DD-YYYY HH:mm")} ~ ${moment(this.state.enddatetime1.substring(0, 16)).format("MM-DD-YYYY HH:mm")}`;
    } else {
      value1 = "Start Date ~ End Date";
    }
    let now = new Date();
    let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    let start1 = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let end1 = moment(start).add(1, "days").subtract(1, "seconds");
    let local = {
      format: "MM-DD-YYYY HH:mm",
      sundayFirst: false,
    };
    let local1 = {
      format: "MM-DD-YYYY HH:mm",
      sundayFirst: false,
    };

    return (
      <div>
        <Newsidebar name="Generate Reports" />
        <div className="align-row" style={{ overflow: "hidden" }}>
            <div style={{  paddingTop: "1%" }}>
              <Tabs
                activeTab="1"
                style={{ margin: "2%" }}
                onClick={(event, tab) => {
                  this.setState({ tabindex: tab });
                }}
              >
              <Tab title="CLAIM WISE">
                {roleAccess.claimwiseReport ? (
                  <div>
                  <div style={{display:"flex", flexDirection:"row"}}>
                <Input
                  type="select"
                  style={{ width: "20%",  marginTop: "1%" }}
                  className="form-control form-select"
                  onChange={(e) => {
                    const index = e.target.selectedIndex;
                    const el = e.target.childNodes[index];
                    const option = el.getAttribute("title");
                    this.valuedate(option, e);
                  }}
                >
                  <option value="" title="custom">
                    Custom
                  </option>
                  <option value={moment().format("MM-DD-YYYY")} title="">
                    Today
                  </option>
                  <option
                    value={moment().subtract(1, "days").format("MM-DD-YYYY")}
                    title="yesterday"
                  >
                    Yesterday
                  </option>
                  <option
                    value={moment().subtract(3, "days").format("MM-DD-YYYY")}
                    title=""
                  >
                    Last 3 days
                  </option>
                  <option
                    value={moment().subtract(7, "days").format("MM-DD-YYYY")}
                    title=""
                  >
                    Last 7 days
                  </option>
                  <option
                    value={moment().subtract(30, "days").format("MM-DD-YYYY")}
                    title=""
                  >
                    Last 30 days
                  </option>
                  <option
                    value={moment().subtract(365, "days").format("MM-DD-YYYY")}
                    title=""
                  >
                    Last 365 days
                  </option>
                </Input>&nbsp;&nbsp;
                {this.state.filterstatus === "custom" && (
                  <RangePicker
                    format="MM-DD-YYYY"
                    onChange={(dates, datestring) => {
                      this.setState({
                        startdate: datestring[0],
                        enddate: datestring[1],
                      });
                    }}
                    width="200%"
                    style={{ borderColor: "gray",  marginTop: "1%" }}
                  />
                )}
              </div>
              <Claim
              startdate={this.state.startdate}
              enddate={this.state.enddate}
            />
                  </div>
                ): 
                <Container
                style={{
                  paddingTop: "100px",
                }}
              >
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <h2>Access Denied !</h2>
                </Row>
                <Row
                  style={{
                    justifyContent: "center",
                  }}
                ></Row>
                <Row
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={warning}
                    alt=""
                    style={{
                      width: "200px",
                      height: "200px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ marginTop: "-3%" }}>
                    {" "}
                    You are not authorized to view this content. Contact admin to get
                    access.
                  </p>
                </Row>
              </Container>
              }
                </Tab>
                <Tab title="CPT WISE">
                {roleAccess.cptwiseReport ? (
                  <div>
                  <div style={{display:"flex", flexDirection:"row"}}>
                  <Input
                    type="select"
                    style={{ width: "20%",  marginTop: "1%" }}
                    className="form-control form-select"
                    onChange={(e) => {
                      const index = e.target.selectedIndex;
                      const el = e.target.childNodes[index];
                      const option = el.getAttribute("title");
                      this.valuedate(option, e);
                    }}
                  >
                    <option value="" title="custom">
                      Custom
                    </option>
                    <option value={moment().format("MM-DD-YYYY")} title="">
                      Today
                    </option>
                    <option
                      value={moment().subtract(1, "days").format("MM-DD-YYYY")}
                      title="yesterday"
                    >
                      Yesterday
                    </option>
                    <option
                      value={moment().subtract(3, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 3 days
                    </option>
                    <option
                      value={moment().subtract(7, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 7 days
                    </option>
                    <option
                      value={moment().subtract(30, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 30 days
                    </option>
                    <option
                      value={moment().subtract(365, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 365 days
                    </option>
                  </Input> &nbsp;&nbsp;
                  {this.state.filterstatus === "custom" && (
                    <RangePicker
                      format="MM-DD-YYYY"
                      onChange={(dates, datestring) => {
                        this.setState({
                          startdate: datestring[0],
                          enddate: datestring[1],
                        });
                      }}
                      width="200%"
                      style={{ borderColor: "gray",  marginTop: "1%" }}
                    />
                  )}
                </div>
                    <Cpt
                      startdate={this.state.startdate}
                      enddate={this.state.enddate}
                    />
                    </div>
                ): 
                <Container
                style={{
                  paddingTop: "100px",
                }}
              >
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <h2>Access Denied !</h2>
                </Row>
                <Row
                  style={{
                    justifyContent: "center",
                  }}
                ></Row>
                <Row
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={warning}
                    alt=""
                    style={{
                      width: "200px",
                      height: "200px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ marginTop: "-3%" }}>
                    {" "}
                    You are not authorized to view this content. Contact admin to get
                    access.
                  </p>
                </Row>
              </Container>
              }
                </Tab>
                <Tab title="AR LOG WISE">
                {roleAccess.arlogwiseReport ? (
                <div>
                <div style={{display:"flex", flexDirection:"row"}}>
                <Input
                  type="select"
                  style={{ width: "20%",  marginTop: "1%" }}
                  className="form-control form-select"
                  onChange={(e) => {
                    const index = e.target.selectedIndex;
                    const el = e.target.childNodes[index];
                    const option = el.getAttribute("title");
                    this.valuedate(option, e);
                  }}
                >
                  <option value="" title="custom">
                    Custom
                  </option>
                  <option value={moment().format("MM-DD-YYYY")} title="">
                    Today
                  </option>
                  <option
                    value={moment().subtract(1, "days").format("MM-DD-YYYY")}
                    title="yesterday"
                  >
                    Yesterday
                  </option>
                  <option
                    value={moment().subtract(3, "days").format("MM-DD-YYYY")}
                    title=""
                  >
                    Last 3 days
                  </option>
                  <option
                    value={moment().subtract(7, "days").format("MM-DD-YYYY")}
                    title=""
                  >
                    Last 7 days
                  </option>
                  <option
                    value={moment().subtract(30, "days").format("MM-DD-YYYY")}
                    title=""
                  >
                    Last 30 days
                  </option>
                  <option
                    value={moment().subtract(365, "days").format("MM-DD-YYYY")}
                    title=""
                  >
                    Last 365 days
                  </option>
                </Input> &nbsp;&nbsp;
                {this.state.filterstatus === "custom" && (
                  <RangePicker
                    format="MM-DD-YYYY"
                    onChange={(dates, datestring) => {
                      this.setState({
                        startdate: datestring[0],
                        enddate: datestring[1],
                      });
                    }}
                    width="200%"
                    style={{ borderColor: "gray",  marginTop: "1%" }}
                  />
                )}
              </div>
                    <Arlog
                      startdate={this.state.startdate}
                      enddate={this.state.enddate}
                    />
                    </div>
                ): 
                <Container
                style={{
                  paddingTop: "100px",
                }}
              >
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <h2>Access Denied !</h2>
                </Row>
                <Row
                  style={{
                    justifyContent: "center",
                  }}
                ></Row>
                <Row
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={warning}
                    alt=""
                    style={{
                      width: "200px",
                      height: "200px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ marginTop: "-3%" }}>
                    {" "}
                    You are not authorized to view this content. Contact admin to get
                    access.
                  </p>
                </Row>
              </Container>
              }
                </Tab>
                  <Tab title="DAILY PRODUCTION REPORT">
                {roleAccess.dailyProductionReport ? (
                  <div>
                  {this.state.tabindex === 4 && roleAccess.dailyProductionReport && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          marginLeft: "6%",
                          marginTop: "1%",
                          width: "40%",
                          position: "relative",
                        }}
                      >
                        <DateTimeRangeContainer
                          start={this.state.start}
                          end={this.state.end}
                          applyCallback={this.applyCallback}
                          local={local}
                        >
                          <FormControl
                            id="formControlsTextB"
                            type="text"
                            label="Text"
                            placeholder="Select Date Range"
                            value={value}
                          />
                        </DateTimeRangeContainer>
                      </div>
      
                      <IoIosRefresh
                        style={{
                          color: "blue",
                          marginTop: "1%",
                          marginLeft: "2%",
                          width: "48px",
                          height: "48px",
                          padding: "10px 12px",
                          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)"
                        }}
                        onClick={() => {
                          this.setState({ startdatetime: "", enddatetime: "" });
                        }}
                      />
                    </div>
                  )}
                  <DailyReport
                      startdate={this.state.startdatetime}
                      enddate={this.state.enddatetime}
                    />
                  </div>
                    
                ): 
                <Container
                style={{
                  paddingTop: "100px",
                }}
              >
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <h2>Access Denied !</h2>
                </Row>
                <Row
                  style={{
                    justifyContent: "center",
                  }}
                ></Row>
                <Row
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={warning}
                    alt=""
                    style={{
                      width: "200px",
                      height: "200px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ marginTop: "-3%" }}>
                    {" "}
                    You are not authorized to view this content. Contact admin to get
                    access.
                  </p>
                </Row>
              </Container>
              }
                </Tab>
                <Tab title="OPEN CLAIMS">
                {roleAccess.openClaimsReport ? (
                  <div>
                  <div style={{display:"flex", flexDirection:"row"}}>
                  <Input
                    type="select"
                    style={{ width: "20%",  marginTop: "1%" }}
                    className="form-control form-select"
                    onChange={(e) => {
                      const index = e.target.selectedIndex;
                      const el = e.target.childNodes[index];
                      const option = el.getAttribute("title");
                      this.valuedate(option, e);
                    }}
                  >
                    <option value="" title="custom">
                      Custom
                    </option>
                    <option value={moment().format("MM-DD-YYYY")} title="">
                      Today
                    </option>
                    <option
                      value={moment().subtract(1, "days").format("MM-DD-YYYY")}
                      title="yesterday"
                    >
                      Yesterday
                    </option>
                    <option
                      value={moment().subtract(3, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 3 days
                    </option>
                    <option
                      value={moment().subtract(7, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 7 days
                    </option>
                    <option
                      value={moment().subtract(30, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 30 days
                    </option>
                    <option
                      value={moment().subtract(365, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 365 days
                    </option>
                  </Input> &nbsp;&nbsp;
                  {this.state.filterstatus === "custom" && (
                    <RangePicker
                      format="MM-DD-YYYY"
                      onChange={(dates, datestring) => {
                        this.setState({
                          startdate: datestring[0],
                          enddate: datestring[1],
                        });
                      }}
                      width="200%"
                      style={{ borderColor: "gray",  marginTop: "1%" }}
                    />
                  )}
                </div>
                <OpenedClaims
                startdate={this.state.startdate}
                enddate={this.state.enddate}
              />
                  </div>
           
                ): 
                <Container
                style={{
                  paddingTop: "100px",
                }}
              >
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <h2>Access Denied !</h2>
                </Row>
                <Row
                  style={{
                    justifyContent: "center",
                  }}
                ></Row>
                <Row
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={warning}
                    alt=""
                    style={{
                      width: "200px",
                      height: "200px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ marginTop: "-3%" }}>
                    {" "}
                    You are not authorized to view this content. Contact admin to get
                    access.
                  </p>
                </Row>
              </Container>
              }
                </Tab>
                <Tab title="CLAIMWISE PRODUCTION REPORT">
                {roleAccess.claimwiseProductionReport ? (
                  <div>
                  {this.state.tabindex === 6 && roleAccess.claimwiseProductionReport && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          marginLeft: "6%",
                          marginTop: "1%",
                          width: "40%",
                          position: "inherit",
                        }}
                      >
                        <DateTimeRangeContainer
                          start={this.state.start1}
                          end={this.state.end1}
                          applyCallback={this.applyCallback1}
                          local={local1}
                        >
                          <FormControl
                            id="formControlsTextB"
                            type="text"
                            label="Text"
                            placeholder="Select Date Range"
                            value={value1}
                          />
                        </DateTimeRangeContainer>
                      </div>
                      <IoIosRefresh
                        style={{
                          color: "blue",
                          marginTop: "1%",
                          marginLeft: "2%",
                          width: "48px",
                          height: "48px",
                          padding: "10px 12px",
                          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                        }}
                        onClick={() => {
                          this.setState({ startdatetime1: "", enddatetime1: "" });
                        }}
                      />
                    </div>
                  )}
                  <ClaimwiseProduction
                      startdate={this.state.startdatetime1}
                      enddate={this.state.enddatetime1}
                    />
                  </div>
                    
                ): 
                <Container
                style={{
                  paddingTop: "100px",
                }}
              >
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <h2>Access Denied !</h2>
                </Row>
                <Row
                  style={{
                    justifyContent: "center",
                  }}
                ></Row>
                <Row
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={warning}
                    alt=""
                    style={{
                      width: "200px",
                      height: "200px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ marginTop: "-3%" }}>
                    {" "}
                    You are not authorized to view this content. Contact admin to get
                    access.
                  </p>
                </Row>
              </Container>
              }
                </Tab>
                <Tab title="CLOSED CPT">
                {roleAccess.closedCPT ? (
                  <div>
                  <div style={{display:"flex", flexDirection:"row"}}>
                  <Input
                    type="select"
                    style={{ width: "20%",  marginTop: "1%" }}
                    className="form-control form-select"
                    onChange={(e) => {
                      const index = e.target.selectedIndex;
                      const el = e.target.childNodes[index];
                      const option = el.getAttribute("title");
                      this.valuedate(option, e);
                    }}
                  >
                    <option value="" title="custom">
                      Custom
                    </option>
                    <option value={moment().format("MM-DD-YYYY")} title="">
                      Today
                    </option>
                    <option
                      value={moment().subtract(1, "days").format("MM-DD-YYYY")}
                      title="yesterday"
                    >
                      Yesterday
                    </option>
                    <option
                      value={moment().subtract(3, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 3 days
                    </option>
                    <option
                      value={moment().subtract(7, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 7 days
                    </option>
                    <option
                      value={moment().subtract(30, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 30 days
                    </option>
                    <option
                      value={moment().subtract(365, "days").format("MM-DD-YYYY")}
                      title=""
                    >
                      Last 365 days
                    </option>
                  </Input>&nbsp;&nbsp;
                  {this.state.filterstatus === "custom" && (
                    <RangePicker
                      format="MM-DD-YYYY"
                      onChange={(dates, datestring) => {
                        this.setState({
                          startdate: datestring[0],
                          enddate: datestring[1],
                        });
                      }}
                      width="200%"
                      style={{ borderColor: "gray",  marginTop: "1%" }}
                    />
                  )}
                </div>
                <ClosedCpt
                startdate={this.state.startdate}
                enddate={this.state.enddate}
              />
                  </div>
           
                ): 
                <Container
                style={{
                  paddingTop: "100px",
                }}
              >
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <h2>Access Denied !</h2>
                </Row>
                <Row
                  style={{
                    justifyContent: "center",
                  }}
                ></Row>
                <Row
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={warning}
                    alt=""
                    style={{
                      width: "200px",
                      height: "200px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ marginTop: "-3%" }}>
                    {" "}
                    You are not authorized to view this content. Contact admin to get
                    access.
                  </p>
                </Row>
              </Container>
              }
                </Tab>
                <Tab title="COMBINED REPORT">
                {roleAccess.combinedReport ? (
                  <div>
                  <div style={{display:"flex", flexDirection:"row"}}>
                <Input
                  type="select"
                  style={{ width: "20%",  marginTop: "1%" }}
                  className="form-control form-select"
                  onChange={(e) => {
                    const index = e.target.selectedIndex;
                    const el = e.target.childNodes[index];
                    const option = el.getAttribute("title");
                    this.valuedate(option, e);
                  }}
                >
                  <option value="" title="custom">
                    Custom
                  </option>
                  <option value={moment().format("MM-DD-YYYY")} title="">
                    Today
                  </option>
                  <option
                    value={moment().subtract(1, "days").format("MM-DD-YYYY")}
                    title="yesterday"
                  >
                    Yesterday
                  </option>
                  <option
                    value={moment().subtract(3, "days").format("MM-DD-YYYY")}
                    title=""
                  >
                    Last 3 days
                  </option>
                  <option
                    value={moment().subtract(7, "days").format("MM-DD-YYYY")}
                    title=""
                  >
                    Last 7 days
                  </option>
                  <option
                    value={moment().subtract(30, "days").format("MM-DD-YYYY")}
                    title=""
                  >
                    Last 30 days
                  </option>
                  <option
                    value={moment().subtract(365, "days").format("MM-DD-YYYY")}
                    title=""
                  >
                    Last 365 days
                  </option>
                </Input>&nbsp;&nbsp;
                {this.state.filterstatus === "custom" && (
                  <RangePicker
                    format="MM-DD-YYYY"
                    onChange={(dates, datestring) => {
                      this.setState({
                        startdate: datestring[0],
                        enddate: datestring[1],
                      });
                    }}
                    width="200%"
                    style={{ borderColor: "gray",  marginTop: "1%" }}
                  />
                )}
              </div>
                <CombinedReport
                startdate={this.state.startdate}
                enddate={this.state.enddate}
              />
                  </div>
          
                ): 
                <Container
                style={{
                  paddingTop: "100px",
                }}
              >
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <h2>Access Denied !</h2>
                </Row>
                <Row
                  style={{
                    justifyContent: "center",
                  }}
                ></Row>
                <Row
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={warning}
                    alt=""
                    style={{
                      width: "200px",
                      height: "200px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ marginTop: "-3%" }}>
                    {" "}
                    You are not authorized to view this content. Contact admin to get
                    access.
                  </p>
                </Row>
              </Container>
              }
                </Tab>
              </Tabs>
            </div>
        </div>
      </div>
    );
  }
}

export default Reports;

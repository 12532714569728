import React, { Component } from "react";
import axios from "axios";
import { ip } from "../../Api";
import ReactPaginate from "react-paginate";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import { Spinner } from "react-bootstrap";
import { Table, Row, Col, Input } from "reactstrap";
import CsvDownloadButton from "react-json-to-csv";
import moment from "moment-timezone";

export class Uploadautomation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchingclaims: true,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("clientid"),
      openclaim: [],
      followupdate: [],
      notesdata: [],
      currentpage1: 0,
      perPage1: 10,
      loading: true,
    };
  }
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage1;

    this.setState(
      {
        currentpage1: selectedPage,
        offset1: offset,
      },
      () => {
        this.getfollowupdate();
      }
    );
  };
  getdownloadreport = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/automatefollowupdateclaims?clientId=${this.state.client}&startdate=${this.props.startdate}&enddate=${this.props.enddate}`;
    } else {
      url = `${ip}/helper/automatefollowupdateclaims?clientId=${this.state.client}`;
    }
    const res = axios;
    fetch(url, { headers: headers }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", "Followupdate");
        a.click();
        URL.revokeObjectURL(a);
      });
    });
  };
  getfollowupdate = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/automatefollowupdateclaims?clientId=${this.state.client}&startdate=${this.props.startdate}&enddate=${this.props.enddate}&pageno=${this.state.currentpage1}`;
    } else {
      url = `${ip}/helper/automatefollowupdateclaims?clientId=${this.state.client}&pageno=${this.state.currentpage1}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          pageCount1: Math.ceil(res.data.count / this.state.perPage1),
          followupdate: res.data.data,
          fetchingclaims: false,
        });
      });
  };

  componentDidMount() {
    

    this.getfollowupdate();
  }

  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.startdate === prevProps.startdate &&
        this.props.enddate === prevProps.enddate
      )
    ) {
      this.getfollowupdate();
    }
  }
  // render() {
  //   return <div>Upload automation</div>;
  // }
  render() {
    return this.state.fetchingclaims ? (
      <div>
        <p
          style={{
            fontSize: "23px",
            marginTop: "10px",
            color: "#000",
          }}
        >
          <span>Loading...</span>
          <span>Please Wait...</span>
        </p>
        <div class="boxes body__loader dribbble">
          <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    ) : this.state.followupdate.length === 0 ? (
      <div class="bot-ui" style={{ marginTop: "6%" }}>
        <div class="bot-head">
          <div class="eyes-container">
            <div class="to-left eye"></div>
            <div class="to-right eye"></div>
          </div>
        </div>
        <div class="bot-body">
          <div class="analyzer"></div>
        </div>
        <p style={{ fontSize: "20px", fontStyle: "italic" }}>No Data Found</p>
      </div>
    ) : (
      <div>
        <br />
        {/* {this.state.loading ? (
          <div>
            <button
              style={{
                //pass other props, like styles
                boxShadow: "inset 0px 1px 0px 0px #e184f3",
                background: "black",
                backgroundColor: "green",
                borderRadius: "6px",
                border: "1px solid green",
                display: "inline-block",
                color: "#ffffff",
                fontSize: "15px",
                fontWeight: "bold",
                padding: "6px 24px",
                textDecoration: "none",
                float: "right",
                textShadow: "0px 1px 0px #9b14b3",
                cursor: "none",
              }}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              &nbsp;&nbsp;Loading data...
            </button>
          </div>
        ) : (
          <CsvDownloadButton
            data={this.state.notesdata}
            filename="openclaimreport.csv"
            style={{
              //pass other props, like styles
              boxShadow: "inset 0px 1px 0px 0px #e184f3",
              background: "black",
              backgroundColor: "black",
              borderRadius: "6px",
              border: "1px solid black",
              display: "inline-block",
              cursor: "pointer",
              color: "#ffffff",
              fontSize: "15px",
              fontWeight: "bold",
              padding: "6px 24px",
              textDecoration: "none",
              textShadow: "0px 1px 0px #9b14b3",
              float: "right",
            }}
          >
            Generate Report
          </CsvDownloadButton>
        )} */}
        <div>
          <button
            style={{
              boxShadow: "inset 0px 1px 0px 0px #E184F3",
              background: "black",
              backgroundColor: "black",
              borderRadius: "6px",
              border: "1px solid black",
              display: "inline-block",
              cursor: "pointer",
              color: "#FFFFFF",
              fontSize: "15px",
              fontWeight: "bold",
              padding: "6px 24px",
              textDecoration: "none",
              textShadow: "0px 1px 0px #9B14B3",
              float: "right",
            }}
            onClick={this.getdownloadreport}
          >
            Generate Report
          </button>
        </div>
        <br />
        <div style={{ width: "100%", overflowX: "scroll" }}>
          <Table
            className="StandardTable"
            bordered
            style={{
              marginLeft: "3%",
              width: "99%",
              marginTop: "4%",
            }}
          >
          <thead style={{ backgroundColor: "#041c3c", color: "white" }}>
          <tr align="center">
            <th>ClaimID</th>
            <th>Machine</th>
            <th>Billing Organization Name</th>
            <th>Physician</th>
            <th>Patient Name</th>
            <th>Patient DOB</th>
            <th>Plan Code</th>
            <th>Pri Payer</th>
            <th>Primary PolicyNo</th>
            <th>Trading Partner ServiceId</th>
            <th>Reason</th>
            <th>Claim Status</th>
            <th>Status Code</th>
            <th>Status Code Value</th>
            <th>Status Category Code</th>
            <th>Status Category Code Value</th>
            <th>Type</th>
            <th>Touch</th>
            <th>Created At</th>
            <th>Billed Amount</th>
            <th>DOS</th>
            <th>Followup Date</th>
            <th>Notes</th>
          </tr>
        </thead>            <tbody>
              {this.state.followupdate &&
                this.state.followupdate.map((data, index) => {
                  return (
                    <tr>
                    <td>{data.ClaimID}</td>
                    <td>{data.Machine}</td>
                    <td
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {data.BillingOrganizationName}
                    </td>
                    <td>{data.Physician}</td>
                    <td>
                      {data.FirstName} {data.LastName}
                    </td>
                    <td>{data.PatientDOB}</td>
                    <td>{data.PlanCode}</td>
                    <td
                      style={{
                        whiteSpace: "normal",
                        textAlign: "justify",
                      }}
                    >
                      {data.Pripayer}
                    </td>
                    <td>{data.PrimaryPolicyNo}</td>
                    <td>{data.tradingPartnerServiceId}</td>

                    <td
                      style={{
                        whiteSpace: "normal",
                        textAlign: "justify",
                      }}
                    >
                      {data.Reason}
                    </td>
                    <td>{data.ClaimStatus}</td>
                    <td>{data.statusCode}</td>
                    <td>{data.statusCodeValue}</td>
                    <td
                      style={{
                        whiteSpace: "normal",
                        textAlign: "justify",
                      }}
                    >
                      {data.statusCategoryCode}
                    </td>
                    <td
                      style={{
                        whiteSpace: "normal",
                        textAlign: "justify",
                      }}
                    >
                      {data.statusCategoryCodeValue}
                    </td>
                    <td
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {data.type}
                    </td>
                    <td
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {data.Touch}
                    </td>
                    <td>{moment(data.CreatedAt).format("YYYY-MM-DD")}</td>
                    <td>{data.TotalBilledAmount}</td>
                    <td>{data.DateOfService}</td>
                    <td>{moment(data.Followup_date).format("YYYY-MM-DD")}</td>
                    <td
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data.Notes}
                    </td>
                  </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
        <br />
        <div>
          <ReactPaginate
            previousLabel={<IoIosArrowBack />}
            nextLabel={<IoIosArrowForward />}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.pageCount1}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick1}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    );
  }
}

export default Uploadautomation;

import React, { Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import "../../Styles/Authorization.css";
import { Row, Col, Card } from "react-bootstrap";
import { Button, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { ip } from "../Api";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Auth from "../../Files/Authorization.xlsx";


export default class Authorization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("clientid"),
      file: null,
      type: "",
      upload: [],
      fileUploaded: false,
    };
    this.fileInput = React.createRef();
  }
  onFileChange = (e) => {
    this.setState({ file: e.target.files[0] });
  };

  onFileUpload = async (e) => {
    if (!this.state.file) {
      // Display an error message or handle the validation as needed
      toast.error("Please select a file before uploading", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      return;
    }
    this.setState({ fileUploaded: !this.state.fileUploaded });
    e.preventDefault();
    const value = new FormData();
    value.append("file", this.fileInput.current.files[0]);
    value.append("clientids", JSON.stringify(this.state.client));
    const headers = {
      "Content-Type": `multipart/form-data`,
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    await axios({
      method: "post",
      url: `${ip}/authorization/upload`,
      data: value,
      headers: headers,
    })
      .then((response) => {
        //handle success
        if (response.status === 200) {
          this.setState({ fileUploaded: !this.state.fileUploaded });
          toast.success("File uploaded successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        //handle error
        this.setState({ fileUploaded: !this.state.fileUploaded });
        this.getfiles();
        if (err.response) {
          if (err.response.status === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 400) {
            toast.error("Please include all the fields in excel data", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  componentDidMount() {
    
  }
  render() {
    return (
      <div>
      <Newsidebar name="Authorization" />
      <div className="align-row" style={{ overflow: "hidden" }}>
        <div className="align-column">
        <ToastContainer></ToastContainer>
          <div>
            <div className="align-row items">
              <form method="post" style={{ width: "17%" }}>
                <div class="files">
                  <input
                    type="file"
                    id="file"
                    required
                    ref={this.fileInput}
                    onChange={this.onFileChange}
                    style={{ display: "none" }}
                    accept=".xlsx"
                  />
                  <label for="file" className="filelabel">
                    {this.state.file ? (
                      <p align="center" style={{ color: "green" }}>
                        {this.state.file.name}
                      </p>
                    ) : (
                      <a className="dropfile">Select files here or browse</a>
                    )}
                  </label>
                </div>
              </form>
            </div>
            <div style={{ marginLeft: "75%", marginTop: "20px" }}>
              <Row>
                <Col className="col-3">
                  <Button
                    outline
                    color="secondary"
                    size="sm"
                    className="exportcss"
                  >
                    <Link
                      style={{ textDecoration: "none", color: "#a8adad" }}
                      download="Authorization.xlsx"
                      target="_blank"
                      to={Auth}
                    >
                      {" "}
                      Sample
                    </Link>
                  </Button>
                </Col>
                <Col className="col-6">
                  <Button
                    outline
                    color="secondary"
                    size="sm"
                    onClick={this.onFileUpload}
                    // onClick={this.togglemodal}
                    className="uploadcss"
                  >
                    Upload
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

import { React, Component } from "react";
import Newsidebar from "../../Dashboard/Newsidebar";
import axios from "axios";
import Badge from "@mui/material/Badge";
import { Row, Col } from "reactstrap";
import {Table} from "react-bootstrap";
import { Button } from "reactstrap";
import ReactPaginate from "react-paginate";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  IoIosArrowBack,
  IoIosArrowForward,
} from "react-icons/io";
import ViewClaim from "./ViewClaim";
import moment from "moment-timezone";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import Tabs, { Tab } from "react-best-tabs";
import SimpleMenu from "./MenuList";
import Twilio from "../Twilio";
import { ip } from "../../Api";
import { BiDownArrowAlt,BiUpArrowAlt } from "react-icons/bi";
import Keyallocated from "./Keyallocated";
import KeyUnallocated from "./KeyUnallocated";
import Keyallocated1 from "./Keyallocated1";
import Keyallocated2 from "./Keyallocated2";
import Viewmoreeligibility from "../../Eligibility/Viewmoreeligibility";
class NotTouchClaims extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      allocatemodal1: false,
      allocatemodal2: false,
      allocatemodal3: false,
      anchorEl: null,
      callmodule: false,
      statuslist: [],
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      queryclient: sessionStorage.getItem("queryclient"),
      querystartdate: sessionStorage.getItem("querystartdate"),
      queryenddate: sessionStorage.getItem("queryenddate"),
      queryLocation: sessionStorage.getItem("queryLocation"),
      clientid: sessionStorage.getItem("clientid"),
      currentpagesearch1: 0,
      currentpagesearch2: 0,
      currentpagesearch3: 0,
      payercategories: [],
      cptdata: [],
      tabname: "",
      fetchingWeb: true,
      fetchingIvr: true,
      fetchingManual: true,
      allocated1: 0,
      allocated2: 0,
      allocated3: 0,
      unallocated1: 0,
      unallocated2: 0,
      unallocated3: 0,
      tabindex: 1,
      offsetweb: 0,
      currentpageweb: 0,
      perPageweb: 10,
      status1: [],
      claimfield1: "",
      claimvalue1: "",
      payervalue1: [],
      cptvalue1: [],
      query1: [],
      searchtext1: "",
      firstsearch1: true,
      firstsearch2: true,
      firstsearch3: true,
      searchdataresponse1: [],
      offsetmanual: 0,
      currentpagemanual: 0,
      perPagemanual: 10,
      status3: [],
      claimfield3: "",
      claimvalue3: "",
      payervalue3: [],
      cptvalue3: [],
      query3: [],
      searchtext3: "",
      searchdataresponse3: [],
      offsetivr: 0,
      currentpageivr: 0,
      perPageivr: 10,
      status2: [],
      claimfield2: "",
      claimvalue2: "",
      payervalue2: [],
      cptvalue2: [],
      query2: [],
      searchtext2: "",
      searchdataresponse2: [],
      offsetsearch: 0,
      currentpagesearch: 0,
      perPagesearch: 10,
      zerotouchweb: [],
      zerotouchmanual: [],
      zerotouchivr: [],
      sortstate: "ASC",
      openicon: false,
      tableaction: [],
      tableactionivr: [],
      tableactionmanual: [],
      currentpageaction: 0,
      perPageaction: 10,
      offsetaction: 0,
      currentpageactionivr: 0,
      perPageactionivr: 10,
      offsetactionivr: 0,
      currentpageactionmanual: 0,
      perPageactionmanual: 10,
      offsetactionmanual: 0,
    };
    this.handlePageClickWeb1 = this.handlePageClickWeb1.bind(this);
  }
  toggle1 = () => {
    this.setState(() => {
      this.setState({ allocatemodal1: !this.state.allocatemodal1 });
    });
  };
  toggle2 = () => {
    this.setState(() => {
      this.setState({ allocatemodal2: !this.state.allocatemodal2 });
    });
  };
  toggle3 = () => {
    this.setState(() => {
      this.setState({ allocatemodal3: !this.state.allocatemodal3 });
    });
  };
  handleClose = () => this.setState({ anchorEl: null });
  handleClick = (event) => this.setState({ anchorEl: event.currentTarget });
  sortingweb = (col) => {
    if (this.state.sortstate === "ASC") {
      const sorted = [...this.state.zerotouchweb].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      this.setState({ zerotouchweb: sorted });
      this.setState({ sortstate: "DSC" });
    }
    if (this.state.sortstate === "DSC") {
      const sorted = [...this.state.zerotouchweb].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      this.setState({ zerotouchweb: sorted });
      this.setState({ sortstate: "ASC" });
    }
  };
  sortingivr = (col) => {
    if (this.state.sortstate === "ASC") {
      const sorted = [...this.state.zerotouchivr].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      this.setState({ zerotouchivr: sorted });
      this.setState({ sortstate: "DSC" });
    }
    if (this.state.sortstate === "DSC") {
      const sorted = [...this.state.zerotouchivr].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      this.setState({ zerotouchivr: sorted });
      this.setState({ sortstate: "ASC" });
    }
  };
  sortingmanual = (col) => {
    if (this.state.sortstate === "ASC") {
      const sorted = [...this.state.zerotouchmanual].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      this.setState({ zerotouchmanual: sorted });
      this.setState({ sortstate: "DSC" });
    }
    if (this.state.sortstate === "DSC") {
      const sorted = [...this.state.zerotouchmanual].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      this.setState({ zerotouchmanual: sorted });
      this.setState({ sortstate: "ASC" });
    }
  };
  getClaimStatus = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

     await axios
      .get(`${ip}/claimstatus/all`, { headers: headers })
      .then((res) => {
        this.setState({
          statuslist: res.data.claimstatus,
        });
      })
      .catch((err) => {});
  };

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  getzerotouchivrclaims = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.queryclient) {
      if (this.state.querystartdate && this.state.queryenddate) {
        if (this.state.queryLocation) {
          url = `${ip}/allocation/bucket/zerotouch/ivr?clientId=${this.state.queryclient}&pageno=${this.state.currentpageivr}&Location=${this.state.queryLocation}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/allocation/bucket/zerotouch/ivr?clientId=${this.state.queryclient}&pageno=${this.state.currentpageivr}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        }
      } else {
        if (this.state.queryLocation) {
          url = `${ip}/allocation/bucket/zerotouch/ivr?clientId=${this.state.queryclient}&pageno=${this.state.currentpageivr}&Location=${this.state.queryLocation}`;
        } else {
          url = `${ip}/allocation/bucket/zerotouch/ivr?clientId=${this.state.queryclient}&pageno=${this.state.currentpageivr}`;
        }
      }
    } else {
      if (this.state.querystartdate && this.state.queryenddate)
        url = `${ip}/allocation/bucket/zerotouch/ivr?pageno=${this.state.currentpageivr}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
      else
        url = `${ip}/allocation/bucket/zerotouch/ivr?pageno=${this.state.currentpageivr}`;
    }
     axios
      .get(url, { headers: headers })
      .then((res) => {
        this.setState({
          pageCountivr: Math.ceil(res.data.count / this.state.perPageivr),
          zerotouchivr: res.data.zerotouch,
          fetchingIvr: false,
          unallocated2: res.data.unallocatedcount,
          allocated2: res.data.allocatedcount,
        });
      })

      .catch((err) => {});
  };

  getzerotouchwebclaims = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.queryclient) {
      if (this.state.querystartdate && this.state.queryenddate) {
        if (this.state.queryLocation) {
          url = `${ip}/allocation/bucket/zerotouch/web?clientId=${this.state.queryclient}&pageno=${this.state.currentpageweb}&Location=${this.state.queryLocation}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/allocation/bucket/zerotouch/web?clientId=${this.state.queryclient}&pageno=${this.state.currentpageweb}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        }
      } else {
        if (this.state.queryLocation) {
          url = `${ip}/allocation/bucket/zerotouch/web?clientId=${this.state.queryclient}&pageno=${this.state.currentpageweb}&Location=${this.state.queryLocation}`;
        } else {
          url = `${ip}/allocation/bucket/zerotouch/web?clientId=${this.state.queryclient}&pageno=${this.state.currentpageweb}`;
        }
      }
    } else {
      if (this.state.querystartdate && this.state.queryenddate)
        url = `${ip}/allocation/bucket/zerotouch/web?pageno=${this.state.currentpageweb}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
      else
        url = `${ip}/allocation/bucket/zerotouch/web?pageno=${this.state.currentpageweb}`;
    }
     axios
      .get(url, { headers: headers })
      .then((res) => {
        this.setState({
          pageCountweb: Math.ceil(res.data.count / this.state.perPageweb),
          zerotouchweb: res.data.zerotouch,
          fetchingWeb: false,
          allocated1: res.data.allocatedcount,
          unallocated1: res.data.unallocatedcount,
        });
      })

      .catch((err) => {});
  };

  getzerotouchmanualclaims = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.queryclient) {
      if (this.state.querystartdate && this.state.queryenddate) {
        if (this.state.queryLocation) {
          url = `${ip}/allocation/bucket/zerotouch/manual?clientId=${this.state.queryclient}&pageno=${this.state.currentpagemanual}&Location=${this.state.queryLocation}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/allocation/bucket/zerotouch/manual?clientId=${this.state.queryclient}&pageno=${this.state.currentpagemanual}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        }
      } else {
        if (this.state.queryLocation) {
          url = `${ip}/allocation/bucket/zerotouch/manual?clientId=${this.state.queryclient}&pageno=${this.state.currentpagemanual}&Location=${this.state.queryLocation}`;
        } else {
          url = `${ip}/allocation/bucket/zerotouch/manual?clientId=${this.state.queryclient}&pageno=${this.state.currentpagemanual}`;
        }
      }
    } else {
      if (this.state.querystartdate && this.state.queryenddate)
        url = `${ip}/allocation/bucket/zerotouch/manual?pageno=${this.state.currentpagemanual}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
      else
        url = `${ip}/allocation/bucket/zerotouch/manual?pageno=${this.state.currentpagemanual}`;
    }
     axios
      .get(url, { headers: headers })
      .then((res) => {
        this.setState({
          pageCountmanual: Math.ceil(res.data.count / this.state.perPagemanual),
          zerotouchmanual: res.data.zerotouch,
          fetchingManual: false,
          allocated3: res.data.allocatedcount,
          unallocated3: res.data.unallocatedcount,
        });
      })

      .catch((err) => {});
  };

  handlePageClickWeb = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPageweb;

    this.setState(
      {
        currentpageweb: selectedPage,
        offsetweb: offset,
      },
      () => {
        this.getzerotouchwebclaims();
      }
    );
  };

  handlePageClickManual = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPagemanual;

    this.setState(
      {
        currentpagemanual: selectedPage,
        offsetmanual: offset,
      },
      () => {
        this.getzerotouchmanualclaims();
      }
    );
  };

  handlePageClickIvr = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPageivr;

    this.setState(
      {
        currentpageivr: selectedPage,
        offsetivr: offset,
      },
      () => {
        this.getzerotouchivrclaims();
      }
    );
  };
  handlePageClickWeb1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPageweb;

    this.setState(
      {
        currentpagesearch1: selectedPage,
        firstsearch1: false,
      },
      () => this.filtersearch1()
    );
  };

  handlePageClickManual1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPagemanual;

    this.setState(
      {
        currentpagesearch3: selectedPage,
        firstsearch3: false,
      },
      () => {
        this.filtersearch3();
      }
    );
  };

  handlePageClickIvr1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPageivr;

    this.setState(
      {
        currentpagesearch2: selectedPage,
        firstsearch2: false,
      },
      () => {
        this.filtersearch2();
      }
    );
  };

  getcpt = async () => {
    //api call to get all cpt data
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     axios
      .get(`${ip}/cptmaster/all`, { headers: headers })
      .then((res) => {
        this.setState({
          cptdata: res.data.cpt,
        });
      })
      .catch((err) => {});
  };

  getpayercategory = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
     axios
      .get(`${ip}/payercategory/all`, {
        headers: headers,
      })
      .then((res) => {
        let other = {
          category_name: "Others",
        };
        let data = res.data.payer;
        data.push(other);
        this.setState({ payercategories: data }, () => {});
      })
      .catch((err) => {});
  };

  getquery = () => {
    if (this.state.tabname === "web") {
      var query_o;
      let query = [];
      if (this.state.payervalue1.length !== 0) {
        if (this.state.payervalue1[0].category_name !== "") {
          query_o = `Payercategory=${this.state.payervalue1[0].category_name}`;
          query.push(query_o);
        }
      }

      if (this.state.status1.length !== 0) {
        query_o = `ClaimStatus=${this.state.status1}`;
        query.push(query_o);
      }

      if (this.state.claimfield1 && this.state.claimvalue1 !== "") {
        query_o = `${this.state.claimfield1}=${this.state.claimvalue1}`;
        query.push(query_o);
      }

      if (query.length === 1) this.state.query1.push(`${query[0]}`);
      if (query.length === 2) this.state.query1.push(`${query[0]}&${query[1]}`);
      if (query.length === 3)
        this.state.query1.push(`${query[0]}&${query[1]}&${query[2]}`);
      if (query.length === 4)
        this.state.query1.push(
          `${query[0]}&${query[1]}&${query[2]}&${query[3]}`
        );
    } else if (this.state.tabname === "ivr") {
      var query_o;
      let query = [];
      if (this.state.cptvalue2.length === 1) {
        query_o = `CPTS=${this.state.cptvalue2[0].cpt_code}`;
        query.push(query_o);
      }
      if (this.state.payervalue2.length !== 0) {
        if (this.state.payervalue2[0].category_name !== "") {
          query_o = `Payercategory=${this.state.payervalue2[0].category_name}`;
          query.push(query_o);
        }
      }
      if (this.state.status2.length !== 0) {
        query_o = `ClaimStatus=${this.state.status2}`;
        query.push(query_o);
      }
      if (this.state.claimfield2 && this.state.claimvalue2 !== "") {
        query_o = `${this.state.claimfield2}=${this.state.claimvalue2}`;
        query.push(query_o);
      }
      if (query.length === 1) this.state.query2.push(`${query[0]}`);
      if (query.length === 2) this.state.query2.push(`${query[0]}&${query[1]}`);
      if (query.length === 3)
        this.state.query2.push(`${query[0]}&${query[1]}&${query[2]}`);
      if (query.length === 4)
        this.state.query2.push(
          `${query[0]}&${query[1]}&${query[2]}&${query[3]}`
        );
    } else if (this.state.tabname === "manual") {
      var query_o;
      let query = [];

      if (this.state.payervalue3.length !== 0) {
        if (this.state.payervalue3[0].category_name !== "") {
          query_o = `Payercategory=${this.state.payervalue3[0].category_name}`;
          query.push(query_o);
        }
      }
      if (this.state.status3.length !== 0) {
        query_o = `ClaimStatus=${this.state.status3}`;
        query.push(query_o);
      }
      if (this.state.claimfield3 && this.state.claimvalue3 !== "") {
        query_o = `${this.state.claimfield3}=${this.state.claimvalue3}`;
        query.push(query_o);
      }
      if (query.length === 1) this.state.query3.push(`${query[0]}`);
      if (query.length === 2) this.state.query3.push(`${query[0]}&${query[1]}`);
      if (query.length === 3)
        this.state.query3.push(`${query[0]}&${query[1]}&${query[2]}`);
      if (query.length === 4)
        this.state.query3.push(
          `${query[0]}&${query[1]}&${query[2]}&${query[3]}`
        );
    }
  };

  filtersearch1 = (e) => {
    if (this.state.firstsearch1) {
      e.preventDefault();
    }
    this.setState({ query1: [] });
    this.getquery();
    let filtereddata = [];
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

    if (this.state.query1.length !== 0) {
      let url;
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.state.queryLocation) {
            url = `${ip}/allocation/bucket/tflexceed/web/advancedmultiplefilter?clientId=${this.state.queryclient}&${this.state.query1}&pageno=${this.state.currentpagesearch1}&Location=${this.state.queryLocation}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/allocation/bucket/tflexceed/web/advancedmultiplefilter?clientId=${this.state.queryclient}&${this.state.query1}&pageno=${this.state.currentpagesearch1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.state.queryLocation) {
            url = `${ip}/allocation/bucket/tflexceed/web/advancedmultiplefilter?clientId=${this.state.queryclient}&${this.state.query1}&pageno=${this.state.currentpagesearch1}&Location=${this.state.queryLocation}`;
          } else {
            url = `${ip}/allocation/bucket/tflexceed/web/advancedmultiplefilter?clientId=${this.state.queryclient}&${this.state.query1}&pageno=${this.state.currentpagesearch1}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/allocation/bucket/tflexceed/web/advancedmultiplefilter?${this.state.query1}&pageno=${this.state.currentpagesearch1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/allocation/bucket/tflexceed/web/advancedmultiplefilter?${this.state.query1}&pageno=${this.state.currentpagesearch1}`;
        }
      }
       axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          this.setState({ searchtext1: 1 });
          this.setState({
            searchdataresponse1: res.data.claims,
            pageCountweb: Math.ceil(
              res.data.claimcount / this.state.perPageweb
            ),
          });
        })
        .catch((err) => {});
    } else {
      alert("Select data to search");
    }
  };

  filtersearch2 = (e) => {
    if (this.state.firstsearch2) {
      e.preventDefault();
    }
    this.setState({ query2: [] });
    this.getquery();
    let filtereddata = [];
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    if (this.state.query2.length !== 0) {
      let url;
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.state.queryLocation) {
            url = `${ip}/allocation/bucket/tflexceed/ivr/advancedmultiplefilter?clientId=${this.state.queryclient}&${this.state.query2}&pageno=${this.state.currentpagesearch2}&Location=${this.state.queryLocation}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/allocation/bucket/tflexceed/ivr/advancedmultiplefilter?clientId=${this.state.queryclient}&${this.state.query2}&pageno=${this.state.currentpagesearch2}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.state.queryLocation) {
            url = `${ip}/allocation/bucket/tflexceed/ivr/advancedmultiplefilter?clientId=${this.state.queryclient}&${this.state.query2}&pageno=${this.state.currentpagesearch2}&Location=${this.state.queryLocation}`;
          } else {
            url = `${ip}/allocation/bucket/tflexceed/ivr/advancedmultiplefilter?clientId=${this.state.queryclient}&${this.state.query2}&pageno=${this.state.currentpagesearch2}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/allocation/bucket/tflexceed/ivr/advancedmultiplefilter?${this.state.query2}&pageno=${this.state.currentpagesearch2}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/allocation/bucket/tflexceed/ivr/advancedmultiplefilter?${this.state.query2}&pageno=${this.state.currentpagesearch2}`;
        }
      }
       axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          this.setState({ searchtext2: 1 });

          this.setState({
            searchdataresponse2: res.data.claims,
            pageCountweb: Math.ceil(
              res.data.claimcount / this.state.perPageivr
            ),
          });
        })
        .catch((err) => {});
    } else {
      alert("Select data to search");
    }
  };

  filtersearch3 = (e) => {
    if (this.state.firstsearch3) {
      e.preventDefault();
    }
    this.setState({ query3: [] });
    this.getquery();
    let filtereddata = [];
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    if (this.state.query3.length !== 0) {
      let url;
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.state.queryLocation) {
            url = `${ip}/allocation/bucket/tflexceed/manual/advancedmultiplefilter?clientId=${this.state.queryclient}&${this.state.query3}&pageno=${this.state.currentpagesearch3}&Location=${this.state.queryLocation}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/allocation/bucket/tflexceed/manual/advancedmultiplefilter?clientId=${this.state.queryclient}&${this.state.query3}&pageno=${this.state.currentpagesearch3}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.state.queryLocation) {
            url = `${ip}/allocation/bucket/tflexceed/manual/advancedmultiplefilter?clientId=${this.state.queryclient}&${this.state.query3}&pageno=${this.state.currentpagesearch3}&Location=${this.state.queryLocation}`;
          } else {
            url = `${ip}/allocation/bucket/tflexceed/manual/advancedmultiplefilter?clientId=${this.state.queryclient}&${this.state.query3}&pageno=${this.state.currentpagesearch3}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/allocation/bucket/tflexceed/manual/advancedmultiplefilter?${this.state.query3}&pageno=${this.state.currentpagesearch3}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/allocation/bucket/tflexceed/manual/advancedmultiplefilter?${this.state.query3}&pageno=${this.state.currentpagesearch3}`;
        }
      }
       axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          this.setState({ searchtext3: 1 });
          this.setState({
            searchdataresponse3: res.data.claims,
            pageCountmanual: Math.ceil(
              res.data.claimcount / this.state.perPagemanual
            ),
          });
        })
        .catch((err) => {});
    } else {
      alert("Select data to search");
    }
  };

  reset = (e) => {
    e.preventDefault();
    if (this.state.tabname === "web") {
      this.setState(
        {
          claimfield1: "",
          claimvalue1: "",
          status1: [],
          payervalue1: [],
          cptvalue1: [],
          searchdataresponse1: [],
          searchtext1: "",
          currentpageweb: 0,
          currentpagesearch1: 0,
          allocated1: 0,
          unallocated1: 0,
        },
        () => this.getzerotouchwebclaims()
      );
    } else if (this.state.tabname === "ivr") {
      this.setState(
        {
          claimfield2: "",
          claimvalue2: "",
          status2: [],
          payervalue2: [],
          cptvalue2: [],
          searchdataresponse2: [],
          searchtext2: "",
          currentpageivr: 0,
          currentpagesearch2: 0,
          unallocated2: 0,
          allocated2: 0,
        },
        () => this.getzerotouchivrclaims()
      );
    } else if (this.state.tabname === "manual") {
      this.setState(
        {
          claimfield3: "",
          claimvalue3: "",
          status3: [],
          payervalue3: [],
          cptvalue3: [],
          searchdataresponse3: [],
          searchtext3: "",
          currentpagemanual: 0,
          currentpagesearch3: 0,
          allocated3: 0,
          unallocated3: 0,
        },
        () => this.getzerotouchclaims()
      );
    }
  };
  gettableaction = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.queryclient) {
      if (this.state.querystartdate && this.state.queryenddate) {
        if (this.state.queryLocation) {
          url = `${ip}/allocation/bucket/allocatedusersummary?allocatedpageno=${this.state.currentpageaction}&clientId=${this.state.clientid}&type=Web&bucketname=zero`;
        } else {
          url = `${ip}/allocation/bucket/allocatedusersummary?allocatedpageno=${this.state.currentpageaction}&clientId=${this.state.clientid}&type=Web&bucketname=zero`;
        }
      } else {
        if (this.state.queryLocation) {
          url = `${ip}/allocation/bucket/allocatedusersummary?allocatedpageno=${this.state.currentpageaction}&clientId=${this.state.clientid}&type=Web&bucketname=zero`;
        } else {
          url = `${ip}/allocation/bucket/allocatedusersummary?allocatedpageno=${this.state.currentpageaction}&clientId=${this.state.clientid}&type=Web&bucketname=zero`;
        }
      }
    } else {
      if (this.state.querystartdate && this.state.queryenddate)
        url = `${ip}/allocation/bucket/allocatedusersummary?allocatedpageno=${this.state.currentpageaction}&type=Web&bucketname=zero`;
      else
        url = `${ip}/allocation/bucket/allocatedusersummary?allocatedpageno=${this.state.currentpageaction}&type=Web&bucketname=zero`;
    }
     axios
      .get(url, { headers: headers })
      .then((res) => {
        this.setState({
          pageCountaction: Math.ceil(
            res.data.usercount / this.state.perPageaction
          ),
          tableaction: res.data.claimdetails,
        });
      })

      .catch((err) => {});
  };
  gettableactionivr = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.queryclient) {
      if (this.state.querystartdate && this.state.queryenddate) {
        if (this.state.queryLocation) {
          url = `${ip}/allocation/bucket/allocatedusersummary?clientId=${this.state.clientid}&type=IVR&allocatedpageno=${this.state.currentpageactionivr}&bucketname=zero`;
        } else {
          url = `${ip}/allocation/bucket/allocatedusersummary?allocatedpageno=${this.state.currentpageactionivr}&clientId=${this.state.clientid}&type=IVR&bucketname=zero`;
        }
      } else {
        if (this.state.queryLocation) {
          url = `${ip}/allocation/bucket/allocatedusersummary?allocatedpageno=${this.state.currentpageactionivr}&clientId=${this.state.clientid}&type=IVR&bucketname=zero`;
        } else {
          url = `${ip}/allocation/bucket/allocatedusersummary?allocatedpageno=${this.state.currentpageactionivr}&clientId=${this.state.clientid}&type=IVR&bucketname=zero`;
        }
      }
    } else {
      if (this.state.querystartdate && this.state.queryenddate)
        url = `${ip}/allocation/bucket/allocatedusersummary?allocatedpageno=${this.state.currentpageactionivr}&type=IVR&bucketname=zero`;
      else
        url = `${ip}/allocation/bucket/allocatedusersummary?allocatedpageno=${this.state.currentpageactionivr}&type=IVR&bucketname=zero`;
    }
     axios
      .get(url, { headers: headers })
      .then((res) => {
        this.setState({
          pageCountactionivr: Math.ceil(
            res.data.usercount / this.state.perPageactionivr
          ),
          tableactionivr: res.data.claimdetails,
        });
      })

      .catch((err) => {});
  };

  gettableactionmanual = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.queryclient) {
      if (this.state.querystartdate && this.state.queryenddate) {
        if (this.state.queryLocation) {
          url = `${ip}/allocation/bucket/allocatedusersummary?allocatedpageno=${this.state.currentpageactionmanual}&clientId=${this.state.clientid}&type=Manual&bucketname=zero`;
        } else {
          url = `${ip}/allocation/bucket/allocatedusersummary?allocatedpageno=${this.state.currentpageactionmanual}&clientId=${this.state.clientid}&type=Manual&bucketname=zero`;
        }
      } else {
        if (this.state.queryLocation) {
          url = `${ip}/allocation/bucket/allocatedusersummary?allocatedpageno=${this.state.currentpageactionmanual}&clientId=${this.state.clientid}&type=Manual&bucketname=zero`;
        } else {
          url = `${ip}/allocation/bucket/allocatedusersummary?allocatedpageno=${this.state.currentpageactionmanual}&clientId=${this.state.clientid}&type=Manual&bucketname=zero`;
        }
      }
    } else {
      if (this.state.querystartdate && this.state.queryenddate)
        url = `${ip}/allocation/bucket/allocatedusersummary?allocatedpageno=${this.state.currentpageactionmanual}&type=Manual&bucketname=zero`;
      else
        url = `${ip}/allocation/bucket/allocatedusersummary?allocatedpageno=${this.state.currentpageactionmanual}&type=Manual&bucketname=zero`;
    }
     axios
      .get(url, { headers: headers })
      .then((res) => {
        this.setState({
          pageCountactionmanual: Math.ceil(
            res.data.usercount / this.state.perPageactionmanual
          ),
          tableactionmanual: res.data.claimdetails,
        });
      })

      .catch((err) => {});
  };
  handlePageClickaction = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPageaction;

    this.setState(
      {
        currentpageaction: selectedPage,
        offsetaction: offset,
      },
      () => {
        this.gettableaction();
      }
    );
  };

  handlePageClickactionivr = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPageactionivr;

    this.setState(
      {
        currentpageactionivr: selectedPage,
        offsetactionivr: offset,
      },
      () => {
        this.gettableactionivr();
      }
    );
  };

  handlePageClickactionmanual = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPageactionmanual;

    this.setState(
      {
        currentpageactionmanual: selectedPage,
        offsetactionmanual: offset,
      },
      () => {
        this.gettableactionmanual();
      }
    );
  };

  componentDidMount() {
    this.getzerotouchivrclaims();
    this.getzerotouchwebclaims();
    this.getzerotouchmanualclaims();
    this.getcpt();
    this.getpayercategory();
    this.getClaimStatus();
    this.gettableaction();
    this.gettableactionivr();
    this.gettableactionmanual();
  }
  render() {
    const { anchorEl } = this.state;
    return (
      <div className="align-row" style={{ overflow: "hidden" }}>
        <Newsidebar>
        <span>
              <Link
                to="/allocation-buckets"
                style={{ textDecoration: "none", color:"white",fontSize: "15px",
                fontWeight: 500 }}
              >
               Key Indicators
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp; 
              <span style={{color:"white"}}>Not Touch Claims</span>
            </span>
        </Newsidebar>
        <div className="align-column">
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          
          {this.state.searchtext1 === "" && this.state.tabindex === 1 && (
            <div style={{ marginLeft: "64%", marginTop:"-90px" }}>
              <Badge
                badgeContent={this.state.allocated1}
                color="primary"
                showZero
                max={this.state.pageCountweb * this.state.perPageweb}
              >
                <Button
                  style={{ backgroundColor: "#2e5a88", color: "white" }}
                  onClick={() => {
                    this.toggle1();
                    this.handleClose();
                  }}
                >
                  Allocated
                </Button>
              </Badge>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Badge
                badgeContent={this.state.unallocated1}
                color="primary"
                showZero
                max={this.state.pageCountweb * this.state.perPageweb}
              >
                <Button>
                  <Link
                    to="/Keyindicatorunallocate"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                    }}
                    onClick={() => (
                      <KeyUnallocated
                        value={
                          sessionStorage.setItem("keytype", "Web") ||
                          sessionStorage.setItem("keycategory", "zerotouch")
                        }
                      />
                    )}
                  >
                    Unallocated
                  </Link>
                </Button>
              </Badge>
            </div>
          )}
          {this.state.allocatemodal1 && (
            <Modal
              // // style={{ width: "30%" }}
              isOpen={this.state.allocatemodal1}
              onHide={this.toggle1}
              onClose={this.handleClose}
              size="lg"
              centered
            >
              <ModalHeader toggle={this.toggle1}>Allocated</ModalHeader>
              <ModalBody>
                <Row style={{ margin: "0px" }}>
                  <div style={{ width: "100%" }}>
                    <Table
                      className="StandardTable"
                      bordered
                      style={{
                        marginLeft: "3%",
                        width: "99%",
                        marginTop: "4%",
                      }}
                    >
                      <thead>
                        <tr align="center">
                          <th>Username</th>
                          <th>No of Claims</th>
                          <th>Completed</th>
                          <th>Not Touched</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.tableaction &&
                          this.state.tableaction.map((data, index) => {
                            return (
                              <tr>
                                <td>{data.username}</td>
                                <td>
                                  <Link
                                    to={{
                                      pathname: "/keyindicatorallocated",
                                    }}
                                    onClick={() => (
                                      <Keyallocated
                                        value={
                                          sessionStorage.setItem(
                                            "usernamekey",
                                            data.username
                                          ) ||
                                          sessionStorage.setItem(
                                            "claimdetailskey",
                                            data.filteredClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "completedclaims",
                                            data.completedClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "nottouch",
                                            data.nottouchedClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "checkfiltertable",
                                            true
                                          ) ||
                                          sessionStorage.setItem(
                                            "keytype",
                                            "Web"
                                          ) ||
                                          sessionStorage.setItem(
                                            "keycategory",
                                            "zero"
                                          ) ||
                                          sessionStorage.setItem(
                                            "keypageno",
                                            this.state.currentpageaction
                                          )
                                        }
                                      />
                                    )}
                                  >
                                    {data.filteredClaims.length}
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={{
                                      pathname: "/keyindicatorallocated1",
                                    }}
                                    onClick={() => (
                                      <Keyallocated1
                                        value={
                                          sessionStorage.setItem(
                                            "usernamekey",
                                            data.username
                                          ) ||
                                          sessionStorage.setItem(
                                            "claimdetailskey",
                                            data.filteredClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "completedclaims",
                                            data.completedClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "nottouch",
                                            data.nottouchedClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "checkcompletetable",
                                            true
                                          ) ||
                                          sessionStorage.setItem(
                                            "keytype",
                                            "Web"
                                          ) ||
                                          sessionStorage.setItem(
                                            "keycategory",
                                            "zero"
                                          ) ||
                                          sessionStorage.setItem(
                                            "keypageno",
                                            this.state.currentpageaction
                                          )
                                        }
                                      />
                                    )}
                                  >
                                    {data.completedClaims.length}
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={{
                                      pathname: "/keyindicatorallocated2",
                                    }}
                                    onClick={() => (
                                      <Keyallocated2
                                        value={
                                          sessionStorage.setItem(
                                            "usernamekey",
                                            data.username
                                          ) ||
                                          sessionStorage.setItem(
                                            "claimdetailskey",
                                            data.filteredClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "completedclaims",
                                            data.completedClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "nottouch",
                                            data.nottouchedClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "nottouchtable",
                                            true
                                          ) ||
                                          sessionStorage.setItem(
                                            "keytype",
                                            "Web"
                                          ) ||
                                          sessionStorage.setItem(
                                            "keycategory",
                                            "zero"
                                          ) ||
                                          sessionStorage.setItem(
                                            "keypageno",
                                            this.state.currentpageaction
                                          )
                                        }
                                      />
                                    )}
                                  >
                                    {data.nottouchedClaims.length}
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </Row>
              </ModalBody>
              <ModalFooter>
                <div style={{ marginRight: "368px" }}>
                  <ReactPaginate
                    previousLabel={<IoIosArrowBack />}
                    nextLabel={<IoIosArrowForward />}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCountaction}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClickaction}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </ModalFooter>
            </Modal>
          )}
          {this.state.searchtext2 === "" && this.state.tabindex === 2 && (
            <div style={{ marginLeft: "64%", marginTop:"-90px" }}>
              <Badge
                badgeContent={this.state.allocated2}
                color="primary"
                showZero
                max={this.state.pageCountivr * this.state.perPageivr}
              >
                <Button
                  style={{ backgroundColor: "#2e5a88", color: "white" }}
                  onClick={() => {
                    this.toggle2();
                    this.handleClose();
                  }}
                >
                  Allocated
                </Button>
              </Badge>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Badge
                badgeContent={this.state.unallocated2}
                color="primary"
                showZero
                max={this.state.pageCountivr * this.state.perPageivr}
              >
                <Button>
                  <Link
                    to="/Keyindicatorunallocate"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                    }}
                    onClick={() => (
                      <KeyUnallocated
                        value={
                          sessionStorage.setItem("keytype", "IVR") ||
                          sessionStorage.setItem("keycategory", "zerotouch")
                        }
                      />
                    )}
                  >
                    Unallocated
                  </Link>
                </Button>
              </Badge>
            </div>
          )}
          {this.state.allocatemodal2 && (
            <Modal
              // // style={{ width: "30%" }}
              isOpen={this.state.allocatemodal2}
              onHide={this.toggle2}
              onClose={this.handleClose}
              size="lg"
              centered
            >
              <ModalHeader toggle={this.toggle2}>Allocated</ModalHeader>
              <ModalBody>
                <Row style={{ margin: "0px" }}>
                  <div style={{ width: "100%" }}>
                    <Table
                      className="StandardTable"
                      bordered
                      style={{
                        marginLeft: "3%",
                        width: "99%",
                        marginTop: "4%",
                      }}
                    >
                      <thead>
                        <tr align="center">
                          <th>Username</th>
                          <th>No of Claims</th>
                          <th>Completed</th>
                          <th>Not Touched</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.tableactionivr &&
                          this.state.tableactionivr.map((data, index) => {
                            return (
                              <tr>
                                <td>{data.username}</td>
                                <td>
                                  <Link
                                    to={{
                                      pathname: "/keyindicatorallocated",
                                    }}
                                    onClick={() => (
                                      <Keyallocated
                                        value={
                                          sessionStorage.setItem(
                                            "usernamekey",
                                            data.username
                                          ) ||
                                          sessionStorage.setItem(
                                            "claimdetailskey",
                                            data.filteredClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "completedclaims",
                                            data.completedClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "nottouch",
                                            data.nottouchedClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "checkfiltertable",
                                            true
                                          ) ||
                                          sessionStorage.setItem(
                                            "keytype",
                                            "IVR"
                                          ) ||
                                          sessionStorage.setItem(
                                            "keycategory",
                                            "zero"
                                          ) ||
                                          sessionStorage.setItem(
                                            "keypageno",
                                            this.state.currentpageaction
                                          )
                                        }
                                      />
                                    )}
                                  >
                                    {data.filteredClaims.length}
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={{
                                      pathname: "/keyindicatorallocated1",
                                    }}
                                    onClick={() => (
                                      <Keyallocated1
                                        value={
                                          sessionStorage.setItem(
                                            "usernamekey",
                                            data.username
                                          ) ||
                                          sessionStorage.setItem(
                                            "claimdetailskey",
                                            data.filteredClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "completedclaims",
                                            data.completedClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "nottouch",
                                            data.nottouchedClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "checkcompletetable",
                                            true
                                          ) ||
                                          sessionStorage.setItem(
                                            "keytype",
                                            "IVR"
                                          ) ||
                                          sessionStorage.setItem(
                                            "keycategory",
                                            "zero"
                                          ) ||
                                          sessionStorage.setItem(
                                            "keypageno",
                                            this.state.currentpageaction
                                          )
                                        }
                                      />
                                    )}
                                  >
                                    {data.completedClaims.length}
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={{
                                      pathname: "/keyindicatorallocated2",
                                    }}
                                    onClick={() => (
                                      <Keyallocated2
                                        value={
                                          sessionStorage.setItem(
                                            "usernamekey",
                                            data.username
                                          ) ||
                                          sessionStorage.setItem(
                                            "claimdetailskey",
                                            data.filteredClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "completedclaims",
                                            data.completedClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "nottouch",
                                            data.nottouchedClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "nottouchtable",
                                            true
                                          ) ||
                                          sessionStorage.setItem(
                                            "keytype",
                                            "IVR"
                                          ) ||
                                          sessionStorage.setItem(
                                            "keycategory",
                                            "zero"
                                          ) ||
                                          sessionStorage.setItem(
                                            "keypageno",
                                            this.state.currentpageaction
                                          )
                                        }
                                      />
                                    )}
                                  >
                                    {data.nottouchedClaims.length}
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </Row>
              </ModalBody>
              <ModalFooter>
                <div style={{ marginRight: "368px" }}>
                  <ReactPaginate
                    previousLabel={<IoIosArrowBack />}
                    nextLabel={<IoIosArrowForward />}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCountactionivr}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClickactionivr}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </ModalFooter>
            </Modal>
          )}
          {this.state.searchtext3 === "" && this.state.tabindex === 3 && (
            <div style={{ marginLeft: "64%", marginTop:"-90px" }}>
              <Badge
                badgeContent={this.state.allocated3}
                color="primary"
                showZero
                max={this.state.pageCountmanual * this.state.perPagemanual}
              >
                <Button
                  style={{ backgroundColor: "#2e5a88", color: "white" }}
                  onClick={() => {
                    this.toggle3();
                    this.handleClose();
                  }}
                >
                  Allocated
                </Button>
              </Badge>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Badge
                badgeContent={this.state.unallocated3}
                color="primary"
                showZero
                max={this.state.pageCountmanual * this.state.perPagemanual}
              >
                <Button>
                  <Link
                    to="/Keyindicatorunallocate"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                    }}
                    onClick={() => (
                      <KeyUnallocated
                        value={
                          sessionStorage.setItem("keytype", "Manual") ||
                          sessionStorage.setItem("keycategory", "zerotouch")
                        }
                      />
                    )}
                  >
                    Unallocated
                  </Link>
                </Button>
              </Badge>
            </div>
          )}
          {this.state.allocatemodal3 && (
            <Modal
              // // style={{ width: "30%" }}
              isOpen={this.state.allocatemodal3}
              onHide={this.toggle3}
              onClose={this.handleClose}
              size="lg"
              centered
            >
              <ModalHeader toggle={this.toggle3}>Allocated</ModalHeader>
              <ModalBody>
                <Row style={{ margin: "0px" }}>
                  <div style={{ width: "100%" }}>
                    <Table
                      className="StandardTable"
                      bordered
                      style={{
                        marginLeft: "3%",
                        width: "99%",
                        marginTop: "4%",
                      }}
                    >
                      <thead>
                        <tr align="center">
                          <th>Username</th>
                          <th>No of Claims</th>
                          <th>Completed</th>
                          <th>Not Touched</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.tableactionmanual &&
                          this.state.tableactionmanual.map((data, index) => {
                            return (
                              <tr>
                                <td>{data.username}</td>
                                <td>
                                  <Link
                                    to={{
                                      pathname: "/keyindicatorallocated",
                                    }}
                                    onClick={() => (
                                      <Keyallocated
                                        value={
                                          sessionStorage.setItem(
                                            "usernamekey",
                                            data.username
                                          ) ||
                                          sessionStorage.setItem(
                                            "claimdetailskey",
                                            data.filteredClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "completedclaims",
                                            data.completedClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "nottouch",
                                            data.nottouchedClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "checkfiltertable",
                                            true
                                          ) ||
                                          sessionStorage.setItem(
                                            "keytype",
                                            "Manual"
                                          ) ||
                                          sessionStorage.setItem(
                                            "keycategory",
                                            "zero"
                                          ) ||
                                          sessionStorage.setItem(
                                            "keypageno",
                                            this.state.currentpageaction
                                          )
                                        }
                                      />
                                    )}
                                  >
                                    {data.filteredClaims.length}
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={{
                                      pathname: "/keyindicatorallocated1",
                                    }}
                                    onClick={() => (
                                      <Keyallocated1
                                        value={
                                          sessionStorage.setItem(
                                            "usernamekey",
                                            data.username
                                          ) ||
                                          sessionStorage.setItem(
                                            "claimdetailskey",
                                            data.filteredClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "completedclaims",
                                            data.completedClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "nottouch",
                                            data.nottouchedClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "checkcompletetable",
                                            true
                                          ) ||
                                          sessionStorage.setItem(
                                            "keytype",
                                            "Manual"
                                          ) ||
                                          sessionStorage.setItem(
                                            "keycategory",
                                            "zero"
                                          ) ||
                                          sessionStorage.setItem(
                                            "keypageno",
                                            this.state.currentpageaction
                                          )
                                        }
                                      />
                                    )}
                                  >
                                    {data.completedClaims.length}
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={{
                                      pathname: "/keyindicatorallocated2",
                                    }}
                                    onClick={() => (
                                      <Keyallocated2
                                        value={
                                          sessionStorage.setItem(
                                            "usernamekey",
                                            data.username
                                          ) ||
                                          sessionStorage.setItem(
                                            "claimdetailskey",
                                            data.filteredClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "completedclaims",
                                            data.completedClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "nottouch",
                                            data.nottouchedClaims.length
                                          ) ||
                                          sessionStorage.setItem(
                                            "nottouchtable",
                                            true
                                          ) ||
                                          sessionStorage.setItem(
                                            "keytype",
                                            "Manual"
                                          ) ||
                                          sessionStorage.setItem(
                                            "keycategory",
                                            "zero"
                                          ) ||
                                          sessionStorage.setItem(
                                            "keypageno",
                                            this.state.currentpageaction
                                          )
                                        }
                                      />
                                    )}
                                  >
                                    {data.nottouchedClaims.length}
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </Row>
              </ModalBody>
              <ModalFooter>
                <div style={{ marginRight: "368px" }}>
                  <ReactPaginate
                    previousLabel={<IoIosArrowBack />}
                    nextLabel={<IoIosArrowForward />}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCountactionmanual}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClickactionmanual}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </ModalFooter>
            </Modal>
          )}
          <Tabs
            activeTab="1"
            activityClassName="text-secondary"
            style={{ marginTop: "56%" }}
            onClick={(event, tab) => {
              this.setState({ tabindex: tab });
            }}
          >
            <Tab title="WEB CLAIMS">
              {this.state.fetchingWeb ? (
                <div>
                  <p
                    style={{
                      fontSize: "23px",
                      marginTop: "10px",
                      color: "#000",
                    }}
                  >
                    <span>Loading...</span>
                    <span>Please Wait...</span>
                  </p>
                  <div class="boxes body__loader dribbble">
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              ) : this.state.zerotouchweb.length === 0 ? (
                <div class="bot-ui">
                  <div class="bot-head">
                    <div class="eyes-container">
                      <div class="to-left eye"></div>
                      <div class="to-right eye"></div>
                    </div>
                  </div>
                  <div class="bot-body">
                    <div class="analyzer"></div>
                  </div>
                  <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                    No Data Found
                  </p>
                </div>
              ) : (
                <div>
                  <div class="table-responsive">
                   <div style={{ marginLeft:"3%", marginTop: "1%" }}>
                    <ReactPaginate
                      previousLabel={<IoIosArrowBack />}
                      nextLabel={<IoIosArrowForward />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCountweb}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClickWeb}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      forcePage={this.state.currentpageweb}
                    />
                  </div> 
                    <Table
                      className="StandardTable"
                      bordered
                      style={{
                        marginLeft: "3%",
                        width: "400px",
                        overflow: "scroll",
                      }}
                    >
                      <thead>
                        <tr align="center">
                          <th>Action</th>
                          <th
                            onClick={() => this.sortingweb("ClaimID")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {" "}
                            ClaimID
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("Location")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {" "}
                            Location
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() =>
                              this.sortingweb("patientAccountNumber")
                            }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            AccountNo
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("DateOfService")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOS
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("PatientFirstName")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PatientName
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("PatientDOB")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PatientDOB
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("PlanCode")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Plan Code
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("Pripayer")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            InsuranceName
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("Pripayerphone")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            InsuranceNo
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("PrimaryPolicyNo")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PolicyNo
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("Physician")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PhysicianName
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("Age")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Age
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon11: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("type")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Type
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon12: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("ClaimStatus")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            ClaimStatus
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("Claimunder")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            AllocatedTo
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.zerotouchweb &&
                          this.state.zerotouchweb.map((data, index) => {
                            return (
                              <tr>
                                <td
                                  onClick={() => (
                                    (
                                      <ViewClaim
                                        value={
                                          sessionStorage.setItem(
                                            "Module",
                                            "NotTouchClaim"
                                          ) ||
                                          sessionStorage.setItem(
                                            "ClaimID",
                                            data.ClaimID
                                          ) ||
                                          sessionStorage.setItem(
                                            "ChartID",
                                            data.ChartID
                                          ) ||
                                          sessionStorage.setItem(
                                            "MRN",
                                            data.MRN
                                          ) ||
                                          sessionStorage.setItem(
                                            "Patient",
                                            data.Patient
                                          ) ||
                                          sessionStorage.setItem(
                                            "PatientDOB",
                                            data.PatientDOB
                                          ) ||
                                          sessionStorage.setItem(
                                            "Physician",
                                            data.Physician
                                          ) ||
                                          sessionStorage.setItem(
                                            "ClaimDate",
                                            data.ClaimDate
                                          ) ||
                                          sessionStorage.setItem(
                                            "Pripayer",
                                            data.Pripayer
                                          ) ||
                                          sessionStorage.setItem(
                                            "DateOfService",
                                            data.DateOfService
                                          ) ||
                                          sessionStorage.setItem(
                                            "ClaimDate",
                                            data.ClaimDate
                                          ) ||
                                          sessionStorage.setItem(
                                            "ClaimDBID",
                                            data._id
                                          ) ||
                                          sessionStorage.setItem(
                                            "AllocatedTo",
                                            data.Claimunder
                                          )
                                        }
                                      />
                                    ),
                                    (
                                      <Viewmoreeligibility
                                      value={
                                        sessionStorage.setItem(
                                          "modulename",
                                          "NotTouchClaim"
                                        ) ||
                                        sessionStorage.setItem(
                                          "Eligibilityclaimid",
                                          data.ClaimID
                                        )
                                      }
                                      />
                                    ),
                                    (
                                      <SimpleMenu
                                        value={
                                          sessionStorage.setItem(
                                            "ClaimID",
                                            data.ClaimID
                                          ) ||
                                          sessionStorage.setItem(
                                            "PatientFirstName",
                                            data.PatientFirstName
                                          ) ||
                                          sessionStorage.setItem(
                                            "PatientLastName",
                                            data.PatientLastName
                                          )
                                        }
                                      ></SimpleMenu>
                                    )
                                  )}
                                >
                                  {" "}
                                  <SimpleMenu></SimpleMenu>
                                </td>
                                <td>{data.ClaimID}</td>
                                <td>{data.Location}</td>
                                <td>{data.patientAccountNumber}</td>
                                <td>
                                  {data.DateOfService
                                    ? data.DateOfService
                                    : "-"}
                                </td>

                                <td>
                                  {data.PatientFirstName}&nbsp;
                                  {data.PatientLastName}
                                </td>

                                <td>
                                  {data.PatientDOB ? moment(data.PatientDOB).format("MM/DD/YYYY") : "-"}
                                </td>
                                <td>{data.PlanCode ? data.PlanCode : "-"}</td>
                                <td>{data.Pripayer}</td>
                                <td>
                                  <Row>
                                    <Col md="4">
                                      <Twilio value11={data.Pripayerphone} />
                                    </Col>
                                    <Col md="2">{data.Pripayerphone}</Col>
                                  </Row>
                                </td>
                                <td>{data.PrimaryPolicyNo}</td>
                                <td>{data.Physician}</td>
                                <td>{data.Age}</td>
                                <td>{data.type}</td>
                                <td>{data.ClaimStatus}</td>
                                <td>{data.Claimunder}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                  <div style={{ marginLeft:"3%", marginTop: "20px" }}>
                    <ReactPaginate
                      previousLabel={<IoIosArrowBack />}
                      nextLabel={<IoIosArrowForward />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCountweb}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClickWeb}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      forcePage={this.state.currentpageweb}
                    />
                  </div>
                </div>
              )}
            </Tab>
            <Tab title="IVR CLAIMS">
              {this.state.fetchingIvr ? (
                <div>
                  <p
                    style={{
                      fontSize: "23px",
                      marginTop: "10px",
                      color: "#000",
                    }}
                  >
                    <span>Loading...</span>
                    <span>Please Wait...</span>
                  </p>
                  <div class="boxes body__loader dribbble">
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              ) : this.state.zerotouchivr.length === 0 ? (
                <div class="bot-ui">
                  <div class="bot-head">
                    <div class="eyes-container">
                      <div class="to-left eye"></div>
                      <div class="to-right eye"></div>
                    </div>
                  </div>
                  <div class="bot-body">
                    <div class="analyzer"></div>
                  </div>
                  <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                    No Data Found
                  </p>
                </div>
              ) : (
                <div>
                  <div class="table-responsive">
                   <div style={{ marginLeft:"3%", marginTop: "20px" }}>
                    <ReactPaginate
                      previousLabel={<IoIosArrowBack />}
                      nextLabel={<IoIosArrowForward />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCountivr}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClickIvr}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      forcePage={this.state.currentpageivr}
                    />
                  </div> 
                    <Table
                      className="StandardTable"
                      bordered
                      style={{
                        marginLeft: "3%",
                        width: "fit-content",
                        marginTop: "4%",
                      }}
                    >
                      <thead>
                        <tr align="center">
                          <th>Action</th>
                          <th
                            onClick={() => this.sortingweb("ClaimID")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {" "}
                            Claim ID
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("Location")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {" "}
                            Location
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() =>
                              this.sortingweb("patientAccountNumber")
                            }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Account No
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("DateOfService")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOS
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("PatientFirstName")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Patient Name
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("PatientDOB")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Patient DOB
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("PlanCode")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Plan Code
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("Pripayer")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Insurance Name
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("Pripayerphone")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Insurance No
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("PrimaryPolicyNo")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Policy No
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("Physician")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Physician Name
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("Age")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Age
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon11: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("type")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Type
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon12: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("ClaimStatus")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Claim Status
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingweb("Claimunder")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            AllocatedTo
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.zerotouchivr &&
                          this.state.zerotouchivr.map((data, index) => {
                            return (
                              <tr>
                                <td
                                  onClick={() => (
                                    (
                                      <ViewClaim
                                        value={
                                          sessionStorage.setItem(
                                            "Module",
                                            "NotTouchClaim"
                                          ) ||
                                          sessionStorage.setItem(
                                            "ClaimID",
                                            data.ClaimID
                                          ) ||
                                          sessionStorage.setItem(
                                            "ChartID",
                                            data.ChartID
                                          ) ||
                                          sessionStorage.setItem(
                                            "MRN",
                                            data.MRN
                                          ) ||
                                          sessionStorage.setItem(
                                            "Patient",
                                            data.Patient
                                          ) ||
                                          sessionStorage.setItem(
                                            "PatientDOB",
                                            data.PatientDOB
                                          ) ||
                                          sessionStorage.setItem(
                                            "Physician",
                                            data.Physician
                                          ) ||
                                          sessionStorage.setItem(
                                            "ClaimDate",
                                            data.ClaimDate
                                          ) ||
                                          sessionStorage.setItem(
                                            "Pripayer",
                                            data.Pripayer
                                          ) ||
                                          sessionStorage.setItem(
                                            "DateOfService",
                                            data.DateOfService
                                          ) ||
                                          sessionStorage.setItem(
                                            "ClaimDate",
                                            data.ClaimDate
                                          ) ||
                                          sessionStorage.setItem(
                                            "ClaimDBID",
                                            data._id
                                          ) ||
                                          sessionStorage.setItem(
                                            "AllocatedTo",
                                            data.Claimunder
                                          ) ||
                                          sessionStorage.setItem(
                                            "PatientFirstName",
                                            data.PatientFirstName
                                          )
                                        }
                                      />
                                    ),
                                    (
                                      <Viewmoreeligibility
                                      value={
                                        sessionStorage.setItem(
                                          "modulename",
                                          "NotTouchClaim"
                                        ) ||
                                        sessionStorage.setItem(
                                          "Eligibilityclaimid",
                                          data.ClaimID
                                        )
                                      }
                                      />
                                    ),
                                    (
                                      <SimpleMenu
                                        value={
                                          sessionStorage.setItem(
                                            "ClaimID",
                                            data.ClaimID
                                          ) ||
                                          sessionStorage.setItem(
                                            "PatientFirstName",
                                            data.PatientFirstName
                                          ) ||
                                          sessionStorage.setItem(
                                            "PatientLastName",
                                            data.PatientLastName
                                          )
                                        }
                                      ></SimpleMenu>
                                    )
                                  )}
                                >
                                  {" "}
                                  <SimpleMenu></SimpleMenu>
                                </td>
                                <td>{data.ClaimID}</td>
                                <td>{data.Location}</td>
                                <td>{data.patientAccountNumber}</td>
                                <td>
                                  {data.DateOfService
                                    ? data.DateOfService
                                    : "-"}
                                </td>

                                <td>
                                  {data.PatientFirstName}&nbsp;
                                  {data.PatientLastName}
                                </td>

                                <td>
                                  {data.PatientDOB ? moment(data.PatientDOB).format("MM/DD/YYYY") : "-"}
                                </td>
                                <td>{data.PlanCode ? data.PlanCode : "-"}</td>
                                <td>{data.Pripayer}</td>
                                <td>
                                  <Row>
                                    <Col md="4">
                                      <Twilio value11={data.Pripayerphone} />
                                    </Col>
                                    <Col md="2">{data.Pripayerphone}</Col>
                                  </Row>
                                </td>
                                <td>{data.PrimaryPolicyNo}</td>
                                <td>{data.Physician}</td>
                                <td>{data.Age}</td>
                                <td>{data.type}</td>
                                <td>{data.ClaimStatus}</td>
                                <td>{data.Claimunder}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                  <div style={{ marginLeft:"3%", marginTop: "20px" }}>
                    <ReactPaginate
                      previousLabel={<IoIosArrowBack />}
                      nextLabel={<IoIosArrowForward />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCountivr}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClickIvr}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      forcePage={this.state.currentpageivr}
                    />
                  </div>
                </div>
              )}
            </Tab>
            <Tab title="MANUAL CLAIMS">
              {this.state.fetchingManual ? (
                <div>
                  <p
                    style={{
                      fontSize: "23px",
                      marginTop: "10px",
                      color: "#000",
                    }}
                  >
                    <span>Loading...</span>
                    <span>Please Wait...</span>
                  </p>
                  <div class="boxes body__loader dribbble">
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              ) : this.state.zerotouchmanual.length === 0 ? (
                <div class="bot-ui">
                  <div class="bot-head">
                    <div class="eyes-container">
                      <div class="to-left eye"></div>
                      <div class="to-right eye"></div>
                    </div>
                  </div>
                  <div class="bot-body">
                    <div class="analyzer"></div>
                  </div>
                  <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                    No Data Found
                  </p>
                </div>
              ) : (
                <div>
                  <div class="table-responsive">
                   <div style={{ marginLeft:"3%", marginTop: "20px" }}>
                    <ReactPaginate
                      previousLabel={<IoIosArrowBack />}
                      nextLabel={<IoIosArrowForward />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCountmanual}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClickManual}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      forcePage={this.state.currentpagemanual}
                    />
                  </div> 
                    <Table
                      className="StandardTable"
                      bordered
                      style={{
                        marginLeft: "3%",
                        width: "fit-content",
                        marginTop: "4%",
                      }}
                    >
                      <thead>
                        <tr align="center">
                          <th>Action</th>
                          <th
                            onClick={() => this.sortingmanual("ClaimID")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {" "}
                            Claim ID
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingmanual("Location")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {" "}
                            Location
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() =>
                              this.sortingmanual("patientAccountNumber")
                            }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Account No
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingmanual("DateOfService")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOS
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() =>
                              this.sortingmanual("PatientFirstName")
                            }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Patient Name
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingmanual("PatientDOB")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Patient DOB
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingmanual("PlanCode")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Plan Code
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingmanual("Pripayer")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Insurance Name
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingmanual("Pripayerphone")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Insurance No
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() =>
                              this.sortingmanual("PrimaryPolicyNo")
                            }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Policy No
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingmanual("Physician")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Physician Name
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingmanual("Age")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Age
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon11: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingmanual("type")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Type
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon12: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingmanual("ClaimStatus")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Claim Status
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.sortingmanual("Claimunder")}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            AllocatedTo
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false })
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true })
                                }
                              />
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.zerotouchmanual &&
                          this.state.zerotouchmanual.map((data, index) => {
                            return (
                              <tr>
                                <td
                                  onClick={() => (
                                    (
                                      <ViewClaim
                                        value={
                                          sessionStorage.setItem(
                                            "Module",
                                            "NotTouchClaim"
                                          ) ||
                                          sessionStorage.setItem(
                                            "ClaimID",
                                            data.ClaimID
                                          ) ||
                                          sessionStorage.setItem(
                                            "ChartID",
                                            data.ChartID
                                          ) ||
                                          sessionStorage.setItem(
                                            "MRN",
                                            data.MRN
                                          ) ||
                                          sessionStorage.setItem(
                                            "Patient",
                                            data.Patient
                                          ) ||
                                          sessionStorage.setItem(
                                            "PatientDOB",
                                            data.PatientDOB
                                          ) ||
                                          sessionStorage.setItem(
                                            "Physician",
                                            data.Physician
                                          ) ||
                                          sessionStorage.setItem(
                                            "ClaimDate",
                                            data.ClaimDate
                                          ) ||
                                          sessionStorage.setItem(
                                            "Pripayer",
                                            data.Pripayer
                                          ) ||
                                          sessionStorage.setItem(
                                            "DateOfService",
                                            data.DateOfService
                                          ) ||
                                          sessionStorage.setItem(
                                            "ClaimDate",
                                            data.ClaimDate
                                          ) ||
                                          sessionStorage.setItem(
                                            "ClaimDBID",
                                            data._id
                                          ) ||
                                          sessionStorage.setItem(
                                            "AllocatedTo",
                                            data.Claimunder
                                          ) ||
                                          sessionStorage.setItem(
                                            "PatientFirstName",
                                            data.PatientFirstName
                                          )
                                        }
                                      />
                                    ),
                                    (
                                      <Viewmoreeligibility
                                      value={
                                        sessionStorage.setItem(
                                          "modulename",
                                          "NotTouchClaim"
                                        ) ||
                                        sessionStorage.setItem(
                                          "Eligibilityclaimid",
                                          data.ClaimID
                                        )
                                      }
                                      />
                                    ),
                                    (
                                      <SimpleMenu
                                        value={
                                          sessionStorage.setItem(
                                            "ClaimID",
                                            data.ClaimID
                                          ) ||
                                          sessionStorage.setItem(
                                            "PatientFirstName",
                                            data.PatientFirstName
                                          ) ||
                                          sessionStorage.setItem(
                                            "PatientLastName",
                                            data.PatientLastName
                                          )
                                        }
                                      ></SimpleMenu>
                                    )
                                  )}
                                >
                                  {" "}
                                  <SimpleMenu></SimpleMenu>
                                </td>
                                <td>{data.ClaimID}</td>
                                <td>{data.Location}</td>
                                <td>{data.patientAccountNumber}</td>
                                <td>
                                  {data.DateOfService
                                    ? data.DateOfService
                                    : "-"}
                                </td>

                                <td>
                                  {data.PatientFirstName}&nbsp;
                                  {data.PatientLastName}
                                </td>

                                <td>
                                  {data.PatientDOB ? moment(data.PatientDOB).format("MM/DD/YYYY") : "-"}
                                </td>
                                <td>{data.PlanCode ? data.PlanCode : "-"}</td>
                                <td>{data.Pripayer}</td>
                                <td>
                                  <Row>
                                    <Col md="4">
                                      <Twilio value11={data.Pripayerphone} />
                                    </Col>
                                    <Col md="2">{data.Pripayerphone}</Col>
                                  </Row>
                                </td>
                                <td>{data.PrimaryPolicyNo}</td>
                                <td>{data.Physician}</td>
                                <td>{data.Age}</td>
                                <td>{data.type}</td>
                                <td>{data.ClaimStatus}</td>
                                <td>{data.Claimunder}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                  <div style={{ marginLeft:"3%", marginTop: "20px" }}>
                    <ReactPaginate
                      previousLabel={<IoIosArrowBack />}
                      nextLabel={<IoIosArrowForward />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCountmanual}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClickManual}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      forcePage={this.state.currentpagemanual}
                    />
                  </div>
                </div>
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}
export default NotTouchClaims;

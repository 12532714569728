import React, { Component } from "react";
import axios from "axios";
import "../../Styles/admin.css";
import "../../Styles/Category.css";
import "../../Styles/PayerMaster.css";
import Newsidebar from "../Dashboard/Newsidebar";
import { Dropdown } from "react-bootstrap";
import Payermastersample from "../../Files/Payermastersample.xlsx";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import Tabs, { Tab } from "react-best-tabs";
import Select from "react-dropdown-select";
import ReactPaginate from "react-paginate";
import loading from "../../Images/loader.gif";
import { MdOutlineSearch } from "react-icons/md";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { styled } from "@mui/material/styles";
import { Table } from "react-bootstrap";
import { ip } from ".././Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const options = [{ value: "1", label: "PayerID" }];
class PayerMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      PayerPlanCode: "",
      PlancodeErr: "",
      payerNameErr: "",
      inseuranceErr: "",
      updatepayerassignedPayerIDErr: "",
      payerIDErr: "",
      CPIDsErr: "",
      bulkmodal: false,
      sample: false,
      TflErr: "",
      inseurance: "",
      reSubmitErr: "",
      createmodal: false,
      routingMethodErr: "",
      payerassignedPayerIDErr: "",
      typeErr: "",
      portalPayerIdErr: "",
      portalPayerId: "",
      payerCategoryErr: "",
      modal: false,
      isActive: false,
      searchBy: "payer_id",
      payerCategory: [],
      machinetype: "",
      querySearch: "",
      searchtext: "",
      payerID: "",
      authorizationRequired: "Yes",
      payerassignedPayerID: "",
      routingMethod: "",
      searchclaimdata: [],
      searchdataresponse: [],
      batchAndRealTime: "",
      payerName: "",
      CPIDs: "",
      reSubmit: "",
      updatemodal: false,
      bulkdeletemodal:false,
      deletemodal: false,
      dataforupdate: null,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      role_type: sessionStorage.getItem("role_type"),
      client: sessionStorage.getItem("clientid"),
      clientname: sessionStorage.getItem("clientname"),
      opendropdown: false,
      clientslist: [],
      payername: "",
      payerid: "",
      contactno: "",
      carriercode: "",
      cat1: "",
      categorydata: [],
      category: [],
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      telph1: "",
      telph2: "",
      fax: "",
      email: "",
      billingmethod: "",
      insurcategory: "",
      recvname: "",
      claimfilingindicator: "",
      plantype: "",
      incentivecategory: "",
      timelyfilinglimit1: "",
      timelyfilinglimit2: "",
      noofdays: "",
      partAclaimfiling: "",
      taxonomycode: "No",
      status: "Enabled",
      providerid: "No",
      sendreferringprovider: "No",
      billedbyEHI: "No",
      resendunappliedclaims: "No",
      sendpatientAddress: "No",
      billingonmins: false,
      sendCRNAID: false,
      checktaxonomyCode: false,
      participatingprovider: false,
      appealclaimaddress: false,
      eleclab: "",
      labcorpid: "",
      labcorplantype: "",
      updatetype:"",
      medicallabid: "",
      questid: "",
      enzoid: "",
      propathid: "",
      biorefid: "",
      phelpsid: "",
      hldid: "",
      accurateid: "",
      chid: "",
      lencoid: "",
      applelabid: "",
      empirelabid: "",
      enigmaid: "",
      sheillabid: "",
      fusionid: "",
      everestid: "",
      mercyid: "",
      smalabid: "",
      ezid: "",
      solstasid: "",
      planname: "",
      payer: [],
      user: [],
      updatepayerName: "",
      updatecontact: "",
      updatecategoryname: "",
      updatecat1: "",
      updatecarriercode: "",
      updateaddress1: "",
      updateaddress2: "",
      updatecity: "",
      updatestate: "",
      updatecountry: "",
      updatezipcode: "",
      updatetelph1: "",
      updatetelph2: "",
      updatefax: "",
      updateemail: "",
      updatebillingmethod: "",
      updateinsurcategory: "",
      updaterecvname: "",
      updateclaimfilingindicator: "",
      updateplantype: "",
      updateincentivecategory: "",
      updatetimelyfilinglimit1: "",
      updatetimelyfilinglimit2: "",
      updatenoofdays: "",
      updatepartAclaimfiling: "",
      updatetaxonomycode: "No",
      updatestatus: "Enabled",
      updateproviderid: "No",
      updatesendreferringprovider: "No",
      updatebilledbyEHI: "No",
      updateresendunappliedclaims: "No",
      updatesendpatientAddress: "No",
      updatebillingonmins: false,
      updatesendCRNAID: false,
      updatechecktaxonomyCode: false,
      updateparticipatingprovider: false,
      updateappealclaimaddress: false,
      updateeleclab: "",
      updatelabcorpid: "",
      updatelabcorplantype: "",
      updatemedicallabid: "",
      updatequestid: "",
      updateenzoid: "",
      updatepropathid: "",
      updatebiorefid: "",
      updatephelpsid: "",
      updatehldid: "",
      updateaccurateid: "",
      updatechid: "",
      updatelencoid: "",
      updateapplelabid: "",
      updateempirelabid: "",
      updateenigmaid: "",
      updatesheillabid: "",
      statearray:[],
      updatefusionid: "",
      updateeverestid: "",
      updatemercyid: "",
      updatesmalabid: "",
      updateezid: "",
      updatesolstasid: "",
      updateplanname: "",
      updateid: null,
      code: "",
      zipcodeErr: "",
      billingmethodErr: "",
      categoryErr: "",
      updatemachineerr: "",
      recvnameErr: "",
      offset: 0,
      currentpage: 0,
      perPage: 10,
      Tfl: "",
      updatepayerName: null,
      updateCPIDs: null,
      updateTfl: null,
      updatepayerID: null,
      updatemachinetype: null,
      updateresubmit: null,
      updatepayerassignedPayerID: null,
      updateauthorizationRequired: null,
      updateroutingMethod: null,
      type: "",
      updatepayercategory: "",
      updatePayerPlanCode: "",
    };
    this.fileInput = React.createRef();
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  toggle = () => {
    const { modal } = this.state;
    this.setState({
      modal: !modal,
      PayerPlanCode: "",
      payerName: "",
      inseurance: "",
      machinetype: "",
      payerID: "",
      CPIDs: "",
      Tfl: "",
      reSubmit: "",
      routingMethod: "",
      payerassignedPayerID: "",
      portalPayerId: "",
      payerCategory: "",
      address1: "",
      address2: "",
      city: "",
      category: "",
      country: "",
      zipcode: "",
      telph1: "",
      telph2: "",
      fax: "",
      email: "",
      category: "",
      timelyfilinglimit1: "",
      timelyfilinglimit2: "",
      noofdays: "",
      taxonomycode: "",
      status: "",
      providerid: "",
      sendreferringprovider: "",
      billedbyEHI: "",
      resendunappliedclaims: "",
      sendpatientAddress: "",
      billingonmins: "",
      sendCRNAID: "",
      checktaxonomyCode: "",
      participatingprovider: "",
      appealclaimaddress: "",
      labcorpid: "",
      plantype: "",
      medicallabid: "",
      questid: "",
      enzoid: "",
      propathid: "",
      biorefid: "",
      phelpsid: "",
      hldid: "",
      accurateid: "",
      chid: "",
      lencoid: "",
      applelabid: "",
      empirelabid: "",
      enigmaid: "",
      fusionid: "",
      mercyid: "",
      smalabid: "",
      ezid: "",
      solstasid: "",
      planname: "",

      typeErr: "",
      claimtype: "",
      payerNameErr: "",
      payerIDErr: "",
      machinetypeerr: "",
      CPIDsErr: "",
      TflErr: "",
      payerCategoryErr: "",
      payerCategory: "",
    });
  };

  getclients = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    axios
      .get(`${ip}/clientonboard/all`, { headers: headers })
      .then((res) => {
        if (res.data.clientonboard.length !== 0) {
          this.setState({
            clientslist: res.data.clientonboard,
          });
        } else {
          this.setState({
            clientslist: [],
          });
        }
      })
      .catch((err) => {});
  };

  toggledropdown = () => {
    this.setState({ opendropdown: !this.state.opendropdown });
  };
  // toogle modal for delete
  toggledelete = () => {
    this.setState({ deletemodal: !this.state.deletemodal });
  };
  //toggle for bulk delete
  toggledeletebulk = () => {
    this.setState({ bulkdeletemodal: !this.state.bulkdeletemodal });
  }
  //toggle modal for update
  toggleupdate = () => {
    this.setState({ updatemodal: !this.state.updatemodal });
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getpayer();
      }
    );
  };
  handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  handleSubmit = async () => {
    const isValid = this.PayerMasterValidate();

    if (isValid) {
      this.setState({ createmodal: !this.state.createmodal });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        payerName: this.state.payerName,
        CPIDs: this.state.CPIDs,
        authorizationRequired: this.state.authorizationRequired,
        payerassignedPayerID: this.state.payerassignedPayerID,
        routingMethod: this.state.routingMethod,
        RealtimePayerid: this.state.payerassignedPayerID,
        Tfl: this.state.Tfl,
        resubmit: this.state.reSubmit,
        type: this.state.claimtype,
        payerCategory: this.state.payerCategory,
        Machinetype: this.state.machinetype,
        payer_id: this.state.payerID,
        clientId: this.state.client,
        client: this.state.clientname,
        PayerPlanCode: this.state.PayerPlanCode,
        CombinedInsurance: this.state.inseurance,
      };
      // eslint-disable-next-line
      await axios
        .post(`${ip}/payermaster/create`, value, { headers: headers })
        .then((res) => {
          //this.getusers();
          this.getpayer();
          this.toggle();
          this.setState({ createmodal: !this.state.createmodal });
          toast.success("Payer Master created", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          if (err.response) {
            this.getpayer();
            this.toggle();
            this.setState({ createmodal: !this.state.createmodal });
            if (err.response.data.statusCode === 500) {
              toast.error("Server Error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else if (err.response.data.statusCode === 401) {
              toast.error("Please reload and try again", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else {
              toast.error("An unknown error occured", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }
          }
        });
    }
  };

  updatePayerMaster = async () => {
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      payerName: this.state.updatepayerName,
      PayerPlanCode: this.state.updatePayerPlanCode,
      CPIDs: this.state.updateCPIDs,
      Tfl: this.state.updateTfl,
      Machinetype: this.state.updatemachinetype,
      payer_id: this.state.updatepayerID,
      resubmit: this.state.updateresubmit,
      payerassignedPayerID: this.state.updatepayerassignedPayerID,
      authorizationRequired: this.state.updateauthorizationRequired,
      routingMethod: this.state.updateroutingMethod,
      type: this.state.updatetype,
    };

    await axios
      .put(`${ip}/payermaster/${this.state.id}`, value, {
        headers: headers,
      })
      .then((res) => {
        this.setState({ createmodal: !this.state.createmodal });
        this.toggleupdate();
        toast.info("Payer Master updated", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        this.getpayer();
      })
      .catch((err) => {
        if (err.response) {
          this.getpayer();
          this.toggleupdate();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };

  deleterequests = async (DataID) => {
    //api call to delete requests
    this.setState({ createmodal: !this.state.createmodal });

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const req = axios
      .delete(`${ip}/payermaster/${DataID}`, { headers: headers })
      .then((req) => {
        const requests = this.state.payer.filter((data) => data._id !== DataID);
        this.setState({ requests }, () => {});
        this.setState({ createmodal: !this.state.createmodal });
        this.toggledelete();
        this.getpayer();
        toast.error("Payer Master deleted", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.response) {
          this.getpayer();
          this.toggledelete();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  //get state names
  getstatename = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.client) {
      url = `${ip}/zipcodes/getstates?clientId=${this.state.client}`;
    } else {
      url = `${ip}/zipcodes/getstates`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          statearray: res.data.zipcodes,
        });
      })

      .catch((err) => {});
  };
  //bulk delete api
   bulkdelete = async (DataID) => {
    //api call to delete requests
    this.setState({ createmodal: !this.state.createmodal });

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const req = axios
      .delete(`${ip}/payermaster/delete/deleteclaims?clientId=${this.state.client}`, { headers: headers })
      .then((req) => {
        this.setState({ createmodal: !this.state.createmodal });
        this.toggledeletebulk();
        this.getpayer();
        toast.error("Payer Master deleted", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.response) {
          this.getpayer();
          this.toggledeletebulk();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  //getusers
  getpayer = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.client) {
      url = `${ip}/payermaster/all?clientId=${this.state.client}&pageno=${this.state.currentpage}`;
    } else {
      url = `${ip}/payermaster/all?pageno=${this.state.currentpage}`;
    }
    axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          pageCount: Math.ceil(res.data.count / this.state.perPage),
          payer: res.data.payer,
        });
      })

      .catch((err) => {});
  };
  onFileChange = (e) => {
    this.setState({ file: e.target.files[0] });
  };
  onFileUpload = async (e) => {
    if (!this.state.file) {
      // Display an error message or handle the validation as needed
      toast.error("Please select a file before uploading", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      return;
    }
    this.setState({ fileUploaded: !this.state.fileUploaded });
    e.preventDefault();
    const value = new FormData();
    value.append("file", this.fileInput.current.files[0]);
    value.append("clientids", JSON.stringify(this.state.client));
    const headers = {
      "Content-Type": `multipart/form-data`,
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    await axios({
      method: "post",
      url: `${ip}/payermaster/bulk/upload`,
      data: value,
      headers: headers,
    })
      .then((response) => {
        //handle success
        if (response.status === 200) {
          this.setState({ fileUploaded: !this.state.fileUploaded });
          toast.success("File uploaded successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
          this.getpayer();
        }
      })
      .catch((err) => {
        //handle error
        this.setState({ fileUploaded: !this.state.fileUploaded });
        if (err.response) {
          if (err.response.status === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 400) {
            toast.error("Please include all the fields in excel data", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  validate = () => {
    let hasErr = "true";
    let zipcodeErr = "";
    let billingmethodErr = "";
    let categoryErr = [];
    let updatemachineerr = "";
    let recvnameErr = "";

    if (this.state.zipcode === "") {
      zipcodeErr = "This field is required";
      this.setState({ zipcodeErr });
      hasErr = false;
    }
    if (this.state.billingmethod.length === 0) {
      billingmethodErr = "This field is required";
      this.setState({ billingmethodErr });
      hasErr = false;
    }

    if (this.state.category.length === 0) {
      categoryErr = "This field is required";
      this.setState({ categoryErr });
      hasErr = false;
    }
    if (this.state.recvname.length === 0) {
      recvnameErr = "This field is required";
      this.setState({ recvnameErr });
      hasErr = false;
    }
    return hasErr;
  };
  PayerMasterValidate = () => {
    let hasErr = "true";
    // let plancodeErr = "";
    let payerNameErr = "";
    // let inseuranceErr = "";
    let payerIDErr = "";
    let machinetypeerr = "";
    let CPIDsErr = "";
    let TflErr = "";
    let reSubmitErr = "";
    let routingMethodErr = "";
    // let payerassignedPayerIDErr = "";
    let typeErr = "";
    // let portalPayerIdErr = "";
    let payerCategoryErr = "";
    // let updatepayerassignedPayerIDErr = "";

    // if (this.state.payerassignedPayerID === "") {
    //   payerassignedPayerIDErr = "This field is required";
    //   this.setState({ payerassignedPayerIDErr });
    //   hasErr = false;
    // }
    if (this.state.claimtype === "") {
      typeErr = "This field is required";
      this.setState({ typeErr });
      hasErr = false;
    }
    // if (this.state.portalPayerId === "") {
    //   portalPayerIdErr = "This field is required";
    //   this.setState({ portalPayerIdErr });
    //   hasErr = false;
    // }

    if (this.state.payerCategory.length === 0) {
      payerCategoryErr = "This field is required";
      this.setState({ payerCategoryErr });
      hasErr = false;
    }
    // if (this.state.plancode === "") {
    //   plancodeErr = "This field is required";
    //   this.setState({ plancodeErr });
    //   hasErr = false;
    // }
    if (this.state.payerName === "") {
      payerNameErr = "This field is required";
      this.setState({ payerNameErr });
      hasErr = false;
    }
    // if (this.state.inseurance === "") {
    //   inseuranceErr = "This field is required";
    //   this.setState({ inseuranceErr });
    //   hasErr = false;
    // }
    if (this.state.CPIDs === "") {
      CPIDsErr = "This field is required";
      this.setState({ CPIDsErr });
      hasErr = false;
    }
    if (this.state.Tfl === "") {
      TflErr = "This field is required";
      this.setState({ TflErr });
      hasErr = false;
    }

    if (this.state.payerID === "") {
      payerIDErr = "This field is required";
      this.setState({ payerIDErr });
      hasErr = false;
    }

    if (this.state.machinetype === "") {
      machinetypeerr = "This field is required";
      this.setState({ machinetypeerr });
      hasErr = false;
    }
    // if (this.state.updatepayerassignedPayerID === "") {
    //   updatepayerassignedPayerIDErr = "This field is required";
    //   this.setState({ updatepayerassignedPayerIDErr });
    //   hasErr = false;
    // }
    // if (this.state.updatepayerassignedPayerIDErr === "") {
    //   inseuranceErr = "This field is required";
    //   this.setState({ inseuranceErr });
    //   hasErr = false;
    // }

    return hasErr;
  };
  updatevalidate = () => {
    let hasErr = "true";
    let firstnameErr = "";
    let lastnameErr = "";
    let phnoErr = "";
    let emailErr = "";

    if (this.state.updatefirstname === "") {
      firstnameErr = "This field is required";
      this.setState({ firstnameErr });
      hasErr = false;
    }
    if (this.state.updatelastname === "") {
      lastnameErr = "This field is required";
      this.setState({ lastnameErr });
      hasErr = false;
    }
    if (this.state.updatephno === "") {
      phnoErr = "This field is required";
      this.setState({ phnoErr });
      hasErr = false;
    } else if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
        this.state.updatephno
      )
    ) {
      phnoErr = "Invalid phone number";
      this.setState({ phnoErr });
      hasErr = false;
    }
    if (this.state.updateemail === "") {
      emailErr = "This field is required";
      this.setState({ emailErr });
      hasErr = false;
    } else if (
      // eslint-disable-next-line
      !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
        this.state.updateemail
      )
    ) {
      //var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      emailErr = "Invalid email address";
      this.setState({ emailErr });
      hasErr = false;
    }
    return hasErr;
  };

  //filter by payer id, insurance name
  handleSearch = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    if (this.state.querySearch === "") {
      toast.error("Please fill the search value!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      await axios
        .get(
          `${ip}/payermaster/advancedfilter?${this.state.searchBy}=${this.state.querySearch}&clientId=${this.state.client}`,
          { headers: headers }
        )
        .then((res) => {
          if (res) {
            toast.success("Searching completed!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.warning("Fetching data", {
              position: "top-right",
              hideProgressBar: false,
              pauseOnHover: true,
              progress: true,
            });
          }
          this.setState({ searchtext: 1 });
          this.setState({ search_result: res.data.payer });
          const slice = res.data.payer.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );
          this.setState({
            searchdataresponse: res.data.payer,
            pageCount: Math.ceil(res.data.payer.length / this.state.perPage),
            searchclaimdata: slice,
          });
        })
        .catch((err) => {});
    }
  };
  getcategory = async () => {
    //api call to get all cpt data
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    axios
      .get(`${ip}/payercategory/all`, { headers: headers })
      .then((res) => {
        this.setState({ categorydata: res.data.payer });
      })
      .catch((err) => {});
  };

  reset = () => {
    toast.info("All values displayed now!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
    });
    this.setState({
      payername: "",
      insurance: "",
      PayerPlanCode: "",
      payerid: "",
      machinetype: "",
      contactno: "",
      carriercode: "",
      //category:[],
      cat1: "",
      updatecat1: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      telph1: "",
      telph2: "",
      fax: "",
      email: "",
      billingmethod: "",
      // insurcategory:[],
      recvname: "",
      claimfilingindicator: "",
      plantype: "",
      incentivecategory: "",
      timelyfilinglimit1: "",
      timelyfilinglimit2: "",
      noofdays: "",
      partAclaimfiling: "",
      taxonomycode: "No",
      status: "Disabled",
      providerid: "No",
      sendreferringprovider: "No",
      billedbyEHI: "No",
      resendunappliedclaims: "No",
      sendpatientAddress: "No",
      billingonmins: false,
      sendCRNAID: false,
      checktaxonomyCode: false,
      participatingprovider: false,
      appealclaimaddress: false,
      eleclab: "",
      labcorpid: "",
      labcorplantype: "",
      medicallabid: "",
      questid: "",
      enzoid: "",
      propathid: "",
      biorefid: "",
      phelpsid: "",
      hldid: "",
      accurateid: "",
      chid: "",
      lencoid: "",
      machinetypeerr: "",
      applelabid: "",
      empirelabid: "",
      enigmaid: "",
      sheillabid: "",
      fusionid: "",
      everestid: "",
      mercyid: "",
      smalabid: "",
      ezid: "",
      solstasid: "",
      planname: "",
      // payerdata:[],
      // user:[],
      // categorydata:[],
      zipcodeErr: "",
      billingmethodErr: "",
      categoryErr: "",
      recvnameErr: "",
      PayerPlanCodeErr: "",
      payerNameErr: "",
      inseuranceErr: "",
      payerIDErr: "",
      CPIDsErr: "",
      TflErr: "",
      reSubmitErr: "",
      routingMethodErr: "",
      payerassignedPayerIDErr: "",
      typeErr: "",
      portalPayerIdErr: "",
      payerCategoryErr: "",
      updatepayerassignedPayerIDErr: "",

      // postcategory:[],
    });
  };

  reset = () => {
    toast.info("All values displayed now!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
    });
    this.setState({
      searchBy: "payer_id",
      querySearch: "",
      searchdataresponse: [],
      searchclaimdata: [],
      searchtext: "",
    });
    this.getpayer();
  };
  bulkmodal = () => {
    this.setState({
      bulkmodal: !this.state.bulkmodal,
    });
  };

  sample = () => {
    this.setState({
      sample: !this.state.sample,
    });
  };
  componentDidMount() {
    
    this.getpayer();
    this.getcategory();
    this.getclients();
    this.getstatename();
  }
  render() {
    return (
      <div>
      <Newsidebar  name="Payer Master" />
      <div className="align-row" style={{ overflow: "hidden" }}>
        <div className="align-column">
          <ToastContainer />
          <br />
          <div style={{display:"flex",justifyContent:"space-between"}}>
            <div>
              <div className="form__group">
                <select
                  className="drop-down__one"
                  value={this.state.searchBy}
                  onChange={(e) => this.setState({ searchBy: e.target.value })}
                >
                  <option value="CPIDs">CPIDs</option>

                  <option value="payer_id">Payer ID</option>
                  <option value="type">Type</option>
                  <option value="PayerPlanCode">Plan Code</option>

                  <option value="payerName">Payer Name </option>
                </select>
                <div className="main__search">
                  <input
                    type="text"
                    className="search__input"
                    value={this.state.querySearch}
                    onChange={(e) => {
                      this.setState({ querySearch: e.target.value });
                    }}
                  ></input>
                </div>
                <button style={{
                  border:"none",
                  padding:"3px",
                  backgroundColor:"#041c3c"
                }}
                onClick={this.handleSearch}>
                <MdOutlineSearch 
                style={{
                  color: "#fff",
                  fontSize: "25px",        
                }}/>
                </button>
                <button      onClick={this.reset}>
                <IoIosRefresh
                  style={{
                    color: "#041c3c",
                    fontSize: "25px",
                  }}
                ></IoIosRefresh>
                </button>
              </div>
            </div>
            <div>
                <div class="btn-group" role="group">
                  <button
                    type="button"
                    class="btn"
                    style={{
                      backgroundColor: "#041c3c",
                      color: "#fff",
                      fontSize: "12px",
                    }}
                    onClick={this.toggle}
                    size=""
                  >
                    + Payer Master
                    <br />
                  </button>
                  <button
                  class="btn bg-danger text-light"
                  type="button"
                  style={{
                    fontSize: "12px",
                  }}
                  onClick={this.toggledeletebulk}
                  size=""
                >
                  Bulk Delete
                  <br />
                </button>
                  <button
                    type="button"
                    class="btn"
                    onClick={this.bulkmodal}
                    style={{
                      backgroundColor: "#7DD657",
                      color: "#fff",
                      fontSize: "12px",
                    }}
                  >
                    Bulk Upload
                  </button>
                  <button
                    type="button"
                    class="btn"
                    style={{
                      backgroundColor: "#041c3c",
                      color: "#fff",
                      fontSize: "12px",
                    }}
                  >
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                        fontSize: "12px",
                      }}
                      to={Payermastersample}
                      download="Payermaster.xlsx"
                      target="_blank"
                    >
                      {" "}
                      Sample File
                    </Link>
                  </button>
                </div>
            </div>
          </div>
          <div>
            {this.state.payer.length === 0 ? (
              <div class="bot-ui">
                <div class="bot-head">
                  <div class="eyes-container">
                    <div class="to-left eye"></div>
                    <div class="to-right eye"></div>
                  </div>
                </div>
                <div class="bot-body">
                  <div class="analyzer"></div>
                </div>
                <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                  No Data Found
                </p>
              </div>
            ) : (
              <div>
                {this.state.searchtext === "" ? (
                  <div style={{ marginLeft: "3%", marginRight: "3%" }}>
                    <Table bordered className="StandardTable">
                      <thead>
                        <tr>
                          <th>Payer Category</th>
                          <th>Plan Code</th>
                          <th>Payer Name</th>
                          <th>CPIDs</th>
                          <th>TFL</th>
                          <th>Machine Type</th>
                          <th>Payer ID</th>
                          <th>ReSubmit</th>
                          <th>Payer Assigned PayerID</th>
                          <th>Routing Method</th>
                          <th>Type</th>
                         <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.payer &&
                          this.state.payer.map((data, index) => (
                            <tr key={data._id}>
                              <td component="th" scope="row">
                                {data.payerCategory}
                              </td>
                              <td>{data.PayerPlanCode}</td>
                              <td>{data.payerName}</td>
                              <td>{data.CPIDs}</td>
                              <td>{data.Tfl}</td>
                              <td>{data.Machinetype}</td>
                              <td>{data.payer_id}</td>
                              <td>{data.resubmit}</td>
                              <td>{data.payerassignedPayerID}</td>

                              <td>{data.routingMethod}</td>
                              <td>{data.type}</td>
                                <td>
                                  <button
                                    className="Edit"
                                    onClick={() => {
                                      this.setState({
                                        id: data._id,
                                        updatepayerName: data.payerName,
                                        updatePayerPlanCode: data.PayerPlanCode,
                                        updatepayercategory: data.payerCategory,
                                        updateCPIDs: data.CPIDs,
                                        updateTfl: data.Tfl,
                                        updatemachinetype: data.Machinetype,
                                        updatepayerID: data.payer_id,
                                        updateresubmit: data.resubmit,
                                        updatepayerassignedPayerID:
                                          data.payerassignedPayerID,
                                        updateauthorizationRequired:
                                          data.authorizationRequired,
                                        updateroutingMethod: data.routingMethod,
                                        updatetype: data.type,
                                        updatemodal: !this.state.updatemodal,
                                      });
                                    }}
                                  >
                                    Edit
                                  </button>
                                  &nbsp;
                                  <button
                                    className="Delete"
                                    onClick={() => {
                                      this.setState({ id: data._id }, () => {
                                        this.toggledelete();
                                      });
                                    }}
                                  >
                                    Delete
                                  </button>
                                </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    {this.state.searchdataresponse.length === 0 ? (
                      <div style={{ marginTop: "10%", fontSize: "25px" }}>
                        No data found
                      </div>
                    ) : (
                      <div>
                        <Table 
                        style={{marginLeft:"4%", marginRight:"4%"}}
                        bordered className="StandardTable">
                          <thead>
                            <tr>
                              <th>Payer Category</th>
                              <th>Plan Code</th>

                              <th>Payer Name</th>
                              <th>CPIDs</th>
                              <th>TFL</th>

                              <th>Machine Type</th>
                              <th>Payer ID</th>
                              <th>ReSubmit</th>
                              <th>Payer Assigned PayerID</th>

                              <th>Routing Method</th>
                              <th>Type</th>
                                <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.searchclaimdata &&
                              this.state.searchclaimdata.map((data, index) => (
                                <tr key={data._id}>
                                  <td component="th" scope="row">
                                    {data.payerCategory}
                                  </td>
                                  <td>{data.PayerPlanCode}</td>
                                  <td>{data.payerName}</td>
                                  <td>{data.CPIDs}</td>
                                  <td>{data.Tfl}</td>
                                  <td>{data.Machinetype}</td>
                                  <td>{data.payer_id}</td>
                                  <td>{data.resubmit}</td>
                                  <td>{data.payerassignedPayerID}</td>

                                  <td>{data.routingMethod}</td>
                                  <td>{data.type}</td>
                                    <td>
                                      <button
                                        className="Edit"
                                        onClick={() => {
                                          this.setState({
                                            updatepayerName: data.payerName,
                                            id: data._id,
                                            updatePayerPlanCode:
                                              data.PayerPlanCode,
                                            updateCPIDs: data.CPIDs,
                                            updateTfl: data.Tfl,
                                            updatemachinetype: data.Machinetype,
                                            updatepayerID: data.payer_id,
                                            updateresubmit: data.resubmit,
                                            updatepayerassignedPayerID:
                                              data.payerassignedPayerID,
                                            updateauthorizationRequired:
                                              data.authorizationRequired,
                                            updateroutingMethod:
                                              data.routingMethod,
                                            updatetype: data.type,
                                            updatemodal:
                                              !this.state.updatemodal,
                                          });
                                        }}
                                      >
                                        Edit
                                      </button>
                                      &nbsp;
                                      <button
                                        className="Delete"
                                        onClick={() => {
                                          this.setState(
                                            { id: data._id },
                                            () => {
                                              this.toggledelete();
                                            }
                                          );
                                        }}
                                      >
                                        Delete
                                      </button>
                                    </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                        <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                          <ReactPaginate
                            previousLabel={<IoIosArrowBack />}
                            nextLabel={<IoIosArrowForward />}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick1}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          {this.state.modal && (
            <Modal
              style={{ width: "100%", height: "90%" }}
              isOpen={this.state.modal}
              onHide={this.toggle}
              onExit={this.reset}
              centered
              animation="false"
              size="lg"
            >
              <ModalHeader toggle={this.toggle}>
                Create Insurance Company
              </ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Payer Plan Code</Label>
                        <Input
                          type="text"
                          value={this.state.PayerPlanCode}
                          onChange={(e) =>
                            this.setState({
                              PayerPlanCode: e.target.value,
                              // plancodeErr: "",
                            })
                          }
                        />
                        {/* <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.plancodeErr}
                        </div> */}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="required">Payer Name</Label>
                        <Input
                          type="text"
                          value={this.state.payerName}
                          onChange={(e) =>
                            this.setState({
                              payerName: e.target.value,
                              payerNameErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.payerNameErr}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Combined Insurance</Label>
                        <Input
                          type="text"
                          value={this.state.inseurance}
                          onChange={(e) =>
                            this.setState({
                              inseurance: e.target.value,
                              // inseuranceErr: "",
                            })
                          }
                        />
                        {/* <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.inseuranceErr}
                        </div> */}
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="required">Machine Type</Label>
                        <Input
                          type="text"
                          value={this.state.machinetype}
                          onChange={(e) =>
                            this.setState({
                              machinetype: e.target.value,
                              machinetypeerr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.machinetypeerr}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="required">Payer ID</Label>
                        <Input
                          type="text"
                          value={this.state.payerID}
                          onChange={(e) =>
                            this.setState({
                              payerID: e.target.value,
                              payerIDErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.payerIDErr}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="required">CPIDs</Label>
                        <Input
                          type="text"
                          value={this.state.CPIDs}
                          onChange={(e) =>
                            this.setState({
                              CPIDs: e.target.value,
                              CPIDsErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.CPIDsErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="required">TFL</Label>
                        <Input
                          type="number"
                          value={this.state.Tfl}
                          onChange={(e) =>
                            this.setState({ Tfl: e.target.value, TflErr: "" })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.TflErr}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Submit</Label>
                        <Input
                          type="number"
                          value={this.state.reSubmit}
                          onChange={(e) =>
                            this.setState({
                              reSubmit: e.target.value,
                              // reSubmitErr: "",
                            })
                          }
                        />
                        {/* <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.reSubmitErr}
                        </div> */}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Routing Method</Label>
                        <Input
                          type="text"
                          value={this.state.routingMethod}
                          onChange={(e) =>
                            this.setState({
                              routingMethod: e.target.value,
                              // routingMethodErr: "",
                            })
                          }
                        />
                        {/* <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.routingMethodErr}
                        </div> */}
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label> RealTime Payer ID</Label>
                        <Input
                          type="text"
                          value={this.state.payerassignedPayerID}
                          onChange={(e) =>
                            this.setState({
                              payerassignedPayerID: e.target.value,
                              // payerassignedPayerIDErr: "",
                            })
                          }
                        />
                        {/* <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.payerassignedPayerIDErr}
                        </div> */}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Portal Payer Id</Label>
                        <Input
                          type="text"
                          value={this.state.portalPayerId}
                          onChange={(e) =>
                            this.setState({
                              portalPayerId: e.target.value,
                              // portalPayerIdErr: "",
                            })
                          }
                        />
                        {/* <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.portalPayerIdErr}
                        </div> */}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="required"> Payer Category</Label>
                        {/* <Input
                          type="select"
                          className="form-control form-select"
                          placeholder="Select category"
                          value={this.state.payerCategory}
                          onChange={(e) =>
                            this.setState({
                              payerCategory: e.target.value,
                              payerCategoryErr: "",
                            })
                          }
                        >
                          {this.state.categorydata.length > 0 &&
                            this.state.categorydata.map((data, i) => {
                              <option hidden value="">
                                Select Payer Category
                              </option>;
                              return (
                                <option key={i} value={data.category_name}>
                                  {data.category_name}
                                </option>
                              );
                            })}
                        </Input> */}
                        <Typeahead
                          id="category"
                          options={this.state.categorydata.map(
                            (data) => data.category_name
                          )}
                          placeholder="Select category"
                          value={this.state.payerCategory}
                          onChange={(selected) => {
                            this.setState({
                              payerCategory: selected[0],
                              payerCategoryErr: "",
                            });
                          }}
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.payerCategoryErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup style={{ width: "31%" }}>
                        <Label className="required">Type</Label>
                        <Typeahead
                          id="typeahed1"
                          clearButton={true}
                          options={["Web", "IVR", "Manual"]} // An array of available options
                          placeholder="Select Type..." // Placeholder text for the input field
                          selected={
                            this.state.claimtype ? [this.state.claimtype] : []
                          } // Pass the selected option(s) as an array
                          onChange={(selected) => {
                            if (selected && selected.length > 0) {
                              this.setState({
                                claimtype: selected[0],
                                typeErr: "", // Update the selected option in state
                              });
                            } else {
                              this.setState({
                                claimtype: "",
                                typeErr: "", // Clear the selected option from state
                              });
                            }
                          }}
                        />

                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.typeErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <br />
                <Tabs
                  activeTab="1"
                  className="tabs"
                  ulClassName=""
                  activityClassName="bg-info"
                >
                  <Tab title="Address" className="mr-3">
                    <br />
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Address 1</Label>
                            <Input
                              type="text"
                              value={this.state.address1}
                              onChange={(e) =>
                                this.setState({
                                  address1: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Address 2</Label>
                            <Input
                              type="text"
                              value={this.state.address2}
                              onChange={(e) =>
                                this.setState({
                                  address2: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>City</Label>
                            <Input
                              type="text"
                              value={this.state.city}
                              onChange={(e) =>
                                this.setState({
                                  city: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>State</Label>
                            <Select
                              placeholder="Select State"
                              options={this.state.statearray}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  medicationErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Country</Label>
                            <Input
                              type="text"
                              value={this.state.country}
                              onChange={(e) =>
                                this.setState({
                                  country: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Zip Code</Label>
                            <Input
                              type="number"
                              value={this.state.zipcode}
                              onChange={(e) =>
                                this.setState({
                                  zipcode: e.target.value,
                                  zipcodeErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label></Label>
                            <Input
                              type="number"

                              //value={this.state.zipcode}
                              // onChange={(e) =>
                              //   this.setState({ zipcode: e.target.value, zipcodeErr: "" })
                              // }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.zipcodeErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Telephone 1</Label>
                            <Input
                              type="number"
                              value={this.state.telph1}
                              onChange={(e) =>
                                this.setState({
                                  telph1: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Telephone 2</Label>
                            <Input
                              type="number"
                              value={this.state.telph2}
                              onChange={(e) =>
                                this.setState({
                                  telph2: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Fax</Label>
                            <Input
                              type="number"
                              value={this.state.fax}
                              onChange={(e) =>
                                this.setState({
                                  fax: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Email</Label>
                            <Input
                              type="text"
                              value={this.state.email}
                              onChange={(e) =>
                                this.setState({
                                  email: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Tab>
                  <Tab title="EDI/Claim Settings" className="mr-3">
                    <br />
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label className="heading required">
                              Default Billing Method
                            </Label>
                            <Select
                              // className=" depselect"
                              placeholder="Select Default Billing Method"
                              options={this.state.categorydata}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  billingmethodErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.billingmethodErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col></Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label className="heading required">
                              Receiver Name
                            </Label>
                            <Select
                              // className=" depselect"
                              placeholder="Select Receiver Name"
                              options={this.state.categorydata}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  recvnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.recvnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Claim Filing Indicator</Label>
                            <Select
                              // className=" depselect"
                              placeholder="Select Claim Filing Indicator"
                              options={this.state.categorydata}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  medicationErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Program Plan Type</Label>
                            <Select
                              // className=" depselect"
                              placeholder="Select Program Plan Type"
                              options={this.state.categorydata}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  medicationErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Incentive Category</Label>
                            <Select
                              // className=" depselect"
                              placeholder="Select Incentive Category"
                              options={this.state.categorydata}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  medicationErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Timely Filing Limit (Original Claims)</Label>
                            <Input
                              type="number"
                              value={this.state.timelyfilinglimit1}
                              onChange={(e) =>
                                this.setState({
                                  timelyfilinglimit1: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>
                              Timely Filing Limit (Resubmitted Claims)
                            </Label>
                            <Input
                              type="number"
                              value={this.state.timelyfilinglimit2}
                              onChange={(e) =>
                                this.setState({
                                  timelyfilinglimit2: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>No.of Days</Label>
                            <Input
                              type="number"
                              value={this.state.noofdays}
                              onChange={(e) =>
                                this.setState({
                                  noofdays: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Part A Claims filing Indicator</Label>
                            <Select
                              // className=" depselect"
                              placeholder="Select Part A Claims filing Indicator"
                              options={this.state.categorydata}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  medicationErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label>
                                  Second Billing Provider <br /> Taxonomy Code
                                </Label>
                              </Col>
                              <Col md="6">
                                <Input
                                  type="select"
                                  value={this.state.taxonomycode}
                                  onChange={(e) =>
                                    this.setState({
                                      taxonomycode: e.target.value,
                                    })
                                  }
                                >
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <br />
                        <Col>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label>Status </Label>
                              </Col>
                              <Col md="6">
                                <Input
                                  type="select"
                                  value={this.state.status}
                                  onChange={(e) =>
                                    this.setState({
                                      status: e.target.value,
                                    })
                                  }
                                >
                                  <option value="Disabled">Disabled</option>
                                  <option value="Enabled">Enabled</option>
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label>
                                  Provider Service
                                  <br />
                                  Location Provider ID{" "}
                                </Label>
                              </Col>
                              <Col md="6">
                                <Input
                                  type="select"
                                  value={this.state.providerid}
                                  onChange={(e) =>
                                    this.setState({
                                      providerid: e.target.value,
                                    })
                                  }
                                >
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label>
                                  Send Default <br /> Referring Provider
                                </Label>
                              </Col>
                              <Col md="6">
                                <Input
                                  type="select"
                                  value={this.state.sendreferringprovider}
                                  onChange={(e) =>
                                    this.setState({
                                      sendreferringprovider: e.target.value,
                                    })
                                  }
                                >
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label>
                                  Billed By EHI For
                                  <br />
                                  RCM
                                </Label>
                              </Col>
                              <Col md="6">
                                <Input
                                  type="select"
                                  value={this.state.billedbyEHI}
                                  onChange={(e) =>
                                    this.setState({
                                      billedbyEHI: e.target.value,
                                    })
                                  }
                                >
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label>
                                  Resend Unapplied
                                  <br />
                                  Claims Automatically
                                </Label>
                              </Col>
                              <Col md="6">
                                <Input
                                  type="select"
                                  value={this.state.resendunappliedclaims}
                                  onChange={(e) =>
                                    this.setState({
                                      resendunappliedclaims: e.target.value,
                                    })
                                  }
                                >
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label>
                                  Send Patient Address for Home Service Location
                                </Label>
                              </Col>
                              <Col md="6">
                                <Input
                                  type="select"
                                  value={this.state.sendpatientAddress}
                                  onChange={(e) =>
                                    this.setState({
                                      sendpatientAddress: e.target.value,
                                    })
                                  }
                                >
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col></Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <br />
                            <Label>Billing on minutes</Label>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Input
                              type="checkbox"
                              name="billingonmins"
                              id="billingonmins"
                              value={this.state.billingonmins}
                              onChange={() => {
                                this.setState({
                                  billingonmins: !this.state.billingonmins,
                                  roleErr: "",
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <br />
                            <Label>Send CRNA Id</Label> &nbsp;&nbsp;&nbsp;&nbsp;
                            <Input
                              type="checkbox"
                              name="sendCRNAID"
                              id="sendCRNAID"
                              value={this.state.sendCRNAID}
                              onChange={() => {
                                this.setState({
                                  sendCRNAID: !this.state.sendCRNAID,
                                  roleErr: "",
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <br />
                            <Label>Taxonomy Code</Label>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Input
                              type="checkbox"
                              name="checktaxonomyCode"
                              id="checktaxonomyCode"
                              value={this.state.checktaxonomyCode}
                              onChange={() => {
                                this.setState({
                                  checktaxonomyCode:
                                    !this.state.checktaxonomyCode,
                                  roleErr: "",
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <br />
                            <Label>Participating Provider</Label>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Input
                              type="checkbox"
                              name="participatingprovider"
                              id="participatingprovider"
                              value={this.state.participatingprovider}
                              onChange={() => {
                                this.setState({
                                  participatingprovider:
                                    !this.state.participatingprovider,
                                  roleErr: "",
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <br />
                            <Label>Appeal Claim Address</Label>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Input
                              type="checkbox"
                              name="appealclaimaddress"
                              id="appealclaimaddress"
                              value={this.state.appealclaimaddress}
                              onChange={() => {
                                this.setState({
                                  appealclaimaddress:
                                    !this.state.appealclaimaddress,
                                  roleErr: "",
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col></Col>
                      </Row>
                    </Form>
                  </Tab>
                  <Tab title="Lab Setup" className="mr-3">
                    <br />
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Default Electronic Lab</Label>
                            <Input
                             type="select"
                              // className=" depselect"
                              placeholder="Select Default Electronic Lab"
                              options={this.state.categorydata}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  medicationErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col></Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>LabCorp ID</Label>
                            <Input
                              type="number"
                              value={this.state.labcorpid}
                              onChange={(e) =>
                                this.setState({
                                  labcorpid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>LabCorp Plan Type</Label>
                            <Input
                              type="text"
                              value={this.state.plantype}
                              onChange={(e) =>
                                this.setState({
                                  plantype: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Medical Lab ID</Label>
                            <Input
                              type="number"
                              required
                              value={this.state.medicallabid}
                              onChange={(e) =>
                                this.setState({
                                  medicallabid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Quest ID</Label>
                            <Input
                              type="number"
                              required
                              value={this.state.questid}
                              onChange={(e) =>
                                this.setState({
                                  questid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Enzoid</Label>
                            <Input
                              type="number"
                              value={this.state.enzoid}
                              onChange={(e) =>
                                this.setState({
                                  enzoid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Pro Path</Label>
                            <Input
                              type="number"
                              value={this.state.propathid}
                              onChange={(e) =>
                                this.setState({
                                  propathid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Bio Ref ID</Label>
                            <Input
                              type="number"
                              value={this.state.biorefid}
                              onChange={(e) =>
                                this.setState({
                                  biorefid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Phelps ID</Label>
                            <Input
                              type="number"
                              value={this.state.phelpsid}
                              onChange={(e) =>
                                this.setState({
                                  phelpsid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>HTL ID</Label>
                            <Input
                              type="number"
                              value={this.state.hldid}
                              onChange={(e) =>
                                this.setState({
                                  hldid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Accurate ID</Label>
                            <Input
                              type="number"
                              value={this.state.accurateid}
                              onChange={(e) =>
                                this.setState({
                                  accurateid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>CH Group ID</Label>
                            <Input
                              type="number"
                              value={this.state.chid}
                              onChange={(e) =>
                                this.setState({
                                  chid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Lencoid</Label>
                            <Input
                              type="number"
                              value={this.state.lencoid}
                              onChange={(e) =>
                                this.setState({
                                  lencoid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Apple Lab ID</Label>
                            <Input
                              type="number"
                              value={this.state.applelabid}
                              onChange={(e) =>
                                this.setState({
                                  applelabid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Empire Lab ID</Label>
                            <Input
                              type="number"
                              value={this.state.empirelabid}
                              onChange={(e) =>
                                this.setState({
                                  empirelabid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Enigma ID</Label>
                            <Input
                              type="number"
                              value={this.state.enigmaid}
                              onChange={(e) =>
                                this.setState({
                                  enigmaid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Shiel Lab ID</Label>
                            <Input
                              type="number"
                              value={this.state.sheillabid}
                              onChange={(e) =>
                                this.setState({
                                  sheillabid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Fusion ID</Label>
                            <Input
                              type="number"
                              value={this.state.fusionid}
                              onChange={(e) =>
                                this.setState({
                                  fusionid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Mercy ID</Label>
                            <Input
                              type="number"
                              value={this.state.mercyid}
                              onChange={(e) =>
                                this.setState({
                                  mercyid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>SMA Lab ID</Label>
                            <Input
                              type="number"
                              value={this.state.smalabid}
                              onChange={(e) =>
                                this.setState({
                                  smalabid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>EZ ID</Label>
                            <Input
                              type="number"
                              value={this.state.ezid}
                              onChange={(e) =>
                                this.setState({
                                  ezid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Solstas ID</Label>
                            <Input
                              type="number"
                              value={this.state.solstasid}
                              onChange={(e) =>
                                this.setState({
                                  solstasid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Tab>
                  <Tab title="Associate Plans" className="mr-3">
                    <br />
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            {/* <Label>Insurance/Payer Name</Label> */}
                            <Input
                              type="text"
                              placeholder="Plan Name"
                              value={this.state.planname}
                              onChange={(e) =>
                                this.setState({
                                  planname: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Tab>
                </Tabs>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggle();
                    this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={this.handleSubmit}
                >
                  Create
                </Button>
              </ModalFooter>
            </Modal>
          )}
          <Modal
            classsName="insuranceedit"
            isOpen={this.state.updatemodal}
            onHide={this.toggleupdate}
            onExit={this.reset}
            centered
            animation="false"
            size="lg"
          >
            <ModalHeader toggle={this.toggleupdate}>
              Edit Payer Master
            </ModalHeader>
            <ModalBody
              style={{
                "max-height": "calc(100vh - 210px)",
                "overflow-y": "auto",
              }}
              //  style={{
              //   "max-height": "calc(100vh - 210px)",
              //   "overflow-y": "auto",
              // }}
            >
              <Form>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Payer Name</Label>
                      <Input
                        type="text"
                        value={this.state.updatepayerName}
                        onChange={(e) =>
                          this.setState({
                            updatepayerName: e.target.value,
                            firstnameErr: "",
                          })
                        }
                      />
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.firstnameErr}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>CPIDs</Label>
                      <Input
                        type="number"
                        value={this.state.updateCPIDs}
                        onChange={(e) =>
                          this.setState({
                            updateCPIDs: e.target.value,
                            firstnameErr: "",
                          })
                        }
                      />
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.firstnameErr}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>TFL</Label>
                      <Input
                        type="number"
                        value={this.state.updateTfl}
                        onChange={(e) =>
                          this.setState({
                            updateTfl: e.target.value,
                            firstnameErr: "",
                          })
                        }
                      />
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.firstnameErr}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>Submit</Label>
                      <Input
                        type="number"
                        value={this.state.updateresubmit}
                        onChange={(e) =>
                          this.setState({
                            updateresubmit: e.target.value,
                            firstnameErr: "",
                          })
                        }
                      />
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.firstnameErr}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Routing Method</Label>
                      <Input
                        type="text"
                        value={this.state.updateroutingMethod}
                        onChange={(e) =>
                          this.setState({
                            updateroutingMethod: e.target.value,
                            firstnameErr: "",
                          })
                        }
                      />
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.firstnameErr}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>Authorization</Label>
                      <Input
                        type="number"
                        value={this.state.updateauthorizationRequired}
                        onChange={(e) =>
                          this.setState({
                            authorizationRequired: e.target.value,
                            firstnameErr: "",
                          })
                        }
                      />
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.firstnameErr}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>Payer Assinged ID</Label>
                      <Input
                        type="number"
                        value={this.state.updatepayerassignedPayerID}
                        onChange={(e) =>
                          this.setState({
                            updatepayerassignedPayerID: e.target.value,
                            updatepayerassignedPayerIDErr: "",
                          })
                        }
                      />
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.updatepayerassignedPayerIDErr}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>Batch and RealTime</Label>
                      <Input
                        type="number"
                        value={this.state.updatebatchAndRealTime}
                        onChange={(e) =>
                          this.setState({
                            batchAndRealTime: e.target.value,
                            firstnameErr: "",
                          })
                        }
                      />
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.firstnameErr}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Machine Type</Label>
                      <Input
                        type="text"
                        value={this.state.updatemachinetype}
                        onChange={(e) =>
                          this.setState({
                            updatemachinetype: e.target.value,
                            updatemachineerr: "",
                          })
                        }
                      />
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.updatemachineerr}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>Payer ID</Label>
                      <Input
                        type="text"
                        value={this.state.updatepayerID}
                        onChange={(e) =>
                          this.setState({
                            updatepayerID: e.target.value,
                            categoryErr: "",
                          })
                        }
                      />
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.categoryErr}
                      </div>
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label> Payer Category</Label>
                      <Input
                        type="select"
                        className="form-control form-select"
                        placeholder="Select category"
                        value={this.state.updatepayercategory}
                        onChange={(e) =>
                          this.setState({
                            updatepayercategory: e.target.value,
                            categoryErr: "",
                          })
                        }
                      >
                        {this.state.categorydata.length > 0 &&
                          this.state.categorydata.map((data, i) => {
                            <option hidden value="">
                              Select Payer Category
                            </option>;
                            return (
                              <option
                                key={i}
                                value={data.category_name}
                                selected={
                                  this.state.updatepayercategory ===
                                  data.category_name
                                }
                              >
                                {data.category_name}
                              </option>
                            );
                          })}
                        {/* {this.state.modifieroption} */}
                      </Input>
                      {/* <Select
                        placeholder="Select category"
                        options={this.state.categorydata}
                        values={this.state.category}
                        searchable={true}
                        dropdownHandle={true}
                        searchBy="category_name"
                        labelField="category_name"
                        noDataLabel="No matches found"
                        onChange={(values) =>
                          this.setState({
                            category: values,
                            cat1:values[0].category_name
                          })
                        }
                      /> */}
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.categoryErr}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Type</Label>
                      <Input
                        type="select"
                        className="form-control form-select"
                        value={this.state.updatetype}
                        onChange={(e) =>
                          this.setState({
                            updatetype: e.target.value,
                            categoryErr: "",
                          })
                        }
                      >
                        <option hidden value="">
                          Select Payer Category
                        </option>
                        <option
                          value="Web"
                          selected={this.state.updatetype === "Web"}
                        >
                          Web
                        </option>
                        <option
                          value="IVR"
                          selected={this.state.updatetype === "IVR"}
                        >
                          IVR
                        </option>
                        <option
                          value="Manual"
                          selected={this.state.updatetype === "Manual"}
                        >
                          Manual
                        </option>
                      </Input>
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.categoryErr}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
              <br />
              <Tabs
                activeTab="1"
                className="tabs"
                ulClassName=""
                activityClassName="bg-info"
              >
                <Tab title="Address" className="mr-3">
                  <br />
                  <Form>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Address 1</Label>
                          <Input
                            type="text"
                            value={this.state.updateaddress1}
                            onChange={(e) =>
                              this.setState({
                                updateaddress1: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Address 2</Label>
                          <Input
                            type="text"
                            value={this.state.updateaddress2}
                            onChange={(e) =>
                              this.setState({
                                updateaddress2: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>City</Label>
                          <Input
                            type="text"
                            value={this.state.updatecity}
                            onChange={(e) =>
                              this.setState({
                                updatecity: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>State</Label>
                          <Input
                           type="select"
                            // className=" depselect"
                            placeholder="Select State"
                            options={this.state.categorydata}
                            values={this.state.category}
                            searchable={true}
                            dropdownHandle={true}
                            searchBy="name"
                            labelField="name"
                            noDataLabel="No matches found"
                            onChange={(values) =>
                              this.setState({
                                category: values,
                                medicationErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Country</Label>
                          <Input
                            type="text"
                            value={this.state.updatecountry}
                            onChange={(e) =>
                              this.setState({
                                updatecountry: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label className="heading required">Zip Code</Label>
                          <Input
                            type="number"
                            value={this.state.updatezipcode}
                            onChange={(e) =>
                              this.setState({
                                updatezipcode: e.target.value,
                                zipcodeErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.zipcodeErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Telephone 1</Label>
                          <Input
                            type="text"
                            value={this.state.updatetelph1}
                            onChange={(e) =>
                              this.setState({
                                updatetelph1: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Telephone 2</Label>
                          <Input
                            type="number"
                            value={this.state.updatetelph2}
                            onChange={(e) =>
                              this.setState({
                                updatetelph2: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Fax</Label>
                          <Input
                            type="number"
                            value={this.state.updatefax}
                            onChange={(e) =>
                              this.setState({
                                updatefax: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Email</Label>
                          <Input
                            type="text"
                            value={this.state.updateemail}
                            onChange={(e) =>
                              this.setState({
                                updateemail: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </Tab>
                <Tab title="EDI/Claim Settings" className="mr-3">
                  <br />
                  <Form>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label className="heading required">
                            Default Billing Method
                          </Label>
                          <Select
                            // className=" depselect"
                            placeholder="Select Default Billing Method"
                            options={this.state.categorydata}
                            values={this.state.category}
                            searchable={true}
                            dropdownHandle={true}
                            searchBy="name"
                            labelField="name"
                            noDataLabel="No matches found"
                            onChange={(values) =>
                              this.setState({
                                category: values,
                                medicationErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col></Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label className="heading required">
                            Receiver Name
                          </Label>
                          <Select
                            // className=" depselect"
                            placeholder="Select Receiver Name"
                            options={this.state.categorydata}
                            values={this.state.category}
                            searchable={true}
                            dropdownHandle={true}
                            searchBy="name"
                            labelField="name"
                            noDataLabel="No matches found"
                            onChange={(values) =>
                              this.setState({
                                category: values,
                                medicationErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Claim Filing Indicator</Label>
                          <Select
                            // className=" depselect"
                            placeholder="Select Claim Filing Indicator"
                            options={this.state.categorydata}
                            values={this.state.category}
                            searchable={true}
                            dropdownHandle={true}
                            searchBy="name"
                            labelField="name"
                            noDataLabel="No matches found"
                            onChange={(values) =>
                              this.setState({
                                category: values,
                                medicationErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Program Plan Type</Label>
                          <Select
                            // className=" depselect"
                            placeholder="Select Program Plan Type"
                            options={this.state.categorydata}
                            values={this.state.category}
                            searchable={true}
                            dropdownHandle={true}
                            searchBy="name"
                            labelField="name"
                            noDataLabel="No matches found"
                            onChange={(values) =>
                              this.setState({
                                category: values,
                                medicationErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Incentive Category</Label>
                          <Select
                            // className=" depselect"
                            placeholder="Select Incentive Category"
                            options={this.state.categorydata}
                            values={this.state.category}
                            searchable={true}
                            dropdownHandle={true}
                            searchBy="name"
                            labelField="name"
                            noDataLabel="No matches found"
                            onChange={(values) =>
                              this.setState({
                                category: values,
                                medicationErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Timely Filing Limit (Original Claims)</Label>
                          <Input
                            type="number"
                            value={this.state.updatetimelyfilinglimit1}
                            onChange={(e) =>
                              this.setState({
                                updatetimelyfilinglimit1: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>
                            Timely Filing Limit (Resubmitted Claims)
                          </Label>
                          <Input
                            type="number"
                            value={this.state.updatetimelyfilinglimit2}
                            onChange={(e) =>
                              this.setState({
                                updatetimelyfilinglimit2: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>No.of Days</Label>
                          <Input
                            type="text"
                            value={this.state.updatenoofdays}
                            onChange={(e) =>
                              this.setState({
                                updatenoofdays: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Part A Claims filing Indicator</Label>
                          <Select
                            // className=" depselect"
                            placeholder="Select Part A Claims filing Indicator"
                            options={this.state.categorydata}
                            values={this.state.category}
                            searchable={true}
                            dropdownHandle={true}
                            searchBy="name"
                            labelField="name"
                            noDataLabel="No matches found"
                            onChange={(values) =>
                              this.setState({
                                category: values,
                                medicationErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col>
                        <FormGroup>
                          <Row>
                            <Col>
                              <Label>
                                Second Billing Provider <br /> Taxonomy Code
                              </Label>
                            </Col>
                            <Col md="6">
                              <Input
                                type="select"
                                value={this.state.updatetaxonomycode}
                                onChange={(e) =>
                                  this.setState({
                                    updatetaxonomycode: e.target.value,
                                  })
                                }
                              >
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                              </Input>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      <br />
                      <Col>
                        <FormGroup>
                          <Row>
                            <Col>
                              <Label>Status </Label>
                            </Col>
                            <Col md="6">
                              <Input
                                type="select"
                                value={this.state.updatestatus}
                                onChange={(e) =>
                                  this.setState({
                                    updatestatus: e.target.value,
                                  })
                                }
                              >
                                <option value="Disabled">Disabled</option>
                                <option value="Enabled">Enabled</option>
                              </Input>
                            </Col>
                          </Row>

                          {/* <div onChange={(e) =>
                          this.setState({ status: e.target.value, firstnameErr: "" })
                        }> */}
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Row>
                            <Col>
                              <Label>
                                Provider Service
                                <br />
                                Location Provider ID{" "}
                              </Label>
                            </Col>
                            <Col md="6">
                              <Input
                                type="select"
                                value={this.state.updateproviderid}
                                onChange={(e) =>
                                  this.setState({
                                    updateproviderid: e.target.value,
                                  })
                                }
                              >
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                              </Input>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Row>
                            <Col>
                              <Label>
                                Send Default <br /> Referring Provider
                              </Label>
                            </Col>
                            <Col md="6">
                              <Input
                                type="select"
                                value={this.state.updatesendreferringprovider}
                                onChange={(e) =>
                                  this.setState({
                                    updatesendreferringprovider: e.target.value,
                                  })
                                }
                              >
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                              </Input>
                            </Col>
                          </Row>
                          {/* <br/>
                    <div onChange={(e) =>
                         <Label>Send Default <br/> Referring Provider</Label>  
                          this.setState({ sendreferringprovider: e.target.value, firstnameErr: "" })
                        }> */}
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Row>
                            <Col>
                              <Label>
                                Billed By EHI For
                                <br />
                                RCM
                              </Label>
                            </Col>
                            <Col md="6">
                              <Input
                                type="select"
                                value={this.state.updatebilledbyEHI}
                                onChange={(e) =>
                                  this.setState({
                                    updatebilledbyEHI: e.target.value,
                                  })
                                }
                              >
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                              </Input>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Row>
                            <Col>
                              <Label>
                                Resend Unapplied
                                <br />
                                Claims Automatically
                              </Label>
                            </Col>
                            <Col md="6">
                              <Input
                                type="select"
                                value={this.state.updateresendunappliedclaims}
                                onChange={(e) =>
                                  this.setState({
                                    updateresendunappliedclaims: e.target.value,
                                  })
                                }
                              >
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                              </Input>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Row>
                            <Col>
                              <Label>
                                Send Patient Address for Home Service Location
                              </Label>
                            </Col>
                            <Col md="6">
                              <Input
                                type="select"
                                value={this.state.updatesendpatientAddress}
                                onChange={(e) =>
                                  this.setState({
                                    updatesendpatientAddress: e.target.value,
                                  })
                                }
                              >
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                              </Input>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      <Col></Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <br />
                          <Label>Billing on minutes</Label>{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <Input
                            type="checkbox"
                            name="billingonmins"
                            id="billingonmins"
                            checked={this.state.updatebillingonmins}
                            value={this.state.updatebillingonmins}
                            onChange={() => {
                              this.setState({
                                updatebillingonmins:
                                  !this.state.updatebillingonmins,
                                roleErr: "",
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <br />
                          <Label>Send CRNA Id</Label> &nbsp;&nbsp;&nbsp;&nbsp;
                          <Input
                            type="checkbox"
                            name="sendCRNAId"
                            id="sendCRNAId"
                            checked={this.state.updatesendCRNAID}
                            value={this.state.updatesendCRNAID}
                            onChange={() => {
                              this.setState({
                                updatesendCRNAID: !this.state.updatesendCRNAID,
                                roleErr: "",
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <br />
                          <Label>Taxonomy Code</Label> &nbsp;&nbsp;&nbsp;&nbsp;
                          <Input
                            type="checkbox"
                            name="checktaxonomyCode"
                            id="checktaxonomyCode"
                            checked={this.state.updatechecktaxonomyCode}
                            value={this.state.updatechecktaxonomyCode}
                            onChange={() => {
                              this.setState({
                                updatechecktaxonomyCode:
                                  !this.state.updatechecktaxonomyCode,
                                roleErr: "",
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <br />
                          <Label>Participating Provider</Label>{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <Input
                            type="checkbox"
                            name="participatingprovider"
                            id="participatingprovider"
                            checked={this.state.updateparticipatingprovider}
                            value={this.state.updateparticipatingprovider}
                            onChange={() => {
                              this.setState({
                                updateparticipatingprovider:
                                  !this.state.updateparticipatingprovider,
                                roleErr: "",
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <br />
                          <Label>Appeal Claim Address</Label>{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <Input
                            type="checkbox"
                            name="appealclaimaddress"
                            id="appealclaimaddress"
                            checked={this.state.updateappealclaimaddress}
                            value={this.state.updateappealclaimaddress}
                            onChange={() => {
                              this.setState({
                                updateappealclaimaddress:
                                  !this.state.updateappealclaimaddress,
                                roleErr: "",
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col></Col>
                    </Row>
                  </Form>
                </Tab>
                <Tab title="Lab Setup" className="mr-3">
                  <br />
                  <Form>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Default Electronic Lab</Label>
                          <Select
                            // className=" depselect"
                            placeholder="Select Default Electronic Lab"
                            options={this.state.categorydata}
                            values={this.state.category}
                            searchable={true}
                            dropdownHandle={true}
                            searchBy="name"
                            labelField="name"
                            noDataLabel="No matches found"
                            onChange={(values) =>
                              this.setState({
                                category: values,
                                medicationErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col></Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>LabCorp ID</Label>
                          <Input
                            type="number"
                            value={this.state.updatelabcorpid}
                            onChange={(e) =>
                              this.setState({
                                updatelabcorpid: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>LabCorp Plan Type</Label>
                          <Input
                            type="text"
                            value={this.state.updatelabcorplantype}
                            onChange={(e) =>
                              this.setState({
                                updatelabcorplantype: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Medical Lab ID</Label>
                          <Input
                            type="number"
                            value={this.state.updatemedicallabid}
                            onChange={(e) =>
                              this.setState({
                                updatemedicallabid: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Quest ID</Label>
                          <Input
                            type="number"
                            value={this.state.updatequestid}
                            onChange={(e) =>
                              this.setState({
                                updatequestid: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Enzoid</Label>
                          <Input
                            type="number"
                            value={this.state.updateenzoid}
                            onChange={(e) =>
                              this.setState({
                                updateenzoid: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Pro Path</Label>
                          <Input
                            type="number"
                            value={this.state.updatepropathid}
                            onChange={(e) =>
                              this.setState({
                                updatepropathid: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Bio Ref ID</Label>
                          <Input
                            type="number"
                            value={this.state.updatebiorefid}
                            onChange={(e) =>
                              this.setState({
                                updatebiorefid: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Phelps ID</Label>
                          <Input
                            type="number"
                            value={this.state.updatephelpsid}
                            onChange={(e) =>
                              this.setState({
                                updatephelpsid: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>HTL ID</Label>
                          <Input
                            type="number"
                            value={this.state.updatehldid}
                            onChange={(e) =>
                              this.setState({
                                updatehldid: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Accurate ID</Label>
                          <Input
                            type="number"
                            value={this.state.updateaccurateid}
                            onChange={(e) =>
                              this.setState({
                                updateaccurateid: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>CH Group ID</Label>
                          <Input
                            type="number"
                            value={this.state.updatechid}
                            onChange={(e) =>
                              this.setState({
                                updatechid: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Lencoid</Label>
                          <Input
                            type="number"
                            value={this.state.updatelencoid}
                            onChange={(e) =>
                              this.setState({
                                updatelencoid: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Apple Lab ID</Label>
                          <Input
                            type="number"
                            value={this.state.updateapplelabid}
                            onChange={(e) =>
                              this.setState({
                                updateapplelabid: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Empire Lab ID</Label>
                          <Input
                            type="number"
                            value={this.state.updateempirelabid}
                            onChange={(e) =>
                              this.setState({
                                updateempirelabid: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Enigma ID</Label>
                          <Input
                            type="number"
                            value={this.state.updateenigmaid}
                            onChange={(e) =>
                              this.setState({
                                updateenigmaid: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Shiel Lab ID</Label>
                          <Input
                            type="number"
                            value={this.state.updatesheillabid}
                            onChange={(e) =>
                              this.setState({
                                updatesheillabid: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Fusion ID</Label>
                          <Input
                            type="number"
                            value={this.state.updatefusionid}
                            onChange={(e) =>
                              this.setState({
                                updatefusionid: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Mercy ID</Label>
                          <Input
                            type="number"
                            value={this.state.updatemercyid}
                            onChange={(e) =>
                              this.setState({
                                updatemercyid: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>SMA Lab ID</Label>
                          <Input
                            type="number"
                            value={this.state.updatesmalabid}
                            onChange={(e) =>
                              this.setState({
                                updatesmalabid: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>EZ ID</Label>
                          <Input
                            type="number"
                            value={this.state.updateezid}
                            onChange={(e) =>
                              this.setState({
                                updateezid: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Solstas ID</Label>
                          <Input
                            type="number"
                            value={this.state.updatesolstasid}
                            onChange={(e) =>
                              this.setState({
                                updatesolstasid: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </Tab>
                <Tab title="Associate Plans" className="mr-3">
                  <br />
                  <Form>
                    <Row>
                      <Col>
                        <FormGroup>
                          {/* <Label>Insurance/Payer Name</Label> */}
                          <Input
                            type="text"
                            placeholder="Plan Name"
                            value={this.state.updateplanname}
                            onChange={(e) =>
                              this.setState({
                                updateplanname: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </Tab>
              </Tabs>
            </ModalBody>
            <ModalFooter>
              <Button
                style={{
                  border: "1px solid grey",
                  color: "black",
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  this.toggleupdate();
                  this.reset();
                }}
              >
                Cancel
              </Button>
              <Button
                style={{ backgroundColor: "black", color: "white" }}
                onClick={this.updatePayerMaster}
              >
                Update
              </Button>
            </ModalFooter>
          </Modal>
          {this.state.deletemodal && (
            <Modal
              isOpen={this.state.deletemodal}
              onHide={this.toggledelete}
              // onExit={this.reloadpage}
              centered
            >
              <ModalHeader toggle={this.toggledelete}>
                Delete Master
              </ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Row style={{ margin: "0px" }}>Do you want to delete this?</Row>

                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid gray",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={this.toggledelete}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "rgb(25,25,25)" }}
                  onClick={() => {
                    this.deleterequests(this.state.id);
                  }}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
          )}
          {this.state.bulkdeletemodal && (
            <Modal
              isOpen={this.state.bulkdeletemodal}
              onHide={this.toggledeletebulk}
              // onExit={this.reloadpage}
              centered
            >
              <ModalHeader toggle={this.toggledeletebulk}>
                Delete Master
              </ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Row style={{ margin: "0px" }}>Do you want to delete this?</Row>

                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid gray",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={this.toggledeletebulk}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "rgb(25,25,25)" }}
                  onClick={() => {
                    this.bulkdelete();
                  }}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
          )}
          <Modal isOpen={this.state.createmodal} centered>
            <ModalBody>
              <img
                src={loading}
                style={{ width: "200px", height: "200px", marginLeft: "30%" }}
              />
            </ModalBody>
          </Modal>
        </div>
        {this.state.bulkmodal && (
          <Modal
            isOpen={this.state.bulkmodal}
            onHide={this.bulkmodal}
            // onExit={this.reloadpage}
            centered
            size="xl"
          >
            <ModalHeader toggle={this.bulkmodal}>Bulk Upload</ModalHeader>
            <ModalBody
              style={{
                "max-height": "calc(100vh - 210px)",
                "overflow-y": "auto",
              }}
            >
              <div className="align-row items">
                <form
                  method="post"
                  enctype="multipart/form-data"
                  style={{ width: "17%" }}
                >
                  <div class="files">
                    <input
                      type="file"
                      id="file"
                      name="file"
                      required
                      ref={this.fileInput}
                      onChange={this.onFileChange}
                      style={{ display: "none" }}
                      accept=".xlsx"
                    />
                    <label for="file" className="filelabel">
                      {this.state.file ? (
                        <p align="center" style={{ color: "green" }}>
                          {this.state.file.name}
                        </p>
                      ) : (
                        <a className="dropfile">Select files here or browse</a>
                      )}
                    </label>
                  </div>
                </form>
              </div>
              <br />
            </ModalBody>
            <ModalFooter>
              <Button
                style={{
                  border: "1px solid gray",
                  color: "black",
                  backgroundColor: "#fff",
                }}
                onClick={this.bulkmodal}
              >
                Cancel
              </Button>
              <Button
                style={{
                  border: "1px solid gray",
                  color: "black",
                  backgroundColor: "#fff",
                }}
                onClick={this.onFileUpload}
                className="uploadcss"
              >
                Upload
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
      </div>
    );
  }
}
export default PayerMaster;

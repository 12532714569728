import React, { createContext, useState, useContext } from 'react';

const UploadContext = createContext();

export const useUpload = () => useContext(UploadContext);

export const UploadProvider = ({ children }) => {
  const [progress, setProgress] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [notInserted, setNotInserted] = useState(0);
  const [inserted, setInserted] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [duration, setDuration] = useState(0);
  const [status, setStatus] = useState('');
  const [filename, setFileName] = useState('');

  const toggleCard = () => {
    setIsVisible(!isVisible);
  };

  const value = {
    progress,
    setProgress,
    isVisible,
    toggleCard,
    notInserted,
    setNotInserted,
    inserted,
    setInserted,
    totalCount,
    setTotalCount,
    duration,
    setDuration,
    status,
    setStatus,
    filename,
    setFileName,
  };

  return (
    <UploadContext.Provider value={value}>
      {children}
    </UploadContext.Provider>
  );
};

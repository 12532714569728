import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { ip } from "../../../Api";
import axios from "axios";
let tooltip;
let url = null;
const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;
  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

const getPath = (x, y, width, height) => `M${x},${y + height}
    C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${
  x + width / 2
}, ${y}
    C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
  x + width
}, ${y + height}
    Z`;
const data2 = [
  { _id: "", totalAmount: 0, totalClaims: 0 },
  { _id: "", totalAmount: 0, totalClaims: 0 },
  { _id: "", totalAmount: 0, totalClaims: 0 },
  { _id: "", totalAmount: 0, totalClaims: 0 },
  { _id: "", totalAmount: 0, totalClaims: 0 },
];
const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};
const ClaimTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;{payload[0].value}
        </p>
      </div>
    );
  }
  return null;
};
export default class DeptCharts extends PureComponent {
  state = {
    payer_class_wise: [],
    refreshtoken: sessionStorage.getItem("refreshtoken"),
    accesstoken: sessionStorage.getItem("accesstoken"),
  };

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getDetails = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.from && this.props.to) {
          url = `${ip}/outcome/payerclasswise?start_date=${this.props.from}&end_date=${this.props.to}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/outcome/payerclasswise?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.from && this.props.to) {
          url = `${ip}/outcome/payerclasswise?start_date=${this.props.from}&end_date=${this.props.to}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/outcome/payerclasswise?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.from && this.props.to) {
        url = `${ip}/outcome/payerclasswise?start_date=${this.props.from}&end_date=${this.props.to}`;
      } else {
        url = `${ip}/outcome/payerclasswise`;
      }
    }
    const req = axios
      .get(url, { headers: headers })
      .then((req) => {
        let i;
        let data = [];
        if (req.data.payerclass_wise.denied.length !== 0) {
          for (i = 0; i < 5; i++) {
            data.push(req.data.payerclass_wise.denied[i]);
          }
          this.setState({ payer_class_wise: data });
        } else {
          this.setState({ payer_class_wise: data2 });
        }
      })
      .catch((err) => {});
  };
  componentDidMount() {
    
    this.getDetails();
  }
  componentDidUpdate(prevProps) {
    if (
      !(this.props.from === prevProps.from && this.props.to === prevProps.to)
    ) {
      this.getDetails();
    } else if (this.props.client !== prevProps.client) {
      this.getDetails();
    } else if (this.props.Location !== prevProps.Location) {
      this.getDetails();
    }
  }

  render() {
    return (
      <div>
        {this.props.deptwise === "$" && (
          <div onMouseOver={() => (tooltip = "totalAmount")}>
            <BarChart
              width={700}
              height={400}
              data={this.state.payer_class_wise}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis dataKey={"_id"} height={120} interval={0} angle={"-45"} minTickGap={6} tick={{strokeWidth:0,fontSize:"11",top:400,textAnchor:"end",dy:6}} />
              <YAxis dataKey={"totalAmount"} />
              <Tooltip
                cursor={{ fill: "transparent" }}
                content={<CustomTooltip />}
              />
              <Bar
                name="Total Amount"
                dataKey={"totalAmount"}
                shape={<TriangleBar />}
                radius={[16, 16, 16, 16]}
                fill="#00A0FC"
                barSize={30}
              />
            </BarChart>
          </div>
        )}
        {this.props.deptwise === "#" && (
          <div onMouseOver={() => (tooltip = "totalClaims")}>
            <BarChart
              width={700}
              height={400}
              data={this.state.payer_class_wise}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis dataKey={"_id"} height={120} interval={0} angle={"-45"} minTickGap={6} tick={{strokeWidth:0,fontSize:"11",top:400,textAnchor:"end",dy:6}} />
              <YAxis dataKey={"totalClaims"} />
              <Tooltip
                cursor={{ fill: "transparent" }}
                content={<ClaimTooltip />}
              />
              <Bar
                name="Total Claims"
                dataKey={"totalClaims"}
                shape={<TriangleBar />}
                radius={[16, 16, 16, 16]}
                fill="#00A0FC"
                barSize={30}
              />
            </BarChart>
          </div>
        )}
      </div>
    );
  }
}
